// freelancer-reducer.js
import { FREELANCERUPDATEDETAILS, FREELANCER_DETAILS, FREELANCER_GET, PAGEFREELANCER, SEARCHFREELANCER, SEARCHVALUE } from "../action/freelancer-action";
import { CERTIFICATION_SEARCHVALUE, CITY_JOB_SEARCH, CITY_SEARCHVALUE, COMPANY_NAME_JOB_SEARCH, COMPANY_NAME_SEARCHVALUE, COUNTRY_JOB_SEARCH, COUNTRY_SEARCHVALUE, EXPERIENCE_SEARCHVALUE, FUNCTIONALAREA_JOB_SEARCH, FUNCTIONAL_AREA_SEARCHVALUE, INDUSTRY_JOB_SEARCH, INDUSTRY_SEARCHVALUE, JOBSEARCHVALUE, JOB_DETAILS, JOB_GET, JOB_PAGE, JOB_SEARCH, JOB_SEARCH_CHANGE, JOB__SERACH_GET, SKILL_JOB_SEARCH, STATE_SEARCHVALUE, TYPE_SEARCHVALUE } from "../action/job-action";

const initialState = {
 job_get: [],
job_details: [],
  loading: true,
   job_search: [],
 job_page: [],
 second_job_page:[],
  total_pages: null, // Total pages for all freelancers
  total_pages_search: null, // Total pages for search results
  searchPerformed: false,
  //ama value searchaka akama nawe bo away la parame pageka bakare bhenm taku ka pagem gore natejay searchaka bda
  search_value:null,
   second_search_value:null,
   third_search_value:null,
   fourth_search_value:null,
   fifth_search_value:null,
   sixth_search_value:null,
   seventh_search_value:null,
   eighth_search_value:null,
   ninth_search_value:null,
   tenth_search_value:null,

  freelancer_update_details:null,
  job_search_get: [],
  job_search_change: [],
  total_jobs:[]
};

export const job_reducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_GET:
      return {
        ...state,
        job_get: action.payload.data,
        total_pages: action.payload.total_pages,
        total_jobs: action.payload.total_jobs,

        loading: false
      };

      case JOB__SERACH_GET:
        return {
          ...state,
          job_search_get: action.payload.data,
          total_pages: action.payload.total_pages,
          loading: false
        };
        

      case JOB_DETAILS:
      return {
        ...state,
        job_details: action.payload.data,
        loading: false
      };
      case JOB_SEARCH_CHANGE:
        return {
          ...state,
          job_search_change: action.payload.data,
          total_pages: action.payload.total_pages,
          loading: false
        };
    case JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
        case FUNCTIONALAREA_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
         case INDUSTRY_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
       case SKILL_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
        case COUNTRY_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
        case CITY_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
        case COMPANY_NAME_JOB_SEARCH:
      return {
        ...state,
        job_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };

      case JOB_SEARCH:
        return {
          ...state,
          job_search: action.payload.data,
          total_pages_search: action.payload.total_pages,
          searchPerformed: true
        };
    case JOB_PAGE:
      return {
        ...state,
        job_page: action.payload.data
      };
      
      //update freelancer 
       case FREELANCERUPDATEDETAILS:
      return {
        ...state,
        freelancer_update_details: action.payload.data
      };
      
      case JOBSEARCHVALUE:
      return {
        ...state,
        search_value: action.search
      };
       case FUNCTIONAL_AREA_SEARCHVALUE:
      return {
        ...state,
        second_search_value: action.search
      };
          case INDUSTRY_SEARCHVALUE:
      return {
        ...state,
        third_search_value: action.search
      };
            case STATE_SEARCHVALUE:
      return {
        ...state,
        fourth_search_value: action.search
      };

      case EXPERIENCE_SEARCHVALUE:
        return {
          ...state,
          eighth_search_value: action.search
        };


        case TYPE_SEARCHVALUE:
          return {
            ...state,
            ninth_search_value: action.search
          };

          case CERTIFICATION_SEARCHVALUE:
            return {
              ...state,
              tenth_search_value: action.search
            };
  




          case COUNTRY_SEARCHVALUE:
      return {
        ...state,
        fifth_search_value: action.search
      };
      case CITY_SEARCHVALUE:
      return {
        ...state,
        sixth_search_value: action.search
      };
       case COMPANY_NAME_SEARCHVALUE:
      return {
        ...state,
        seventh_search_value: action.search
      };
      
      
    default:
      return state;
  }
};
