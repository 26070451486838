import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Work_experience_action,
  course_action,
} from "../../../redux/action/action";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Course = () => {
  const [title, setTitle] = useState([]);
  const [year, setYear] = useState([]);
  const [description, setDescription] = useState([]);
  const [inputCount, setInputCount] = useState(1);

  //change data state
  const [course_title_state, setCourse_title_state] = useState([]); // Initialize skillsData with empty array
  const [course_year_state, setCourse_year_state] = useState([]); // Initialize skillsData with empty array
  const [course_description_state, setCourse_description_state] = useState([]); // Initialize skillsData with empty array

  const course_title = useSelector((state) => state.main.course_title);
  const course_year = useSelector((state) => state.main.course_year);
  const course_description = useSelector(
    (state) => state.main.course_description
  );
  const dispatch = useDispatch();
  const [skillsData, setSkillsData] = useState([]); // Initialize skillsData with empty array
  // For cv1_data
  const data1 = useSelector((state) => state.cv.cv1_details);
  const course_title_data1 = data1 ? data1.course_title : "";
  const course_year_data1 = data1 ? data1.course_year : "";
  const course_description_data1 = data1 ? data1.course_description : "";

  // For cv2_data
  const data2 = useSelector((state) => state.cv.cv2_details);
  const course_title_data2 = data2 ? data2.course_title : "";
  const course_year_data2 = data2 ? data2.course_year : "";
  const course_description_data2 = data2 ? data2.course_description : "";

  // For cv3_data
  const data3 = useSelector((state) => state.cv.cv3_details);
  const course_title_data3 = data3 ? data3.course_title : "";
  const course_year_data3 = data3 ? data3.course_year : "";
  const course_description_data3 = data3 ? data3.course_description : "";

  // For cv4_data
  const data4 = useSelector((state) => state.cv.cv4_details);
  const course_title_data4 = data4 ? data4.course_title : "";
  const course_year_data4 = data4 ? data4.course_year : "";
  const course_description_data4 = data4 ? data4.course_description : "";

  // For cv5_data
  const data5 = useSelector((state) => state.cv.cv5_details);
  const course_title_data5 = data5 ? data5.course_title : "";
  const course_year_data5 = data5 ? data5.course_year : "";
  const course_description_data5 = data5 ? data5.course_description : "";

  // For cv6_data
  const data6 = useSelector((state) => state.cv.cv6_details);
  const course_title_data6 = data6 ? data6.course_title : "";
  const course_year_data6 = data6 ? data6.course_year : "";
  const course_description_data6 = data6 ? data6.course_description : "";

  // For cv7_data
  const data7 = useSelector((state) => state.cv.cv7_details);
  const course_title_data7 = data7 ? data7.course_title : "";
  const course_year_data7 = data7 ? data7.course_year : "";
  const course_description_data7 = data7 ? data7.course_description : "";

  // For cv8_data
  const data8 = useSelector((state) => state.cv.cv8_details);
  const course_title_data8 = data8 ? data8.course_title : "";
  const course_year_data8 = data8 ? data8.course_year : "";
  const course_description_data8 = data8 ? data8.course_description : "";

  // For cv9_data
  const data9 = useSelector((state) => state.cv.cv9_details);
  const course_title_data9 = data9 ? data9.course_title : "";
  const course_year_data9 = data9 ? data9.course_year : "";
  const course_description_data9 = data9 ? data9.course_description : "";

  // For cv10_data
  const data10 = useSelector((state) => state.cv.cv10_details);
  const course_title_data10 = data10 ? data10.course_title : "";
  const course_year_data10 = data10 ? data10.course_year : "";
  const course_description_data10 = data10 ? data10.course_description : "";

  // For cv11_data
  const data11 = useSelector((state) => state.cv.cv11_details);
  const course_title_data11 = data11 ? data11.course_title : "";
  const course_year_data11 = data11 ? data11.course_year : "";
  const course_description_data11 = data11 ? data11.course_description : "";

  // For cv12_data
  const data12 = useSelector((state) => state.cv.cv12_details);
  const course_title_data12 = data12 ? data12.course_title : "";
  const course_year_data12 = data12 ? data12.course_year : "";
  const course_description_data12 = data12 ? data12.course_description : "";

  // For cv13_data
  const data13 = useSelector((state) => state.cv.cv13_details);
  const course_title_data13 = data13 ? data13.course_title : "";
  const course_year_data13 = data13 ? data13.course_year : "";
  const course_description_data13 = data13 ? data13.course_description : "";

  // For cv14_data
  const data14 = useSelector((state) => state.cv.cv14_details);
  const course_title_data14 = data14 ? data14.course_title : "";
  const course_year_data14 = data14 ? data14.course_year : "";
  const course_description_data14 = data14 ? data14.course_description : "";

  // For cv15_data
  const data15 = useSelector((state) => state.cv.cv15_details);
  const course_title_data15 = data15 ? data15.course_title : "";
  const course_year_data15 = data15 ? data15.course_year : "";
  const course_description_data15 = data15 ? data15.course_description : "";

  // For cv16_data
  const data16 = useSelector((state) => state.cv.cv16_details);
  const course_title_data16 = data16 ? data16.course_title : "";
  const course_year_data16 = data16 ? data16.course_year : "";
  const course_description_data16 = data16 ? data16.course_description : "";

  // For cv17_data
  const data17 = useSelector((state) => state.cv.cv17_details);
  const course_title_data17 = data17 ? data17.course_title : "";
  const course_year_data17 = data17 ? data17.course_year : "";
  const course_description_data17 = data17 ? data17.course_description : "";

  // For cv18_data
  const data18 = useSelector((state) => state.cv.cv18_details);
  const course_title_data18 = data18 ? data18.course_title : "";
  const course_year_data18 = data18 ? data18.course_year : "";
  const course_description_data18 = data18 ? data18.course_description : "";

  // For cv19_data
  const data19 = useSelector((state) => state.cv.cv19_details);
  const course_title_data19 = data19 ? data19.course_title : "";
  const course_year_data19 = data19 ? data19.course_year : "";
  const course_description_data19 = data19 ? data19.course_description : "";

  // For cv20_data
  const data20 = useSelector((state) => state.cv.cv20_details);
  const course_title_data20 = data20 ? data20.course_title : "";
  const course_year_data20 = data20 ? data20.course_year : "";
  const course_description_data20 = data20 ? data20.course_description : "";

  // For cv1_data
  useEffect(() => {
    if (course_title_data1 || course_year_data1 || course_description_data1) {
      setInputCount(course_title_data1.length);
      setCourse_year_state(course_year_data1);
      setCourse_description_state(course_description_data1);
      setCourse_title_state(course_title_data1);
    }
  }, [course_title_data1, course_description_data1, course_year_data1]);

  // For cv2_data
  useEffect(() => {
    if (course_title_data2 || course_year_data2 || course_description_data2) {
      setInputCount(course_title_data2.length);
      setCourse_year_state(course_year_data2);
      setCourse_description_state(course_description_data2);
      setCourse_title_state(course_title_data2);
    }
  }, [course_title_data2, course_description_data2, course_year_data2]);

  // For cv3_data
  useEffect(() => {
    if (course_title_data3 || course_year_data3 || course_description_data3) {
      setInputCount(course_title_data3.length);
      setCourse_year_state(course_year_data3);
      setCourse_description_state(course_description_data3);
      setCourse_title_state(course_title_data3);
    }
  }, [course_title_data3, course_description_data3, course_year_data3]);

  // For cv4_data
  useEffect(() => {
    if (course_title_data4 || course_year_data4 || course_description_data4) {
      setInputCount(course_title_data4.length);
      setCourse_year_state(course_year_data4);
      setCourse_description_state(course_description_data4);
      setCourse_title_state(course_title_data4);
    }
  }, [course_title_data4, course_description_data4, course_year_data4]);

  // For cv5_data
  useEffect(() => {
    if (course_title_data5 || course_year_data5 || course_description_data5) {
      setInputCount(course_title_data5.length);
      setCourse_year_state(course_year_data5);
      setCourse_description_state(course_description_data5);
      setCourse_title_state(course_title_data5);
    }
  }, [course_title_data5, course_description_data5, course_year_data5]);

  // For cv6_data
  useEffect(() => {
    if (course_title_data6 || course_year_data6 || course_description_data6) {
      setInputCount(course_title_data6.length);
      setCourse_year_state(course_year_data6);
      setCourse_description_state(course_description_data6);
      setCourse_title_state(course_title_data6);
    }
  }, [course_title_data6, course_description_data6, course_year_data6]);

  // For cv7_data
  useEffect(() => {
    if (course_title_data7 || course_year_data7 || course_description_data7) {
      setInputCount(course_title_data7.length);
      setCourse_year_state(course_year_data7);
      setCourse_description_state(course_description_data7);
      setCourse_title_state(course_title_data7);
    }
  }, [course_title_data7, course_description_data7, course_year_data7]);

  // For cv8_data
  useEffect(() => {
    if (course_title_data8 || course_year_data8 || course_description_data8) {
      setInputCount(course_title_data8.length);
      setCourse_year_state(course_year_data8);
      setCourse_description_state(course_description_data8);
      setCourse_title_state(course_title_data8);
    }
  }, [course_title_data8, course_description_data8, course_year_data8]);

  // For cv9_data
  useEffect(() => {
    if (course_title_data9 || course_year_data9 || course_description_data9) {
      setInputCount(course_title_data9.length);
      setCourse_year_state(course_year_data9);
      setCourse_description_state(course_description_data9);
      setCourse_title_state(course_title_data9);
    }
  }, [course_title_data9, course_description_data9, course_year_data9]);

  // For cv10_data
  useEffect(() => {
    if (
      course_title_data10 ||
      course_year_data10 ||
      course_description_data10
    ) {
      setInputCount(course_title_data10.length);
      setCourse_year_state(course_year_data10);
      setCourse_description_state(course_description_data10);
      setCourse_title_state(course_title_data10);
    }
  }, [course_title_data10, course_description_data10, course_year_data10]);

  // For cv11_data
  useEffect(() => {
    if (
      course_title_data11 ||
      course_year_data11 ||
      course_description_data11
    ) {
      setInputCount(course_title_data11.length);
      setCourse_year_state(course_year_data11);
      setCourse_description_state(course_description_data11);
      setCourse_title_state(course_title_data11);
    }
  }, [course_title_data11, course_description_data11, course_year_data11]);

  // For cv12_data
  useEffect(() => {
    if (
      course_title_data12 ||
      course_year_data12 ||
      course_description_data12
    ) {
      setInputCount(course_title_data12.length);
      setCourse_year_state(course_year_data12);
      setCourse_description_state(course_description_data12);
      setCourse_title_state(course_title_data12);
    }
  }, [course_title_data12, course_description_data12, course_year_data12]);

  // For cv13_data
  useEffect(() => {
    if (
      course_title_data13 ||
      course_year_data13 ||
      course_description_data13
    ) {
      setInputCount(course_title_data13.length);
      setCourse_year_state(course_year_data13);
      setCourse_description_state(course_description_data13);
      setCourse_title_state(course_title_data13);
    }
  }, [course_title_data13, course_description_data13, course_year_data13]);

  // For cv14_data
  useEffect(() => {
    if (
      course_title_data14 ||
      course_year_data14 ||
      course_description_data14
    ) {
      setInputCount(course_title_data14.length);
      setCourse_year_state(course_year_data14);
      setCourse_description_state(course_description_data14);
      setCourse_title_state(course_title_data14);
    }
  }, [course_title_data14, course_description_data14, course_year_data14]);

  // For cv15_data
  useEffect(() => {
    if (
      course_title_data15 ||
      course_year_data15 ||
      course_description_data15
    ) {
      setInputCount(course_title_data15.length);
      setCourse_year_state(course_year_data15);
      setCourse_description_state(course_description_data15);
      setCourse_title_state(course_title_data15);
    }
  }, [course_title_data15, course_description_data15, course_year_data15]);

  // For cv16_data
  useEffect(() => {
    if (
      course_title_data16 ||
      course_year_data16 ||
      course_description_data16
    ) {
      setInputCount(course_title_data16.length);
      setCourse_year_state(course_year_data16);
      setCourse_description_state(course_description_data16);
      setCourse_title_state(course_title_data16);
    }
  }, [course_title_data16, course_description_data16, course_year_data16]);

  // For cv17_data
  useEffect(() => {
    if (
      course_title_data17 ||
      course_year_data17 ||
      course_description_data17
    ) {
      setInputCount(course_title_data17.length);
      setCourse_year_state(course_year_data17);
      setCourse_description_state(course_description_data17);
      setCourse_title_state(course_title_data17);
    }
  }, [course_title_data17, course_description_data17, course_year_data17]);

  // For cv18_data
  useEffect(() => {
    if (
      course_title_data18 ||
      course_year_data18 ||
      course_description_data18
    ) {
      setInputCount(course_title_data18.length);
      setCourse_year_state(course_year_data18);
      setCourse_description_state(course_description_data18);
      setCourse_title_state(course_title_data18);
    }
  }, [course_title_data18, course_description_data18, course_year_data18]);

  // For cv19_data
  useEffect(() => {
    if (
      course_title_data19 ||
      course_year_data19 ||
      course_description_data19
    ) {
      setInputCount(course_title_data19.length);
      setCourse_year_state(course_year_data19);
      setCourse_description_state(course_description_data19);
      setCourse_title_state(course_title_data19);
    }
  }, [course_title_data19, course_description_data19, course_year_data19]);

  // For cv20_data
  useEffect(() => {
    if (
      course_title_data20 ||
      course_year_data20 ||
      course_description_data20
    ) {
      setInputCount(course_title_data20.length);
      setCourse_year_state(course_year_data20);
      setCourse_description_state(course_description_data20);
      setCourse_title_state(course_title_data20);
    }
  }, [course_title_data20, course_description_data20, course_year_data20]);

  const handleTitleChange = (index, e) => {
    const newTitle = [...course_title_state];
    newTitle[index] = e.target.value;
    setCourse_title_state(newTitle);
  };
  const handleYearChange = (index, e) => {
    const newYear = [...course_year_state];
    newYear[index] = e.target.value;
    setCourse_year_state(newYear);
  };

  const handleDescriptionChange = (index, e) => {
    const newDescription = [...course_description_state];
    newDescription[index] = e.target.value;
    setCourse_description_state(newDescription);
  };

  useEffect(() => {
    dispatch(
      course_action(
        course_title_state,
        course_year_state,
        course_description_state
      )
    );
  }, [course_title_state, course_year_state, course_description_state]);

  useEffect(() => {
    dispatch(course_action(title, year, description));
  }, [title, year, description, dispatch]);

  const addInputField = () => {
    setInputCount(inputCount + 1);
  };
  const handleSkillsChange = (index, e) => {
    const newTitle = [...skillsData];
    newTitle[index] = e.target.value;
    setSkillsData(newTitle);
  };
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();
  return (
   
    <Accordion 
    className='py-[25px]'
    style={{ fontFamily: 'Poppins, sans-serif' }}
    expanded={expanded === "panel11"}
    onChange={handleChange("panel11")}
    >
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      className='text-[28px]  cv-input-header font-[500] text-gray-700'
    >
                 
    {t("Course")}                  </AccordionSummary>
    <AccordionDetails        style={{
                direction:
                  i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
              }}    className="p-4 cv-input-content">
    {[...Array(inputCount)].map((_, index) => (
            <div key={index}>
              <p className="text-[25px] mt-[20px]  font-[500] ">
              {t("Course")}       : {index + 1}
              </p>
              <div className="flex mt-[20px]">
                <div className="fixed-height-input flex-1">
                  <label
                    htmlFor="inputname1"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                     {t("Title")}      
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name={`inputname${index}`}
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleTitleChange(index, e)}
                      value={course_title_state[index] || ""} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                    />
                  </div>
                </div>
                <div    style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }}  className="fixed-height-input flex-1 ml-2">
                  <label
                    htmlFor="inputname2"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                     {t("years")}      
                     </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleYearChange(index, e)}
                      value={course_year_state[index] || ""}
                    >
                      <option value="">{t("years")}      
                      </option>

                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                    </select>
                  </div>
                </div>
              </div>
              {/*another input*/}
              <h1 className="text-gray-800 Accordion-sub-title mt-[30px]">
                
                {t("Description")}      

              </h1>
              <div className="fixed-height-input">
                <textarea
                  className="input_add_freelancer min-h-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={course_description_state[index] || ""}
                  onChange={(e) => handleDescriptionChange(index, e)}
                />

                {/*another select*/}
              </div>
            </div>
          ))}
           
         <button onClick={addInputField} className="add-icon min-w-[180px] mt-6 rounded">
         <AddIcon/>                {t("Add_Course")}      

         </button>
    
    </AccordionDetails>
    </Accordion>
  );
};

export default Course;
