import React, { useEffect, useState } from "react";
import Design from "./input-create-cv";

import Cv4_content from "../../components/cv designs/cv4-content";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { user_action } from "../../redux/action/cv-action";
import Loading from "../../sub component/show design data/loading";
import { user_payment } from "../../redux/action/auth-action";
import Pricing from "../../sub component/home/pricing";
import Verify_email from "../verify-email/verify-email";

const Cv4 = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(user_payment());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const is_Active = useSelector((state) => state.auth.is_Active);
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  useEffect(() => {
    // Create a link element for Bootstrap CSS
    const bootstrapCSS = document.createElement("link");
    bootstrapCSS.href =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css";
    bootstrapCSS.rel = "stylesheet";
    bootstrapCSS.integrity =
      "sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC";
    bootstrapCSS.crossOrigin = "anonymous";

    // Create a script element for Bootstrap JS
    const bootstrapJS = document.createElement("script");
    bootstrapJS.src =
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js";
    bootstrapJS.integrity =
      "sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM";
    bootstrapJS.crossOrigin = "anonymous";

    // Append the link and script to the document head
    document.head.appendChild(bootstrapCSS);
    document.body.appendChild(bootstrapJS);

    // Cleanup function to remove the link and script when the component unmounts
    return () => {
      document.head.removeChild(bootstrapCSS);
      document.body.removeChild(bootstrapJS);
    };
  }, []);

  return (
    <>
     <Helmet>
        <title>
          Create CV - Professional Templates for All Needs - Karbein
        </title>
        <meta
          name="description"
          content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Karbein offers user-friendly and customizable CV templates to help you stand out in your job search."
        />
        <meta
          name="keywords"
          content="create CV, karbein ,  CV templates, professional CV, resume templates, free CV builder, customizable CV, CV designs, multilingual CV"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Create CV - Professional Templates for All Needs | Karbein"
        />
        <meta
          property="og:description"
          content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Karbein offers user-friendly and customizable CV templates to help you stand out in your job search."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/resumes=4" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Create CV - Professional Templates for All Needs | Karbein"
        />
        <meta
          name="twitter:description"
          content="Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Karbein offers user-friendly and customizable CV templates to help you stand out in your job search."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/resumes=4" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/resumes=4" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Create CV - Professional Templates for All Needs",
        "description": "Create your perfect CV with our professional templates. Choose from a variety of designs tailored for different industries and languages, and build your CV for free. Karbein offers user-friendly and customizable CV templates to help you stand out in your job search.",
        "image": "https://karbein.com/images/Brand-photos.png",
        "url": "https://karbein.com/resumes=4",
        "publisher": {
          "@type": "Organization",
          "name": "Karbein",
          "logo": {
            "@type": "ImageObject",
            "url": "https://karbein.com/images/Brand-photos.png"
          }
        }
      }
    `}
        </script>
      </Helmet>


      {loading ? (
        <Loading />
      ) : isUser_verified === null ? (
        <Verify_email />
      ) : (
        <div className="flex cv1-container lg:h-screen  pt-[80px]">
          <Design className=" w-1/2 " />
          <Cv4_content className=" w-1/2" />
        </div>
      )}
    </>
  );
};

export default Cv4;
