import React from 'react'
import { Link } from 'react-router-dom'
import Photography_photo   from "../../assets/image/Photography.webp";
import { useTranslation } from 'react-i18next';

const Photography = () => {
const { t, i18n } = useTranslation();
  return (
    <>
<Link  to="/services/Photography-Videography  ">
   <div className="flex justify-center border in-h-full max-w-full border-gray-500 rounded-lg bg-white">
    <div className="relative max-w-full min-w-full">
      <div className="cleaning h-[200px] rounded-lg relative">
        <img src={Photography_photo} alt="karbein - services" title='karbein - services' className="h-[100%] w-full object-cover rounded-lg" />
        <h1 className="absolute inset-0 flex items-center text-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 rounded-lg">
                        {t("Photography")}{" "}
    </h1>
      </div>
    </div>
  </div>  
</Link> 

      
    </>
  )
}

export default Photography
