import React from "react";
import { useSelector } from "react-redux";

const Image_data = ({fourth_content,eleven_content_bg_color}) => {
    const profile_image = useSelector((state) => state.main.profile_image);

  return (
    <>
      <div className="flex justify-center ">
        {profile_image && (
          <img
            src={profile_image}
            
            className={fourth_content?" h-[180px] w-[180px] rounded-[90px]  "
            :
            eleven_content_bg_color?"min-h-[200px] w-[200px]" :
            "h-[150px] w-[150px] rounded-[75px] mt-[30px]"}
            alt="Profile"
          />
        )}
      </div>
      
    </>
  );
};

export default Image_data;
