import React, { useCallback, useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "rsuite/Input/styles/index.css";

import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import { Country, State, City } from "country-state-city";
import brand_image from "../../../assets/image/Brand-photos.png";

import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import {
  services_delete,
  services_get,
  services_get_search,
  services_search,
} from "../../../redux/action/services-action";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";
import Search_loading from "../../main-pages/search loading/search-loading";
import HomeFooter from "../../../sub component/home/footer";
import {
  city_search_value,
  country_search_value,
  state_search_value,
  type_search_value,
} from "../../../redux/action/job-action";
import Services_Pagination from "./Admin-services-pagination";
import Swal from "sweetalert2";
import Admin_nav from "../../../app bar/admin-nav";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@rsuite/icons/Search";
import { styled } from "@mui/material/styles";
import Error404 from "../../error page/error404";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Admin_services = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [image, setImage] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);
  const [Type, setType] = useState("");

  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState(null);
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.isoCode === e.target.value);
    setSelectedCountry(country || null);
    setCountry(country?.name || "");
    setSelectedState(null);
    setSelectedCity("");
  };

  const handleStateChange = (e) => {
    const state = states.find((s) => s.isoCode === e.target.value);
    setSelectedState(state || null);
    setState(state?.name || "");
    setSelectedCity("");
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const Data = useSelector((state) => state.services.services_get);
  const search_get_Data = useSelector(
    (state) => state.services.services_search_get
  );

  const search = useSelector((state) => state.services.search);
  const page = useSelector((state) => state.services.cleaning_page);
  const second_job_page = useSelector((state) => state.job.second_job_page);
  useEffect(() => {
    setData(Data);
  }, [Data]);
  useEffect(() => {
    setSearchData(search_get_Data);
  }, [search_get_Data]);

  useEffect(() => {
    setData(search);
  }, [search]);
  useEffect(() => {
    setData(page);
  }, [page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(services_get());
      await dispatch(services_get_search());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const [searchLoading, setSearchLoading] = useState(false);
  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(services_search(country, state, selectedCity, Type));
      setSearchLoading(false);
      dispatch(country_search_value(country));
      dispatch(city_search_value(selectedCity));
      dispatch(state_search_value(state));
      dispatch(type_search_value(Type));
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (country !== "" || state !== "" || selectedCity !== "" || Type !== "") {
      Search();
    }
  }, [country, state, selectedCity, Type, dispatch]);

  const searchPerformed = useSelector(
    (state) => state.services.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };

  //   useEffect(() => {
  //     handleDataFetchComplete();
  //   }, [handleDataFetchComplete]);

  const navigate = useNavigate();
  const user_id = useSelector((state) => state.cv.user.id);
  const services_data = useSelector((state) => state.services.services_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    setLecturerData(services_data);
  }, [services_data]);

  const user = lecturerData.find((user) => user.user_id === `${user_id}`);

  const shownType = new Set();

  const typeCount = searchData.reduce((acc, item) => {
    acc[item.type] = (acc[item.type] || 0) + 1;
    return acc;
  }, {});

  const handleTypeChange = (value) => {
    if (Type === value) {
      // If the same checkbox is clicked again, uncheck it
      setType(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setType(value);
    }
  };

  //delete functions
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(services_delete(id));
          await dispatch(services_search(country, state, selectedCity, Type));
          await dispatch(services_get_search());
          await dispatch(services_get());

          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
      }
    });
  };

  // cheak if user cant show this route
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
      {loading ? null : <Admin_nav />}
      <div className="flex flex-col flex-1 overflow-y-auto ">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div className="">
              {searchLoading ? (
                <Search_loading />
              ) : (
                <div className="pt-[100px] px-[2%]">
                  {/* phone searchbar */}
                  <div className="left-details-container  ">
                    <div className="left-details  border rounded-[10px]   ">
                      <div className="left-details-header rounded-t-[9px] text-[20px] bg-[#ffb923] h-[45px]  text-white flex  font-[500]   border-top-[20px] justify-center items-center">
                        {t("search")}{" "}
                      </div>
                      <div
                        className=" flex flex-end"
                        style={{
                          direction:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "rtl"
                              : "ltr",
                        }}
                      >
                        <div className="flex min-w-full   overflow-auto justify-center ">
                          <div className="grid w-full  mx-[20px]   md:grid-cols-1  mt-[5px]">
                            <Accordion
                              expanded={expanded === "panel6"}
                              onChange={handleChange("panel6")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("Country")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedCountry?.isoCode || ""}
                                    onChange={handleCountryChange}
                                  >
                                    <option value="">
                                      {t("select_country")}
                                    </option>
                                    {countries.map((country) => (
                                      <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel7"}
                              onChange={handleChange("panel7")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("State")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedState?.isoCode || ""}
                                    onChange={handleStateChange}
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">
                                      {t("select_state")}
                                    </option>
                                    {states.map((state) => (
                                      <option
                                        key={state.isoCode}
                                        value={state.isoCode}
                                      >
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel8"}
                              onChange={handleChange("panel8")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("City")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div>
                                  <select
                                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={selectedCity}
                                    onChange={(e) =>
                                      setSelectedCity(e.target.value)
                                    }
                                    disabled={!selectedCountry}
                                  >
                                    <option value="">{t("select_city")}</option>
                                    {cities.map((city) => (
                                      <option key={city.name} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel9"}
                              onChange={handleChange("panel9")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("service_type")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownType.has(data.type)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownType.add(data.type); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={Type === data.type}
                                          onChange={() =>
                                            handleTypeChange(data.type)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          {data.type}
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px "
                                                  : null,
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            {typeCount[data.type]}
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-900 flex flex-col justify-center">
                    {/* Add other search options here */}

                    <div className="w-full overflow-x-auto mb-[50px]">
                      <div className="pb-3 flex justify-center"></div>
                      <table className="min-w-full bg-gray-50 border rounded-lg  overflow-hidden">
                        <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <tr>
                            <th className="py-3 px-6 text-left">id</th>
                            <th className="py-3 px-6 text-left"> image</th>

                            <th className="py-3 px-6 text-left"> title</th>
                            <th className="py-3 px-6 text-left"> delevery</th>
                            <th className="py-3 px-6 text-left">type</th>
                            <th className="py-3 px-6 text-left">phone</th>
                            <th className="py-3 px-6 text-left">email</th>
                            <th className="py-3 px-6 text-left">price</th>

                            <th className="py-3 px-6 text-left">Edit</th>
                          </tr>
                        </thead>

                        <tbody className="text-gray-600 text-sm font-light">
                          {data.map((data, index) => (
                            <tr
                              key={index}
                              className="border-b hover:bg-gray-100"
                            >
                              <td className="py-3 px-6 text-left">{data.id}</td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  <img
                                    src={data.image ? data.image : brand_image}
                                    alt=""
                                    className=" min-h-[70px]  max-h-[70px] p-1 rounded-[10px] min-w-[70px] max-w-[70px]"        
                                    
                                    />
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.title}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.delevery}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">{data.type}</span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.phone}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.email}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.price} {data.currency}
                                </span>
                              </td>

                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <div className="flex">
                                  <Link
                                    to={`/Admin-panel/services/update/${data.id}`}
                                  >
                                    <button
                                      type="button"
                                      class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                    >
                                      Update
                                    </button>
                                  </Link>{" "}
                                  <button
                                    onClick={() => handleDelete(data.id)}
                                    type="button"
                                    class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {data.length === 0 && (
                        <div className="search-message my-[30px] flex justify-center w-full text-center items-center h-[100%] main-freeLancer-container-text">
                          {t("not_haveData")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center w-full mb-[50px]">
                    {data.length > 0 ? <Services_Pagination /> : null}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin_services;
