import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HomeFooter from "../../sub component/home/footer";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { user_action } from "../../redux/action/cv-action";
import Loading from "../../sub component/show design data/loading";

const HowTo_write = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
<Helmet>
        {/* Basic Meta Tags */}
        <title>How to Write a CV ? - Karbein</title>
        <meta
          name="description"
          content="Learn how to write a compelling CV with our expert guide. Discover tips, structure, and best practices for crafting a CV that stands out to employers and showcases your qualifications effectively."
        />
        <meta
          name="keywords"
          content="how to write a CV, CV writing tips, CV structure, CV best practices, resume writing, career development , karbein "
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="How to Write a CV ? - Karbein" />
        <meta
          property="og:description"
          content="Discover expert advice on how to write a CV that stands out. Our guide covers essential tips, structure, and best practices to help you create an impactful CV that grabs attention."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/cv-writing-guide.png"
        />
        <meta property="og:url" content="https://karbein.com/cv/howToWrite" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How to Write a CV - Karbein" />
        <meta
          name="twitter:description"
          content="Get expert tips on how to write a CV that makes a lasting impression. Our guide includes essential advice on structure and content to help you craft a standout resume."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/cv-writing-guide.png"
        />
        <meta name="twitter:url" content="https://karbein.com/cv/howToWrite" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/cv/howToWrite" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "headline": "How to Write a CV - Karbein",
        "description": "Learn how to write a compelling CV with our expert guide. Discover tips, structure, and best practices for crafting a CV that stands out to employers and showcases your qualifications effectively.",
        "image": "https://karbein.com/images/cv-writing-guide.png",
        "url": "https://karbein.com/cv/howToWrite",
        "publisher": {
          "@type": "Organization",
          "name": "Karbein",
          "logo": {
            "@type": "ImageObject",
            "url": "https://karbein.com/images/Brand-photos.png"
          }
        }
      }
    `}
        </script>
      </Helmet>


      {loading ? (
        <Loading />
      ) : (
<div className="flex justify-between flex-col min-h-screen">
<div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="quations container md:pt-[140px] pt-[110px]"
            >
        <div className="mx-[-10px] ">
        <h1 className="quation-title font-bold text-[50px] mx-[100px]">
                {" "}
                {t("cv_howTo_write_header")}
              </h1>
              <br />
              <br />
              <p className="articles-text text-gray-700 mx-[100px]">
                {t("cv_howTo_write_content")}

                <br />
                <br />
              </p>
            </div>
          </div>
          <HomeFooter />
        </div>
      )}
    </>
  );
};

export default HowTo_write;
