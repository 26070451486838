import {
  CV1_DETAILS,
  CV1_SHOW,
  CV2_DETAILS,
  CV2_SHOW,
  CV3_DETAILS,
  CV3_SHOW,
  CV4_DETAILS,
  CV4_SHOW,
  CV5_DETAILS,
  CV5_SHOW,
  CV6_DETAILS,
  CV6_SHOW,
  CV7_DETAILS,
  CV7_SHOW,
  CV8_DETAILS,
  CV8_SHOW,
  CV9_DETAILS,
  CV9_SHOW,
  CV10_DETAILS,
  CV10_SHOW,
  CV11_DETAILS,
  CV11_SHOW,
  CV12_DETAILS,
  CV12_SHOW,
  CV13_DETAILS,
  CV13_SHOW,
  CV14_DETAILS,
  CV14_SHOW,
  CV15_DETAILS,
  CV15_SHOW,
  CV16_DETAILS,
  CV16_SHOW,
  CV17_DETAILS,
  CV17_SHOW,
  CV18_DETAILS,
  CV18_SHOW,
  CV19_DETAILS,
  CV19_SHOW,
  CV20_DETAILS,
  CV20_SHOW,
  USER,
} from "../action/cv-action";
import {
  ADD_PROPERTY,
  DETAILS,
  DETAILS1,
  PROPERTY,
  SHOW_PROPERTY,
  UPDATE_PROPERTY,
} from "../action/property-action";

const initialState = {
  // CV1
  cv1_details: [],
  cv1_show: [],

  // CV2
  cv2_details: [],
  cv2_show: [],

  // CV3
  cv3_details: [],
  cv3_show: [],

  // CV4
  cv4_details: [],
  cv4_show: [],

  // CV5
  cv5_details: [],
  cv5_show: [],

  // CV6
  cv6_details: [],
  cv6_show: [],

  // CV7
  cv7_details: [],
  cv7_show: [],

  // CV8
  cv8_details: [],
  cv8_show: [],

  // CV9
  cv9_details: [],
  cv9_show: [],

  // CV10
  cv10_details: [],
  cv10_show: [],

  // CV11
  cv11_details: [],
  cv11_show: [],

  // CV12
  cv12_details: [],
  cv12_show: [],

  // CV13
  cv13_details: [],
  cv13_show: [],

  // CV14
  cv14_details: [],
  cv14_show: [],

  // CV15
  cv15_details: [],
  cv15_show: [],

  // CV16
  cv16_details: [],
  cv16_show: [],

  // CV17
  cv17_details: [],
  cv17_show: [],

  // CV18
  cv18_details: [],
  cv18_show: [],

  // CV19
  cv19_details: [],
  cv19_show: [],

  // CV20
  cv20_details: [],
  cv20_show: [],
loading:true,
  user: [],
  //auth
  isAuthuntucated:false
};

export const cv_reducer = (state = initialState, action) => {
  // console.clear()
  switch (action.type) {
    // CV1
    case CV1_DETAILS:
      return {
        ...state,
        cv1_details: action.payload,
            loading:false

      };
    case CV1_SHOW:
      return {
        ...state,
        cv1_show: action.payload,
        loading:false
      };

    // CV2
    case CV2_DETAILS:
      return {
        ...state,
        cv2_details: action.payload,
                    loading:false

      };
    case CV2_SHOW:
      return {
        ...state,
        cv2_show: action.payload,
      };

    // CV3
    case CV3_DETAILS:
      return {
        ...state,
        cv3_details: action.payload,
                    loading:false

      };
    case CV3_SHOW:
      return {
        ...state,
        cv3_show: action.payload,
      };

    // CV4
    case CV4_SHOW:
      return {
        ...state,
        cv4_show: action.payload,
        
      };
case CV4_DETAILS:
  return {
    ...state,
    cv4_details: action.payload,
                loading:false

  };
    // CV5
    case CV5_SHOW:
      return {
        ...state,
        cv5_show: action.payload,
      };
case CV5_DETAILS:
  return {
    ...state,
    cv5_details: action.payload,
                loading:false

  };
    // CV6
    case CV6_SHOW:
      return {
        ...state,
        cv6_show: action.payload,
      };
case CV6_DETAILS:
  return {
    ...state,
    cv6_details: action.payload,
                loading:false

  };
    // CV7
    case CV7_SHOW:
      return {
        ...state,
        cv7_show: action.payload,
      };
case CV7_DETAILS:
  return {
    ...state,
    cv7_details: action.payload,
                loading:false

  };
    // CV8
    case CV8_SHOW:
      return {
        ...state,
        cv8_show: action.payload,
        
      };
case CV8_DETAILS:
  return {
    ...state,
    cv8_details: action.payload,
                loading:false

  };

    // CV9
    case CV9_SHOW:
      return {
        ...state,
        cv9_show: action.payload,
      };
case CV9_DETAILS:
  return {
    ...state,
    cv9_details: action.payload,
                loading:false

  };
    // CV10
    case CV10_SHOW:
      return {
        ...state,
        cv10_show: action.payload,
      };

      // CV10
case CV10_DETAILS:
  return {
    ...state,
    cv10_details: action.payload,
    loading:false
  };


// CV11
case CV11_DETAILS:
  return {
    ...state,
    cv11_details: action.payload,
        loading:false

    
  };
case CV11_SHOW:
  return {
    ...state,
    cv11_show: action.payload,

  };

// CV12
case CV12_DETAILS:
  return {
    ...state,
    cv12_details: action.payload,
        loading:false

  };
case CV12_SHOW:
  return {
    ...state,
    cv12_show: action.payload,
          
  };

// CV13
case CV13_DETAILS:
  return {
    ...state,
    cv13_details: action.payload,
            loading:false

  };
case CV13_SHOW:
  return {
    ...state,
    cv13_show: action.payload
  };

// CV14
case CV14_DETAILS:
  return {
    ...state,
    cv14_details: action.payload,
                loading:false

  };
case CV14_SHOW:
  return {
    ...state,
    cv14_show: action.payload
  };

// CV15
case CV15_DETAILS:
  return {
    ...state,
    cv15_details: action.payload,
                loading:false

  };
case CV15_SHOW:
  return {
    ...state,
    cv15_show: action.payload
  };

// CV16
case CV16_DETAILS:
  return {
    ...state,
    cv16_details: action.payload,
                loading:false

  };
case CV16_SHOW:
  return {
    ...state,
    cv16_show: action.payload
  };

// CV17
case CV17_DETAILS:
  return {
    ...state,
    cv17_details: action.payload,
                loading:false

  };
case CV17_SHOW:
  return {
    ...state,
    cv17_show: action.payload
  };

// CV18
case CV18_DETAILS:
  return {
    ...state,
    cv18_details: action.payload,
                loading:false

  };
case CV18_SHOW:
  return {
    ...state,
    cv18_show: action.payload
  };

// CV19
case CV19_DETAILS:
  return {
    ...state,
    cv19_details: action.payload,
                loading:false

  };
case CV19_SHOW:
  return {
    ...state,
    cv19_show: action.payload
  };

// CV20
case CV20_DETAILS:
  return {
    ...state,
    cv20_details: action.payload,
                loading:false

  };
case CV20_SHOW:
  return {
    ...state,
    cv20_show: action.payload
  };

    // User
    case USER:
      return {
        ...state,
        user: action.payload,
        isAuthuntucated:true,
             loading:false

      };

    default:
      return state;
  }
  
};
