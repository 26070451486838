import React, { useEffect } from "react";
import "../../assets/style/How_itWorks.css";

import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Card } from "flowbite-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import HighlightAltOutlinedIcon from "@mui/icons-material/HighlightAltOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined";
const About_cv = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        direction:
          i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
      }}
      className="mb-[170px]  max-w-[1570px] freelance-div justify-center mx-auto"
    >
      <section class="relative overflow-hidden">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {/* <div class="mx-auto text-center">
            <h1 className="text-center works_title text-3xl leading-tight mb-[80px] font-[500] tracking-tight text-gray-900">
              {t("about_cv_top_text")}{" "}
            </h1>{" "}
          </div> */}
          <ul
            role="list"
            class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-2"
          >
            <Link
              to={"/cv/whatIs"}
              class="rounded-2xl border cvInfo-card border-[#ffb923] p-8  hover:border-[#ffb923]  duration-500"
            >
              <h3 class="font-bold text-lg text-gray-900 flex gap-1 items-center">
                <span>
                  <QuestionMarkIcon
                    className=" text-[#ffb923]"
                    style={{ fontSize: "29px" }}
                  />
                </span>
                {t("What_isCV")}
              </h3>
              <p class="mt-6 text-base text-gray-700">
                {t("What_isCV_content")}
              </p>
            </Link>
            <Link
              to={"/cv/howToWrite"}
              class="rounded-2xl border cvInfo-card border-gray-200 p-8 duration-500 "
            >
              <h3 class="font-bold text-lg text-gray-900 flex gap-2.5 items-center">
                <span>
                  <AppRegistrationOutlinedIcon
                    className=" text-[#ffb923]"
                    style={{ fontSize: "29px" }}
                  />
                </span>

                {t("How_writeCV")}
              </h3>
              <p class="mt-6 text-base text-gray-700">
                {t("How_writeCV_content")}.
              </p>
            </Link>
            <Link
              to={"/cv/type"}
              class="rounded-2xl cvInfo-card duration-500 border border-gray-200 p-8"
            >
              <h3 class="font-bold text-lg text-gray-900 flex gap-2.5 items-center">
                <span>
                  <AutoFixHighOutlinedIcon
                    className=" text-[#ffb923]"
                    style={{ fontSize: "29px" }}
                  />
                </span>
                {t("type_cv")}
              </h3>
              <p class="mt-6 text-base text-gray-700">{t("type_cv_content")}</p>
            </Link>
            <Link
              to={"/cv/purpose"}
              class="rounded-2xl duration-500 cvInfo-card border border-gray-200 p-8"
            >
              <h3 class="font-bold text-lg text-gray-900 flex gap-2.5 items-center">
                <span>
                  <DashboardOutlinedIcon
                    className=" text-[#ffb923]"
                    style={{ fontSize: "29px" }}
                  />
                </span>
                {t("purpose_cv")}{" "}
              </h3>
              <p class="mt-6 text-base text-gray-700">
                {t("purpose_cv_content")}{" "}
              </p>
            </Link>
          </ul>
        </div>
      </section>
    </div>
  );
};
export default About_cv;
