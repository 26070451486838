import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { page_freeLancer, review_page } from '../../../redux/action/freelancer-action';

const Reviews_pagination = ({id}) => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.freelancer.reviews_total_page

  ); 
    
  const [currentPage, setCurrentPage] = useState(1);
 
  const handlePageChange = (event, page) => {
    dispatch(review_page(id,page));
    setCurrentPage(page);
  };

  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default Reviews_pagination;
