
import React, { useEffect, useState } from "react";
import { profile_action } from "../../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import filesize from 'filesize';
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Profile = () => {
  const [previewSource, setPreviewSource] = useState("");
  const [profileDescription, setProfileDescription] = useState("");
  const [profileName, setProfileName] = useState("");
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  //cv 1
  const details = useSelector((state) => state.cv.cv1_details);

  const profile_name = details ? details.profile_name : '';
  const profile_description = details ? details.profile_description : '';
  
  useEffect(() => {
    if (profile_name || profile_description) {
      setProfileName(profile_name);
      setProfileDescription(profile_description);
    }
  }, [profile_name, profile_description]);
// For cv2
const details2 = useSelector((state) => state.cv.cv2_details);
const profile_name2 = details2 ? details2.profile_name : '';
const profile_description2 = details2 ? details2.profile_description : '';

useEffect(() => {
  if (profile_name2 || profile_description2) {
    setProfileName(profile_name2);
    setProfileDescription(profile_description2);
  }
}, [profile_name2, profile_description2]);

// For cv3
const details3 = useSelector((state) => state.cv.cv3_details);
const profile_name3 = details3 ? details3.profile_name : '';
const profile_description3 = details3 ? details3.profile_description : '';

useEffect(() => {
  if (profile_name3 || profile_description3) {
    setProfileName(profile_name3);
    setProfileDescription(profile_description3);
  }
}, [profile_name3, profile_description3]);

// For cv4
const details4 = useSelector((state) => state.cv.cv4_details);
const profile_name4 = details4 ? details4.profile_name : '';
const profile_description4 = details4 ? details4.profile_description : '';

useEffect(() => {
  if (profile_name4 || profile_description4) {
    setProfileName(profile_name4);
    setProfileDescription(profile_description4);
  }
}, [profile_name4, profile_description4]);

// For cv5
const details5 = useSelector((state) => state.cv.cv5_details);
const profile_name5 = details5 ? details5.profile_name : '';
const profile_description5 = details5 ? details5.profile_description : '';

useEffect(() => {
  if (profile_name5 || profile_description5) {
    setProfileName(profile_name5);
    setProfileDescription(profile_description5);
  }
}, [profile_name5, profile_description5]);

// For cv6
const details6 = useSelector((state) => state.cv.cv6_details);
const profile_name6 = details6 ? details6.profile_name : '';
const profile_description6 = details6 ? details6.profile_description : '';

useEffect(() => {
  if (profile_name6 || profile_description6) {
    setProfileName(profile_name6);
    setProfileDescription(profile_description6);
  }
}, [profile_name6, profile_description6]);

// For cv7
const details7 = useSelector((state) => state.cv.cv7_details);
const profile_name7 = details7 ? details7.profile_name : '';
const profile_description7 = details7 ? details7.profile_description : '';

useEffect(() => {
  if (profile_name7 || profile_description7) {
    setProfileName(profile_name7);
    setProfileDescription(profile_description7);
  }
}, [profile_name7, profile_description7]);

// For cv8
const details8 = useSelector((state) => state.cv.cv8_details);
const profile_name8 = details8 ? details8.profile_name : '';
const profile_description8 = details8 ? details8.profile_description : '';

useEffect(() => {
  if (profile_name8 || profile_description8) {
    setProfileName(profile_name8);
    setProfileDescription(profile_description8);
  }
}, [profile_name8, profile_description8]);

// For cv9
const details9 = useSelector((state) => state.cv.cv9_details);
const profile_name9 = details9 ? details9.profile_name : '';
const profile_description9 = details9 ? details9.profile_description : '';

useEffect(() => {
  if (profile_name9 || profile_description9) {
    setProfileName(profile_name9);
    setProfileDescription(profile_description9);
  }
}, [profile_name9, profile_description9]);

// For cv10
const details10 = useSelector((state) => state.cv.cv10_details);
const profile_name10 = details10 ? details10.profile_name : '';
const profile_description10 = details10 ? details10.profile_description : '';

useEffect(() => {
  if (profile_name10 || profile_description10) {
    setProfileName(profile_name10);
    setProfileDescription(profile_description10);
  }
}, [profile_name10, profile_description10]);

// For cv11
const details11 = useSelector((state) => state.cv.cv11_details);
const profile_name11 = details11 ? details11.profile_name : '';
const profile_description11 = details11 ? details11.profile_description : '';

useEffect(() => {
  if (profile_name11 || profile_description11) {
    setProfileName(profile_name11);
    setProfileDescription(profile_description11);
  }
}, [profile_name11, profile_description11]);

// For cv12
const details12 = useSelector((state) => state.cv.cv12_details);
const profile_name12 = details12 ? details12.profile_name : '';
const profile_description12 = details12 ? details12.profile_description : '';

useEffect(() => {
  if (profile_name12 || profile_description12) {
    setProfileName(profile_name12);
    setProfileDescription(profile_description12);
  }
}, [profile_name12, profile_description12]);

// For cv13
const details13 = useSelector((state) => state.cv.cv13_details);
const profile_name13 = details13 ? details13.profile_name : '';
const profile_description13 = details13 ? details13.profile_description : '';

useEffect(() => {
  if (profile_name13 || profile_description13) {
    setProfileName(profile_name13);
    setProfileDescription(profile_description13);
  }
}, [profile_name13, profile_description13]);

// For cv14
const details14 = useSelector((state) => state.cv.cv14_details);
const profile_name14 = details14 ? details14.profile_name : '';
const profile_description14 = details14 ? details14.profile_description : '';

useEffect(() => {
  if (profile_name14 || profile_description14) {
    setProfileName(profile_name14);
    setProfileDescription(profile_description14);
  }
}, [profile_name14, profile_description14]);

// For cv15
const details15 = useSelector((state) => state.cv.cv15_details);
const profile_name15 = details15 ? details15.profile_name : '';
const profile_description15 = details15 ? details15.profile_description : '';

useEffect(() => {
  if (profile_name15 || profile_description15) {
    setProfileName(profile_name15);
    setProfileDescription(profile_description15);
  }
}, [profile_name15, profile_description15]);

// For cv16
const details16 = useSelector((state) => state.cv.cv16_details);
const profile_name16 = details16 ? details16.profile_name : '';
const profile_description16 = details16 ? details16.profile_description : '';

useEffect(() => {
  if (profile_name16 || profile_description16) {
    setProfileName(profile_name16);
    setProfileDescription(profile_description16);
  }
}, [profile_name16, profile_description16]);

// For cv17
const details17 = useSelector((state) => state.cv.cv17_details);
const profile_name17 = details17 ? details17.profile_name : '';
const profile_description17 = details17 ? details17.profile_description : '';

useEffect(() => {
  if (profile_name17 || profile_description17) {
    setProfileName(profile_name17);
    setProfileDescription(profile_description17);
  }
}, [profile_name17, profile_description17]);

// For cv18
const details18 = useSelector((state) => state.cv.cv18_details);
const profile_name18 = details18 ? details18.profile_name : '';
const profile_description18 = details18 ? details18.profile_description : '';

useEffect(() => {
  if (profile_name18 || profile_description18) {
    setProfileName(profile_name18);
    setProfileDescription(profile_description18);
  }
}, [profile_name18, profile_description18]);

// For cv19
const details19 = useSelector((state) => state.cv.cv19_details);
const profile_name19 = details19 ? details19.profile_name : '';
const profile_description19 = details19 ? details19.profile_description : '';

useEffect(() => {
  if (profile_name19 || profile_description19) {
    setProfileName(profile_name19);
    setProfileDescription(profile_description19);
  }
}, [profile_name19, profile_description19]);

// For cv20
const details20 = useSelector((state) => state.cv.cv20_details);
const profile_name20 = details20 ? details20.profile_name : '';
const profile_description20 = details20 ? details20.profile_description : '';

useEffect(() => {
  if (profile_name20 || profile_description20) {
    setProfileName(profile_name20);
    setProfileDescription(profile_description20);
  }
}, [profile_name20, profile_description20]);

  // Update Redux store when profile details change
  useEffect(() => {
    dispatch(profile_action(previewSource, profileDescription, profileName));
  }, [previewSource, profileDescription, profileName, dispatch]);


  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    }
  };
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState(null);

  const handleFileChange = (event) => {
      setFiles(event.target.files);
      console.log(event.target.files);
  };

  return (
   


    <Accordion
    
    className='py-[25px]'
    style={{ fontFamily: 'Poppins, sans-serif' }}
    expanded={expanded === "panel11"}
    onChange={handleChange("panel11")}
  >
    <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      className='text-[30px]  [10px] font-[500] text-gray-700'
    >
{t("summary")}                           </AccordionSummary>
<AccordionDetails      style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}  className="p-4 cv-input-content">
<h1 className="text-gray-800 Accordion-sub-title">{t("image")}</h1>
<div className="flex flex-col justify-center  flex-grow mb-3 ">
            <div
                className=" w-full flex justify-center py-5 px-3  relative bg-white appearance-none border-2 border-gray-300 border-solid rounded-md hover:shadow-outline-gray"
                onDragOver={(e) => e.currentTarget.classList.add('active')}
                onDragLeave={(e) => e.currentTarget.classList.remove('active')}
                onDrop={(e) => e.currentTarget.classList.remove('active')}
            >
                <input
                    type="file"
                    multiple
                    accept="image/*"
                    className="absolute inset-0 z-50 m-0 p-0 w-full h-full outline-none opacity-0"
                    onChange={handleFileInputChange}
                />
                {files ? (
                    <div className="flex flex-col space-y-1">
                        {Array.from(files).map((file, index) => (
                            <div key={index} className="flex flex-row items-center space-x-2">
                                {file.type.includes('audio/') && <i className="far fa-file-audio fa-fw"></i>}
                                {file.type.includes('application/') && <i className="far fa-file-alt fa-fw"></i>}
                                {file.type.includes('image/') && <i className="far fa-file-image fa-fw"></i>}
                                {file.type.includes('video/') && <i className="far fa-file-video fa-fw"></i>}
                                <span className="font-medium text-gray-900">{file.name}</span>
                                <span className="text-xs self-end text-gray-500">{filesize(file.size)}</span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col space-y-2 items-center justify-center">
                        <i className="fas fa-cloud-upload-alt fa-3x text-currentColor"></i>
                        <p className="text-gray-700">{t("image_select")} </p>
                        <Link className="flex items-center mx-auto py-2 px-4 text-white text-center font-medium border border-transparent rounded-md outline-none bg-red-700">
                        {t("select_file")}                        </Link>
                    </div>
                )}
            </div>
        </div>
          <div className="fixed-height-input mt-[50px]">
            <label htmlFor="inputname1" className="block text-gray-800 font-semibold text-sm">
            {t("name")}
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="inputname1"
                className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setProfileName(e.target.value)}
                value={profileName}
              />
            </div>
            <h1 className="text-gray-800 Accordion-sub-title mt-2">      
                    {t("Description")}
            </h1>
            <textarea
                    className="input_add_freelancer h-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => setProfileDescription(e.target.value)}
              value={profileDescription}
            />
          </div>
    </AccordionDetails>
  </Accordion>
  );
};

export default Profile;
