import React from "react";
import { useSelector } from "react-redux";
const Use_contact_hook = () => {
  const email = useSelector((state) => state.main.email);
  const phone = useSelector((state) => state.main.phone);
  const address = useSelector((state) => state.main.address);
  const font_size = useSelector((state) => state.open.font_size);
  return { email, phone, address, font_size };
};
export default Use_contact_hook;
