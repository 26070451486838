import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Profile_name = ({fourth_content,eight_content,second_cv}) => {
    const bg_color = useSelector((state) => state.open.bg_color);

const profile_name = useSelector((state) => state.main.profile_name);

  const [isSmallSize, setIsSmallSize] = useState(false);
  const font_family = useSelector((state) => state.open.font_family);

  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  });
  return (
    <div 
    className={
      isSmallSize?'text-[10px]':null
    }
    >
<p 

style={{
  fontFamily: `${font_family}`,
}}
className=
{ 
fourth_content?"letter-space   break-words text-[40px] font-[600]"
:

second_cv?'max-w-[90%]   truncate text-[40px]  font-[600]' :
eight_content?"max-w-[640px] break-words truncate text-[40px]  font-[600] "

:
      isSmallSize?'text-[10px]'
:
" letter-space    break-words truncate text-[40px]  font-[600] "
}

>{profile_name}</p>

    </div>
  )
}

export default Profile_name
