import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { color_action, open_action } from "../../redux/action/open-action";
import { color } from "@uiw/react-color";

const Use_bottom_nav_hook = () => {
  const [value, setValue] = React.useState("recents");
  const [bg_coloropen, setBgColoropen] = useState(false);
  const [bg_color, setBgColor] = useState(false);
  const [coloropen, setColoropen] = useState(false);
  const [fontFamily_close, setFontFamily_close] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedFont, setSelectedFont] = useState(null);
  const [selectedFontSize, setSelectedFontSize] = useState(null);

  const handleClose = () => {
    // Close the dropdown by setting dropdownOpen to false
    setFontFamily_close(false);
  };

  //line height cv s
  const line_height_data1 = useSelector((state) =>
    state.cv.cv1_details ? state.cv.cv1_details.line_height : ""
  );
  const font1 = useSelector((state) =>
    state.cv.cv1_details ? state.cv.cv1_details.font : ""
  );

  const line_height_data2 = useSelector((state) =>
    state.cv.cv2_details ? state.cv.cv2_details.line_height : ""
  );
  const font2 = useSelector((state) =>
    state.cv.cv2_details ? state.cv.cv2_details.font : ""
  );

  const line_height_data3 = useSelector((state) =>
    state.cv.cv3_details ? state.cv.cv3_details.line_height : ""
  );
  const font3 = useSelector((state) =>
    state.cv.cv3_details ? state.cv.cv3_details.font : ""
  );

  const line_height_data4 = useSelector((state) =>
    state.cv.cv4_details ? state.cv.cv4_details.line_height : ""
  );
  const font4 = useSelector((state) =>
    state.cv.cv4_details ? state.cv.cv4_details.font : ""
  );

  const line_height_data5 = useSelector((state) =>
    state.cv.cv5_details ? state.cv.cv5_details.line_height : ""
  );
  const font5 = useSelector((state) =>
    state.cv.cv5_details ? state.cv.cv5_details.font : ""
  );

  const line_height_data6 = useSelector((state) =>
    state.cv.cv6_details ? state.cv.cv6_details.line_height : ""
  );
  const font6 = useSelector((state) =>
    state.cv.cv6_details ? state.cv.cv6_details.font : ""
  );

  const line_height_data7 = useSelector((state) =>
    state.cv.cv7_details ? state.cv.cv7_details.line_height : ""
  );
  const font7 = useSelector((state) =>
    state.cv.cv7_details ? state.cv.cv7_details.font : ""
  );

  const line_height_data8 = useSelector((state) =>
    state.cv.cv8_details ? state.cv.cv8_details.line_height : ""
  );
  const font8 = useSelector((state) =>
    state.cv.cv8_details ? state.cv.cv8_details.font : ""
  );

  const line_height_data9 = useSelector((state) =>
    state.cv.cv9_details ? state.cv.cv9_details.line_height : ""
  );
  const font9 = useSelector((state) =>
    state.cv.cv9_details ? state.cv.cv9_details.font : ""
  );

  const line_height_data10 = useSelector((state) =>
    state.cv.cv10_details ? state.cv.cv10_details.line_height : ""
  );
  const font10 = useSelector((state) =>
    state.cv.cv10_details ? state.cv.cv10_details.font : ""
  );

  const line_height_data11 = useSelector((state) =>
    state.cv.cv11_details ? state.cv.cv11_details.line_height : ""
  );
  const font11 = useSelector((state) =>
    state.cv.cv11_details ? state.cv.cv11_details.font : ""
  );

  const line_height_data12 = useSelector((state) =>
    state.cv.cv12_details ? state.cv.cv12_details.line_height : ""
  );
  const font12 = useSelector((state) =>
    state.cv.cv12_details ? state.cv.cv12_details.font : ""
  );

  const line_height_data13 = useSelector((state) =>
    state.cv.cv13_details ? state.cv.cv13_details.line_height : ""
  );
  const font13 = useSelector((state) =>
    state.cv.cv13_details ? state.cv.cv13_details.font : ""
  );

  const line_height_data14 = useSelector((state) =>
    state.cv.cv14_details ? state.cv.cv14_details.line_height : ""
  );
  const font14 = useSelector((state) =>
    state.cv.cv14_details ? state.cv.cv14_details.font : ""
  );

  const line_height_data15 = useSelector((state) =>
    state.cv.cv15_details ? state.cv.cv15_details.line_height : ""
  );
  const font15 = useSelector((state) =>
    state.cv.cv15_details ? state.cv.cv15_details.font : ""
  );

  const line_height_data16 = useSelector((state) =>
    state.cv.cv16_details ? state.cv.cv16_details.line_height : ""
  );
  const font16 = useSelector((state) =>
    state.cv.cv16_details ? state.cv.cv16_details.font : ""
  );

  const line_height_data17 = useSelector((state) =>
    state.cv.cv17_details ? state.cv.cv17_details.line_height : ""
  );
  const font17 = useSelector((state) =>
    state.cv.cv17_details ? state.cv.cv17_details.font : ""
  );

  const line_height_data18 = useSelector((state) =>
    state.cv.cv18_details ? state.cv.cv18_details.line_height : ""
  );
  const font18 = useSelector((state) =>
    state.cv.cv18_details ? state.cv.cv18_details.font : ""
  );

  const line_height_data19 = useSelector((state) =>
    state.cv.cv19_details ? state.cv.cv19_details.line_height : ""
  );
  const font19 = useSelector((state) =>
    state.cv.cv19_details ? state.cv.cv19_details.font : ""
  );

  const line_height_data20 = useSelector((state) =>
    state.cv.cv20_details ? state.cv.cv20_details.line_height : ""
  );
  const font20 = useSelector((state) =>
    state.cv.cv20_details ? state.cv.cv20_details.font : ""
  );
  useEffect(() => {
    setSelectedItem(
      line_height_data1 ||
        line_height_data2 ||
        line_height_data3 ||
        line_height_data4 ||
        line_height_data5 ||
        line_height_data6 ||
        line_height_data7 ||
        line_height_data8 ||
        line_height_data9 ||
        line_height_data10 ||
        line_height_data11 ||
        line_height_data12 ||
        line_height_data13 ||
        line_height_data14 ||
        line_height_data15 ||
        line_height_data16 ||
        line_height_data17 ||
        line_height_data18 ||
        line_height_data19 ||
        line_height_data20
    );
  }, [
    line_height_data1,
    line_height_data2,
    line_height_data3,
    line_height_data4,
    line_height_data5,
    line_height_data6,
    line_height_data7,
    line_height_data8,
    line_height_data9,
    line_height_data10,
    line_height_data11,
    line_height_data12,
    line_height_data13,
    line_height_data14,
    line_height_data15,
    line_height_data16,
    line_height_data17,
    line_height_data18,
    line_height_data19,
    line_height_data20,
  ]);

  const allLineHeightData = [];
  for (let i = 1; i <= 20; i++) {
    const lineHeightData = eval(`line_height_data${i}`);
    if (lineHeightData) {
      allLineHeightData.push(lineHeightData);
    }
  }
  const concatenatedLineHeightData = allLineHeightData.join(" || ");

  const handleSelect = (value) => {
    setSelectedItem(value);
    // You can do additional actions here based on the selected value
  };
  //fonts select

  useEffect(() => {
    setSelectedFont(
      font1 ||
        font2 ||
        font3 ||
        font4 ||
        font5 ||
        font6 ||
        font7 ||
        font8 ||
        font9 ||
        font10 ||
        font11 ||
        font12 ||
        font13 ||
        font14 ||
        font15 ||
        font16 ||
        font17 ||
        font18 ||
        font19 ||
        font20
    );
  }, [
    font1,
    font2,
    font3,
    font4,
    font5,
    font6,
    font7,
    font8,
    font9,
    font10,
    font11,
    font12,
    font13,
    font14,
    font15,
    font16,
    font17,
    font18,
    font19,
    font20,
  ]);

  const handleFontSelect = (value) => {
    setSelectedFont(value);
    // You can do additional actions here based on the selected value
  };
  //font size  select
  const font_size1 = useSelector((state) =>
    state.cv.cv1_details ? state.cv.cv1_details.font_size : ""
  );
  const font_size2 = useSelector((state) =>
    state.cv.cv2_details ? state.cv.cv2_details.font_size : ""
  );
  const font_size3 = useSelector((state) =>
    state.cv.cv3_details ? state.cv.cv3_details.font_size : ""
  );
  const font_size4 = useSelector((state) =>
    state.cv.cv4_details ? state.cv.cv4_details.font_size : ""
  );
  const font_size5 = useSelector((state) =>
    state.cv.cv5_details ? state.cv.cv5_details.font_size : ""
  );
  const font_size6 = useSelector((state) =>
    state.cv.cv6_details ? state.cv.cv6_details.font_size : ""
  );
  const font_size7 = useSelector((state) =>
    state.cv.cv7_details ? state.cv.cv7_details.font_size : ""
  );
  const font_size8 = useSelector((state) =>
    state.cv.cv8_details ? state.cv.cv8_details.font_size : ""
  );
  const font_size9 = useSelector((state) =>
    state.cv.cv9_details ? state.cv.cv9_details.font_size : ""
  );
  const font_size10 = useSelector((state) =>
    state.cv.cv10_details ? state.cv.cv10_details.font_size : ""
  );
  const font_size11 = useSelector((state) =>
    state.cv.cv11_details ? state.cv.cv11_details.font_size : ""
  );
  const font_size12 = useSelector((state) =>
    state.cv.cv12_details ? state.cv.cv12_details.font_size : ""
  );
  const font_size13 = useSelector((state) =>
    state.cv.cv13_details ? state.cv.cv13_details.font_size : ""
  );
  const font_size14 = useSelector((state) =>
    state.cv.cv14_details ? state.cv.cv14_details.font_size : ""
  );
  const font_size15 = useSelector((state) =>
    state.cv.cv15_details ? state.cv.cv15_details.font_size : ""
  );
  const font_size16 = useSelector((state) =>
    state.cv.cv16_details ? state.cv.cv16_details.font_size : ""
  );
  const font_size17 = useSelector((state) =>
    state.cv.cv17_details ? state.cv.cv17_details.font_size : ""
  );
  const font_size18 = useSelector((state) =>
    state.cv.cv18_details ? state.cv.cv18_details.font_size : ""
  );
  const font_size19 = useSelector((state) =>
    state.cv.cv19_details ? state.cv.cv19_details.font_size : ""
  );
  const font_size20 = useSelector((state) =>
    state.cv.cv20_details ? state.cv.cv20_details.font_size : ""
  );

  useEffect(() => {
    setSelectedFontSize(
      font_size1 ||
        font_size2 ||
        font_size3 ||
        font_size4 ||
        font_size5 ||
        font_size6 ||
        font_size7 ||
        font_size8 ||
        font_size9 ||
        font_size10 ||
        font_size11 ||
        font_size12 ||
        font_size13 ||
        font_size14 ||
        font_size15 ||
        font_size16 ||
        font_size17 ||
        font_size18 ||
        font_size19 ||
        font_size20
    );
  }, [
    font_size1,
    font_size2,
    font_size3,
    font_size4,
    font_size5,
    font_size6,
    font_size7,
    font_size8,
    font_size9,
    font_size10,
    font_size11,
    font_size12,
    font_size13,
    font_size14,
    font_size15,
    font_size16,
    font_size17,
    font_size18,
    font_size19,
    font_size20,
  ]);

  const handleFontSizeSelect = (value) => {
    setSelectedFontSize(value);
    // You can do additional actions here based on the selected value
  };
  //open color
  const handleBg_Open_color = () => {
    setBgColoropen(!bg_coloropen);
  };
  const details = useSelector((state) => state.cv.cv1_details);

  // color
  const details1 = useSelector((state) => state.cv.cv1_details);
  const color_data1 = details1 ? details1.color : "";

  const details2 = useSelector((state) => state.cv.cv2_details);
  const color_data2 = details2 ? details2.color : "";

  const details3 = useSelector((state) => state.cv.cv3_details);
  const color_data3 = details3 ? details3.color : "";

  const details4 = useSelector((state) => state.cv.cv4_details);
  const color_data4 = details4 ? details4.color : "";

  const details5 = useSelector((state) => state.cv.cv5_details);
  const color_data5 = details5 ? details5.color : "";

  const details6 = useSelector((state) => state.cv.cv6_details);
  const color_data6 = details6 ? details6.color : "";

  const details7 = useSelector((state) => state.cv.cv7_details);
  const color_data7 = details7 ? details7.color : "";

  const details8 = useSelector((state) => state.cv.cv8_details);
  const color_data8 = details8 ? details8.color : "";

  const details9 = useSelector((state) => state.cv.cv9_details);
  const color_data9 = details9 ? details9.color : "";

  const details10 = useSelector((state) => state.cv.cv10_details);
  const color_data10 = details10 ? details10.color : "";

  const details11 = useSelector((state) => state.cv.cv11_details);
  const color_data11 = details11 ? details11.color : "";

  const details12 = useSelector((state) => state.cv.cv12_details);
  const color_data12 = details12 ? details12.color : "";

  const details13 = useSelector((state) => state.cv.cv13_details);
  const color_data13 = details13 ? details13.color : "";

  const details14 = useSelector((state) => state.cv.cv14_details);
  const color_data14 = details14 ? details14.color : "";

  const details15 = useSelector((state) => state.cv.cv15_details);
  const color_data15 = details15 ? details15.color : "";

  const details16 = useSelector((state) => state.cv.cv16_details);
  const color_data16 = details16 ? details16.color : "";

  const details17 = useSelector((state) => state.cv.cv17_details);
  const color_data17 = details17 ? details17.color : "";

  const details18 = useSelector((state) => state.cv.cv18_details);
  const color_data18 = details18 ? details18.color : "";

  const details19 = useSelector((state) => state.cv.cv19_details);
  const color_data19 = details19 ? details19.color : "";

  const details20 = useSelector((state) => state.cv.cv20_details);
  const color_data20 = details20 ? details20.color : "";

  // line height and font size and font family

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  // dispatch to open

  const [selectedColor, setSelectedColor] = useState(); // Initial color (red in this case)
  useEffect(() => {
    setSelectedColor(
      color_data1 ||
        color_data2 ||
        color_data3 ||
        color_data4 ||
        color_data5 ||
        color_data6 ||
        color_data7 ||
        color_data8 ||
        color_data9 ||
        color_data10 ||
        color_data11 ||
        color_data12 ||
        color_data13 ||
        color_data14 ||
        color_data15 ||
        color_data16 ||
        color_data17 ||
        color_data18 ||
        color_data19 ||
        color_data20 ||
        "#f5f5f5"
    );
    // setSelectedFont(font);
    // setSelectedFontSize(line_height_data);
  }, [
    color_data1,
    color_data2,
    color_data3,
    color_data4,
    color_data5,
    color_data6,
    color_data7,
    color_data8,
    color_data9,
    color_data10,
    color_data11,
    color_data12,
    color_data13,
    color_data14,
    color_data15,
    color_data16,
    color_data17,
    color_data18,
    color_data19,
    color_data20,
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      open_action(
        bg_colorRef,
        bg_coloropen,
        selectedFont,
        selectedItem,
        selectedFontSize,
        selectedColor
      )
    );
  }, [
    bg_coloropen,
    bg_color,
    selectedItem,
    selectedFont,
    selectedFontSize,
    selectedColor,
    dispatch,
  ]);
  useEffect(() => {
    dispatch(color_action(coloropen));
  }, [coloropen, dispatch]);

  // to close
  const [isPickerVisible, setPickerVisible] = useState(false);

  // Show custom dropdown when "Nearby" is clicked

  const bg_colorRef = useRef();
  const handleBgColorChange = (color) => {
    setSelectedColor(color.hex);
    const container = bg_colorRef.current;
    if (container) {
      container.style.backgroundColor = selectedColor;
    }
    setPickerVisible(false);
  };

  const handleDropdownClose = (event) => {
    // Prevent dropdown from closing if a color is selected
    if (event && event.target.closest(".bgcolor-converter")) {
      event.stopPropagation();
    }
  };
  return {
    handleClose,
    handleSelect,
    handleFontSelect,
    handleFontSizeSelect,
    handleBg_Open_color,
    handleChange,
    handleBgColorChange,
    handleDropdownClose,
    selectedColor,
    value,
    setFontFamily_close,
    fontFamily_close,
  };
};

export default Use_bottom_nav_hook;
