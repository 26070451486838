import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { user_action } from "../../../redux/action/cv-action";
import {
  certification_search_value,
  city_search_value,
  company_name_search_value,
  country_search_value,
  functional_area_search_value,
  industry_search_value,
  job_delete,
  job_get,
  job_search_data,
  job_search_get,
  job_search_value,
  state_search_value,
  type_search_value,
} from "../../../redux/action/job-action";
import { experience_search_value } from "../../../redux/action/freelancer-action";
import Loading from "../../../sub component/show design data/loading";
import Search_loading from "../../main-pages/search loading/search-loading";
import JobPagination from "../../main-pages/job/job-pagination";
import HomeFooter from "../../../sub component/home/footer";
import Swal from "sweetalert2";
import Admin_nav from "../../../app bar/admin-nav";
import brand from "../../../assets/image/Brand-photos.png";
import brand_image from "../../../assets/image/Brand-photos.png";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@rsuite/icons/Search";
import { styled } from "@mui/material/styles";
import { name_search_value } from "../../../redux/action/auth-action";
import Error404 from "../../error page/error404";
import TuneIcon from "@mui/icons-material/Tune";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Admin_jobs = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [tipe, setTipe] = useState();
  const [title, setTitle] = useState("");
  const [phoneTitle, setPhoneTitle] = useState("");

  const [funcrional_area, setFuncrional_area] = useState();
  const [Industry, setIndustry] = useState("");
  const [Skill, setSkill] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [ShowFilterBar, setShowFilterBar] = useState();

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [Experience, setExperience] = useState("");
  const [Type, setType] = useState("");
  const [Certifications, seCertifications] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [search, setSearch] = useState("");

  const jobData = useSelector((state) => state.job.job_get);
  const job_search = useSelector((state) => state.job.job_search);
  const jobSearchData = useSelector((state) => state.job.job_search_get);

  const job_page = useSelector((state) => state.job.job_page);
  const second_job_page = useSelector((state) => state.job.second_job_page);

  // get data for search

  useEffect(() => {
    setSearchData(jobSearchData);
  }, [jobSearchData]);

  useEffect(() => {
    setData(jobData);
  }, [jobData]);

  useEffect(() => {
    setData(job_search);
  }, [job_search]);
  useEffect(() => {
    setData(job_page);
  }, [job_page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);

      await dispatch(job_get());
      await dispatch(job_search_get());

      await setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const [searchLoading, setSearchLoading] = useState(false);

  // const searchSubmit = async () => {
  //   setSearchLoading(true);
  //   // await dispatch(search_job(tipe));
  //   setSearchLoading(false);
  //   dispatch(job_search_value(tipe));
  //   dispatch(job_search_value(tipe));
  //   dispatch(
  //     functional_area_search_value("")
  //   ); /* jga law searchay aykam awanay deka hamuy batal akam bo away value searchakan deka war nagre chunka jar
  //    waya user search bo shte aka yaksar acheta sar searcheke deka lam kataya
  //     valuekay batal nabetawa searchaka saqat abe*/
  //   dispatch(industry_search_value(""));
  //   dispatch(skill_search_value(""));
  //   dispatch(country_search_value(""));
  //   dispatch(city_search_value(""));

  //   // Set loading to false after dispatch
  // };
  // const functionalAreaSearch = async () => {
  //   setSearchLoading(true);
  //   await dispatch(functionalAreaSearch_job(funcrional_area));
  //   setSearchLoading(false);
  //   dispatch(functional_area_search_value(funcrional_area)); // Set loading to false after dispatch
  //   dispatch(job_search_value(""));
  //   dispatch(industry_search_value(""));
  //   dispatch(skill_search_value(""));
  //   dispatch(country_search_value(""));
  //   dispatch(city_search_value(""));
  // };
  // const industrySearch = async () => {
  //   setSearchLoading(true);
  //   await dispatch(industrySearch_job(Industry));
  //   setSearchLoading(false);
  //   dispatch(industry_search_value(Industry)); // Set loading to false after dispatch
  //   dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //   dispatch(job_search_value(""));
  //   dispatch(skill_search_value(""));
  //   dispatch(country_search_value(""));
  //   dispatch(city_search_value(""));
  // };
  // const fourth_search_get_value =
  //   useSelector((state) => state.job.fifth_search_value) || "";

  // const skillSearch = async () => {
  //   setSearchLoading(true);
  //   await dispatch(skillSearch_job(Skill));
  //   setSearchLoading(false);
  //   await dispatch(skill_search_value(Skill)); // Set loading to false after dispatch
  //   dispatch(industry_search_value("")); // Set loading to false after dispatch
  //   dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //   dispatch(job_search_value(""));
  //   dispatch(city_search_value(""));
  //   //  dispatch(country_search_value(""));
  // };
  // const countrySearch = async () => {
  //   setSearchLoading(true);
  //   // await dispatch(countrySearch_job(Country));
  //   setSearchLoading(false);
  //   // dispatch(country_search_value(Country)); // Set loading to false after dispatch
  //   dispatch(city_search_value("")); // Set loading to false after dispatch
  //   dispatch(skill_search_value("")); // Set loading to false after dispatch
  //   dispatch(industry_search_value("")); // Set loading to false after dispatch
  //   dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //   dispatch(job_search_value(""));
  // };

  // const citySearch = async () => {
  //   setSearchLoading(true);
  //   // await dispatch(citySearch_job(City));
  //   setSearchLoading(false);
  //   // dispatch(city_search_value(City)); // Set loading to false after dispatch
  //   dispatch(country_search_value("")); // Set loading to false after dispatch
  //   dispatch(skill_search_value("")); // Set loading to false after dispatch
  //   dispatch(industry_search_value("")); // Set loading to false after dispatch
  //   dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //   dispatch(job_search_value(""));
  // };
  // const companyNameSearch = async () => {
  //   setSearchLoading(true);
  //   await dispatch(company_nameSearch_job(CompanyName));
  //   setSearchLoading(false);
  //   dispatch(company_name_search_value(CompanyName));
  //   dispatch(city_search_value("")); // Set loading to false after dispatch
  //   dispatch(country_search_value("")); // Set loading to false after dispatch
  //   dispatch(skill_search_value("")); // Set loading to false after dispatch
  //   dispatch(industry_search_value("")); // Set loading to false after dispatch
  //   dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  // };

  const user_id = useSelector((state) => state.cv.user.id);
  const searchPerformed = useSelector((state) => state.job.searchPerformed);
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const navigate = useNavigate(); // Initialize useNavigate hook
  //cheak agar  token  nabu   atba bo login balam agar habu du 7alat haya agar am user freelancere drust krdbe atba bo update balam agar druste nakrdbe atba bo add freelancer

  const styles = {
    width: 300,
    marginBottom: 10,
  };

  // amana bo batal krdnawaya bo nmuna say aka agar inputaka value te bu ka cheak man krd value batal akatawa wa agar heche te nabu valuekay te daka
  //company onchange
  const handleCompanyChange = (value) => {
    if (CompanyName === value) {
      // If the same checkbox is clicked again, uncheck it
      setCompanyName("");
    } else {
      // Otherwise, check the clicked checkbox
      setCompanyName(value);
    }
  };

  //functional_area onchange
  const handleTitleChange = (value) => {
    if (title === value) {
      // If the same checkbox is clicked again, uncheck it
      setTitle("");
    } else {
      // Otherwise, check the clicked checkbox
      setTitle(value);
    }
  };

  //functional_area onchange
  const handleIndustryChange = (value) => {
    if (Industry === value) {
      // If the same checkbox is clicked again, uncheck it
      setIndustry("");
    } else {
      // Otherwise, check the clicked checkbox
      setIndustry(value);
    }
  };

  //functional_area onchange
  const handleFuncrional_areaChange = (value) => {
    if (funcrional_area === value) {
      // If the same checkbox is clicked again, uncheck it
      setFuncrional_area("");
    } else {
      // Otherwise, check the clicked checkbox
      setFuncrional_area(value);
    }
  };

  const handleCountryChange = (value) => {
    if (country === value) {
      // If the same checkbox is clicked again, uncheck it
      setCountry("");
    } else {
      // Otherwise, check the clicked checkbox
      setCountry(value);
    }
  };

  const handleStateChange = (value) => {
    if (state === value) {
      // If the same checkbox is clicked again, uncheck it
      setState("");
    } else {
      // Otherwise, check the clicked checkbox
      setState(value);
    }
  };
  const handleCityChange = (value) => {
    if (city === value) {
      // If the same checkbox is clicked again, uncheck it
      setCity("");
    } else {
      // Otherwise, check the clicked checkbox
      setCity(value);
    }
  };

  const handleExperienceChange = (value) => {
    if (Experience === value) {
      // If the same checkbox is clicked again, uncheck it
      setExperience("");
    } else {
      // Otherwise, check the clicked checkbox
      setExperience(value);
    }
  };
  const handleTypeChange = (value) => {
    if (Type === value) {
      // If the same checkbox is clicked again, uncheck it
      setType("");
    } else {
      // Otherwise, check the clicked checkbox
      setType(value);
    }
  };
  const handleCertificationsChange = (value) => {
    if (Certifications === value) {
      // If the same checkbox is clicked again, uncheck it
      seCertifications("");
    } else {
      // Otherwise, check the clicked checkbox
      seCertifications(value);
    }
  };

  //search
  // useEffect(() => {
  //   const Search = async () => {
  //     setSearchLoading(true);
  //     await dispatch(
  //       search_high(
  //         country,
  //         state,
  //         selectedCity,
  //         selectedSubject,
  //         selectedMode,
  //         selectedLanguage
  //       )
  //     );
  //     setSearchLoading(false);
  //     dispatch(country_search_value(country));
  //     dispatch(city_search_value(selectedCity));
  //     dispatch(state_search_value(state));
  //     dispatch(subject_search_value(selectedSubject));
  //     dispatch(study_mode_search_value(selectedMode));
  //     dispatch(language_search_value(selectedLanguage));
  //   };

  //   //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
  //   if (
  //     tipe !== "" ||
  //     funcrional_area !== "" ||
  //     Industry !== "" ||
  //     Skill !== "" ||
  //     CompanyName !== "" ||
  //     selectedCountry !== ""||
  //     selectedState !== ""||
  //     selectedCity !== ""||
  //     selectedCity !== ""

  //   ) {
  //     Search();
  //   }
  // }, [
  //   country,
  //   state,
  //   selectedCity,
  //   selectedSubject,
  //   selectedMode,
  //   selectedLanguage,
  //   dispatch,
  // ]);
  //companyNameSearch
  // useEffect(() => {
  //   const companyNameSearch = async () => {
  //     setSearchLoading(true);
  //     await dispatch(company_nameSearch_job(CompanyName));
  //     setSearchLoading(false);
  //     dispatch(company_name_search_value(CompanyName));
  //     // dispatch(city_search_value("")); // Set loading to false after dispatch
  //     // dispatch(country_search_value("")); // Set loading to false after dispatch
  //     // dispatch(skill_search_value("")); // Set loading to false after dispatch
  //     // dispatch(industry_search_value("")); // Set loading to false after dispatch
  //     // dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //   };

  //   //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
  //   if (

  //     CompanyName !== ""

  //   ) {
  //     companyNameSearch();
  //   }
  // }, [
  //   country,
  //   state,
  //   selectedCity,
  //   CompanyName,
  //   dispatch,
  // ]);
  //functionalAreaSearch
  const costomsSearch = async () => {
    setSearchLoading(true);
    await dispatch(
      job_search_data(
        CompanyName,
        funcrional_area,
        Industry,
        phoneTitle,
        country,
        state,
        city,
        Experience,
        Certifications,
        Type
      )
    );

    dispatch(functional_area_search_value(funcrional_area)); // Set loading to false after dispatch
    dispatch(industry_search_value(Industry));
    dispatch(company_name_search_value(CompanyName));

    dispatch(type_search_value(Type));
    dispatch(experience_search_value(Experience));

    dispatch(certification_search_value(Certifications));

    dispatch(state_search_value(state));
    dispatch(country_search_value(country));
    dispatch(city_search_value(city));

    job_search_value();
    dispatch(job_search_value(phoneTitle));

    setSearchLoading(false);
  };
  useEffect(() => {
    const search = async () => {
      setSearchLoading(true);
      await dispatch(
        job_search_data(
          CompanyName,
          funcrional_area,
          Industry,
          title || phoneTitle,
          country,
          state,
          city,
          Experience,
          Certifications,
          Type
        )
      );

      dispatch(functional_area_search_value(funcrional_area)); // Set loading to false after dispatch
      dispatch(industry_search_value(Industry));
      dispatch(company_name_search_value(CompanyName));

      dispatch(type_search_value(Type));
      dispatch(experience_search_value(Experience));

      dispatch(certification_search_value(Certifications));

      dispatch(state_search_value(state));
      dispatch(country_search_value(country));
      dispatch(city_search_value(city));

      job_search_value();
      dispatch(job_search_value(title));

      setSearchLoading(false);
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (
      CompanyName !== "" ||
      funcrional_area !== "" ||
      Industry !== "" ||
      title !== "" ||
      country !== "" ||
      state !== "" ||
      city !== "" ||
      Experience !== "" ||
      Certifications !== "" ||
      Type !== ""
    ) {
      search();
    }
  }, [
    country,
    state,
    city,
    Industry,
    CompanyName,
    title,
    Certifications,
    Experience,
    Type,
    funcrional_area,
    dispatch,
  ]);

  const experienceList = [
    { value: "1 to 3 years", label: "1 to 3 years" },
    { value: "3 to 6 years", label: "3 to 6 years" },
    { value: "6 to 9 years", label: "6 to 9 years" },
    { value: "More than 10 years", label: "More than 10 years" },
  ];

  //functionalAreaSearch
  // useEffect(() => {
  //   const IndustryreaSearch = async () => {
  //     setSearchLoading(true);
  //     await dispatch(industrySearch_job(Industry));
  //     setSearchLoading(false);
  //     dispatch(industry_search_value(Industry)); // Set loading to false after dispatch
  //     // dispatch(functional_area_search_value("")); // Set loading to false after dispatch
  //     // dispatch(job_search_value(""));
  //     // dispatch(skill_search_value(""));
  //     // dispatch(country_search_value(""));
  //     // dispatch(city_search_value(""));
  //   };

  //   //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
  //   if (

  //     Industry !== ""

  //   ) {
  //     IndustryreaSearch();
  //   }
  // }, [
  //   country,
  //   state,
  //   selectedCity,
  //   Industry,
  //   dispatch,
  // ]);

  //bo awaya agar hatu datay databaseka dubaray tebu  lay bda wata har title la database bo nmuna 20 danay haya
  //nache 20 jar benuse har yakjar aynuse wa he mobile u desktopm krdwa chunka agar yak dana bkay  natwani la shwene deka maoaka bakar bhenetawa
  const shownIndustries = new Set();
  const shownPhoneIndustries = new Set();

  const shownFunctionalAreas = new Set();
  const shownPhoneFunctionalAreas = new Set();

  const shownCompanies = new Set();
  const shownPhoneCompanies = new Set();

  const shownTitle = new Set();
  const shownPhoneTitle = new Set();

  const showncountry = new Set();
  const shownPhonecountry = new Set();

  const shownState = new Set();
  const shownPhoneState = new Set();

  const shownCity = new Set();
  const shownPhoneCity = new Set();

  const shownExperience = new Set();
  const shownType = new Set();
  const shownPhoneType = new Set();

  const shownCertifications = new Set();
  const shownPhoneCertifications = new Set();

  //get date to show expired
  const currentDate = new Date();

  // bo henanaway county shtakan bo nmuna  companyayak du eshe blaw krdwa du anusre
  // bo nmuna chan esh la iraq haya danusry
  const nameCount = searchData.reduce((acc, item) => {
    acc[item.industry] = (acc[item.industry] || 0) + 1;
    return acc;
  }, {});

  const countryCount = searchData.reduce((acc, item) => {
    acc[item.country] = (acc[item.country] || 0) + 1;
    return acc;
  }, {});

  const stateCount = searchData.reduce((acc, item) => {
    acc[item.state] = (acc[item.state] || 0) + 1;
    return acc;
  }, {});

  const cityCount = searchData.reduce((acc, item) => {
    acc[item.city] = (acc[item.city] || 0) + 1;
    return acc;
  }, {});

  const TypeCount = searchData.reduce((acc, item) => {
    acc[item.job_type] = (acc[item.job_type] || 0) + 1;
    return acc;
  }, {});

  const ExperienceCount = searchData.reduce((acc, item) => {
    acc[item.Experience] = (acc[item.Experience] || 0) + 1;
    return acc;
  }, {});

  const funcrional_areaCount = searchData.reduce((acc, item) => {
    acc[item.funcrional_area] = (acc[item.funcrional_area] || 0) + 1;
    return acc;
  }, {});

  const CertificationsCount = searchData.reduce((acc, item) => {
    acc[item.Certifications] = (acc[item.Certifications] || 0) + 1;
    return acc;
  }, {});

  const company_nameCount = searchData.reduce((acc, item) => {
    acc[item.company_name] = (acc[item.company_name] || 0) + 1;
    return acc;
  }, {});

  const industrysCount = searchData.reduce((acc, item) => {
    acc[item.industry] = (acc[item.industry] || 0) + 1;
    return acc;
  }, {});

  const job_titleCount = searchData.reduce((acc, item) => {
    acc[item.job_title] = (acc[item.job_title] || 0) + 1;
    return acc;
  }, {});
  // name filter if have repeat name not show in search bar

  // Format date to YYYY-MM-DD
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(job_delete(id));
          if (job_search.length <= 1) {
            await dispatch(job_search_data(""));
            // await dispatch(job_get());
          } else {
            await dispatch(
              job_search_data(
                CompanyName,
                funcrional_area,
                Industry,
                title,
                country,
                state,
                city,
                Experience,
                Certifications,
                Type
              )
            );
          }
          await dispatch(job_search_get());

          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
      }
    });
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }

  return (
    <div className="flex h-screen">
    {loading ? null : <Admin_nav />}
    <div className="flex flex-col flex-1 overflow-y-auto ">
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div className="px-[2%]">
            {searchLoading ? (
              <Search_loading />
            ) : (
              <div>
                <div className="text-gray-900   pt-[100px]  mt-4 flex flex-col justify-center">
                  {/* Add other search options here */}
                  <div className="left-details- mt-[-25px]   ">
                          <form
                            onSubmit={costomsSearch}
                            className="flex items-center max-w-[260px] mx-auto"
                          >
                            <label className="sr-only">Search</label>
                            <div className="relative ">
                              <button
                                type="submit"
                                className="max-w-[40px]  justify-center   absolute inset-y-0  start-0 flex items-center "
                                style={{
                                  marginRight:
                                    i18n.language === "ku" ||
                                    i18n.language === "ar"
                                      ? "0px"
                                      : "0px",
                                  marginLeft:
                                    i18n.language === "ku" ||
                                    i18n.language === "ar"
                                      ? "0px"
                                      : "10px",
                                }}
                              >
                                <SearchIcon />
                              </button>
                              <input
                                type="text"
                                id="simple-search"
                                className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={t("Job_Title")}
                                required
                                value={phoneTitle}
                                onChange={(e) => {
                                  setPhoneTitle(e.target.value);
                                }}
                              />
                            </div>

                            <button
                              type="button"
                              onClick={toggleFilter} // Toggle visibility on click
                              className="p-[6px] ms-2 text-sm font-medium text-white bg-[#ffb923] rounded-lg hover:bg-[#ffc64a] dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
    <TuneIcon
                            className=" text-white "
                            style={{ fontSize: "29px" }}
                          />                              <span className="sr-only">Search</span>
                            </button>
                          </form>

                          {showFilter && ( // Conditional rendering of the div
                            <div className="filter-options mt-4 p-2 border rounded-lg  dark:bg-gray-800">
                              {/* Your filter options go here */}
                              <Accordion
                                expanded={expanded === "panel12"}
                                onChange={handleChange("panel12")}
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                >
                                  <Typography>{t("Job_Title")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex flex-col max-h-[250px] overflow-auto min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (shownPhoneTitle.has(data.job_title)) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneTitle.add(data.job_title); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={title === data.job_title}
                                            onChange={() =>
                                              handleTitleChange(data.job_title)
                                            }
                                          />

                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.job_title}
                                            <h1
                                              style={{
                                                marginRight:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "0px"
                                                    : "10px",
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100  text-gray-600 h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {job_titleCount[data.job_title]}
                                            </h1>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                              >
                                <AccordionSummary
                                  aria-controls="panel2d-content"
                                  id="panel2d-header"
                                >
                                  <Typography>{t("Country")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex max-h-[250px] overflow-auto flex-col min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (shownPhonecountry.has(data.country)) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhonecountry.add(data.country); // Add company name to set

                                      return (
                                        <div
                                          key={`country-${data.id}`}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={country === data.country}
                                            onChange={() =>
                                              handleCountryChange(data.country)
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.country}
                                            <h1
                                              style={{
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {countryCount[data.country]}
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("State")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex max-h-[250px] overflow-auto flex-col min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (shownPhoneState.has(data.state)) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneState.add(data.state); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={state === data.state}
                                            onChange={() =>
                                              handleStateChange(data.state)
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.state}
                                            <h1
                                              style={{
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {stateCount[data.state]}
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("City")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex max-h-[250px] overflow-auto flex-col min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (shownPhoneCity.has(data.city)) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneCity.add(data.city); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={city === data.city}
                                            onChange={() =>
                                              handleCityChange(data.city)
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.city}
                                            <h1
                                              style={{
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {cityCount[data.city]}
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel5"}
                                onChange={handleChange("panel5")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("Job_Type")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex max-h-[250px] overflow-auto flex-col min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (shownPhoneType.has(data.job_type)) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneType.add(data.job_type); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={Type === data.job_type}
                                            onChange={() =>
                                              handleTypeChange(data.job_type)
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.job_type}
                                            <h1
                                              style={{
                                                marginRight:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "0px"
                                                    : "10px",
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600 h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {TypeCount[data.job_type]}
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel6"}
                                onChange={handleChange("panel6")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("Jobs_By_degree")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex max-h-[250px] overflow-auto flex-col min-w-full  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (
                                        shownPhoneCertifications.has(
                                          data.Certifications
                                        )
                                      ) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneCertifications.add(
                                        data.Certifications
                                      ); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={
                                              Certifications ===
                                              data.Certifications
                                            }
                                            onChange={() =>
                                              handleCertificationsChange(
                                                data.Certifications
                                              )
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.Certifications}
                                            <h1
                                              style={{
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 mr-[10px] text-gray-600 h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {
                                                CertificationsCount[
                                                  data.Certifications
                                                ]
                                              }
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel7"}
                                onChange={handleChange("panel7")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>
                                    {t("Functional_Area")}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex flex-col min-w-full max-h-[250px] overflow-auto gap-[30px]">
                                    {jobSearchData.map((data) => {
                                      if (
                                        shownPhoneFunctionalAreas.has(
                                          data.funcrional_area
                                        )
                                      ) {
                                        return null; // Skip rendering if functional area is already shown
                                      }
                                      shownPhoneFunctionalAreas.add(
                                        data.funcrional_area
                                      ); // Add functional area to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={
                                              funcrional_area ===
                                              data.funcrional_area
                                            }
                                            onChange={() =>
                                              handleFuncrional_areaChange(
                                                data.funcrional_area
                                              )
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.funcrional_area}
                                            <h1
                                              style={{
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 mr-[10px] text-gray-600  h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {
                                                funcrional_areaCount[
                                                  data.funcrional_area
                                                ]
                                              }
                                            </h1>
                                          </div>{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel8"}
                                onChange={handleChange("panel8")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("Industry")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex flex-col min-w-full max-h-[250px] overflow-auto  gap-[30px]">
                                    {searchData.map((data) => {
                                      if (
                                        shownPhoneIndustries.has(data.industry)
                                      ) {
                                        return null; // Skip rendering if industry is already shown
                                      }
                                      shownPhoneIndustries.add(data.industry); // Add industry to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={Industry === data.industry}
                                            onChange={() =>
                                              handleIndustryChange(
                                                data.industry
                                              )
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.industry}
                                            <h1
                                              style={{
                                                marginRight:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "0px"
                                                    : "10px",
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600  h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {" "}
                                              {nameCount[data.industry]}
                                            </h1>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion
                                expanded={expanded === "panel9"}
                                onChange={handleChange("panel9")}
                              >
                                <AccordionSummary
                                  aria-controls="panel3d-content"
                                  id="panel3d-header"
                                >
                                  <Typography>{t("company_name")}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="flex flex-col max-h-[250px] overflow-auto min-w-full   gap-[30px]">
                                    {searchData.map((data) => {
                                      if (
                                        shownPhoneCompanies.has(
                                          data.company_name
                                        )
                                      ) {
                                        return null; // Skip rendering if company name is already shown
                                      }
                                      shownPhoneCompanies.add(
                                        data.company_name
                                      ); // Add company name to set

                                      return (
                                        <div
                                          key={data.id}
                                          className="flex max-w-full gap-2 items-center"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={
                                              CompanyName === data.company_name
                                            }
                                            onChange={() =>
                                              handleCompanyChange(
                                                data.company_name
                                              )
                                            }
                                          />
                                          <div className="flex gap-3 justify-between w-full items-center">
                                            {data.company_name}
                                            <h1
                                              style={{
                                                marginRight:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "0px"
                                                    : "10px",
                                                marginLeft:
                                                  i18n.language === "ku" ||
                                                  i18n.language === "ar"
                                                    ? "10px"
                                                    : "0px",
                                              }}
                                              className="bg-gray-100 text-gray-600 h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                            >
                                              {
                                                company_nameCount[
                                                  data.company_name
                                                ]
                                              }
                                            </h1>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                        </div>

                  <div className="w-full overflow-x-auto mb-[50px]">
                    <div className="pb-3 flex justify-center"></div>
                    <table className="min-w-full bg-gray-50 border rounded-lg shadow overflow-hidden">
                      <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <tr>
                          <th className="py-3 px-6 text-left">id</th>

                          <th className="py-3 px-6 text-left">image</th>
                          <th className="py-3 px-6 text-left">job title</th>
                          <th className="py-3 px-6 text-left">funcrional area</th>
                          <th className="py-3 px-6 text-left">company name</th>
                          <th className="py-3 px-6 text-left">skills</th>

                          <th className="py-3 px-6 text-left">job type</th>
                          <th className="py-3 px-6 text-left">Phone</th>
                          <th className="py-3 px-6 text-left">Email</th>
                          <th className="py-3 px-6 text-left">Salary</th>
                          <th className="py-3 px-6 text-left">Certifications</th>
                          <th className="py-3 px-6 text-left">gender</th>
                          <th className="py-3 px-6 text-left">Edit</th>

                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light">
                        {data.map((data, index) => (
                          <tr
                            key={index}
                            className="border-b hover:bg-gray-100"
                          >
                            <td className="py-3 px-6 text-left">{data.id}</td>

                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
<img                                     src={data.image ? data.image : brand_image}
 alt="" className=" min-h-[70px]  max-h-[70px] p-1 rounded-[10px] min-w-[70px] max-w-[70px]" />                              </span>
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.job_title}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.funcrional_area}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.company_name}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.skill}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.job_type}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.Phone}
                            </td>
                            <td className="py-3 px-6 text-left">
                              {data.Email}
                            </td>       <td className="py-3 px-6 text-left">
                              {data.Salary}
                            </td>       <td className="py-3 px-6 text-left">
                              {data.Certifications}
                            </td>       <td className="py-3 px-6 text-left">
                              {data.gender}
                            </td>
                            <td className="py-3 px-6 text-left">
                            <div className="flex">
<Link to={`/Admin-panel/jobs/update/${data.id}`}>
<button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>

</Link>                               <button onClick={()=>
  handleDelete(data.id)
} type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Delete</button>

                               </div>                            </td>          
                            
                                            </tr>
                        ))}
                      </tbody>
                    </table>
                    {data.length === 0 && (
                          <div className="search-message my-[30px] flex justify-center w-full text-center items-center h-[100%] main-freeLancer-container-text">
                            {t("not_haveData")}
                          </div>
                        )}
                  </div>
                </div>
                <div className="flex justify-center w-full mb-[50px]">
        {
          data.length>0?
          <JobPagination />
:null

        }
                  </div>
              </div>
            )}
          </div>
        )}
      </div>
 
      {/* {(data.length > 0 || user_search_data.length > 0) && (
        <div className="flex justify-center w-full mb-[50px]">
        </div>
      )} */}
    </div>
  </div>
  );
};

export default Admin_jobs;
