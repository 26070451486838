import { CRETAED_AT__SEARCHVALUE, EMAIL_SEARCHVALUE, LOGIN, LOGIN_ERROR, NAME_SEARCHVALUE, ORDER_GET, ORDER_PAGE, ORDER_SEARCH, ORDERGET, PLAN_TYPE__SEARCHVALUE, STATUS__SEARCHVALUE, USER_GET, USER_PAGE, USER_PAYMENT, USER_SEARCH, USERNAME_SEARCHVALUE } from "../action/auth-action";

const initialState = {
  token: null,
  user_data: [], 
  user_search: [], // Consider changing to user object if user_data is intended to hold single user data
  user_page: [], // Consider changing to user object if user_data is intended to hold single user data

  total_pages_search: null, // Total pages for search results
  searchPerformed: false,
  search_value:[],
  total_pages:[]
  
  ,
  //order
  order_get:[],
  order_total_pages:[],
  order_search:[],
  order_page:[]
,
user_name__value:[]
,  email__value:[]
,  Plan_Type_search_value:[]
,  status_search_value:[]
,  created_at_value:[]
,
  // Consider changing to user object if user_data is intended to hold single user data
  errors: null, // Initialize errors to null

  // get all users
  users_get: [],
  is_Active:false
};

export const auth_reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
      };
      case USER_GET:
        return {
          ...state,
          users_get: action.payload.data,
          total_pages: action.payload.total_pages,

        };
        case NAME_SEARCHVALUE:
        return {
          ...state,
          search_value: action.search,

        };
        case USER_PAGE:
          return {
            ...state,
            user_page: action.payload.data
  
          };
        case USER_SEARCH:
          return {
            ...state,
            user_search: action.payload.data,
            total_pages_search: action.payload.total_pages,
            searchPerformed: true
          };
    case LOGIN_ERROR:
      return {
        ...state,
        errors: action.payload.errors, // Set errors from payload
      };

      //payment
      case USER_PAYMENT:
        return {
          ...state,
          is_Active: action.payload.data, // Set errors from payload
        };
        //order

          //order get
          case ORDER_GET:
            return {
              ...state,
              order_get: action.payload.data,
              order_total_pages: action.payload.total_pages,
    
            };
               //order search
          case ORDER_SEARCH:
            return {
              ...state,
              order_search: action.payload.data,
              total_pages_search: action.payload.total_pages,
              searchPerformed: true
            };
            case ORDER_PAGE:
              return {
                ...state,
                order_page: action.payload.data
      
              };

              //order search values to use in paginaion
              case USERNAME_SEARCHVALUE:
                return {
                  ...state,
                  user_name__value: action.search,
        
                };
                case EMAIL_SEARCHVALUE:
                  return {
                    ...state,
                    email__value: action.search,
          
                  };
  
                  case PLAN_TYPE__SEARCHVALUE:
                    return {
                      ...state,
                      Plan_Type_search_value: action.search,
            
                    };
                    
                  case STATUS__SEARCHVALUE:
                    return {
                      ...state,
                      status_search_value: action.search,
            
                    };
                    case CRETAED_AT__SEARCHVALUE:
                    return {
                      ...state,
                      created_at_value: action.search,
            
                    };
        default:
      return state;
  }
};
