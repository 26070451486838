import { Cookie } from "@mui/icons-material";
import axios from "axios";
import Cookies from "js-cookie";

//cv1

//cv1 create
export const CV1_INSERT = "CV1_INSERT";
export const cv1_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv1/insert",

        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV1_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};
// cv1_update

export const CV1_UPDATE = "CV1_UPDATE";
export const cv1_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.post(
        `http://localhost:8000/api/cv1/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV1_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv1_details
export const CV1_DETAILS = "CV1_DETAILS";
export const cv1_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv1/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV1_DETAILS, payload: response.data.data });
    } catch (error) {}
  };
};

//cv1_show
export const CV1_SHOW = "CV1_SHOW";
export const cv1_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv1/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV1_SHOW, payload: response.data.data });
    } catch (error) {}
  };
};

//cv2

//cv2 create
export const CV2_INSERT = "CV2_INSERT";
export const cv2_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv2/insert",

        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV2_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};
// cv2_update

export const CV2_UPDATE = "CV2_UPDATE";
export const cv2_update = (
  id,
  color,
  font,
  font_size,
  line_height,

  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.post(
        `http://localhost:8000/api/cv2/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV2_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv1_details
export const CV2_DETAILS = "CV2_DETAILS";
export const cv2_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv2/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV2_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv2_show
export const CV2_SHOW = "CV2_SHOW";
export const cv2_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv2/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
            const cvEntries = response.data.data;

      console.log("cvEntries"+cvEntries)
      dispatch({ type: CV2_SHOW, payload:cvEntries });
    } catch (error) {
      console.log(error)
    }
  };
};

//cv3

export const CV3_INSERT = "CV3_INSERT";
export const cv3_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv3/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV3_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV3 Update
export const CV3_UPDATE = "CV3_UPDATE";
export const cv3_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv3/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV3_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV3 Details
export const CV3_DETAILS = "CV3_DETAILS";
export const cv3_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv3/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV3_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV3 Show
export const CV3_SHOW = "CV3_SHOW";
export const cv3_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv3/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV3_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv4
// CV4 Insert
export const CV4_INSERT = "CV4_INSERT";
export const cv4_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv4/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV4_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV4 Update
export const CV4_UPDATE = "CV4_UPDATE";
export const cv4_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv4/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV4_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV4 Details
export const CV4_DETAILS = "CV4_DETAILS";
export const cv4_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv4/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV4_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV4 Show
export const CV4_SHOW = "CV4_SHOW";
export const cv4_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv4/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV4_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv5
// CV5 Insert
export const CV5_INSERT = "CV5_INSERT";
export const cv5_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv5/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV5_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV5 Update
export const CV5_UPDATE = "CV5_UPDATE";
export const cv5_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv5/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV5_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV5 Details
export const CV5_DETAILS = "CV5_DETAILS";
export const cv5_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv5/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV5_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV5 Show
export const CV5_SHOW = "CV5_SHOW";
export const cv5_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv5/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV5_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//show_user

export const USER = "USER";
export const USER_ERROR = "USER_ERROR";
export const UNAUTHORIZED_ERROR = "UNAUTHORIZED_ERROR"; // Define an unauthorized error action type

export const user_action = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      if (!token) {
        dispatch({ type: UNAUTHORIZED_ERROR, payload: "No token found" });
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_URL}/api/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: USER, payload: response.data });
    } catch (error) {
      console.clear()

      if (error.response && error.response.status === 401) {
        dispatch({ type: UNAUTHORIZED_ERROR, payload: "Unauthorized access" });
      } else {
        dispatch({ type: USER_ERROR, payload: error.message });
      }
      // You can also display an error message to the user or log it to an error tracking service here
    }
  };
};














































//cv6
// CV6 Insert
export const CV6_INSERT = "CV6_INSERT";
export const cv6_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv6/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV6_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV6 Update
export const CV6_UPDATE = "CV6_UPDATE";
export const cv6_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv6/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV6_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV6 Details
export const CV6_DETAILS = "CV6_DETAILS";
export const cv6_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv6/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV6_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV6 Show
export const CV6_SHOW = "CV6_SHOW";
export const cv6_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv6/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV6_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//CV7
// CV7 Insert
export const CV7_INSERT = "CV7_INSERT";
export const cv7_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv7/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV7_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV7 Update
export const CV7_UPDATE = "CV7_UPDATE";
export const cv7_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv7/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV7_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV7 Details
export const CV7_DETAILS = "CV7_DETAILS";
export const cv7_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv7/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV7_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV7 Show
export const CV7_SHOW = "CV7_SHOW";
export const cv7_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv7/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV7_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};
//CV8
// CV8 Insert
export const CV8_INSERT = "CV8_INSERT";
export const cv8_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv8/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV8_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV8 Update
export const CV8_UPDATE = "CV8_UPDATE";
export const cv8_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv8/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV8_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV8 Details
export const CV8_DETAILS = "CV8_DETAILS";
export const cv8_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv8/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV8_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV8 Show
export const CV8_SHOW = "CV8_SHOW";
export const cv8_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv8/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV8_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv9
// CV9 Insert
export const CV9_INSERT = "CV9_INSERT";
export const cv9_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv9/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV9_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV9 Update
export const CV9_UPDATE = "CV9_UPDATE";
export const cv9_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv9/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV9_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV9 Details
export const CV9_DETAILS = "CV9_DETAILS";
export const cv9_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv9/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV9_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV9 Show
export const CV9_SHOW = "CV9_SHOW";
export const cv9_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv9/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV9_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv10

// CV10 Insert
export const CV10_INSERT = "CV10_INSERT";
export const cv10_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv10/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV10_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV10 Update
export const CV10_UPDATE = "CV10_UPDATE";
export const cv10_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv10/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV10_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV10 Details
export const CV10_DETAILS = "CV10_DETAILS";
export const cv10_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv10/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV10_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV10 Show
export const CV10_SHOW = "CV10_SHOW";
export const cv10_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv10/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV10_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};
//cv 11
// CV11 Insert
export const CV11_INSERT = "CV11_INSERT";
export const cv11_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv11/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV11_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV11 Update
export const CV11_UPDATE = "CV11_UPDATE";
export const cv11_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv11/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV11_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV11 Details
export const CV11_DETAILS = "CV11_DETAILS";
export const cv11_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv11/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV11_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV11 Show
export const CV11_SHOW = "CV11_SHOW";
export const cv11_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv11/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV11_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// cv12
// CV12 Insert
export const CV12_INSERT = "CV12_INSERT";
export const cv12_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv12/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV12_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV12 Update
export const CV12_UPDATE = "CV12_UPDATE";
export const cv12_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv12/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV12_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV12 Details
export const CV12_DETAILS = "CV12_DETAILS";
export const cv12_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv12/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV12_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV12 Show
export const CV12_SHOW = "CV12_SHOW";
export const cv12_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv12/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV12_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};
//cv13
// CV13 Insert
export const CV13_INSERT = "CV13_INSERT";
export const cv13_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv13/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV13_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV13 Update
export const CV13_UPDATE = "CV13_UPDATE";
export const cv13_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv13/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV13_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV13 Details
export const CV13_DETAILS = "CV13_DETAILS";
export const cv13_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv13/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV13_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV13 Show
export const CV13_SHOW = "CV13_SHOW";
export const cv13_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv13/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV13_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv14
// CV14 Insert
export const CV14_INSERT = "CV14_INSERT";
export const cv14_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv14/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV14_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV14 Update
export const CV14_UPDATE = "CV14_UPDATE";
export const cv14_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv14/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV14_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV14 Details
export const CV14_DETAILS = "CV14_DETAILS";
export const cv14_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv14/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV14_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV14 Show
export const CV14_SHOW = "CV14_SHOW";
export const cv14_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv14/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV14_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};
//cv15
// CV15 Insert
export const CV15_INSERT = "CV15_INSERT";
export const cv15_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv15/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV15_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV15 Update
export const CV15_UPDATE = "CV15_UPDATE";
export const cv15_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv15/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV15_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV15 Details
export const CV15_DETAILS = "CV15_DETAILS";
export const cv15_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv15/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV15_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV15 Show
export const CV15_SHOW = "CV15_SHOW";
export const cv15_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv15/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV15_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv16
// CV16 Insert
export const CV16_INSERT = "CV16_INSERT";
export const cv16_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv16/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV16_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV16 Update
export const CV16_UPDATE = "CV16_UPDATE";
export const cv16_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv16/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV16_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV16 Details
export const CV16_DETAILS = "CV16_DETAILS";
export const cv16_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv16/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV16_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV16 Show
export const CV16_SHOW = "CV16_SHOW";
export const cv16_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv16/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV16_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv17
// CV17 Insert
export const CV17_INSERT = "CV17_INSERT";
export const cv17_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv17/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV17_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV17 Update
export const CV17_UPDATE = "CV17_UPDATE";
export const cv17_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv17/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV17_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV17 Details
export const CV17_DETAILS = "CV17_DETAILS";
export const cv17_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv17/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV17_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV17 Show
export const CV17_SHOW = "CV17_SHOW";
export const cv17_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv17/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV17_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV18 Insert
export const CV18_INSERT = "CV18_INSERT";
export const cv18_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv18/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV18_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV18 Update
export const CV18_UPDATE = "CV18_UPDATE";
export const cv18_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv18/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV18_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV18 Delete
export const CV18_DELETE = "CV18_DELETE";
export const cv18_delete = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.delete(
        `http://localhost:8000/api/cv18/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV18_DELETE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV18 Details
export const CV18_DETAILS = "CV18_DETAILS";
export const cv18_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv18/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV18_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV18 Show
export const CV18_SHOW = "CV18_SHOW";
export const cv18_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv18/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV18_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv19
// CV19 Insert
export const CV19_INSERT = "CV19_INSERT";
export const cv19_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv19/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV19_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV19 Update
export const CV19_UPDATE = "CV19_UPDATE";
export const cv19_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv19/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV19_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV19 Delete
export const CV19_DELETE = "CV19_DELETE";
export const cv19_delete = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.delete(
        `http://localhost:8000/api/cv19/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV19_DELETE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV19 Details
export const CV19_DETAILS = "CV19_DETAILS";
export const cv19_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv19/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV19_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV19 Show
export const CV19_SHOW = "CV19_SHOW";
export const cv19_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv19/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV19_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

//cv20
// CV20 Insert
export const CV20_INSERT = "CV20_INSERT";
export const cv20_insert = (
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        "http://localhost:8000/api/cv20/insert",
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV20_INSERT, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV20 Update
export const CV20_UPDATE = "CV20_UPDATE";
export const cv20_update = (
  id,
  color,
  font,
  font_size,
  line_height,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/cv20/update/${id}`,
        {
          color,
          font,
          font_size,
          line_height,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV20_UPDATE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV20 Delete
export const CV20_DELETE = "CV20_DELETE";
export const cv20_delete = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.delete(
        `http://localhost:8000/api/cv20/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: CV20_DELETE, payload: { response, message } });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV20 Details
export const CV20_DETAILS = "CV20_DETAILS";
export const cv20_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/cv20/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: CV20_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};

// CV20 Show
export const CV20_SHOW = "CV20_SHOW";
export const cv20_show = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/cv20/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: CV20_SHOW, payload: response.data.data });
    } catch (error) {
      console.log(error);
    }
  };
};
