import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const Education_data = ({ third_content_bg_color, ten_content_bg_color }) => {
  //education data
  const line_height = useSelector((state) => state.open.line_height);
  const bg_header_color = useSelector((state) => state.open.selectedColor);
  const city = useSelector((state) => state.education.city);
  const scholl = useSelector((state) => state.education.scholl);
  const start_date = useSelector((state) => state.education.start_date);
  const end_date = useSelector((state) => state.education.end_date);
  const description = useSelector((state) => state.education.description);
  const font_size = useSelector((state) => state.open.font_size);

  const combineDates = (start_date, end_date, city, scholl, description) => {
    const mergedDates = [];
    const maxLength = Math.max(
      start_date?.length || 0,
      end_date?.length || 0,
      city?.length || 0,
      scholl?.length || 0,
      description?.length || 0
    );
    for (let i = 0; i < maxLength; i++) {
      mergedDates.push({
        startDate: start_date ? start_date[i] || "" : "",
        endDate: end_date ? end_date[i] || "" : "",
        scholl: scholl ? scholl[i] || "" : "",
        description: description ? description[i] || "" : "",
        city: city ? city[i] || "" : "",
      });
    }
    return mergedDates;
  };

  const [isSmallSize, setIsSmallSize] = useState(false);

  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  });
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={
          isSmallSize
            ? "text-[10px] max-w-[75px]"
            : "max-w-[210px] text-[15px] mt-[20px]"
        }
        style={{ fontSize: font_size }}
      >
        {scholl.length > 0 ||
        city.length > 0 ||
        start_date.length > 0 ||
        end_date.length > 0 ||
        description.length > 0 ? (
          <div
            className={
              third_content_bg_color || ten_content_bg_color
                ? "text-center"
                : null
            }
          >
            <div
              className={
                third_content_bg_color
                  ? ` mb-[-8px]   text-center border-[2px] rounded-[20px] font-bold`
                  : isSmallSize
                  ? "text-[10px] mb-[-8px]"
                  : ten_content_bg_color
                  ? " border-[2px] mb-[20px] max-w-[91%]  font-bold"
                  : "bg-color rounded-[20px] toTop max-w-[91%] font-bold "
              }
              // `1px solid ${bg_header_color}`
              style={{
                border:
                  third_content_bg_color || ten_content_bg_color
                    ? `1px solid ${bg_header_color} `
                    : null,
                third_content_bg_color,
              }}
            >
   
   Education
            </div>
            <hr
              className={`my-[10px] ${
                third_content_bg_color || ten_content_bg_color
                  ? "d-none"
                  : "max-w-[200px]"
              }`}
            />{" "}
          </div>
        ) : null}
        <div className={third_content_bg_color ? " pt-[20px] " : null}>
          <div className="flex gap- flex-col ">
            {combineDates(start_date, end_date, city, scholl, description).map(
              (combined, index) => (
                <div
                  key={index}
                  className={
                    isSmallSize ? "flex flex-col  " : "flex flex-col  gap-1"
                  }
                >
                  <div className="">
                    <span className="toTop flex max-w-[100]  break-words  gap-1   ">
                      <span className=" break-words max-w-[200px]">
                        {" "}
                        {combined.startDate} - {combined.endDate}{" "}
                        {combined.city}{" "}
                      </span>
                    </span>
                  </div>
                  <p
                    className={
                      isSmallSize
                        ? "max-w-[80px] break-words"
                        : "max-w-[200px] break-words toTop font-bold	"
                    }
                  >
                    {combined.scholl}
                  </p>
                  <p
                    className={
                      isSmallSize
                        ? "max-w-[80px] break-words truncate"
                        : "max-w-[200px] break-words toTop	"
                    }
                  >
                    {combined.description}
                  </p>
                  <br />
                </div>
              )
            )}
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default Education_data;
