// import HeightIcon from "@mui/icons-material/Height";
// import FontDownloadIcon from "@mui/icons-material/FontDownload";
// import BottomNavigation from "@mui/material/BottomNavigation";
// import ColorLensIcon from "@mui/icons-material/ColorLens";
// import { Dropdown } from "flowbite-react";
// import FormatSizeIcon from "@mui/icons-material/FormatSize";
// import "../assets/style/bottom-nav.css";
// import { Chrome } from "@uiw/react-color";
// import Use_bottom_nav_hook from "../hooks/appbar hook/bottom-nav-hook";

// const Bottom_nav = () => {
//   const {
//     handleClose,
//     handleSelect,
//     handleFontSelect,
//     handleFontSizeSelect,
//     handleBg_Open_color,
//     handleChange,
//     handleBgColorChange,
//     handleDropdownClose,
//     selectedColor,
//     value,
//     setFontFamily_close,
//   } = Use_bottom_nav_hook();

//   return (
//     <div className="bottom-navigation-container flex justify-center">
//       <BottomNavigation
//         value={value}
//         onChange={handleChange}
//         className="bottom-navigation fixed bottom-0 bg-gray-200 text-center w-[50%]"
//       >
//         <div className="divAll flex items-center gap-8 mr-[90px]">
//           <Dropdown
//             label={
//               <ColorLensIcon
//                 onClose={handleDropdownClose}
//                 className="text-gray-700 bottoNav-icons"
//                 style={{ fontSize: "40px" }}
//               />
//             }
//             onClick={handleBg_Open_color}
//             inline
//           >
//             <Dropdown.Item onClick={() => handleSelect("dashboard")}>
//               <Chrome color={selectedColor} onChange={handleBgColorChange} />
//             </Dropdown.Item>
//           </Dropdown>

//           <Dropdown
//             className="w-[200px] max-h-[300px] overflow-y-scroll"
//             label={
//               <FontDownloadIcon
//                 className="text-gray-700 bottoNav-icons"
//                 style={{ fontSize: "40px" }}
//               />
//             }
//             onClose={() => setFontFamily_close(false)}
//             inline
//           >
//             <Dropdown.Item
//               className="text-[21px] my-[15px] font-[550]"
//               onClick={() => handleFontSelect("dashboard")}
//             >
//               FONT
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Arial, sans-serif")}
//             >
//               Arial
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Helvetica, sans-serif")}
//             >
//               Helvetica
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Times New Roman, serif")}
//             >
//               Times New Roman
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Georgia, serif")}
//             >
//               Georgia
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Verdana, sans-serif")}
//             >
//               Verdana
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Courier New, monospace")}
//             >
//               Courier New
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Lucida Console, monospace")}
//             >
//               Lucida Console
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Trebuchet MS, sans-serif")}
//             >
//               Trebuchet MS
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Palatino Linotype, serif")}
//             >
//               Palatino Linotype
//             </Dropdown.Item>
//             <Dropdown.Item
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//               onClick={() => handleFontSelect("Impact, sans-serif")}
//             >
//               Impact
//             </Dropdown.Item>
//             <div
//               onClick={() => handleClose()}
//               className="select-items flex justify-center cursor-pointer border mx-[10px] rounded font-bold text-[20px] hover:bg-[#ffb923]"
//             >
//               <Dropdown.Item className="select-items text-center">
//                 Close
//               </Dropdown.Item>
//             </div>
//           </Dropdown>

//           <Dropdown
//             className="w-[200px] max-h-[300px] overflow-y-scroll"
//             label={
//               <HeightIcon
//                 className="text-gray-700 bottoNav-icons"
//                 style={{ fontSize: "40px" }}
//               />
//             }
//             inline
//           >
//             <Dropdown.Item
//               className="text-[21px] my-[15px] font-[550] whitespace-nowrap"
//               onClick={() => handleSelect("dashboard")}
//             >
//               LINE HEIGHT
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleSelect("1rem")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               1
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleSelect("1.15rem")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               1.15
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleSelect("1.25rem")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               1.25
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleSelect("1.5rem")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               1.5
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleSelect("2rem")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               2
//             </Dropdown.Item>
//             <div
//               onClick={() => handleClose()}
//               className="select-items flex justify-center cursor-pointer border mx-[10px] rounded font-bold text-[20px] hover:bg-[#ffb923]"
//             >
//               <Dropdown.Item className="select-items text-center">
//                 Close
//               </Dropdown.Item>
//             </div>
//           </Dropdown>

//           <Dropdown
//             className="w-[200px] max-h-[300px] overflow-y-scroll"
//             label={
//               <FormatSizeIcon
//                 className="text-gray-700 bottoNav-icons"
//                 style={{ fontSize: "40px" }}
//               />
//             }
//             inline
//           >
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("dashboard")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               FONT SIZE
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("settings")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               Settings
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("14px")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               S
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("16px")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               M
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("18px")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               L
//             </Dropdown.Item>
//             <Dropdown.Item
//               onClick={() => handleFontSizeSelect("20px")}
//               className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
//             >
//               XL
//             </Dropdown.Item>
//           </Dropdown>
//         </div>
//       </BottomNavigation>
//     </div>
//   );
// };

// export default Bottom_nav;
import HeightIcon from "@mui/icons-material/Height";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import BottomNavigation from "@mui/material/BottomNavigation";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Dropdown } from "flowbite-react";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import "../assets/style/bottom-nav.css";
import { Chrome, Sketch } from "@uiw/react-color";
import Use_bottom_nav_hook from "../hooks/appbar hook/bottom-nav-hook";
import { Box } from "@mui/material";

const Bottom_nav = () => {
  const {
    handleClose,
    handleSelect,
    handleFontSelect,
    handleFontSizeSelect,
    handleBg_Open_color,
    handleChange,
    handleBgColorChange,
    handleDropdownClose,
    selectedColor,
    value,
    setFontFamily_close,
  } = Use_bottom_nav_hook();

  return (
    <div className="bottom-navigation-container flex justify-center">
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className="bottom-navigation flex flex-wrap gap-[25px] fixed bottom-0 bg-gray-200 text-center w-[50%]"
      >
          <Dropdown
            label={
              <ColorLensIcon
                onClose={handleDropdownClose}
                className="text-gray-700 bottoNav-icons"
                style={{ fontSize: "40px" }}
              />
            }
            onClick={handleBg_Open_color}
            inline
          >
            <Dropdown.Item onClick={() => handleSelect("dashboard")}>
              <Sketch color={selectedColor} onChange={handleBgColorChange} />
            </Dropdown.Item>
          </Dropdown>

          <Dropdown
            className="w-[200px] max-h-[300px] overflow-y-scroll"
            label={
              <FontDownloadIcon
                className="text-gray-700 bottoNav-icons"
                style={{ fontSize: "40px" }}
              />
            }
            onClose={() => setFontFamily_close(false)}
            inline
          >
            <Dropdown.Item
              className="text-[21px] my-[15px] font-[550]"
              onClick={() => handleFontSelect("dashboard")}
            >
              FONT
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Arial, sans-serif")}
            >
              Arial
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Helvetica, sans-serif")}
            >
              Helvetica
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Times New Roman, serif")}
            >
              Times New Roman
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Georgia, serif")}
            >
              Georgia
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Verdana, sans-serif")}
            >
              Verdana
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Courier New, monospace")}
            >
              Courier New
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Lucida Console, monospace")}
            >
              Lucida Console
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Trebuchet MS, sans-serif")}
            >
              Trebuchet MS
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Palatino Linotype, serif")}
            >
              Palatino Linotype
            </Dropdown.Item>
            <Dropdown.Item
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
              onClick={() => handleFontSelect("Impact, sans-serif")}
            >
              Impact
            </Dropdown.Item>
            <div
              onClick={() => handleClose()}
              className="select-items flex justify-center cursor-pointer border mx-[10px] rounded font-bold text-[20px] hover:bg-[#ffb923]"
            >
              <Dropdown.Item className="select-items text-center">
                Close
              </Dropdown.Item>
            </div>
          </Dropdown>

          <Dropdown
            className="w-[200px] max-h-[300px] overflow-y-scroll"
            label={
              <HeightIcon
                className="text-gray-700 bottoNav-icons"
                style={{ fontSize: "40px" }}
              />
            }
            inline
          >
            <Dropdown.Item
              className="text-[21px] my-[15px] font-[550] whitespace-nowrap"
              onClick={() => handleSelect("dashboard")}
            >
              LINE HEIGHT
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSelect("1rem")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              1
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSelect("1.15rem")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              1.15
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSelect("1.25rem")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              1.25
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSelect("1.5rem")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              1.5
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleSelect("2rem")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              2
            </Dropdown.Item>
            <div
              onClick={() => handleClose()}
              className="select-items flex justify-center cursor-pointer border mx-[10px] rounded font-bold text-[20px] hover:bg-[#ffb923]"
            >
              <Dropdown.Item className="select-items text-center">
                Close
              </Dropdown.Item>
            </div>
          </Dropdown>

          <Dropdown
            className="w-[200px] max-h-[300px] overflow-y-scroll"
            label={
              <FormatSizeIcon
                className="text-gray-700 bottoNav-icons"
                style={{ fontSize: "40px" }}
              />
            }
            inline
          >
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("dashboard")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              FONT SIZE
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("settings")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("14px")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              S
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("16px")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              M
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("18px")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              L
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFontSizeSelect("20px")}
              className="select-items text-[19px] my-[20px] font-[500] whitespace-nowrap"
            >
              XL
            </Dropdown.Item>
          </Dropdown>
      </BottomNavigation>
    </div>
  );
};

export default Bottom_nav;
