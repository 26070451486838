import { DESCRIPTION } from "../action/profile-acton";

const enitialState={
description:[],


}
  export const  profile_reducer = (state=enitialState , action) => {
//ADD user
switch (action.type) {
  //add
    case DESCRIPTION: 
      return {...state ,
        description:action.description ,
      }
 
    default:
     return state;
  }
  }
