import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';

import { garden_paginate, home_appliance_paginate, legal_consultation_paginate, nail_paginate, page_electrical, page_services, page_vehicle, veterinary_paginate } from '../../../../../redux/action/services-action';

const LegalPagination = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.services.searchPerformed
      ? state.services.total_pages_search
      :    state.services.legal_total_pages

  ); 


    const country_search_value = useSelector((state) => state.services.first_search_value)||"";
    const city_search_value = useSelector((state) => state.services.second_search_value)||"";
    const state_search_value = useSelector((state) => state.services.third_search_value)||"";


  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {

    dispatch(legal_consultation_paginate(page,country_search_value,city_search_value,state_search_value));
    setCurrentPage(page);
  };
  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default LegalPagination;
