import React, { useEffect, useState } from "react";
import "../../assets/style/quation.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HomeFooter from "../../sub component/home/footer";
import { Helmet } from "react-helmet";
import { user_action } from "../../redux/action/cv-action";
import { useDispatch } from "react-redux";
import Loading from "../../sub component/show design data/loading";
const What_isCV = () => {
  const { t, i18n } = useTranslation();
  const renderTranslatedText = () => {
    return { __html: t("whatis_content") };
  };
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
        <Helmet>
        {/* Basic Meta Tags */}
        <title>What is a CV ? - Karbein</title>
        <meta
          name="description"
          content="Learn what a CV is and why it's essential for your career. Our comprehensive guide covers everything from CV structure to key tips for creating an impactful curriculum vitae that stands out to employers."
        />
        <meta
          name="keywords"
          content="CV, curriculum vitae, what is a CV, CV guide, CV tips, career development , karbein"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="What is a CV ? - Karbein" />
        <meta
          property="og:description"
          content="Discover what a CV is and why it's crucial for your career advancement. Our guide provides detailed insights and tips for creating an effective curriculum vitae that grabs attention."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/cv/whatIs" />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="What is a CV ? - Karbein" />
        <meta
          name="twitter:description"
          content="Explore what a CV is and learn essential tips for crafting an effective curriculum vitae. Our guide helps you create a standout CV that highlights your qualifications and skills."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/cv/whatIs" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/cv/whatIs" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Article",
              "headline": "What is a CV ? - Karbein",
              "description": "Discover what a CV is and learn essential tips for crafting an effective curriculum vitae. Our guide helps you create a standout CV that highlights your qualifications and skills.",
              "image": "https://karbein.com/images/Brand-photos.png",
              "url": "https://karbein.com/cv/whatIs",
              "publisher": {
                "@type": "Organization",
                "name": "Karbein",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://karbein.com/images/Brand-photos.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <div className="flex justify-between flex-col min-h-screen">
          <div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="quations container m md:pt-[140px] pt-[110px]"
          >
            <div className="mx-[-10px] ">
              <h1 className="quation-title  font-bold text-[50px] mx-[100px]">
                {" "}
                {t("whatis_header")}
              </h1>
              <br />
              <br />
              <p className="articles-text text-gray-700 md:mt-0 mt-[-30px] mx-[100px]">
                {t("whatis_content")}

                <br />
                <br />
              </p>
            </div>
          </div>
          <HomeFooter />
        </div>
      )}
    </>
  );
};

export default What_isCV;
