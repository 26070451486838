import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { contact_action } from '../../redux/action/contact-action';

const Use_Contact_hook = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  return {setPhone,setAddress,setEmail,phone, email, address,}
}

export default Use_Contact_hook
