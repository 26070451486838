// freelancer-reducer.js
import { EXPERIENCESEARCHVALUE, FREELANCERINSERT, FREELANCERUPDATE, FREELANCERUPDATEDETAILS, FREELANCER_DETAILS, FREELANCER_GET, FREELANCER_SEARCH_GET, FUNCTIONAL_AREA_SEARCHVALUE, PAGEFREELANCER, REVIEW_GET, REVIEW_INSERT, REVIEW_PAGE, SEARCHFREELANCER, SEARCHVALUE, USER_REVIEW_GET } from "../action/freelancer-action";

const initialState = {
  freelancer_get: [],
  freelancer_search_get: [],

    freelancer_details: [],

  loading: true,
  freelancer_search: [],
  freelancer_page: [],
  total_pages: null, // Total pages for all freelancers
  total_pages_search: null, // Total pages for search results
  searchPerformed: false,
  search_value:[],
  second_search_value:[],
  third_search_value:[],
  freelancer_insert:[],
review_get_data:[],
review_insert:[],
// get total freelancers for pagination if data>10 then show paginations 
total_freelancers:[],
// to user just can one time insert user
isInserted: false,
  freelancer_update_details: [],

  //user review
  user_review:[],
  reviews_page:[],
  reviews_total_page:[],
  total_reviews:[]
};

export const freelancer_reducer = (state = initialState, action) => {
  switch (action.type) {
    case FREELANCER_GET:
      return {
        ...state,
        freelancer_get: action.payload.data,
        total_pages: action.payload.total_pages,
        total_freelancers: action.payload.total_freelancers,
        loading: false,

      };
      case REVIEW_INSERT:
        return {
          ...state,
          review_insert: action.payload.data,
       
        };




      case FREELANCERINSERT:
        return {
          ...state,
          isInserted:action.payload.isInserted,
  
        };
        
        case FREELANCERUPDATE:
          return {
            ...state,
            isInserted:action.payload.isInserted,
    
          };  

      case FREELANCER_SEARCH_GET:
        return {
          ...state,
          freelancer_search_get: action.payload.data,
       
        };


      case FREELANCER_DETAILS:
      return {
        ...state,
        freelancer_details: action.payload.data,
        loading: false
      };
    case SEARCHFREELANCER:
      return {
        ...state,
        freelancer_search: action.payload.data,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true
      };
    case PAGEFREELANCER:
      return {
        ...state,
        freelancer_page: action.payload.data
      };
      case REVIEW_PAGE:
        return {
          ...state,
          reviews_page: action.payload.data
        };
      //update freelancer 
       case FREELANCERUPDATEDETAILS:
      return {
        ...state,
        freelancer_update_details: action.payload.data,
                loading: false

      };
      
      case SEARCHVALUE:
      return {
        ...state,
        search_value: action.search
      };
      case FUNCTIONAL_AREA_SEARCHVALUE:
        return {
          ...state,
          second_search_value: action.search
        };
         case EXPERIENCESEARCHVALUE:
      return {
        ...state,
        third_search_value: action.search
      };
      case REVIEW_GET:
        return {
          ...state,
          review_get_data: action.payload.data,
          reviews_total_page: action.payload.total_pages,
          total_reviews:action.payload.total_reviews,
        };
        //user review
        case USER_REVIEW_GET:
          return {
            ...state,
            user_review: action.payload,
          };
    default:
      return state;
  }
};
