import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { page_freeLancer } from "../../../redux/action/freelancer-action";
import { page_job } from "../../../redux/action/job-action";
import { login } from "../../../redux/action/auth-action";

const JobPagination = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector((state) =>
    state.job.searchPerformed
      ? state.job.total_pages_search
      : state.job.total_pages
  );
  const search_get_value = useSelector((state) => state.job.search_value) || "";
  const srcond_search_get_value =
    useSelector((state) => state.job.second_search_value) || "";
  const third_search_get_value =
    useSelector((state) => state.job.third_search_value) || "";
  const fourth_search_get_value =
    useSelector((state) => state.job.fourth_search_value) || "";
  const fifth_search_value =
    useSelector((state) => state.job.fifth_search_value) || "";
  const sixth_search_value =
    useSelector((state) => state.job.sixth_search_value) || "";
  const seventh_search_value =
    useSelector((state) => state.job.seventh_search_value) || "";

  const eighth_search_value =
    useSelector((state) => state.job.eighth_search_value) || "";
  const ninth_search_value =
    useSelector((state) => state.job.ninth_search_value) || "";
  const tenth_search_value =
    useSelector((state) => state.job.tenth_search_value) || "";

  const [currentPage, setCurrentPage] = useState(1);
  const freelancer_search = useSelector(
    (state) => state.freelancer.freelancer_search
  );
  const handlePageChange = (event, page) => {
    dispatch(
      page_job(
        page,
        search_get_value,
        srcond_search_get_value,
        third_search_get_value,
        seventh_search_value,
        fourth_search_get_value,
        sixth_search_value,
        fifth_search_value,
        eighth_search_value,
        tenth_search_value,
        ninth_search_value
      )
    );
    setCurrentPage(page);
  };
  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: "#ffb923" }}
    />
  );
};

export default JobPagination;
