import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import MonetizationOnOutlinedIcon from "@mui/icons-material/AttachMoney";
import {
  experience_search_value,
  freelancer_get,
  freelancer_search_get,
  functiona_area_search_value,
  review_get,
  search_freelancer,
  search_value,
} from "../../../redux/action/freelancer-action";
import "../../../assets/style/main-pages.css";
import brand_image from "../../../assets/image/Brand-photos.png";
import Cookies from "js-cookie";

import Loading from "../../../sub component/show design data/loading";
import "../../../assets/style/search_loading.css";
import Search_loading from "../search loading/search-loading";
import HomeFooter from "../../../sub component/home/footer";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { user_action } from "../../../redux/action/cv-action";
import { useTranslation } from "react-i18next";
import { login, user_get } from "../../../redux/action/auth-action";
import PaginationComponent from "./freeLancer_pagoinations";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Helmet } from "react-helmet";
import { Box, Rating } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import Filters from "./filter";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Main_freeLancers = () => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(false);

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [title, setTitle] = useState([]);
  const [experience, setExperience] = useState([]);

  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);
  const freeLance_searchData = useSelector(
    (state) => state.freelancer.freelancer_search_get
  );
  //get  total number freelancers for pagination if data>10 then show paginations
  const total_freelancers = useSelector(
    (state) => state.freelancer.total_freelancers
  );
  const tokens = Cookies.get("hama%20cookie");
  const experience_data = [
    { value: t("one_to_3_years"), label: "1 to 3 years" },
    { value: t("four_to_6_years"), label: "4 to 6 years" },
    { value: t("more_than_6"), label: "More than 6 years" },
  ];

  const freelancer_search = useSelector(
    (state) => state.freelancer.freelancer_search
  );
  const freelancer_page = useSelector(
    (state) => state.freelancer.freelancer_page
  );
  const users = useSelector((state) => state.auth.users_get);
  //henany barwar
  const isExpired = (subscriptionDate) => {
    const now = new Date();
    return new Date(subscriptionDate) < now;
  };
  //felter krdny hamu datakan bo away har userek paymenty nakrdbu hide bkre w neshan nadre
  // filter krdny freelance data  agar aw user ay ka freelance kay drust krdwa  agar paray nadabe yan basasr chube awa asrdretawa wata  away paray nadabe neshan nadre

  //end filtering data
  useEffect(() => {
    setData(freeLanceData);
  }, [freeLanceData]);

  useEffect(() => {
    setSearchData(freeLance_searchData);
  }, [freeLance_searchData]);

  useEffect(() => {
    setData(freelancer_search);
  }, [freelancer_search]);

  useEffect(() => {
    setData(freelancer_page);
  }, [freelancer_page]);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(true);

      await dispatch(freelancer_get());
      await dispatch(freelancer_search_get());
      await dispatch(user_action());

      await dispatch(user_get());

      setError(false);

      setLoading(false);
    };

    fetchData();
  }, [dispatch]);
  useEffect(() => {
    console.clear();
  }, [error]);
  const review_get_data = useSelector(
    (state) => state.freelancer.review_get_data
  );
  const [value, setValue] = useState();

  //accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [searchLoading, setSearchLoading] = useState(false);

  const searchSubmit = async () => {
    setSearchLoading(true);
    await dispatch(search_freelancer(search, title, experience));
    setSearchLoading(false);
    dispatch(search_value(search));

    // Set loading to false after dispatch
  };
  // useEffect(() => {
  //   dispatch(user_action());
  // }, []);

  const user_id = useSelector((state) => state.cv.user.id);

  const searchPerformed = useSelector(
    (state) => state.freelancer.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const navigate = useNavigate(); // Initialize useNavigate hook
  //cheak agar  token  nabu   atba bo login balam agar habu du 7alat haya agar am user freelancere drust krdbe atba bo update balam agar druste nakrdbe atba bo add freelancer

  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(search_freelancer(search, title, experience));
      setSearchLoading(false);
      dispatch(functiona_area_search_value(title));
      dispatch(experience_search_value(experience));
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (title !== "" || experience !== "") {
      Search();
    }
  }, [title, experience]);

  const shownTitle = new Set();
  const shownPhoneTitle = new Set();

  const shownExperience = new Set();

  const TitleCount = searchData.reduce((acc, item) => {
    acc[item.functional_area] = (acc[item.functional_area] || 0) + 1;
    return acc;
  }, {});
  const experienceCount = searchData.reduce((acc, item) => {
    acc[item.experience] = (acc[item.experience] || 0) + 1;
    return acc;
  }, {});

  const handleTitleChange = (value) => {
    if (title === value) {
      // If the same checkbox is clicked again, uncheck it
      setTitle(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setTitle(value);
    }
  };

  const handleExperienceChange = (value) => {
    if (experience === value) {
      // If the same checkbox is clicked again, uncheck it
      setExperience(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setExperience(value);
    }
  };
  const services_data = useSelector((state) => state.lecturers.lecturer_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  const user = freeLance_searchData.find(
    (user) => user.user_id === `${user_id}`
  );
  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Freelancers - Karbein</title>
        <meta
          name="description"
          content="Discover and connect with top professional freelancers. Use our search and filter features to find the perfect freelancer for your project. Explore various profiles, view ratings, and make informed decisions."
        />
        <meta
          name="keywords"
          content="freelancers, professional freelancers, find freelancers, freelancer search, freelancer filter, freelance services, hire freelancers , Karbein"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Freelancers - Karbein" />
        <meta
          property="og:description"
          content="Explore and hire skilled freelancers with ease. Utilize our advanced search and filtering tools to match the right freelancer to your needs. Browse profiles, check ratings, and hire with confidence."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/freelance" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Freelancers - Karbein" />
        <meta
          name="twitter:description"
          content="Find and connect with expert freelancers. Utilize our search and filter options to match the right freelancer to your projects. Browse profiles, view ratings, and make informed hiring decisions."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/freelance" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/freelance" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Freelancers",
        "description": "Browse and hire top freelancers with ease. Use our advanced tools to search and filter for professionals who meet your project needs.",
        "url": "https://karbein.com/freelance",
        "image": "https://karbein.com/images/Brand-photos.png"
      }
    `}
        </script>
      </Helmet>

      <div>
        {loading ? (
          <Loading />
        ) : searchLoading ? (
          <Search_loading />
        ) : (
          <div className="min-h-screen flex flex-col justify-between">
            <div className="pt-[70px] mx-auto w-full main-freeLancer-container">
              <div
                style={{
                  direction:
                    i18n.language === "ku" || i18n.language === "ar"
                      ? "rtl"
                      : "ltr",
                }}
                className="w-full md:h-[250px] flex  flex-col justify-center bg-[#ffb923] h-[180px] "
              >
                <div className="flex justify-center w-full  items-center">
                  <div className="flex flex-col mx-[50px]  w-full items-center gap-4">
                    <h1 className="text-white font-[300] md:text-[22px] text-[18px] text-center">
                      {t("Top_Freelancers_search")}
                    </h1>
                    <div className="searchbar ">
                      <form
                        onSubmit={searchSubmit}
                        className="searchbar-wrapper"
                      >
                        <div className="searchbar-left flex gap-2 px-[13px]">
                          <button type="submit" className="search-icon-wrapper">
                            <span className="search-icon searchbar-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                              </svg>
                            </span>
                          </button>
                          <div className="bg-gray-300  h-[20px]  w-[1px]"></div>
                        </div>

                        <div
                          onClick={toggleDropdown}
                          className="searchbar-center "
                        >
                          <div className="searchbar-input-spacer"></div>
                          <input
                            type="text"
                            className="searchbar-input main-freeLancer-container-text input_add_freelancer"
                            maxLength="2048"
                            name="q"
                            autoCapitalize="off"
                            autoComplete="off"
                            title="Search"
                            role="combobox"
                            required
                            placeholder={t("search")}
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </form>

                      {/* Dropdown div */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex w-full md:absolute justify-center z-10   w-ful ">
                {isDropdownOpen && (
                  <div className=" freelance-filter mx-[16px]  border w-[600px]  max-h-[250px] overflow-hidden  md:mt-[-50px]   rounded-xl bg-white">
                    {/* Add your dropdown content here */}
                    <div
                      className=" flex  flex-end"
                      style={{
                        direction:
                          i18n.language === "ku" || i18n.language === "ar"
                            ? "rtl"
                            : "ltr",
                      }}
                    >
                      <div className="flex w-full flex-col mx-[20px]">
                        {" "}
                        <div className="flex min-w-full mt-[20px]  mb-[25px]  max-h-[220px] overflow-auto justify-center  gap-[30px]">
                          <div className="grid  w-full gap-6 mb-6 md:grid-cols-1">
                            <div>
                              <p className="">{t("Job_Title")}</p>
                              <hr className="mb-[20px] w-[95%] mt-1" />
                              <div className="flex flex-col min-w-full overflow-y-auto overflow-x-hidden  gap-[20px]">
                                {searchData.map((data) => {
                                  if (shownTitle.has(data.functional_area)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownTitle.add(data.functional_area); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={title === data.functional_area}
                                        onChange={() =>
                                          handleTitleChange(
                                            data.functional_area
                                          )
                                        }
                                      />
                                      <div className="flex gap-1 justify-between w-full items-center">
                                        <h1 className="break-words main-freeLancer-container-text max-w-[250px] line-clamp-1 ">
                                          {data.functional_area}
                                        </h1>
                                 
                                        <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] min-w-[25px] max-w-[8ch]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[6ch]  truncate  main-freeLancer-container-text ">
                                          {TitleCount[data.functional_area]}
                                            </p>
                                          </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                onClick={() => {
                  setIsDropdownOpen(false);
                }}
                className="max-w-[1570px]  md:p-4 my:px-0 pt-4 freelance-div justify-center mx-auto"
              >
                <div className="flex justify-center min-w-[100%]  md:pt-[50px] pt-[40px]">
                  <div className="main-freeLancer-container main-freeLancer-details-container min-w-[100%] flex justify-center gap-[40px]">
                    <div className="flex flex-col min-w-[100%]">
                      <div className="flex justify-end">
                        <Link
                          to={
                            token
                              ? user
                                ? "/freelance/update "
                                : "/freelance/add"
                              : "/login"
                          }
                          className="left-search-button flex justify-center md:text-[15px]  text-[12px] items-center md:mt-[-50px]   mt-[-40px] md:w-[180px] w-[130px] max-w-[130px] md:max-w-[180px] bg-[#ffb923] rounded-[5px] text-white md:h-[40px] h-[30px] hover:bg-[#ffcc5f]"
                        >
                          {user ? t("freelancer_update") : t("Add_freelance")}
                        </Link>
                      </div>
                      {data.length === 0 && (
                        <div className=" flex justify-center mt-[20px] main-freeLancer-container-text text-center items-center h-[100%] main--container-text">
                          {t("not_haveData")}
                        </div>
                      )}
                      <div className="grid grid-cols-1 2xl:grid-cols-4 xl:grid-cols-3  md:grid-cols-2 max-w-[1700px] gap-4 min-w-[100%]">
                        {data.map((data) => {
                          return (
                            <div className="swiper-slide ">
                              <Link
                                target="blank"
                                key={data.id}
                                to={
                                  token
                                    ? `/freelance/freelance-detail/${data.id}`
                                    : "/login"
                                }
                              >
                                {" "}
                                <div className="group bg-white flex justify-between flex-col  sm:min-h-[275px] sm:max-h-[275px] min-h-[260px] max-h-[260px] border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500 w-full mx-auto hover:border-indigo-600 hover:shadow-sm slide_active:border-indigo-600">
                                  <div className="max-w-full">
                                    <div className="flex items-center mb-3 gap-2 text-amber-500 transition-allduration-500">
                                      <Box
                                        className="mt-1"
                                        sx={{
                                          "& > legend": { mt: 2 },
                                        }}
                                      >
                                        <Rating
                                          name="size-small"
                                          readOnly
                                          value={Number(data.average_rating)}
                                          size="medium"
                                        />
                                      </Box>
                                    </div>
                                    <p className="text-[13px] overflow-hidden  sm:min-h-[70px]  min-h-[60px]  sm:text-[15px] text-gray-600 line-clamp-3 break-words duration-500 mb-4">
                                      {data.description}
                                    </p>
                                  </div>
                                  <div className="flex items-center max-w-full gap-3.5 border-t border-solid border-gray-200 pt-4">
                                    <img
                                      className="rounded-full mb-[25px] card-avatar p-0.5 border-red-900"
                                      src={data.image || brand_image}
                                      alt="avatar"
                                    />
                                    <div className="block max-w-[80%]">
                                      <h5 className="text-gray-900 sm:text-[16px] text-[13px] font-medium duration-500 max-w-full line-clamp-2 break-words mb-1">
                                        {data.name}
                                      </h5>
                                      <p className="text-[13px] w-full sm:text-[15px] text-gray-600 truncate break-words duration-500 mb-4">
                                        {data.functional_area}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="freelancer-pagination mt-[30px]  flex justify-center   ">
              {total_freelancers > 12 ? (
                <div>
                  <PaginationComponent />
                </div>
              ) : null}
            </div>
            <HomeFooter />
          </div>
        )}
      </div>
    </>
  );
};

export default Main_freeLancers;
