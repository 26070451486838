import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import axios from "axios";
import { user_action } from "../../redux/action/cv-action";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import Loading from "../../sub component/show design data/loading";
import HomeFooter from "../../sub component/home/footer";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Verify_email from "../verify-email/verify-email";

const Free_plan = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const plan_type = useSelector(
    (state) => state.cv.user.plan_type
  );
  const [Payloading, setPayloading] = useState(false); // Initialize loading state
  const [readableCode, setReadableCode] = useState();
  const navigate = useNavigate()
  const [hasDispatched, setHasDispatched] = useState(false);

  const createPayment = async () => {

    if (!hasDispatched) {
      setHasDispatched(true);
      try {

        const token = Cookies.get("jwt");
  
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/payment/freePlan`
          ,
          {
         
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await dispatch(user_action());
        toast.success(t("freePlan_succesfuly_Actived"))
        setTimeout(() => {
          navigate(-1); // Navigate to /services/Profile after the timeout
        }, 2000);
    } catch (error) {
      
      Swal.fire({
        title: t("freePlan_is_used"),
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        },
        customClass: {
          confirmButton: 'custom-button', // Apply custom CSS class to confirm button
          cancelButton: 'custom-button'   // Apply custom CSS class to cancel button if you have one
        }
      });  
    } finally {
      setPayloading(false);
     // Set loading to false once the request is complete
    }
    setTimeout(() => {
      setHasDispatched(false);
    }, 10000);

    }
  };



  const { t, i18n } = useTranslation();
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  const token = useSelector((state) => state.cv.isAuthuntucated);
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }
  return (
    <>
{
loading?
<Loading />
:
token?

Payloading ? (
  <Loading />
) : (
  isUser_verified === null ?
  <Verify_email />
:
  <>
    <div
      className=" w-full mt-[70px] "
      style={{
        direction:
          i18n.language === "ku" || i18n.language === "ar"
            ? "rtl"
            : "ltr",
      }}
    >
      <ToastContainer />

      <div class="w-full flex justify-center  px-3 ">
        <div class="mx-auto mt-8  rounded-3xl ring-1 ring-gray-200 sm:mt-12 lg:mx-0 lg:flex lg:max-w-none">
          <div class="p-8 sm:p-10 lg:flex-auto">
            <h1 class="text-2xl font-bold tracking-tight text-gray-900">
              {" "}
              {t("Free")}{" "}
            </h1>
            <p class="mt-6 text-base leading-7 text-gray-600">
              {" "}
              {t("free_info")}              </p>
            <div class="mt-10 flex items-center gap-x-4">
              
              <div class="h-px flex-auto bg-gray-100"></div>
            </div>
            <ul class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              <li class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  zoomAndPan="magnify"
                  viewBox="0 0 30 30.000001"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  version="1.0"
                  id="IconChangeColor"
                >
                  <defs>
                    <clipPath id="id1">
                      <path
                        d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                        clip-rule="nonzero"
                        id="mainIconPathAttribute"
                        fill="green"
                      ></path>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#id1)">
                    <path
                      fill="green"
                      d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                      fill-opacity="1"
                      fill-rule="nonzero"
                      id="mainIconPathAttribute"
                    ></path>
                  </g>
                </svg>{" "}
                <span class="font-bold mx-1">{t("Create_CV")}</span>
              </li>
              <li class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  zoomAndPan="magnify"
                  viewBox="0 0 30 30.000001"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  version="1.0"
                  id="IconChangeColor"
                >
                  <defs>
                    <clipPath id="id1">
                      <path
                        d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                        clip-rule="nonzero"
                        id="mainIconPathAttribute"
                        fill="green"
                      ></path>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#id1)">
                    <path
                      fill="green"
                      d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                      fill-opacity="1"
                      fill-rule="nonzero"
                      id="mainIconPathAttribute"
                    ></path>
                  </g>
                </svg>{" "}
                <span class="font-bold mx-1">{t("add_lecturer")}</span>
              </li>
              <li class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  zoomAndPan="magnify"
                  viewBox="0 0 30 30.000001"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  version="1.0"
                  id="IconChangeColor"
                >
                  <defs>
                    <clipPath id="id1">
                      <path
                        d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                        clip-rule="nonzero"
                        id="mainIconPathAttribute"
                        fill="green"
                      ></path>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#id1)">
                    <path
                      fill="green"
                      d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                      fill-opacity="1"
                      fill-rule="nonzero"
                      id="mainIconPathAttribute"
                    ></path>
                  </g>
                </svg>{" "}
                <span class="font-bold mx-1">{t("add_freelancer")}</span>
              </li>
              <li class="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  zoomAndPan="magnify"
                  viewBox="0 0 30 30.000001"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  version="1.0"
                  id="IconChangeColor"
                >
                  <defs>
                    <clipPath id="id1">
                      <path
                        d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                        clip-rule="nonzero"
                        id="mainIconPathAttribute"
                        fill="green"
                      ></path>
                    </clipPath>
                  </defs>
                  <g clip-path="url(#id1)">
                    <path
                      fill="green"
                      d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                      fill-opacity="1"
                      fill-rule="nonzero"
                      id="mainIconPathAttribute"
                    ></path>
                  </g>
                </svg>{" "}
                <span class="font-bold mx-1"> {t("Add_service")}</span>
              </li>
            </ul>
          </div>
          <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div class="rounded-2xl bg-gray-50 py-10 h-full text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
        
                <div class="mx-auto max-w-xs px-8">
                  {/* <p class="text-base font-semibold text-gray-600">
                    {" "}
                    {t("free")}
                  </p> */}
                  <p class="mt-6 mb-12 flex items-baseline justify-center gap-x-2">
                    <span class="text-4xl font-bold tracking-tight text-gray-900 ">
                      <span class="italic text-gray-700">
                        {t("Free")}
                      </span>
                    </span>
                 
                  </p>
                  <button
                    onClick={createPayment}
                    class="bg-[#ffb923] text-white rounded-md py-2 px-3 w-full block my-6"
                  >
                    {t("get_started")} →
                  </button>
                </div>
            
            </div>
          </div>
        </div>
      </div>

      {/* <button  onClick={cheakPayment} className="mt-[200px]">
cheakPayment
</button> */}
    </div>
    <HomeFooter />
  </>
)
:          <Loading />
}

   
    </>
  );
};

export default Free_plan;
