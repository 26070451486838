// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// const Languages_data = ({ third_content_bg_color,ten_content_bg_color }) => {
//   const lang = useSelector((state) => state.main.languages);
//   const range = useSelector((state) => state.main.range);
//   const font_size = useSelector((state) => state.open.font_size);
//   const bg_header_color = useSelector((state) => state.open.selectedColor);

//   const combine_languages = (lang, range) => {
//     const mergedDates = [];
//     for (let i = 0; i < Math.max(lang.length, range.length); i++) {
//       mergedDates.push({
//         lang: lang[i ]|| "",
//         range: range[i] || "",
//       });
//     }
//     return mergedDates;
//   };
//    const [isSmallSize, setIsSmallSize] = useState(false);

//   const location = useLocation();
//   const url = ["/history"];
//   const path = location.pathname;
//   useEffect(() => {
//     if (url.includes(path)) {
//       setIsSmallSize(true);
//     } else {
//       setIsSmallSize(false);
//     }
//   });
//   return (
//     <>
//       {/*show language*/}
//       <div
//         className={
//           isSmallSize
//             ? "text-[10px] mt-[20px] max-h-[40px] truncate overflow- max-w-[100px]"
//             : "   mt-[50px] pb-[50px] max-w-[200px] text-[15px]"
//         }

//         style={{ fontSize: `${font_size}` }}
//       >
//         {range.length > 0 || lang.length > 0 ? (
//           <div className={third_content_bg_color||ten_content_bg_color ? "text-center" : null}>
//             {" "}
//             <p
//               className={
//                 third_content_bg_color
//                   ? `mb-[20px]  border-[2px] rounded-[20px]`
//                      : isSmallSize
//                   ? "text-[10px] mb-[-8px]"
//                      :
//                      ten_content_bg_color? " border-[2px] mb-[20px]"

//                  :
//                   "bg-color rounded-[20px] mb-[20px]"}
//               // `1px solid ${bg_header_color}`
//               style={{

//                 border: third_content_bg_color||ten_content_bg_color
//                   ? `1px solid ${bg_header_color}`
//                   : null,
//                 third_content_bg_color,
//               }}
//             >
//               Languages
//             </p>
//             <hr
//               className={`my-[10px] ${third_content_bg_color||ten_content_bg_color ? "d-none" : ""}`}
//             />{" "}
//           </div>
//         ) : null}
//         <div
//           className={
//             third_content_bg_color ? "flex  flex-col   justify-center pt-[20px] " : null
//           }
//         >
//           {combine_languages(lang, range).map((combined, index) => (
//             <div
//               key={index}
//               className="flex flex-col  gap-2 max-w-[200px] break-words"
//             >
//               <div className="flex gap-2  ">
//                 <div className=" flex gap-2  max-w-[200px] ">
//                   {combined.lang.length > 0 ? (
//                     <p className="max-w-[120px] break-words ">
//                       {combined.lang}
//                     </p>
//                   ) : null}
//                   <div>
//                     {combined.range ? (
//                       <p className="max-w-[80px] break-words">
//                         <div className=" ">   :{" "} {combined.range}</div>
//                       </p>
//                     ) : null}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Languages_data;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Languages_data = ({ third_content_bg_color, ten_content_bg_color }) => {
  const lang = useSelector((state) => state.main.languages);
  const range = useSelector((state) => state.main.range);
  const font_size = useSelector((state) => state.open.font_size);
  const bg_header_color = useSelector((state) => state.open.selectedColor);

  const combine_languages = (lang, range) => {
    const mergedDates = [];
    for (let i = 0; i < Math.max(lang.length, range.length); i++) {
      mergedDates.push({
        lang: lang[i] || "",
        range: range[i] || "",
      });
    }
    return mergedDates;
  };

  const [isSmallSize, setIsSmallSize] = useState(false);
  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;

  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  }, [path, url]);
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* Show language */}
      <div
        className={
          isSmallSize
            ? "text-[10px] mt-[20px] max-h-[40px] truncate overflow- max-w-[100px]"
            : "mt-[20px] pb-[50px] max-w-[200px] text-[15px]"
        }
        style={{ fontSize: `${font_size}` }}
      >
        {range.length > 0 || lang.length > 0 ? (
          <div
            className={
              third_content_bg_color || ten_content_bg_color
                ? "text-center"
                : null
            }
          >
            <p
              className={
                third_content_bg_color
                  ? "mb-[-3px]   border-[2px] rounded-[20px] font-bold"
                  : isSmallSize
                  ? "text-[10px] mb-[-8px] font-bold"
                  : ten_content_bg_color
                  ? "border-[2px] font-bold mb-[20px]" 
                  : "bg-color rounded-[20px] font-bold"
              }
              style={{
                border:
                  third_content_bg_color || ten_content_bg_color
                    ? `1px solid ${bg_header_color}`
                    : null,
                backgroundColor: third_content_bg_color || ten_content_bg_color,
              }}
            >
              
              Language
            </p>
            <hr
              className={`mt-[10px] mb-[7px] ${
                third_content_bg_color || ten_content_bg_color ? "d-none" : ""
              }`}
            />
          </div>
        ) : null}
        <div
          className={
            third_content_bg_color
              ? "flex flex-col justify-center pt-[10px] "
              : null
          }
        >
          {combine_languages(lang, range).map((combined, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 li Class

leading-8 max-w-[200px] break-words"
            >
              <div className="flex gap-2">
                <div className="flex  gap-2 max-w-[200px]">
                  {combined.lang.length > 0 ? (
                    <p className="max-w-[200px] break-words">
                      <span>{combined.lang}</span>
                      <span className="max-w-[] break-words">
                        : {combined.range}
                      </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Languages_data;
