import React from 'react'
import { useSelector } from 'react-redux';

const Use_course_hook = () => {
                       //course
  const course_title = useSelector((state) => state.main.course_title);
  const course_year = useSelector((state) => state.main.course_year);
  const course_description = useSelector((state) => state.main.course_description);
     const font_size = useSelector((state) => state.open.font_size);
  const combine_courses = (course_title, course_year, course_description) => {
    const mergedDates = [];
    for (
      let i = 0;
      i <
      Math.max(
        course_title.length,
        course_year.length,
        course_description.length
      );
      i++
    ) {
      mergedDates.push({
        course_title: course_title[i],
        course_year: course_year[i],
        course_description: course_description[i],
      });
    }
    
    return mergedDates;
  };
  return {course_title,course_year,course_description,font_size,combine_courses}
}

export default Use_course_hook
