import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//lecturers insert
export const LECTURERSINSERT = "LECTURERSINSERT";

export const lecturers_insert = (
  image,
  name,
  email,
  phone,
  description,
  language,
  duration,
  price,
  currency,
  study_mode,
  numberOf_years_teaching,
  grade_level,
  Subject,
  Certifications,
  country,
  state,
  city,
  t,
  navigate 
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("description", description);
      formData.append("language", language);
      formData.append("duration", duration);
      formData.append("price", price);
      formData.append("currency", currency);
      formData.append("study_mode", study_mode);
      formData.append("numberOf_years_teaching", numberOf_years_teaching);
      formData.append("grade_level", grade_level);
      formData.append("Subject", Subject);
      formData.append("Certifications", Certifications);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);

      const response = await axios.post(
`        ${process.env.REACT_APP_URL}/api/lecturers/insert
`        ,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const  isInserted=true
      const status = response.data.data;

      dispatch({ type: LECTURERSINSERT, payload: { response, status , isInserted } });

      toast.success(t("Created_successfully")); // Using translation function passed as parameter
      navigate("/teachers/Profile"); // Navigate to /home after the timeout

    } catch (error) {
      const  isInserted=false

      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: LECTURERSINSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly

    }
  };
};

//lecturers admin  insert
export const LECTURERS_ADMIN_INSERT = "LECTURERS_ADMIN_INSERT";

export const lecturers_admin_insert = (
  image,
  name,
  email,
  phone,
  description,
  language,
  duration,
  price,
  currency,
  study_mode,
  numberOf_years_teaching,
  grade_level,
  Subject,
  Certifications,
  country,
  state,
  city,
  t,
  navigate 
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("description", description);
      formData.append("language", language);
      formData.append("duration", duration);
      formData.append("price", price);
      formData.append("currency", currency);
      formData.append("study_mode", study_mode);
      formData.append("numberOf_years_teaching", numberOf_years_teaching);
      formData.append("grade_level", grade_level);
      formData.append("Subject", Subject);
      formData.append("Certifications", Certifications);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);

      const response = await axios.post(
`        ${process.env.REACT_APP_URL}/api/lecturers/insert
`        ,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      const  isInserted=true
      const status = response.data.data;

      dispatch({ type: LECTURERS_ADMIN_INSERT, payload: { response, status , isInserted } });

      toast.success(t("Created_successfully")); // Using translation function passed as parameter
   
    } catch (error) {
      const  isInserted=false

      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: LECTURERS_ADMIN_INSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly

    }
  };
};
//lecturers update
export const LECTURERUPDATE = "LECTURERUPDATE";
export const lecturers_update = (
  image,
  name,
  email,
  phone,
    description,
language,
duration,
price,
  currency,
study_mode,
numberOf_years_teaching,
grade_level,
Subject,
Certifications,
t


) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("description", description);
      formData.append("language", language);
      formData.append("duration", duration);
      formData.append("price", price);
      formData.append("currency", currency);
      formData.append("study_mode", study_mode);
      formData.append("numberOf_years_teaching", numberOf_years_teaching);
      formData.append("grade_level", grade_level);
      formData.append("Subject", Subject);
      formData.append("Certifications", Certifications);

   
   
      const response = await axios.post(
`      ${process.env.REACT_APP_URL}/api/lecturers/update
`        ,

        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: LECTURERUPDATE, payload: { response, status } });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter

    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter
    }
  };
};

export const LECTURERS_DELETE = "FREELANCE_DELETE";
export const lecturers_delete = (
  id,

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/lecturers/delete/${id}`,
{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: LECTURERS_DELETE, payload: { response, status } });
    //   setTimeout(() => {
    //     navigate("/services/Profile"); // Navigate to /home after the timeout
    // }, 2000); 
    } catch (error) {
    }
  };
};

//lecturers update
export const LECTURER_ADMIN_UPDATE = "LECTURER_ADMIN_UPDATE";
export const lecturers_admin_update = (
  id,
  image,
  name,
  email,
  phone,
    description,
language,
duration,
price,
  currency,
study_mode,
numberOf_years_teaching,
grade_level,
Subject,
Certifications,
t


) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("description", description);
      formData.append("language", language);
      formData.append("duration", duration);
      formData.append("price", price);
      formData.append("currency", currency);
      formData.append("study_mode", study_mode);
      formData.append("numberOf_years_teaching", numberOf_years_teaching);
      formData.append("grade_level", grade_level);
      formData.append("Subject", Subject);
      formData.append("Certifications", Certifications);

   
   
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/lecturers/admin/update/${id}`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: LECTURER_ADMIN_UPDATE, payload: { response, status } });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter

    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter
    }
  };
};
export const GETPRIMARY = "GETPRIMARY";
export const get_primary = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/primary/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_lecturers = response.data.data.total;

      dispatch({ type: GETPRIMARY, payload: { data, total_pages,total_lecturers } });
    } catch (error) {
      dispatch({ type: GETPRIMARY, payload: error });
    }
  };
};
export const GETSEARDCHPRIMARY = "GETSEARDCHPRIMARY";
export const getSearch_primary = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/Primary_searchData`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: GETSEARDCHPRIMARY, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: GETSEARDCHPRIMARY, payload: error });
    }
  };
};

export const SEARCHPRIMARY = "SEARCHPRIMARY";
export const search_primary = (country= '',stateSearch= '',citySearch= '',subjectSearch= '',study_modeSearch= '',languageSearch= '',nameSearch= '') => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/primary/get?countrySearch=${country}&citySearch=${citySearch}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: SEARCHPRIMARY, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: SEARCHPRIMARY, payload: error });
    }
  };
};


export const PAGEPRIMARY = "PAGEPRIMARY";
export const page_primary = (page, country=" ",city=" ",stateSearch=" ",subjectSearch=" ",study_modeSearch="",languageSearch="",nameSearch="") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/primary/get?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: PAGEPRIMARY, payload: { data } });
    } catch (error) {
    }
  };
};

//middle
export const GETMIDDLE = "GETMIDDLE";
export const get_middle = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/middle/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_lecturers = response.data.data.total;

      dispatch({ type: GETMIDDLE, payload: { data, total_pages,total_lecturers } });
    } catch (error) {
      dispatch({ type: GETMIDDLE, payload: error });
    }
  };
};


export const GETSEARDCHMIDDLE_DATA = "GETSEARDCHMIDDLE_DATA";
export const getSearch_middle = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/Middle_searchData`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: GETSEARDCHMIDDLE_DATA, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: GETSEARDCHMIDDLE_DATA, payload: error });
    }
  };
};


export const SEARCHMIDDLE = "SEARCHMIDDLE";
export const search_middle = (country = '', stateSearch= '', citySearch= '', subjectSearch= '', study_modeSearch= '', languageSearch= '',nameSearch= '') => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/middle/get?countrySearch=${country}&citySearch=${citySearch}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: SEARCHMIDDLE, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: SEARCHMIDDLE, payload: error });
    }
  };
};

export const PAGEMIDDLE = "PAGEMIDDLE";
export const page_middle = (page, country=" ", city=" ", stateSearch=" ", subjectSearch=" ", study_modeSearch="", languageSearch="",nameSearch='') => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/middle/get?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: PAGEMIDDLE, payload: { data } });
    } catch (error) {
    }
  };
};
//high

export const GETHIGH = "GETHIGH";
export const get_high = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/high/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_lecturers = response.data.data.total;

      dispatch({ type: GETHIGH, payload: { data, total_pages,total_lecturers } });
    } catch (error) {
      dispatch({ type: GETHIGH, payload: error });
    }
  };
};


export const GETSEARDCHHIGH = "GETSEARDCHHIGH";
export const getSearch_high = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/High_searchData`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: GETSEARDCHHIGH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: GETSEARDCHHIGH, payload: error });
    }
  };
};




export const SEARCHHIGH = "SEARCHHIGH";
export const search_high = (country ='', stateSearch='', citySearch='', subjectSearch='', study_modeSearch='', languageSearch='',nameSearch='') => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/high/get?countrySearch=${country}&citySearch=${citySearch}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: SEARCHHIGH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: SEARCHHIGH, payload: error });
    }
  };
};

export const PAGEHIGH = "PAGEHIGH";
export const page_high = (page, country=" ", city=" ", stateSearch=" ", subjectSearch=" ", study_modeSearch="", languageSearch="",nameSearch='') => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/high/get?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}&subjectSearch=${subjectSearch}&study_modeSearch=${study_modeSearch}&languageSearch=${languageSearch}&nameSearch=${nameSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: PAGEHIGH, payload: { data } });
    } catch (error) {
    }
  };
};



export const NAME_SEARCHVALUE = "NAME_SEARCHVALUE";
export const name_search_value = (search) => {
  return {
    type: NAME_SEARCHVALUE,
    search: search,
  };
};


export const CITY_SEARCHVALUE = "CITY_SEARCHVALUE";
export const city_search_value = (search) => {
  return {
    type: CITY_SEARCHVALUE,
    search: search,
  };
};
export const COUNTRY_SEARCHVALUE = "COUNTRY_SEARCHVALUE";
export const country_search_value = (search) => {
  return {
    type: COUNTRY_SEARCHVALUE,
    search: search,
  };
};
export const STATE_SEARCHVALUE = "STATE_SEARCHVALUE";
export const state_search_value = (search) => {
  return {
    type: STATE_SEARCHVALUE,
    search: search,
  };
};
export const SUBJECT_SEARCHVALUE = "SUBJECT_SEARCHVALUE";
export const subject_search_value = (search) => {
  return {
    type: SUBJECT_SEARCHVALUE,
    search: search,
  };
};
export const STUDY_MODE_SEARCHVALUE = "STUDY_MODE_SEARCHVALUE";
export const study_mode_search_value = (search) => {
  return {
    type: STUDY_MODE_SEARCHVALUE,
    search: search,
  };
};
export const LANGUAGE_SEARCHVALUE = "LANGUAGE_SEARCHVALUE";
export const language_search_value = (search) => {
  return {
    type: LANGUAGE_SEARCHVALUE,
    search: search,
  };

  
};

//lecturers update details
export const LECTURERUPDATEDETAILS = "LECTURERUPDATEDETAILS";
export const lecturers_update_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/update/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      dispatch({ type: LECTURERUPDATEDETAILS, payload: { data } });
    } catch (error) {
    }
  };
};
export const LECTURER_DETAILS = "LECTURER_DETAILS";

export const lecturers_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;

      dispatch({ type: LECTURER_DETAILS, payload: { data } });
    } catch (error) {
    }
  };
};
export const LECTURER_GET = "LECTURER_GET";

export const lecturers_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;

      dispatch({ type: LECTURER_GET, payload: { data } });
    } catch (error) {
    }
  };
};
export const DOWNLOAD_ACTION = "DOWNLOAD_ACTION";
export const download_action = () => {
  return {
    type: DOWNLOAD_ACTION,
  };

  
};


//review

export const  REVIEW_INSERT = "REVIEW_INSERT";

export const review_insert = (
  id,
  rating,
  comment,
  t
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get('jwt');
      const formData = new FormData();
      formData.append('rating', rating);
      formData.append('comment', comment);
      const response = await axios.post(
       `${process.env.REACT_APP_URL}/api/lecturers/${id}/review`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include', // include credentials if needed
        }
      );

      const status = response.data.data;
      dispatch({ type: REVIEW_INSERT, payload: { response, status } });
      toast.success(t('Created_successfully')); // Using translation function passed as parameter

    } catch (error) {
      // Handle error without logging to console
      toast.error(t('error')); // Using translation function passed as parameter
      
      dispatch({ type: REVIEW_INSERT, payload: { error: true } }); // Dispatch error action quietly
      // console.clear()
    }
  };
};


//review get 
export const REVIEW_GET = "REVIEW_GET";
export const review_get = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/lecturers/${id}/average-review`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        } 
      );

      const data = response.data.reviews;
      const total_pages = response.data.pagination.last_page;

      const total_reviews = response.data.pagination.total;
  
      dispatch({ type: REVIEW_GET, payload: { data,total_pages,total_reviews } });
    } catch (error) {
    }
  };
};



// henanaway review e user  bo har freelance ba pee id  bo nmuna au useray login krdwa ch review eke haya bo har freelance

export const USER_REVIEW_GET = "USER_REVIEW_GET";
export const user_review_get = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/reviews/lecturers/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.review;

      dispatch({ type: USER_REVIEW_GET, payload: data });
    } catch (error) {
    }
  };
};
