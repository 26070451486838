import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Use_Contact_hook from "../../../hooks/cv-inputs-hook/contact-hook";
import { contact_action } from "../../../redux/action/contact-action";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Contact = () => {
  

  const { setPhone, setEmail, address, setAddress, phone, email } =
    Use_Contact_hook();
  const [adress_state, setAdress_state] = useState("");
  const [phone_state, setPhone_state] = useState("");
  const [email_state, setEmail_state] = useState("");

  const dispatch = useDispatch();

 // For cv1_details
const details1 = useSelector((state) => state.cv.cv1_details);
const address_api1 = details1 ? details1.address : "";
const email_api1 = details1 ? details1.email : "";
const phone_api1 = details1 ? details1.phone : "";

// For cv2_details
const details2 = useSelector((state) => state.cv.cv2_details);
const address_api2 = details2 ? details2.address : "";
const email_api2 = details2 ? details2.email : "";
const phone_api2 = details2 ? details2.phone : "";

// For cv3_details
const details3 = useSelector((state) => state.cv.cv3_details);
const address_api3 = details3 ? details3.address : "";
const email_api3 = details3 ? details3.email : "";
const phone_api3 = details3 ? details3.phone : "";

// For cv4_details
const details4 = useSelector((state) => state.cv.cv4_details);
const address_api4 = details4 ? details4.address : "";
const email_api4 = details4 ? details4.email : "";
const phone_api4 = details4 ? details4.phone : "";

// For cv5_details
const details5 = useSelector((state) => state.cv.cv5_details);
const address_api5 = details5 ? details5.address : "";
const email_api5 = details5 ? details5.email : "";
const phone_api5 = details5 ? details5.phone : "";

// For cv6_details
const details6 = useSelector((state) => state.cv.cv6_details);
const address_api6 = details6 ? details6.address : "";
const email_api6 = details6 ? details6.email : "";
const phone_api6 = details6 ? details6.phone : "";

// For cv7_details
const details7 = useSelector((state) => state.cv.cv7_details);
const address_api7 = details7 ? details7.address : "";
const email_api7 = details7 ? details7.email : "";
const phone_api7 = details7 ? details7.phone : "";

// For cv8_details
const details8 = useSelector((state) => state.cv.cv8_details);
const address_api8 = details8 ? details8.address : "";
const email_api8 = details8 ? details8.email : "";
const phone_api8 = details8 ? details8.phone : "";

// For cv9_details
const details9 = useSelector((state) => state.cv.cv9_details);
const address_api9 = details9 ? details9.address : "";
const email_api9 = details9 ? details9.email : "";
const phone_api9 = details9 ? details9.phone : "";

// For cv10_details
const details10 = useSelector((state) => state.cv.cv10_details);
const address_api10 = details10 ? details10.address : "";
const email_api10 = details10 ? details10.email : "";
const phone_api10 = details10 ? details10.phone : "";

// For cv11_details
const details11 = useSelector((state) => state.cv.cv11_details);
const address_api11 = details11 ? details11.address : "";
const email_api11 = details11 ? details11.email : "";
const phone_api11 = details11 ? details11.phone : "";

// For cv12_details
const details12 = useSelector((state) => state.cv.cv12_details);
const address_api12 = details12 ? details12.address : "";
const email_api12 = details12 ? details12.email : "";
const phone_api12 = details12 ? details12.phone : "";

// For cv13_details
const details13 = useSelector((state) => state.cv.cv13_details);
const address_api13 = details13 ? details13.address : "";
const email_api13 = details13 ? details13.email : "";
const phone_api13 = details13 ? details13.phone : "";

// For cv14_details
const details14 = useSelector((state) => state.cv.cv14_details);
const address_api14 = details14 ? details14.address : "";
const email_api14 = details14 ? details14.email : "";
const phone_api14 = details14 ? details14.phone : "";

// For cv15_details
const details15 = useSelector((state) => state.cv.cv15_details);
const address_api15 = details15 ? details15.address : "";
const email_api15 = details15 ? details15.email : "";
const phone_api15 = details15 ? details15.phone : "";

// For cv16_details
const details16 = useSelector((state) => state.cv.cv16_details);
const address_api16 = details16 ? details16.address : "";
const email_api16 = details16 ? details16.email : "";
const phone_api16 = details16 ? details16.phone : "";

// For cv17_details
const details17 = useSelector((state) => state.cv.cv17_details);
const address_api17 = details17 ? details17.address : "";
const email_api17 = details17 ? details17.email : "";
const phone_api17 = details17 ? details17.phone : "";

// For cv18_details
const details18 = useSelector((state) => state.cv.cv18_details);
const address_api18 = details18 ? details18.address : "";
const email_api18 = details18 ? details18.email : "";
const phone_api18 = details18 ? details18.phone : "";

// For cv19_details
const details19 = useSelector((state) => state.cv.cv19_details);
const address_api19 = details19 ? details19.address : "";
const email_api19 = details19 ? details19.email : "";
const phone_api19 = details19 ? details19.phone : "";

// For cv20_details
const details20 = useSelector((state) => state.cv.cv20_details);
const address_api20 = details20 ? details20.address : "";
const email_api20 = details20 ? details20.email : "";
const phone_api20 = details20 ? details20.phone : "";

// For cv1_details
useEffect(() => {
  if (address_api1 || email_api1 || phone_api1) {
    setAdress_state(address_api1);
    setPhone_state(phone_api1);
    setEmail_state(email_api1);
  }
}, [address_api1, email_api1, phone_api1]);

// For cv2_details
useEffect(() => {
  if (address_api2 || email_api2 || phone_api2) {
    setAdress_state(address_api2);
    setPhone_state(phone_api2);
    setEmail_state(email_api2);
  }
}, [address_api2, email_api2, phone_api2]);

// For cv3_details
useEffect(() => {
  if (address_api3 || email_api3 || phone_api3) {
    setAdress_state(address_api3);
    setPhone_state(phone_api3);
    setEmail_state(email_api3);
  }
}, [address_api3, email_api3, phone_api3]);

// For cv4_details
useEffect(() => {
  if (address_api4 || email_api4 || phone_api4) {
    setAdress_state(address_api4);
    setPhone_state(phone_api4);
    setEmail_state(email_api4);
  }
}, [address_api4, email_api4, phone_api4]);

// For cv5_details
useEffect(() => {
  if (address_api5 || email_api5 || phone_api5) {
    setAdress_state(address_api5);
    setPhone_state(phone_api5);
    setEmail_state(email_api5);
  }
}, [address_api5, email_api5, phone_api5]);

// For cv6_details
useEffect(() => {
  if (address_api6 || email_api6 || phone_api6) {
    setAdress_state(address_api6);
    setPhone_state(phone_api6);
    setEmail_state(email_api6);
  }
}, [address_api6, email_api6, phone_api6]);

// For cv7_details
useEffect(() => {
  if (address_api7 || email_api7 || phone_api7) {
    setAdress_state(address_api7);
    setPhone_state(phone_api7);
    setEmail_state(email_api7);
  }
}, [address_api7, email_api7, phone_api7]);

// For cv8_details
useEffect(() => {
  if (address_api8 || email_api8 || phone_api8) {
    setAdress_state(address_api8);
    setPhone_state(phone_api8);
    setEmail_state(email_api8);
  }
}, [address_api8, email_api8, phone_api8]);

// For cv9_details
useEffect(() => {
  if (address_api9 || email_api9 || phone_api9) {
    setAdress_state(address_api9);
    setPhone_state(phone_api9);
    setEmail_state(email_api9);
  }
}, [address_api9, email_api9, phone_api9]);

// For cv10_details
useEffect(() => {
  if (address_api10 || email_api10 || phone_api10) {
    setAdress_state(address_api10);
    setPhone_state(phone_api10);
    setEmail_state(email_api10);
  }
}, [address_api10, email_api10, phone_api10]);

// For cv11_details
useEffect(() => {
  if (address_api11 || email_api11 || phone_api11) {
    setAdress_state(address_api11);
    setPhone_state(phone_api11);
    setEmail_state(email_api11);
  }
}, [address_api11, email_api11, phone_api11]);

// For cv12_details
useEffect(() => {
  if (address_api12 || email_api12 || phone_api12) {
    setAdress_state(address_api12);
    setPhone_state(phone_api12);
    setEmail_state(email_api12);
  }
}, [address_api12, email_api12, phone_api12]);

// For cv13_details
useEffect(() => {
  if (address_api13 || email_api13 || phone_api13) {
    setAdress_state(address_api13);
    setPhone_state(phone_api13);
    setEmail_state(email_api13);
  }
}, [address_api13, email_api13, phone_api13]);

// For cv14_details
useEffect(() => {
  if (address_api14 || email_api14 || phone_api14) {
    setAdress_state(address_api14);
    setPhone_state(phone_api14);
    setEmail_state(email_api14);
  }
}, [address_api14, email_api14, phone_api14]);

// For cv15_details
useEffect(() => {
  if (address_api15 || email_api15 || phone_api15) {
    setAdress_state(address_api15);
    setPhone_state(phone_api15);
    setEmail_state(email_api15);
  }
}, [address_api15, email_api15, phone_api15]);

// For cv16_details
useEffect(() => {
  if (address_api16 || email_api16 || phone_api16) {
    setAdress_state(address_api16);
    setPhone_state(phone_api16);
    setEmail_state(email_api16);
  }
}, [address_api16, email_api16, phone_api16]);

// For cv17_details
useEffect(() => {
  if (address_api17 || email_api17 || phone_api17) {
    setAdress_state(address_api17);
    setPhone_state(phone_api17);
    setEmail_state(email_api17);
  }
}, [address_api17, email_api17, phone_api17]);

// For cv18_details
useEffect(() => {
  if (address_api18 || email_api18 || phone_api18) {
    setAdress_state(address_api18);
    setPhone_state(phone_api18);
    setEmail_state(email_api18);
  }
}, [address_api18, email_api18, phone_api18]);

useEffect(() => {
  if (address_api19 || email_api19 || phone_api19) {
    setAdress_state(address_api19);
    setPhone_state(phone_api19);
    setEmail_state(email_api19);
  }
}, [address_api19, email_api19, phone_api19]);

// For cv20_details
useEffect(() => {
  if (address_api20 || email_api20 || phone_api20) {
    setAdress_state(address_api20);
    setPhone_state(phone_api20);
    setEmail_state(email_api20);
  }
}, [address_api20, email_api20, phone_api20]);

  useEffect(() => {
    dispatch(contact_action(phone_state, email_state, adress_state));
  }, [phone_state, email_state, adress_state,dispatch]);


  // Retrieve address from localStorage on component mount
  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    if (storedAddress) {
      setAddress(storedAddress); // Update address state with stored value
    }
  }, []);

  // Handle changes in the address input field
  const handleAddressChange = (event) => {
    const newValue = event.target.value;
    setAddress(newValue); // Update address state
    localStorage.setItem("address", newValue); // Update localStorage
  };
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();

  return (
 
      <Accordion      className='py-[25px]'
      style={{ fontFamily: 'Poppins, sans-serif' }}
      expanded={expanded === "panel11"}
      onChange={handleChange("panel11")}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        className='text-[30px] cv-input-header font-[500] text-gray-700'
      >
            {t("Contact")}
            </AccordionSummary>
      <AccordionDetails    style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }} className="p-4 cv-input-content">
      <div className="flex ">
            <div className="fixed-height-input flex-1">
              <label
                htmlFor="inputname1"
                className="block text-gray-800 font-semibold text-sm"
              >
 {t("phone_number")}                </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="inputname1"
                  className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setPhone_state(e.target.value)}
                  value={phone_state}
                />
              </div>
            </div>
            <div 
              style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }} 
          className="fixed-height-input flex-1 ml-2">

              <label
                htmlFor="inputname2"
                className="block text-gray-800 font-semibold text-sm"
              >
                 {t("Email")}     
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="inputname2"
                  className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setEmail_state(e.target.value)}
                  value={email_state}
                />
              </div>
            </div>
          </div>
      <div className="fixed-height-input flex-1  mt-[30px]">
          <label
              htmlFor="inputname1"
              className="block text-gray-800 font-semibold text-sm"
            >
                 {t("address")}     
                 </label>
            <div className="mt-2">
              <input
                type="text"
                name="inputname1"
                className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setAdress_state(e.target.value)}
                  value={adress_state}
              />
            </div>
          </div>
          {/*another input*/}
      
      </AccordionDetails>
    </Accordion>
  );
};

export default Contact;
