import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import "rsuite/Accordion/styles/index.css";
import "rsuite/Input/styles/index.css";
import WorkHistoryIcon from "@mui/icons-material/WorkHistoryOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/style/main-pages.css";
import Loading from "../../../sub component/show design data/loading";
import "../../../assets/style/search_loading.css";
import HomeFooter from "../../../sub component/home/footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { job_details } from "../../../redux/action/job-action";
import Error404 from "../../error page/error404";
import { user_action } from "../../../redux/action/cv-action";
import brand_image from "../../../assets/image/Brand-photos.png";

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Cookies from "js-cookie";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

import {
  services_details,
  services_get,
  services_get_search,
  services_update_details,
} from "../../../redux/action/services-action";
import { Helmet } from "react-helmet";
import Verify_email from "../../verify-email/verify-email";
import Pricing from "../../../sub component/home/pricing";
import { user_payment } from "../../../redux/action/auth-action";
const Services_profile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [services_data, setServices_data] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    dispatch(user_action());
  }, [dispatch]);

  const data = useSelector((state) => state.services.services_update_details);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(services_get());
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(user_action());
        await dispatch(services_get_search());
        await dispatch(user_payment());

        await dispatch(services_update_details());
        setActionsCompleted(true); // Set actionsCompleted to true when all actions are done
      } catch (error) {
        console.error("An error occurred while dispatching actions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);
  const navigate = useNavigate();

  const services_data_get = useSelector(
    (state) => state.services.services_search_get
  );

  const user_data = useSelector((state) => state.cv.user);

  const user_id = user_data.id;

  useEffect(() => {
    setServices_data(services_data_get);
  }, [services_data_get]);

  const token = useSelector((state) => state.cv.isAuthuntucated);
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }

  useEffect(() => {
    if (actionsCompleted && services_data && user_id) {
      const user = services_data.find((user) => user.user_id === `${user_id}`);
      if (user) {
        navigate("/services/Profile"); // Navigate to "/lecturers/update" route if user found
      } else {
        navigate("/services/add"); // Navigate to "/lecturers/add" route if user not found
      }
      // hataku find naka  loading nabe ba false wata la database agare agar am user services drust na krdbe ayba bo add services`
      setLoading(false);
    }
  }, [actionsCompleted, services_data, user_id, navigate]);
  // bo away agar user active naby njeshan nadre
  const is_Active = useSelector((state) => state.auth.is_Active);

  return (
    <>
        <Helmet>
        {/* Basic Meta Tags */}
        <title>Service Profile - Karbein</title>
        <meta
          name="description"
          content="View and manage your service profile on Karbein. Showcase your service details, update your information, and connect with potential clients effectively."
        />
        <meta
          name="keywords"
          content="Service Profile, Service Details, Manage Service Profile, Karbein, Service Showcase, Professional Services"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Service Profile - Karbein" />
        <meta
          property="og:description"
          content="Explore your service profile on Karbein. View your service details, manage your information, and enhance your visibility to potential clients."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta
          property="og:url"
          content="https://karbein.com/services/Profile"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Service Profile - Karbein" />
        <meta
          name="twitter:description"
          content="Manage and view your service profile on Karbein. Update service details and connect with potential clients effectively."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta
          name="twitter:url"
          content="https://karbein.com/services/Profile"
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/services/Profile" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "headline": "Service Profile",
              "description": "View and manage your service profile on Karbein. Showcase your service details and connect with potential clients.",
              "image": "https://karbein.com/images/Brand-photos.png",
              "url": "https://karbein.com/services/Profile",
              "publisher": {
                "@type": "Organization",
                "name": "Karbein",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://karbein.com/images/Brand-photos.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      <div
        style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
        }}
      >
        {loading ? (
          <Loading />
        ) : token ? (
          isUser_verified === null ? (
            <Verify_email />
          ) : is_Active ? (


            <section className="sm:pt-[120px] pt-[100px] flex justify-between flex-col min-h-screen relative">
              <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                <div className="w-full flex-col justify-start items-start lg:gap-14 gap-10 inline-flex">
                  <div className="w-full flex-col justify-start items-start gap-9 flex">
                    <div className="w-full sm:p-5 p-3.5 rounded-xl border border-gray-200 flex-col justify-start items-center sm:gap-5 gap-3.5 flex  ">
                      <div className="w-full ">
                        <img
                          className="rounded-lg md:h-[120px]  h-[90px] w-[90px]  md:w-[120px]"
                          src={data ? data.image || brand_image : null}
                        />
                      </div>

                      <div className="w-full flex-col sm:text-start   gap-1.5 inline-flex">
                        <h4 className="text-gray-900  md:w-[80%] w-[90%] md:text-xl text-md font-semibold break-words leading-8">
                          {data ? data.title : null}
                        </h4>
                        <h6 className="text-gray-700  main-freeLancer-container-text text-base  leading-relaxed">
                          {data
                            ? `                            ${data.city} , ${data.state} , ${data.country}
`
                            : null}
                        </h6>
                        <Link to={'/services/update'} className="cursor-pointer font-[500] main-freeLancer-container-text flex items-center   text-[#ffb923] hover:text-[#fac95e]  z-10 ">

<div className={
            i18n.language === "ku" || i18n.language === "ar"?
null
:"hidden"


}>


<svg class="mt-0.5 ml-2 -mr-1 stroke-[#ffb923] stroke-2" fill="none" width="10"
height="10" viewBox="0 0 10 10" aria-hidden="true">
<path class="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path>
<path class="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
</svg>
</div>


{t("Update_your_profile")}

<div className={
            i18n.language === "ku" || i18n.language === "ar"?
"hidden"
:null


}>


<svg class="mt-0.5 ml-2 -mr-1 stroke-[#ffb923] stroke-2" fill="none" width="10"
height="10" viewBox="0 0 10 10" aria-hidden="true">
<path class="transition opacity-0 group-hover:opacity-100" d="M0 5h7"></path>
<path class="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
</svg>
</div>
</Link>
                      </div>
             
                    </div>
      
                    <div className="w-full justify-start items-start gap-2.5 inline-flex">
                      <ol className="w-full overflow-hidden flex flex-col gap-1.5">
                        <li
                          className={
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:right-2.5"
                              : "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:left-2.5"
                          }
                        >
                          <div className="w-6 h-6 relative flex items-center justify-center font-medium gap-2.5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M8.92958 11.9106C8.92958 11.9106 8.93233 11.9093 8.93699 11.9086C8.93177 11.9106 8.92958 11.9106 8.92958 11.9106Z"
                                fill="#ffb923"
                              />
                              <path
                                d="M8.95662 11.9086C8.96128 11.9093 8.96403 11.9106 8.96403 11.9106C8.96403 11.9106 8.96184 11.9106 8.95662 11.9086Z"
                                fill="#ffb923"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.94001 0.199707H11.0597C12.8945 0.19969 14.353 0.199676 15.4955 0.353289C16.6735 0.511662 17.6348 0.846394 18.394 1.6056C19.1532 2.3648 19.4879 3.32606 19.6463 4.50402C19.7999 5.64658 19.7999 7.10502 19.7999 8.93985V11.0596C19.7999 12.8944 19.7999 14.3528 19.6463 15.4954C19.4879 16.6734 19.1532 17.6346 18.394 18.3938C17.6348 19.153 16.6735 19.4878 15.4955 19.6461C14.353 19.7997 12.8945 19.7997 11.0597 19.7997H8.93999C7.10516 19.7997 5.64672 19.7997 4.50417 19.6461C3.3262 19.4878 2.36494 19.153 1.60574 18.3938C0.846539 17.6346 0.511807 16.6734 0.353434 15.4954C0.199821 14.3528 0.199835 12.8944 0.199852 11.0596V8.93986C0.199835 7.10503 0.199821 5.64658 0.353434 4.50402C0.511807 3.32606 0.846539 2.3648 1.60574 1.6056C2.36494 0.846394 3.3262 0.511662 4.50417 0.353289C5.64672 0.199676 7.10518 0.19969 8.94001 0.199707ZM15.236 7.96437C15.5484 7.65195 15.5484 7.14542 15.236 6.833C14.9236 6.52058 14.4171 6.52058 14.1046 6.833L9.79534 11.1423C9.44601 11.4916 9.23972 11.6955 9.07398 11.822C9.00748 11.8727 8.96734 11.8952 8.94681 11.9045C8.92628 11.8952 8.88613 11.8727 8.81964 11.822C8.65389 11.6955 8.44761 11.4916 8.09828 11.1423L6.56559 9.60962C6.25317 9.2972 5.74664 9.2972 5.43422 9.60962C5.1218 9.92204 5.1218 10.4286 5.43422 10.741L7.00002 12.3068C7.3047 12.6116 7.58742 12.8944 7.84911 13.094C8.13871 13.315 8.49446 13.508 8.94681 13.508C9.39916 13.508 9.7549 13.315 10.0445 13.094C10.3062 12.8944 10.5889 12.6116 10.8936 12.3068L15.236 7.96437Z"
                                fill="#ffb923"
                              />
                            </svg>
                          </div>
                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "w-full block max-w-[90%] flex flex-col gap-2.5 pr-3 absolute top-0 right-6"
                                : "w-full block max-w-[90%]  flex flex-col gap-2.5 pl-3 absolute top-0 left-6"
                            }
                          >
                            <h4 className="text-lg text-gray-900 font-semibold leading-relaxed">
                              {t("price")}{" "}
                            </h4>
                            <ul>
                              <h6 className="text-gray-500 main-freeLancer-container-text text-base font-normal leading-relaxed">
                                {data
                                  ? `                          
                                     

                                     ${data.price}  ${data.currency}

`
                                  : null}
                              </h6>
                            </ul>
                          </div>
                        </li>
                        <li
                          className={
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:right-2.5"
                              : "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:left-2.5"
                          }
                        >
                          <dv className="w-6 h-6 relative flex items-center justify-center font-medium gap-2.5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M16.6704 9.39887L12.3611 13.7082C11.6945 14.3749 11.3611 14.7082 10.9469 14.7082C10.5327 14.7082 10.1994 14.3749 9.53269 13.7082L8 12.1755M11 21H13C16.7712 21 18.6569 21 19.8284 19.8284C21 18.6569 21 16.7712 21 13V11C21 7.22876 21 5.34315 19.8284 4.17157C18.6569 3 16.7712 3 13 3H11C7.22876 3 5.34315 3 4.17157 4.17157C3 5.34315 3 7.22876 3 11V13C3 16.7712 3 18.6569 4.17157 19.8284C5.34315 21 7.22876 21 11 21Z"
                                stroke="#ffb923"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                              />
                            </svg>
                          </dv>
                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "w-full block max-w-[90%] flex flex-col gap-2.5 pr-3 absolute top-0 right-6"
                                : "w-full block max-w-[90%] flex flex-col gap-2.5 pl-3 absolute top-0 left-6"
                            }
                          >
                            {" "}
                            <h4 className="text-lg text-gray-900 font-semibold leading-relaxed">
                              {t("delevery")}{" "}
                            </h4>
                            <ul>
                              <h6 className="text-gray-500 main-freeLancer-container-text text-base font-normal leading-relaxed">
                                {data
                                  ? ` ${
                                      data.delevery === "Available"
                                        ? t("available")
                                        : t("not_available")
                                    }`
                                  : null}
                              </h6>
                            </ul>
                          </div>
                        </li>
                        <li
                          className={
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:right-2.5"
                              : "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:left-2.5"
                          }
                        >
                          <div className="w-6 h-6 relative flex items-center justify-center font-medium gap-2.5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M16.6704 9.39887L12.3611 13.7082C11.6945 14.3749 11.3611 14.7082 10.9469 14.7082C10.5327 14.7082 10.1994 14.3749 9.53269 13.7082L8 12.1755M11 21H13C16.7712 21 18.6569 21 19.8284 19.8284C21 18.6569 21 16.7712 21 13V11C21 7.22876 21 5.34315 19.8284 4.17157C18.6569 3 16.7712 3 13 3H11C7.22876 3 5.34315 3 4.17157 4.17157C3 5.34315 3 7.22876 3 11V13C3 16.7712 3 18.6569 4.17157 19.8284C5.34315 21 7.22876 21 11 21Z"
                                stroke="#ffb923"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "w-full block flex max-w-[90%] flex-col gap-2.5 pr-3 absolute top-0 right-6"
                                : "w-full block flex max-w-[90%] flex-col gap-2.5 pl-3 absolute top-0 left-6"
                            }
                          >
                            {" "}
                            <h4 className="text-lg text-gray-900 font-semibold leading-relaxed">
                              {t("Address")}{" "}
                            </h4>
                            <ul>
                              <h6 className="text-gray-500 main-freeLancer-container-text  text-base font-normal leading-relaxed">
                                {data
                                  ? `                            ${data.city} , ${data.state} , ${data.country}

`
                                  : null}{" "}
                              </h6>
                            </ul>
                          </div>
                        </li>
                        <li
                          className={
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:right-2.5"
                              : "w-full relative h-48 after:content-[''] after:w-0.5 after:h-40 after:bg-[#ffb923] after:inline-block after:mt-2 after:-bottom-0 after:absolute after:left-2.5"
                          }
                        >
                          <div className="w-6 h-6 relative flex items-center justify-center font-medium gap-2.5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M16.6704 9.39887L12.3611 13.7082C11.6945 14.3749 11.3611 14.7082 10.9469 14.7082C10.5327 14.7082 10.1994 14.3749 9.53269 13.7082L8 12.1755M11 21H13C16.7712 21 18.6569 21 19.8284 19.8284C21 18.6569 21 16.7712 21 13V11C21 7.22876 21 5.34315 19.8284 4.17157C18.6569 3 16.7712 3 13 3H11C7.22876 3 5.34315 3 4.17157 4.17157C3 5.34315 3 7.22876 3 11V13C3 16.7712 3 18.6569 4.17157 19.8284C5.34315 21 7.22876 21 11 21Z"
                                stroke="#ffb923"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                          <div
                            className={
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "w-full block flex max-w-[90%] flex-col gap-2.5 pr-3 absolute top-0 right-6"
                                : "w-full block flex max-w-[90%] flex-col gap-2.5 pl-3 absolute top-0 left-6"
                            }
                          >
                            {" "}
                            <h4 className="text-lg max-w-full break-words text-gray-900 font-semibold leading-relaxed">
                              {t("Description")}{" "}
                            </h4>
                            <ul>
                              <h6 className="text-gray-500  break-words main-freeLancer-container-text  text-base font-normal leading-relaxed">
                                {data ? data.description : null}
                              </h6>
                            </ul>
                          </div>
                        </li>
                      </ol>
                    </div>

                    <div className="w-full md:pt-5  pt-2   rounded-xl border border-gray-200    px-[30px] ">
                      <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                        <div className="w-full flex lg:flex-row  flex-col  xs:flex-col gap-2 justify-center">
                          <div className="w-[90%]">
                            <dl className="text-gray-900 ">
                              <div className="flex flex-col pb-3">
                                <dt className="mb-1 text-gray-900 md:text-lg dark:text-gray-400">
                                  {t("service_title")}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data ? data.title : null}
                                </dd>
                              </div>
                              <div className="flex flex-col pt-3 lg:py-0 py-3 lg:mb-[-10px] ">
                                <dt className="mb-1 text-gray-900 md:text-lg dark:text-gray-400">
                                  {" "}
                                  {t("service_type")}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data
                                    ? `${
                                        data.type === "Cleaning the house"
                                          ? t("Cleaning_the_house")
                                          : data.type === "Vehicle Maintenance"
                                          ? t("Vehicle_Maintenance")
                                          : data.type === "Electrical Repairs"
                                          ? t("Electrical_Repairs")
                                          : data.type === "Freight Forwarding"
                                          ? t("Freight_Forwarding")
                                          : data.type ===
                                            "Home Appliance Repairs"
                                          ? t("Home_ApplianceRepairs")
                                          : data.type ===
                                            "construction of houses"
                                          ? t("construction_of_houses")
                                          : data.type === "Garden Maintenance"
                                          ? t("Garden_Maintenance")
                                          : data.type === "Car Wash"
                                          ? t("Car_Wash")
                                          : data.type === "Electronic Services"
                                          ? t("Electronic_Services")
                                          : data.type === "Carpet"
                                          ? t("Carpet")
                                          : data.type ===
                                            "Post Construction Cleaning"
                                          ? t("Post")
                                          : data.type === "Hairdressing"
                                          ? t("Hairdressing")
                                          : data.type === "Skincare Treatments"
                                          ? t("Skincare_Treatments")
                                          : data.type === "Makeup"
                                          ? t("Makeup")
                                          : data.type === "Nail"
                                          ? t("Nail")
                                          : data.type === "Veterinary"
                                          ? t("Veterinary")
                                          : data.type === "Legal Consultation"
                                          ? t("Legal_Consultation")
                                          : data.type === "Hairdressing"
                                          ? t("Post")
                                          : data.type ===
                                            "Photography and Videography"
                                          ? t("Photography")
                                          : data.type === "Tire Replacement"
                                          ? t("Tire_Replacement")
                                          : data.type === "battery"
                                          ? t("Battery_Services")
                                          : data.type === "Travel and Tourism"
                                          ? t("Travel_Tourism")
                                          : null
                                      }`
                                    : null}
                                </dd>
                              </div>
                            </dl>
                          </div>
                          <div className="w-full">
                            <dl className="text-gray-900 ">
                              <div className="flex flex-col pb-3">
                                <dt className="mb-1 text-gray-900 md:text-lg dark:text-gray-400">
                                  {t("phone_number")}
                                </dt>
                                <dd className="text-md font-[500] main-freeLancer-container-text">
                                  {data ? `${data.phone} ` : null}
                                </dd>
                              </div>
                              <div className="flex flex-col lg:pt-3   lg:mb-[-10px] ">
                                <dt className="mb-1 text-gray-900 md:text-lg dark:text-gray-400">
                                  {t("email")}
                                </dt>
                                <dd className="text-lg font-[500] main-freeLancer-container-text">
                                  {data ? data.email || "Null" : null}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HomeFooter />
            </section>
          ) : (
            <Pricing />
          )
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default Services_profile;
