import axios from 'axios';

// Create an instance of Axios
const axiosInstance = axios.create();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Suppress request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  function (error) {
    // Suppress response errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
