export const CONTACT = "CONTACT";

export const contact_action = (phone, email, address) => {
  return {
    type: CONTACT,
    phone: phone,
    email: email,
    address: address,
  };
};
