import React, { useEffect, useState } from "react";
import "../assets/style/auth.css";
import { useDispatch, useSelector } from "react-redux";
import { login, register, reset_password } from "../redux/action/auth-action";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { user_action } from "../redux/action/cv-action";
import Loading from "../sub component/show design data/loading";

const Reset_password = () => {
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [ShowConfirmPassword, setShowConfirmPassword] = useState(false);

  const errors = useSelector((state) => state.auth.errors);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(user_action());
    

        setLoading(false);
      } catch {}
    };
    fetchData();
  }, [dispatch]);
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const { token } = useParams();
  const email = searchParams.get("email");
  const [hasDispatched, setHasDispatched] = useState(false);
  const submit = async (e) => {
    e.preventDefault();

    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(
        reset_password(email, token, password, ConfirmPassword, navigate, t)
      );      setTimeout(() => {
        setHasDispatched(false);
      }, 5000);
    }
 
  };

  return (
   <>
   {
loading?
<Loading/>
:
<div className="">
<div
  style={{
    direction:
      i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
  }}
  className="flex auth-container justify-center h-screen items-center  pt-[80px] "
>
  <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 w-[600px]">
    <div className="mx-auto max-w-lg">
      <form
        onSubmit={submit}
        className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow sm:p-6 lg:p-8 bg-white"
      >
        <p className="text-center text-lg font-medium">
          {t("Reset_Password")}{" "}
        </p>

        <div></div>
        <div>
          <label
            htmlFor="password"
            className="block mb-[10px] ml-[5px] text-sm font-medium text-gray-700"
          >
            {t("Password")}{" "}
          </label>
          <div className=" border rounded-lg  flex justify-between items-center focus:border-gray-200 border-gray-200">
            <input
              type={showPassword ? "text" : "password"}
              name={t("Password")}
              className="w-full rounded-lg auth-input border-none p-3 pe-12 text-sm"
              placeholder={t("Password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div
              onClick={() => setShowPassword(!showPassword)}
              style={{
                marginLeft:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "15px"
                    : "0px",
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "0px"
                    : "15px",
              }}
            >
              {showPassword ? (
                <RemoveRedEyeOutlinedIcon
                  style={{ fontSize: "25px" }}
                  className="text-gray-400 pl-[3px]"
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  style={{ fontSize: "25px" }}
                  className="text-gray-400 pl-[3px]"
                />
              )}
            </div>
          </div>
          {errors ? (
            <div className="text-red-500  mt-[10px]">
              {errors.password == "The password field is required."
                ? null
                : (errors.password =
                    "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit."
                      ? t("password_atLeast")
                      : (errors.password =
                          "The password field confirmation does not match."
                            ? null
                            : null))}
            </div>
          ) : null}
        </div>

        <div>
          <label
            htmlFor="password"
            className="block mb-[10px] ml-[5px] text-sm font-medium text-gray-700"
          >
            {t("password_confirm")}{" "}
          </label>
          <div className=" border rounded-lg  flex justify-between items-center focus:border-gray-200 border-gray-200">
            <input
              type={ShowConfirmPassword ? "text" : "password"}
              name={t("Password")}
              className="w-full rounded-lg auth-input border-none p-3 pe-12 text-sm"
              placeholder={t("password_confirm")}
              value={ConfirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              onClick={() => setShowConfirmPassword(!ShowConfirmPassword)}
              style={{
                marginLeft:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "15px"
                    : "0px",
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "0px"
                    : "15px",
              }}
            >
              {ShowConfirmPassword ? (
                <RemoveRedEyeOutlinedIcon
                  style={{ fontSize: "25px" }}
                  className="text-gray-400 pl-[3px]"
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  style={{ fontSize: "25px" }}
                  className="text-gray-400 pl-[3px]"
                />
              )}
            </div>
          </div>
        </div>
        {errors ? (
          <div className="text-red-500 ">
            {errors.password == "The password field is required."
              ? null
              : errors.password ==
                "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit."
              ? null
              : (errors.password =
                  "The password field confirmation does not match."
                    ? t("password_not_match")
                    : null)}
          </div>
        ) : null}
        <div className="text-right h-[20px]"></div>
        <button
          type="submit"
          className="block w-full rounded-lg bg-[#ffb923] px-5 py-3 text-sm font-medium text-white hover:bg-[#ffc64a]"
        >
          {t("Reset_Password")}
        </button>
      </form>
    </div>
  </div>
</div>
<ToastContainer />
</div>
   }
   </>
  );
};

export default Reset_password;
