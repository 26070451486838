
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { freelancer_get } from "../../redux/action/freelancer-action";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "../../assets/style/freelance.css";

import cv1 from "../../assets/image/CV template/cv1.jpeg";
import cv2 from "../../assets/image/CV template/cv2.jpeg";
import cv3 from "../../assets/image/CV template/cv3.jpeg";
import cv4 from "../../assets/image/CV template/cv4.jpeg";
import cv5 from "../../assets/image/CV template/cv5.jpeg";
import cv6 from "../../assets/image/CV template/cv6.jpeg";
import cv7 from "../../assets/image/CV template/cv7.jpeg";
import cv8 from "../../assets/image/CV template/cv8.jpeg";
import cv9 from "../../assets/image/CV template/cv9.jpeg";
import cv10 from "../../assets/image/CV template/cv10.jpeg";
import cv11 from "../../assets/image/CV template/cv11.jpeg";
import cv12 from "../../assets/image/CV template/cv12.jpeg";
import cv13 from "../../assets/image/CV template/cv13.jpeg";

import { Link } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { cv13_insert } from "../../redux/action/cv-action";
import { useTranslation } from "react-i18next";

const FreeLancer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(freelancer_get());
    };
    fetchData();
  }, [dispatch]);

  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    const updateDisplayData = () => {
      const width = window.innerWidth;
      if (width <= 1500) {
        setDisplayData(freeLanceData.slice(0, 6));
      } else {
        setDisplayData(freeLanceData.slice(0, 12));
      }
    };

    updateDisplayData();
    window.addEventListener("resize", updateDisplayData);

    return () => window.removeEventListener("resize", updateDisplayData);
  }, [freeLanceData]);
  const { t, i18n } = useTranslation();

  return (
    <div className="my-[170px]  max-w-[1570px] freelance-div justify-center mx-auto">
      <h1 className="text-center works_title text-xl leading-tight mb-[30px] font-[500] tracking-tight text-gray-900">
{t("CV_templates")}      </h1>
      <section>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-14 flex flex-col justify-center items-center sm:flex-row sm:items-center sm:justify-between max-sm:gap-8">
            <h2 className="text-4xl text-center font-bold text-gray-900 lg:text-left"></h2>
            <div className="flex items-center mb-[-30px] mr-2 gap-2.5">
            <button className="cv-button-prev group flex justify-center items-center border border-solid min-w-12 h-12 transition-all duration-500 rounded-full hover:bg-[#ffb923]">
                <svg
                  className="h-6 w-6 text-[#ffb923] group-hover:text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.9999 12L4.99992 12M9.99992 6L4.70703 11.2929C4.3737 11.6262 4.20703 11.7929 4.20703 12C4.20703 12.2071 4.3737 12.3738 4.70703 12.7071L9.99992 18"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <button className="cv-button-next group flex justify-center items-center border border-solid min-w-12 min-h-12 transition-all duration-500 rounded-full hover:bg-[#ffb923]">
                <svg
                  className="h-6 w-6 text-[#ffb923] group-hover:text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="lg:flex grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-8 swiper ">
            
  <Swiper
    slidesPerView={4}
    spaceBetween={28}
    loop={true}
    autoplay={{ delay: 2000, disableOnInteraction: false }}
    navigation={{
      nextEl: ".cv-button-next",
      prevEl: ".cv-button-prev",
    }}
    modules={[Navigation, Pagination, Autoplay]}
    breakpoints={{
      0: { slidesPerView: 1, spaceBetween: 20 },
      468: { slidesPerView: 2, spaceBetween: 20 },
      768: { slidesPerView: 2, spaceBetween: 20 },
      968: { slidesPerView: 3, spaceBetween: 20 },
      1100: { slidesPerView: 3, spaceBetween: 20 },
      
    }}
    className="swiper-container" // Add this class to handle swiper container style
  >


    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=1"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv1} alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=2"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv2} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>
    
    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=3"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv3} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=4"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv4} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=5"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv5} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>
    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=6"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv6} alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>
    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=13"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv13}alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>
    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=7"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv7} alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=8"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv8} alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=9"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv9} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=10"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv10} alt="cv"  title="Karbein cv" className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=11"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv11} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>

    <SwiperSlide className="swiper-slide">
      <Link to={"/resumes=12"} className="pdf-container rounded-[10px] max-w-full h-full border">
      <img src={cv12} alt="cv"  title="Karbein cv"  className="rounded-[10px] pdf-page h-full object-cover" style={{ objectFit: 'cover' }} />

      </Link>
    </SwiperSlide>


  </Swiper>

       
        </div>

        </div>
      </section>
      <div className="flex mt-[-65px] z-10 w-full justify-end ">
          <Link
                to={"/CV-templates"}

            className="relative w-[140px] z-10 h-[56px] overflow-hidden border-none hover:text-gray-500 text-black bg-none pb-8 cursor-pointer group"
          >
            <div className="absolute inset-0 flex justify-center items-center clone">
              <span className="ml-1 underline">{t("Show_all")}</span>
            </div>
          </Link>
        </div>
    </div>
  );
};

export default FreeLancer;

// import React, { useEffect, useState } from "react";