import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  freelancer_admin__update,
  freelancer_details,
  freelancer_get,
  freelancer_insert,
  freelancer_update,
  freelancer_update_details,
} from "../../../redux/action/freelancer-action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SelectCurrency from "react-select-currency";
import { Link, useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";
import { services_update_details } from "../../../redux/action/services-action";
import HomeFooter from "../../../sub component/home/footer";
import Cookies from "js-cookie";
import Error404 from "../../error page/error404";
import Admin_nav from "../../../app bar/admin-nav";
import brand from "../../../assets/image/Brand-photos.png";

const Admin_freeLancers_update = () => {
  const { id } = useParams();
  const userId = parseInt(id, 10);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), []);
  const user_data = useSelector((state) => state.cv.user);

  const [loading, setLoading] = useState("");
  const [loading_data, setLoadingData] = useState(true);

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [experience, setExperience] = useState("");
  const [city, setCity] = useState("");
  const [description, setDescription] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");
  const [skills, setSkills] = useState("");
  const [perHourPrice, setPerHourPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [actionsCompleted, setActionsCompleted] = useState(false);

  const data = useSelector((state) => state.freelancer.freelancer_details);
  //for image if ichanged image show in screen
  const [imageFile, setImageFile] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [image, setImage] = useState(data ? data.image : " ");

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file instanceof Blob) {
      setImageFile(file);
    } else {
      console.error("The selected file is not a valid Blob or File object");
    }
  };

  useEffect(() => {
    if (imageFile) {
      const url = URL.createObjectURL(imageFile);
      setImageUrl(url);

      // Clean up the object URL when the component unmounts or the imageFile changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [imageFile]);
  const currencyValue = currency;
  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);


  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        
        await setLoadingData(true);

        await dispatch(freelancer_details(userId));
        await dispatch(user_action());

        await setLoadingData(false);
      }
    };
    fetchData();
  }, [dispatch, userId]);

  const currencies = [
    { code: "AED", name: "United Arab Emirates Dirham" },
    { code: "AFN", name: "Afghan Afghani" },
    { code: "ALL", name: "Albanian Lek" },
    { code: "AMD", name: "Armenian Dram" },
    { code: "ANG", name: "Netherlands Antillean Guilder" },
    { code: "AOA", name: "Angolan Kwanza" },
    { code: "ARS", name: "Argentine Peso" },
    { code: "AUD", name: "Australian Dollar" },
    { code: "AWG", name: "Aruban Florin" },
    { code: "AZN", name: "Azerbaijani Manat" },
    { code: "BAM", name: "Bosnia-Herzegovina Convertible Mark" },
    { code: "BBD", name: "Barbadian Dollar" },
    { code: "BDT", name: "Bangladeshi Taka" },
    { code: "BGN", name: "Bulgarian Lev" },
    { code: "BHD", name: "Bahraini Dinar" },
    { code: "BIF", name: "Burundian Franc" },
    { code: "BMD", name: "Bermudian Dollar" },
    { code: "BND", name: "Brunei Dollar" },
    { code: "BOB", name: "Bolivian Boliviano" },
    { code: "BRL", name: "Brazilian Real" },
    { code: "BSD", name: "Bahamian Dollar" },
    { code: "BTN", name: "Bhutanese Ngultrum" },
    { code: "BWP", name: "Botswana Pula" },
    { code: "BYN", name: "Belarusian Ruble" },
    { code: "BZD", name: "Belize Dollar" },
    { code: "CAD", name: "Canadian Dollar" },
    { code: "CDF", name: "Congolese Franc" },
    { code: "CHF", name: "Swiss Franc" },
    { code: "CLP", name: "Chilean Peso" },
    { code: "CNY", name: "Chinese Yuan" },
    { code: "COP", name: "Colombian Peso" },
    { code: "CRC", name: "Costa Rican Colón" },
    { code: "CUP", name: "Cuban Peso" },
    { code: "CVE", name: "Cape Verdean Escudo" },
    { code: "CZK", name: "Czech Koruna" },
    { code: "DJF", name: "Djiboutian Franc" },
    { code: "DKK", name: "Danish Krone" },
    { code: "DOP", name: "Dominican Peso" },
    { code: "DZD", name: "Algerian Dinar" },
    { code: "EGP", name: "Egyptian Pound" },
    { code: "ERN", name: "Eritrean Nakfa" },
    { code: "ETB", name: "Ethiopian Birr" },
    { code: "EUR", name: "Euro" },
    { code: "FJD", name: "Fijian Dollar" },
    { code: "FKP", name: "Falkland Islands Pound" },
    { code: "FOK", name: "Funko Coin" },
    { code: "GBP", name: "British Pound Sterling" },
    { code: "GEL", name: "Georgian Lari" },
    { code: "GGP", name: "Guernsey Pound" },
    { code: "GHS", name: "Ghanaian Cedi" },
    { code: "GIP", name: "Gibraltar Pound" },
    { code: "GMD", name: "Gambian Dalasi" },
    { code: "GNF", name: "Guinean Franc" },
    { code: "GTQ", name: "Guatemalan Quetzal" },
    { code: "GYD", name: "Guyanaese Dollar" },
    { code: "HKD", name: "Hong Kong Dollar" },
    { code: "HNL", name: "Honduran Lempira" },
    { code: "HRK", name: "Croatian Kuna" },
    { code: "HTG", name: "Haitian Gourde" },
    { code: "HUF", name: "Hungarian Forint" },
    { code: "IDR", name: "Indonesian Rupiah" },
    { code: "ILS", name: "Israeli New Shekel" },
    { code: "IMP", name: "Manx Pound" },
    { code: "INR", name: "Indian Rupee" },
    { code: "IQD", name: "Iraqi Dinar" },
    { code: "IRR", name: "Iranian Rial" },
    { code: "ISK", name: "Icelandic Króna" },
    { code: "JEP", name: "Jersey Pound" },
    { code: "JMD", name: "Jamaican Dollar" },
    { code: "JOD", name: "Jordanian Dinar" },
    { code: "JPY", name: "Japanese Yen" },
    { code: "KES", name: "Kenyan Shilling" },
    { code: "KGS", name: "Kyrgystani Som" },
    { code: "KHR", name: "Cambodian Riel" },
    { code: "KID", name: "Kid Laroi Coin" },
    { code: "KMF", name: "Comorian Franc" },
    { code: "KRW", name: "South Korean Won" },
    { code: "KWD", name: "Kuwaiti Dinar" },
    { code: "KYD", name: "Cayman Islands Dollar" },
    { code: "KZT", name: "Kazakhstani Tenge" },
    { code: "LAK", name: "Laotian Kip" },
    { code: "LBP", name: "Lebanese Pound" },
    { code: "LKR", name: "Sri Lankan Rupee" },
    { code: "LRD", name: "Liberian Dollar" },
    { code: "LSL", name: "Lesotho Loti" },
    { code: "LYD", name: "Libyan Dinar" },
    { code: "MAD", name: "Moroccan Dirham" },
    { code: "MDL", name: "Moldovan Leu" },
    { code: "MGA", name: "Malagasy Ariary" },
    { code: "MKD", name: "Macedonian Denar" },
    { code: "MMK", name: "Myanmar Kyat" },
    { code: "MNT", name: "Mongolian Tugrik" },
    { code: "MOP", name: "Macanese Pataca" },
    { code: "MRU", name: "Mauritanian Ouguiya" },
    { code: "MUR", name: "Mauritian Rupee" },
    { code: "MVR", name: "Maldivian Rufiyaa" },
    { code: "MWK", name: "Malawian Kwacha" },
    { code: "MXN", name: "Mexican Peso" },
    { code: "MYR", name: "Malaysian Ringgit" },
    { code: "MZN", name: "Mozambican Metical" },
    { code: "NAD", name: "Namibian Dollar" },
    { code: "NGN", name: "Nigerian Naira" },
    { code: "NIO", name: "Nicaraguan Córdoba" },
    { code: "NOK", name: "Norwegian Krone" },
    { code: "NPR", name: "Nepalese Rupee" },
    { code: "NZD", name: "New Zealand Dollar" },
    { code: "OMR", name: "Omani Rial" },
    { code: "PAB", name: "Panamanian Balboa" },
    { code: "PEN", name: "Peruvian Sol" },
    { code: "PGK", name: "Papua New Guinean Kina" },
    { code: "PHP", name: "Philippine Peso" },
    { code: "PKR", name: "Pakistani Rupee" },
    { code: "PLN", name: "Polish Zloty" },
    { code: "PYG", name: "Paraguayan Guarani" },
    { code: "QAR", name: "Qatari Rial" },
    { code: "RON", name: "Romanian Leu" },
    { code: "RSD", name: "Serbian Dinar" },
    { code: "RUB", name: "Russian Ruble" },
    { code: "RWF", name: "Rwandan Franc" },
    { code: "SAR", name: "Saudi Riyal" },
    { code: "SBD", name: "Solomon Islands Dollar" },
    { code: "SCR", name: "Seychellois Rupee" },
    { code: "SDG", name: "Sudanese Pound" },
    { code: "SEK", name: "Swedish Krona" },
    { code: "SGD", name: "Singapore Dollar" },
    { code: "SHP", name: "Saint Helena Pound" },
    { code: "SLL", name: "Sierra Leonean Leone" },
    { code: "SOS", name: "Somali Shilling" },
    { code: "SRD", name: "Surinamese Dollar" },
    { code: "SSP", name: "South Sudanese Pound" },
    { code: "STN", name: "São Tomé & Príncipe Dobra" },
    { code: "SYP", name: "Syrian Pound" },
    { code: "SZL", name: "Swazi Lilangeni" },
    { code: "THB", name: "Thai Baht" },
    { code: "TJS", name: "Tajikistani Somoni" },
    { code: "TMT", name: "Turkmenistani Manat" },
    { code: "TND", name: "Tunisian Dinar" },
    { code: "TOP", name: "Tongan Paʻanga" },
    { code: "TRY", name: "Turkish Lira" },
    { code: "TTD", name: "Trinidad & Tobago Dollar" },
    { code: "TVD", name: "Tuvalu Coin" },
    { code: "TWD", name: "New Taiwan Dollar" },
    { code: "TZS", name: "Tanzanian Shilling" },
    { code: "UAH", name: "Ukrainian Hryvnia" },
    { code: "UGX", name: "Ugandan Shilling" },
    { code: "USD", name: "United States Dollar" },
    { code: "UYU", name: "Uruguayan Peso" },
    { code: "UZS", name: "Uzbekistani Som" },
    { code: "VES", name: "Venezuelan Bolívar" },
    { code: "VND", name: "Vietnamese Dong" },
    { code: "VUV", name: "Vanuatu Vatu" },
    { code: "WST", name: "Samoan Tala" },
    { code: "XAF", name: "Central African CFA Franc" },
    { code: "XCD", name: "East Caribbean Dollar" },
    { code: "XDR", name: "Special Drawing Rights" },
    { code: "XOF", name: "West African CFA Franc" },
    { code: "XPF", name: "CFP Franc" },
    { code: "YER", name: "Yemeni Rial" },
    { code: "ZAR", name: "South African Rand" },
    { code: "ZMW", name: "Zambian Kwacha" },
    { code: "ZWL", name: "Zimbabwean Dollar" },
  ];

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };
  useEffect(() => {
    if (data) {
      setName(data.name || "");
      setEmail(data.email || "");
      setPhone(data.phone || "");

      setCountry(data.country || "");
      setExperience(data.experience || "");
      setCity(data.city || "");
      setDescription(data.description || "");
      setFunctionalArea(data.functional_area || "");
      setSkills(data.skills || "");
      setPerHourPrice(data.per_hour_price || "");
      setCurrency(data.currency || "");
      setImage(data.image || "");
    }
  }, [data]);

  const submit = async (e) => {
    e.preventDefault();
    await dispatch(
      freelancer_admin__update(
        id,
        imageFile,
        name,
        email,
        phone,
        experience,
        description,
        functionalArea,
        skills,
        perHourPrice,
        currency,
        t
      )
    );
  };
  const currencyData = currency;
  const handleCurrencyChange = (selectedCurrency) => {
    setCurrency(selectedCurrency.value);
  };

  const user_role = useSelector((state) => state.cv.user.role) || "";

  const user_id = useSelector((state) => state.cv.user.id);
  const freelance_data = useSelector(
    (state) => state.freelancer.freelancer_get
  );
  const [findData, setfindData] = useState([]);

  useEffect(() => {
    setfindData(freelance_data);
  }, [freelance_data]);
  const user = findData.find((user) => user.user_id === `${user_id}`);

  const token = useSelector((state) => state.cv.isAuthuntucated);

  if (!loading_data) {
    if (!token || user_role !== "admin") {
      return (
        <Error404 message="Access Denied: You do not have the necessary permissions." />
      );
    }
  }

  if (!loading_data && (!data || Object.keys(data).length === 0)) {
    return <Error404 />;
  }
  return (
    <div className="flex h-screen ">
      {loading_data ? null : <Admin_nav />}
      <div className="flex flex-col  flex-1 overflow-y-auto ">
        {loading_data ? (
          <Loading />
        ) : (
          <div
            className="flex justify-center mb-[20px] min-w-[100%] pt-[100px] w-full"
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
          >
            <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container min-w-[90%] flex justify-center gap-[40px]">
              {/* <div className="left-details-container">
          <div className="left-details border rounded h-[150px]">
            <div className="left-details-header text-[20px] bg-[#ffb923] h-[45px] text-white flex font-[500] border-top-[20px] justify-center items-center">
              {t("update_freelancer")}
            </div>
            <div className="mx-[20px] flex gap-[10px] flex-col text-[18px] justify-center my-[20px] max-w-[340px] break-words">
              <p>
                - <Link to={"/Admin-panel/freelance/insert"}>{t("add_freelancer")}</Link>
              </p>
              <p>
                - <Link to={"/Admin-panel/freelance"}>{t("freelancers_list")}</Link>
              </p>
            </div>
          </div>
        </div> */}
              <div className="main-card-container flex flex-col w-[900px] gap-[50px]">
                <div className="freeLancers_border max-w-[900px] min-w-[100%] border rounded min-h-[100px]">
                  <div className="freelancer-card-container">
                    <div className="add-input-group w-[100%] bg-gray-100 p-[40px] rounded">
                      <div className="update-image ">
                        {
                          <img
                            src={imageUrl || image || brand}
                            alt="Selected file"
                            className="h-[120px] border-[1px] mt-[-10px] mb-[20px] border-gray-300 w-[120px] rounded-[10px]"
                          />
                        }
                      </div>
                      <form onSubmit={submit}>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("image")}{" "}
                            </label>
                            <input
                              className="block w-full pr-[15px] text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              id="multiple_files"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={handleFileInputChange}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              {t("freelancer_Name")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("freelancer_Name")}
                              required
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("phone_number")}
                            </label>
                            <PhoneInput
                              inputStyle={{
                                minHeight: "2.6rem",
                                border: "1px solid #d2d6dc",
                                backgroundColor: "#F9FAFB",
                                paddingLeft:
                                  i18n.language === "en" ? "100px" : null,
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "100px"
                                    : "0px",
                                color: "#1a202c",
                                fontSize: "0.875rem",
                                borderRadius: "0.5rem",
                                direction:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "rtl"
                                    : "ltr",
                                width: "100%",
                              }}
                              buttonStyle={{
                                borderRadius:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "0px 0.5rem 0.5rem 0px"
                                    : "0.5rem 0px 0px 0.5rem",
                                border: "1px solid #d2d6dc",
                                width: "70px",
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "30px"
                                    : "0px",
                              }}
                              inputClass="input_add_freelancer rtl-phone-input-button min-h-[50px] bg-gray-900 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              buttonClass="custom-button-class"
                              containerClass="custom-container-class"
                              dropdownClass="custom-dropdown-class"
                              onChange={setPhone}
                              placeholder={t("phone_number")}
                              value={phone}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Email")}
                            </label>
                            <input
                              type="email"
                              id="company"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Email")}
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Skills")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Skills")}
                              required
                              value={skills}
                              onChange={(e) => setSkills(e.target.value)}
                            />
                          </div>
                          <div className="mb-6">
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("experience")} / {"Years"}
                            </label>
                            <input
                              type="number"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("experience")}
                              required
                              value={experience}
                              onChange={(e) => {
                                setExperience(e.target.value);
                              }}
                            />
                             
                          </div>{" "}
                        </div>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("per_hour_price")}
                            </label>
                            <input
                              type="number"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("per_hour_price")}
                              required
                              value={perHourPrice}
                              onChange={(e) => {
                                setPerHourPrice(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("currency")}
                            </label>
                            <select
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={currency}
                              onChange={handleChange}
                            >
                              <option value="">{t("currency")} </option>
                              {currencies.map((currency) => (
                                <option
                                  key={currency.code}
                                  value={currency.code}
                                >
                                  {currency.name} ({currency.code})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            {t("Functional_Area")}{" "}
                          </label>
                          <input
                            type="text"
                            className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={t("Functional_Area")}
                            required
                            value={functionalArea}
                            onChange={(e) => {
                              setFunctionalArea(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-6">
                          <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            {t("Description")}
                          </label>
                          <textarea
                            className="input_add_freelancer text-area border bg-gray-50"
                            placeholder={t("Description")}
                            required
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                        <button
                          type="submit"
                          className="text-white bg-[#ffb923] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          {t("submit")}
                        </button>
                      </form>
                    </div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin_freeLancers_update;
