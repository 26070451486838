import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { user_page } from '../../../redux/action/auth-action';


const User_Pagination = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.auth.searchPerformed
      ? state.auth.total_pages_search
      :    state.auth.total_pages

  ); 

    const serach_value = useSelector((state) => state.auth.search_value)||"";
   


  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {

    dispatch(user_page(page,serach_value));
    setCurrentPage(page);
  };
  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default User_Pagination;
