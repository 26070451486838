import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const Admin_nav = () => {
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const user_role = useSelector((state) => state.cv.user.role) || "";

  const token = Cookies.get("jwt");

  useEffect(() => {
    const role = () => {
      if (user_role !== "admin") {
        return "";
      }
    };

    if (user_role != "") {
      role();
    }
  }, [user_role]);

  if (!token) {
    return "";
  }
  return (
    <div className="flex h-screen  pt-[70px]">
      <div className="hidden md:flex flex-col w-64 bg-gray-200">
        <div className="flex flex-col flex-1 overflow-y-auto">
          <nav className="flex-1 px-2 py-4 bg-gray-50">
            <div className="  py-2">
              <Link
                to={"/Admin-panel/jobs"}
                className="flex items-center px-4 pb-2  text-gray-900 h hover:text-gray-500"
              >
                Jobs{" "}
              </Link>

              <Link
                to={"/Admin-panel/jobs/insert"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900 hover:text-gray-500"
              >
                Add job{" "}
              </Link>
              <Link
                to={"/Admin-panel/freelance"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                freelancers{" "}
              </Link>

              <Link
                to={"/Admin-panel/freelance/insert"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Add freelancer{" "}
              </Link>
              <Link
                to={"/Admin-panel/Primary-Lecturer"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Primary School
              </Link>

              <Link
                to={"/Admin-panel/Middle-Lecturer"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Middle School{" "}
              </Link>

              <Link
                to={"/Admin-panel/High-Lecturer"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                High School{" "}
              </Link>

              <Link
                to={"/Admin-panel/Lecturer/insert"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Add lecturer{" "}
              </Link>

              <Link
                to={"/Admin-panel/services"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Services list{" "}
              </Link>
              <Link
                to={"/Admin-panel/services/insert"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Add service
              </Link>

              <Link
                to={"/Admin-panel/users"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Users{" "}
              </Link>
              <Link
                to={"/Admin-panel/order"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Order{" "}
              </Link>
              <Link
                to={"/Admin-panel/Refound"}
                className="flex items-center px-4 py-2 mt-2 text-gray-900  hover:text-gray-500"
              >
                Payment Refund{" "}
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Admin_nav;
