import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { page_freeLancer } from '../../../redux/action/freelancer-action';

const PaginationComponent = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.freelancer.searchPerformed
      ? state.freelancer.total_pages_search
      : state.freelancer.total_pages
  ); 
     const search_get_value = useSelector((state) => state.freelancer.search_value);
     const second_get_value = useSelector((state) => state.freelancer.second_search_value);
     const third_get_value = useSelector((state) => state.freelancer.third_search_value);

  const [currentPage, setCurrentPage] = useState(1);
  const freelancer_search = useSelector(
    (state) => state.freelancer.freelancer_search
 );
  const handlePageChange = (event, page) => {
    dispatch(page_freeLancer(page,search_get_value,second_get_value,third_get_value));
    setCurrentPage(page);
  };

  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default PaginationComponent;
