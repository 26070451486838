import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { MantineProvider } from '@mantine/core';

// Override console methods to suppress logs
// const originalConsoleLog = console.log;
// const originalConsoleError = console.error;
// const originalConsoleWarn = console.warn;

// console.log = () => {};
// console.error = () => {};
// console.warn = () => {};

// Get the root element
const container = document.getElementById('root');
const root = createRoot(container); // Create a root
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider> {/* Wrap your App with MantineProvider */}
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// Measure performance in your app
reportWebVitals();
// // src/index.js

// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import store from './redux/store/store';
// import { MantineProvider } from '@mantine/core';
// // import customAxios from './customAxios'; // Import your custom Axios instance
// // import ErrorBoundary from './ErrorBoundary';

// // Override console methods to suppress logs
// // console.log = () => {};
// // console.error = () => {};
// // console.warn = () => {};

// // Get the root element
// const container = document.getElementById('root');
// const root = createRoot(container); // Create a root
// // window.addEventListener('error', (event) => {
// //   if (process.env.NODE_ENV === 'production') {
// //     // Suppress console error logs in production
// //     console.error = () => {};
// //   }
// // });
// // if (process.env.NODE_ENV === 'development') {
// //   console.error = () => {};
// // }

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <MantineProvider>
//         {/* <ErrorBoundary> */}
//           <App />
//         {/* </ErrorBoundary> */}
//       </MantineProvider>
//     </Provider>
//   </React.StrictMode>
// );

// // Measure performance in your app
// reportWebVitals();
