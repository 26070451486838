import "../../assets/style/lecturers.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dividerClasses } from "@mui/material";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { freelancer_get } from "../../redux/action/freelancer-action";
import { cv12_details, cv1_details } from "../../redux/action/cv-action";
import image1 from "../../assets/image/cvv.jpg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useTranslation } from "react-i18next";
import first_lecturer from "../../assets/image/first-lecturer.jpg";
import second_lecturer from "../../assets/image/second-lecturer.jpg";
import third_lecturer from "../../assets/image/third-lecturer.jpg";

const Lecturers = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        direction:
          i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
      }}
      className="my-[-45px]  max-w-[1565px] freelance-div justify-center  mx-auto "
    >
      <h1 className="text-center works_title   leading-tight  mb-[85px] font-[500]  tracking-tight text-gray-900 ">
      {t("lecturers")}
      </h1>
      <div className="flex justify-center">
        <ul className="grid grid-cols-1 xl:grid-cols-3 gap-y-10 gap-x-6 items-start p-4   ">
        <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
        <div className="order-1 ">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-indigo-500">
                {t("Primary_School")}
                </span>
                {t("primary_header")}
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                {t("primary_content")}

                </p>
              </div>
              
              {/* <Link
                className="group gap-2   inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 focus:ring-slate-500 mt-6"
to={'lecturers/Primary-School-Lecturers'}              >
                {t("Show_all")}
            
<p className="text-slate-500">            {">"}
</p>              </Link>  */}



            </div>
            <Link to={'teachers/Primary-School-teachers'} className="relative text-center frfrr third_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6">
              <img
                src={first_lecturer}
                alt=" teachers - image"
                title=" teachers "                className="third_lecturer flex justify-center items-center text-white text-center h-[195px]   shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
              />
              <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                {t("Primary_School")}
              </div>
            </Link>
          </li>
          <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
            <div className="order-1 ">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-purple-500">
                {t("Middle_School")}
                </span>
                {t("middle_header")}
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                {t("middle_content")}

                </p>
              </div>
           
            </div>
            <Link to={'teachers/Middle-School-teachers'} className="relative frfrr third_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6">
              <img
                src={second_lecturer}
                alt=" teachers - image"
                title=" teachers "

                className="third_lecturer lecturer-image flex justify-center items-center text-white h-[195px]  shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
              />
              <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                {t("Middle_School")}
              </div>
            </Link>
          </li>
          <li className="relative flex gap-3 flex-col sm:flex-row xl:flex-col items-center">
            <div className="order-1">
              <h3 className="mb-1 text-slate-900 font-semibold">
                <span className="mb-1 block text-sm leading-6 text-cyan-500">
                {t("High_School")}
                </span>
                {t("High_header")}
              </h3>
              <div className="prose prose-slate prose-sm text-slate-600">
                <p>
                {t("High_content")}

                </p>
              </div>
           
  {/* <Link
                className="group gap-2  inline-flex items-center h-9 rounded-full text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-slate-100 text-slate-700 hover:bg-slate-200 hover:text-slate-900 focus:ring-slate-500 mt-6"
to={'lecturers/High-School-Lecturers'}              >
                {t("Show_all")}
            
<p className="text-slate-500">            {">"}
</p>              </Link>              */}
               
            </div>
            <Link to={'/teachers/High-School-teachers'} className="relative frfrr first_lecturer flex justify-center items-center text-whitem shadow-md  h-[195px] rounded-lg bg-slate-50 w-full sm:mb-0 xl:mb-6">
              <img
                src={third_lecturer}
                alt=" teachers - image"
                title=" teachers "
                                className="third_lecturer  flex justify-center items-center text-white h-[195px]  shadow-md rounded-lg bg-slate-50 w-full sm:mb-0 "
              />
              <div className="absolute inset-0 flex items-center justify-center text-white font-[500] text-3xl">
                {t("High_School")}
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Lecturers;
