import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  freelancer_details,
  review_get,
  user_review_get,
  review_insert,
} from "../../../redux/action/freelancer-action";
import Loading from "../../../sub component/show design data/loading";
import HomeFooter from "../../../sub component/home/footer";
import logger from "../../../utils/logger";
import {
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import brand_image from "../../../assets/image/Brand-photos.png";

import Error404 from "../../error page/error404";
import { useTranslation } from "react-i18next";
import { user_action } from "../../../redux/action/cv-action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Rating } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Verify_email from "../../verify-email/verify-email";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import Reviews_pagination from "./reviews-pagination";
import { Login } from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Main_freeLancers_details = () => {
  const [loading, setLoading] = useState(true);
  const [review_data, setReview_data] = useState();

  (function () {
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    console.log = function (...args) {
      if (
        typeof args[0] === "string" &&
        args[0].includes("XHR finished loading")
      ) {
        return;
      }
      originalLog.apply(console, args);
    };

    console.warn = function (...args) {
      if (
        typeof args[0] === "string" &&
        args[0].includes("XHR finished loading")
      ) {
        return;
      }
      originalWarn.apply(console, args);
    };

    console.error = function (...args) {
      if (
        typeof args[0] === "string" &&
        args[0].includes("XHR finished loading")
      ) {
        return;
      }
      originalError.apply(console, args);
    };
  })();

  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const dispatch = useDispatch();
  const userId = parseInt(id, 10);
  const data = useSelector((state) => state.freelancer.freelancer_details);
  const reviews = useSelector((state) => state.freelancer.review_get_data);
  const user_reviews = useSelector((state) => state.freelancer.user_review);
  const reviews_page = useSelector((state) => state.freelancer.reviews_page);
  const total_reviews = useSelector((state) => state.freelancer.total_reviews);

  useEffect(() => {
    setReview_data(reviews);
  }, [reviews]);
  useEffect(() => {
    setReview_data(reviews_page);
  }, [reviews_page]);
  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        await setLoading(true);

        await dispatch(freelancer_details(userId));
        await dispatch(user_action());
        await dispatch(review_get(userId));
        await dispatch(user_review_get(userId));

        await setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, userId]);

  const token = useSelector((state) => state.cv.isAuthuntucated);
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  // useEffect(() => {
  //   if (!loading && (!data || Object.keys(data).length === 0)) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: "Something went wrong!",
  //       footer: '<a href="#">Why do I have this issue?</a>',
  //     });
  //   }
  // }, [loading, data]);
  const navigate = useNavigate();
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }
  const style = {
    fontFamily: '"Poppins", sans-serif',
  };
  //  ama index e yak la tab aka  wtm bkretawa ba default
  const [value, setValue] = React.useState(0);
  const [rating, setRating] = useState(); // State for the rating value

  const [comment, setComment] = useState("");
  useEffect(() => {
    if (user_reviews) {
      setRating(user_reviews.rating);
      setComment(user_reviews.comment);
    }
  }, [user_reviews]);
  // to user cant all 1s send reqwest because server will be down
  const [hasDispatched, setHasDispatched] = useState(false);
  const handleComment = async () => {
    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(review_insert(userId, rating, comment || "No Comment", t));
      //re get reviews data to show new data
      await dispatch(review_get(userId));
      //re get freelancer_details data to show new data
      await dispatch(freelancer_details(userId));
      setTimeout(() => {
        setHasDispatched(false);
      }, 4000);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!loading && (!data || Object.keys(data).length === 0)) {
    return <Error404 />;
  }
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  return (
    <>
 
        <Helmet>
        {/* Basic Meta Tags */}
        <title>{`${data.name || "Freelancer Detail"}`} - Karbein</title>

        <meta
          name="description"
          content="Explore detailed information about this freelancer on Karbein. Find out about their skills, experience, and how they can help with your projects. Search and filter through top freelancers to find the perfect match for your needs."
        />
        <meta
          name="keywords"
          content="freelancer details, freelance profiles, freelancer expertise, hire freelancers, freelance services, professional freelancers, freelancer skills"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Freelancer Detail - Karbein" />
        <meta
          property="og:description"
          content="Discover detailed information about this freelancer on Karbein. Learn about their skills, experience, and how they can contribute to your projects. Explore top freelancers and find the best fit for your needs."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta
          property="og:url"
          content="https://karbein.com/freelance/freelance-detail/:id"
        />
        <meta property="og:type" content="profile" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Freelancer Detail - Karbein" />
        <meta
          name="twitter:description"
          content="Get detailed information about this freelancer on Karbein. Learn about their expertise, experience, and how they can assist with your projects. Browse top freelancers and choose the best one for your needs."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta
          name="twitter:url"
          content="https://karbein.com/freelance/freelance-detail/:id"
        />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://karbein.com/freelance/freelance-detail/:id"
        />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "Person",
        "name": "Freelancer Name",
        "description": "Detailed information about this freelancer, including their skills, experience, and how they can assist with your projects. Browse top freelancers on Karbein to find the best fit for your needs.",
        "url": "https://karbein.com/freelance/freelance-detail/:id",
        "image": "https://karbein.com/images/Brand-photos.png",
        "sameAs": "https://karbein.com/freelance/freelance-detail/:id"
      }
    `}
        </script>
      </Helmet>
      <div>
        {loading ? (
          <Loading />
        ) : token ? (
          isUser_verified === null ? (
            <Verify_email />
          ) : (
            <div
              className="flex justify-between flex-col min-h-screen"
              style={{
                direction:
                  i18n.language === "ku" || i18n.language === "ar"
                    ? "rtl"
                    : "ltr",
              }}
            >
              <section className="main-freeLancer-container relative pt-10 md:pt-40 mt-8 md:mt-[70px] ">
                <div
                  alt="cover-image"
                  className="freelance-details-cover w-full absolute top-0 left-0 z-0 h-40 md:h-60"
                />
                <div className="w-full max-w-7xl mx-auto px-4 md:px-6 lg:px-8">
                  <div className="flex items-center justify-center sm:justify-start relative z-10 mb-5">
                    <img
                      src={data.image || brand_image}
                      alt="user-avatar-image"
                      className="border-4 border-solid border-white w-32 h-32 sm:w-40 sm:h-40 md:w-[200px] md:mt-0  mt-[60px] md:h-[200px] rounded-full"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:gap-5 items-center justify-between mb-5">
                    <div className=" sm:text-left">
                      <h3 className="font- font-bold text-2xl sm:text-3xl md:text-4xl text-gray-900 mb-1">
                        {data.name}
                      </h3>
                      <p className="font-normal text-sm sm:text-base leading-6 text-gray-500">
                        {data.city}, {data.state}, {data.country}
                      </p>
                    </div>
                    <div className="flex flex-col gap-[10px] items-center md:mt-0  mt-[30px]">
                      <Box
                        sx={{
                          "& > legend": { mt: 2 },
                        }}
                      >
                        <Rating
                          name="size-small"
                          readOnly
                          value={Number(data.average_rating)}
                          size="medium"
                        />
                      </Box>
                      <button className="rounded-full py-2 px-4 sm:py-3 sm:px-5 bg-gray-100 flex items-center group transition-all duration-500 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            className="stroke-gray-700 transition-all duration-500"
                            d="M14.1667 11.6666V13.3333C14.1667 14.9046 14.1667 15.6903 13.6785 16.1785C13.1904 16.6666 12.4047 16.6666 10.8333 16.6666H7.50001C5.92866 16.6666 5.14299 16.6666 4.65483 16.1785C4.16668 15.6903 4.16668 14.9047 4.16668 13.3333V11.6666M16.6667 9.16663V13.3333M11.0157 10.434L12.5064 9.44014C14.388 8.18578 15.3287 7.55861 15.3287 6.66663C15.3287 5.77466 14.388 5.14749 12.5064 3.89313L11.0157 2.8993C10.1194 2.3018 9.67131 2.00305 9.16668 2.00305C8.66205 2.00305 8.21393 2.3018 7.31768 2.8993L5.82693 3.89313C3.9454 5.14749 3.00464 5.77466 3.00464 6.66663C3.00464 7.55861 3.9454 8.18578 5.82693 9.44014L7.31768 10.434C8.21393 11.0315 8.66205 11.3302 9.16668 11.3302C9.67131 11.3302 10.1194 11.0315 11.0157 10.434Z"
                            stroke="#374151"
                            stroke-width="1.6"
                            stroke-linecap="round"
                          />
                        </svg>

                        <span className="px-2 font-medium text-sm sm:text-base text-gray-700 transition-all duration-500 ">
                          {data.functional_area}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row gap-5 items-center justify-between py-2 md:py-4">
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => handlePhoneCall(data.phone)}
                        className="py-2 px-4 sm:py-3 sm:px-5 rounded-full bg-[#ffb923] text-white font-semibold text-sm sm:text-base shadow-sm shadow-transparent transition-all duration-500 hover:shadow-gray-100 hover:bg-[#ffc64a]"
                      >
                        {t("Phone")}
                      </button>
                      <button
                        onClick={() => handleSendEmail(data.email)}
                        className="py-2 px-4 sm:py-3 sm:px-5 rounded-full bg-gray-50 text-[#ffb923] font-semibold text-sm sm:text-base shadow-sm shadow-transparent transition-all duration-500 hover:bg-gray-100"
                      >
                        {t("Email")}
                      </button>
                    </div>
                    {/* <div class="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                <p class="flex items-center gap-2 font-medium text-sm sm:text-base text-gray-400">
                    Skills
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.78135 5.55191C9.4453 3.5173 9.77728 2.5 10.3928 2.5C11.0083 2.5 11.3403 3.5173 12.0043 5.55191L12.2949 6.44244C12.4784 7.00479 12.5701 7.28596 12.7928 7.44706C13.0155 7.60816 13.3125 7.60816 13.9063 7.60816H14.8683C17.0355 7.60816 18.119 7.60816 18.3081 8.19335C18.4972 8.77854 17.6169 9.40763 15.8563 10.6658L15.0921 11.2118C14.6069 11.5586 14.3643 11.732 14.278 11.9937C14.1918 12.2554 14.2841 12.5382 14.4687 13.1038L14.7569 13.9872C15.4209 16.0218 15.7529 17.0391 15.2549 17.3993C14.7569 17.7595 13.8878 17.1308 12.1496 15.8733L11.3887 15.323C10.9083 14.9754 10.6681 14.8016 10.3928 14.8016C10.1175 14.8016 9.87731 14.9754 9.39687 15.323L8.63605 15.8733C6.89779 17.1308 6.02866 17.7595 5.5307 17.3993C5.03273 17.0391 5.36471 16.0218 6.02866 13.9872L6.31927 13.0966C6.50278 12.5343 6.59454 12.2531 6.50948 11.9924C6.42441 11.7318 6.18419 11.558 5.70375 11.2104L4.94293 10.6601C3.20467 9.40261 2.33555 8.77389 2.52575 8.19102C2.71596 7.60816 3.79026 7.60816 5.93886 7.60816H6.87929C7.47315 7.60816 7.77008 7.60816 7.99277 7.44706C8.21547 7.28596 8.30723 7.00479 8.49074 6.44244L8.78135 5.55191Z" stroke="#9CA3AF" stroke-width="1.6"/>
                    </svg>
                </p>
                <span class="text-sm sm:text-base">{data.skills}</span>
            </div> */}
                  </div>

                  <div className=" mt-[50px]  rounded-lg  bg-white">
                    <div className="w-full my-auto py-6 flex flex-col justify-center gap-2">
                      <div className="w-full flex lg:flex-row  flex-col  xs:flex-col gap-2 justify-center">
                        <div className="w-[90%]">
                          <dl className="text-gray-900 ">
                            <div className="flex flex-col pb-3">
                              <dt className="mb-1 text-gray-800 md:text-lg font-[600] dark:text-gray-400">
                                {t("Functional_Area")}
                              </dt>
                              <dd className="text-md font-[500] main-freeLancer-container-text">
                                {data.functional_area}
                              </dd>
                            </div>
                            <div className="flex flex-col py-3">
                              <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                {" "}
                                {t("per_hour_price")}{" "}
                              </dt>
                              <dd className="text-md font-[500] main-freeLancer-container-text">
                                {data.per_hour_price} {data.currency}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div className="w-full">
                          <dl className="text-gray-900 ">
                            <div className="flex flex-col pb-3">
                              <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                {t("experience")}
                              </dt>
                              <dd className="text-md font-[500] main-freeLancer-container-text">
                                {data
                                  ? `${data.experience} ${t("years")} `
                                  : null}
                              </dd>
                            </div>
                            <div className="flex flex-col py-3">
                              <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                                {t("Skills")}
                              </dt>
                              <dd className="text-lg font-[500] main-freeLancer-container-text">
                                {data.skills}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col mt-[-10px] mb-[30px]">
                      <dt className="mb-1 text-gray-800 font-[600] md:text-lg dark:text-gray-400">
                        {t("Description")}
                      </dt>
                      <dd className="text-md font-[500] main-freeLancer-container-text">
                        {data ? `${data.description}  ` : null}
                      </dd>
                    </div>
                    <div className=" max-w-full">
                      <Box className="mb-[-20px] ">
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab
                              label={
                                <dt
                                  className={
                                    i18n.language === "ar" ||
                                    i18n.language === "ku"
                                      ? "mb-1 mr-[-16px] text-gray-600 md:text-lg dark:text-gray-400"
                                      : "mb-1 ml-[-16px] text-gray-600 md:text-lg dark:text-gray-400"
                                  }
                                >
                                  {t("Reviews")}
                                </dt>
                              }
                              {...a11yProps(0)}
                            />
                            <Tab
                              label={
                                <dt className="mb-1 text-gray-600 md:text-lg dark:text-gray-400">
                                  {t("My_Review")}
                                </dt>
                              }
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                          {review_data.map((review, index) => (
                            <div key={index} className="my-[10px]">
                              <h1 className="font-[600] text-gray-700 mb-[5px]  break-words">
                                {review.user_name}
                              </h1>
                              <Box
                                sx={{
                                  "& > legend": { mt: 2 },
                                }}
                              >
                                <Rating
                                  name="size-small"
                                  readOnly
                                  value={Number(review.rating)}
                                  size="small"
                                />
                              </Box>
                              <p className="text-[14px] break-words">
                                {" "}
                                {review.comment}{" "}
                              </p>
                              {/* bo awaya ka axer dana hr e nabe */}
                              {index !== review_data.length - 1 && (
                                <hr className="min-w-full mt-[10px]" />
                              )}
                            </div>
                          ))}
                          {review_data
                            ? review_data.length === 0 && (
                                <div className="search-message my-[10px] text-[14px]   h-[100%] main-freeLancer-container-text">
                                  {t("not_haveData")}
                                </div>
                              )
                            : setLoading(true)}
                          <div className="flex justify-center w-full mt-[40px]  mb-[20px]">
                            <hr />
                            {total_reviews > 5 ? (
                              <Reviews_pagination id={id} />
                            ) : null}
                          </div>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                          <Box
                            sx={{
                              "& > legend": { mt: 2 },
                            }}
                          >
                            <Rating
                              name="size-small"
                              value={Number(rating)}
                              size="medium"
                              onChange={(event, newValue) => {
                                setRating(newValue); // Update the rating value only
                              }}
                            />
                            {/* {user_reviews.comment} */}
                          </Box>

                          <div className="mb-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("comment")}
                            </label>
                            <textarea
                              className="input_add_freelancer h-[50px] text-area border bg-white"
                              placeholder={t("comment")}
                              required
                              value={comment}
                              onChange={(e) => {
                                setComment(e.target.value);
                              }}
                            />
                          </div>
                          <button
                            onClick={handleComment}
                            type="submit"
                            className="text-white bg-[#ffb923] hover:bg-[#ffcc5f] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            {t("send")}
                          </button>
                        </CustomTabPanel>
                      </Box>
                    </div>
                  </div>
                </div>
              </section>
              <div className="max-w-full">
                <HomeFooter />
              </div>
            </div>
          )
        ) : (
          <Login />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Main_freeLancers_details;
