//bg_open
export const OPEN = "OPEN";

export const open_action = (bg_color,bg_color_open,font_family,line_height,font_size,selected_color) => {
  return {
    type: OPEN,
    bg_color:bg_color,
    bg_color_open: bg_color_open,
    line_height: line_height,
    font_family:font_family,
font_size:font_size,
selected_color:selected_color
  };
};
//color pen
export const COLOR = "COLOR";

export const color_action = (color_open) => {
  return {
    type: COLOR,
    color_open: color_open,

  };
};
