import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';

import { page_middle, page_primary } from '../../../../redux/action/lecturers-action';

const Middle_Pagination = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.lecturers.searchPerformed
      ? state.lecturers.total_pages_search
      :    state.lecturers.middle_total_page

  ); 


    const country_search_value = useSelector((state) => state.lecturers.first_search_value)||"";
    const city_search_value = useSelector((state) => state.lecturers.second_search_value)||"";
    const state_search_value = useSelector((state) => state.lecturers.third_search_value)||"";
    const subject_search_value = useSelector((state) => state.lecturers.fourth_search_value)||"";
    const study_mode_search_value = useSelector((state) => state.lecturers.fifth_search_value)||"";
    const language_search_value = useSelector((state) => state.lecturers.sixth_search_value)||"";
    const name_search_value = useSelector((state) => state.lecturers.seventh_search_value)||"";


  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {

    dispatch(page_middle(page,country_search_value,city_search_value,state_search_value,subject_search_value,study_mode_search_value,language_search_value,name_search_value));
    setCurrentPage(page);
  };
  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default Middle_Pagination;
