import React from "react";

import "../../assets/style/inputs.css";
import Course from "../../sub component/home/cv-inputs/course";
import Education from "../../sub component/home/cv-inputs/education";
import Profile from "../../sub component/home/cv-inputs/profile";
import Contact from "../../sub component/home/cv-inputs/contact";
import Skills from "../../sub component/home/cv-inputs/skills";
import Language from "../../sub component/home/cv-inputs/language";
import Work_experience from "../../sub component/home/cv-inputs/work-experiense";
import References from "../../sub component/home/cv-inputs/references";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Design = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="create-cv   min-w-[50%] ">

        <Profile />
        <Contact />
        <Education />
        <Skills />
        <Language />
        <Course />
        <Work_experience />

      
    </div>
  );
};

export default Design;
