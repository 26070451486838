//education

import axios from "axios";
//work experience
export const PROFILE = "PROFILE";
export const profile_action = (image,description,name) => {
  return {
    type: PROFILE,
    name:name,
    image: image,
    description: description,
  };
};
export const EDUCATION = "EDUCATION";
export const education = (
  education,
  scholl,
  city,
  start_date,
  end_date,
  description
) => {
  return {
    type: EDUCATION,
    education: education,
    scholl: scholl,
    city: city,
    start_date: start_date,
    end_date: end_date,
    description: description,
  };
};
//skills
export const SKILLS = "SKILLS";
export const skills = (skills) => {
  return {
    type: SKILLS,
    skills: skills,
  };
};
//languages
export const LANGUAGES = "LANGUAGES";
export const languages_action = (languages, range) => {
  return {
    type: LANGUAGES,
    languages: languages,
    range: range,
  };
};
//course
export const COURSE = "COURSE";
export const course_action = (title, year, description) => {
  return {
    type: COURSE,
    title: title,
    year: year,
    description: description,
  };
};
//work experience
export const WORKEXPERIENCE = "WORKEXPERIENCE";
export const Work_experience_action = (title, year, description) => {
  return {
    type: WORKEXPERIENCE,
    title: title,
    year: year,
    description: description,
  };
};
// 
export const REFERENCES = "REFERENCES";
export const references_action = (name, organization, city,phone,email) => {
  return {
    type: REFERENCES,
    name: name,
    organization: organization,
    city: city,
    phone:phone,
    email:email
  };
};
export const LANGU = "LANGU";
export const languages = (langs, ranges) => {
  return async (dispatch) => {
    try {
      // Check if both arrays have the same length
      if (langs.length !== ranges.length) {
        console.error(
          "Error: langs and ranges arrays must have the same length."
        );
        return;
      }
      // Iterate through each language item
      for (let i = 0; i < langs.length; i++) {
        const lang = langs[i];
        const range = ranges[i];

        const response = await axios.post(
          `http://localhost:8000/api/language/add`, // Make sure to replace with the correct URL
          {
            lang,
            range,
          },
          {
            credentials: "include",
          }
        );
        dispatch({ type: LANGU, payload: response });
        console.error("Error inserting languages:");
      }
    } catch (error) {
      console.error("Error inserting languages:", error);
    }
  };
};

