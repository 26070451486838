import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Sections = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        direction: i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
      }}
      className="relative no-underline inline-block text-left"
    >
      <div>
        <Link
          onClick={toggleDropdown}
          className="i flex items-center no-underline gap-2 cursor-pointer"
          id="options-menu"
          aria-haspopup="true"
        >
          <Link
            className="hover:text-gray-600 no-underline whitespace-nowrap text-gray-800"
          >
            {t("Sections")}
          </Link>
          {/* Arrow Icon */}
          {isOpen ? (
            <div className="w-full hover:text-gray-600 no-underline whitespace-nowrap text-gray-800">
              <svg
                className="w-3 h-3 text-gray-600 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 8"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
                />
              </svg>
            </div>
          ) : (
            <svg
              className="w-3 h-3 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
              />
            </svg>
          )}
        </Link>
      </div>

      {/* Dropdown menu, show/hide based on state */}
      {isOpen && (
        <div
          style={{
            direction: i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}
          className="origin-top text-[15px] cursor-pointer absolute left-1 right-0 mt-[30px] w-60 rounded shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="" role="none">
            {/* Dropdown items */}
            <div className="gap-[50px]">
              <div
                className="flex flex-col gap-1"
                style={{
                  direction: i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Link
                  to="/CV-templates"
                  className="p-2.5  px-3 pt-3 text-gray-800 no-underline hover:text-white hover:bg-[#ffb923] duration-500"
                  onClick={closeDropdown}
                >
                  {t("Create_CV")}
                </Link>
                <Link
                  to="/jobs"
                  className="p-2.5 px-3 text-gray-800 no-underline hover:text-white hover:bg-[#ffb923] duration-500"
                  onClick={closeDropdown}
                >
                  {t("Jobs")}
                </Link>
                <Link
                  to="/freelance"
                  className="p-2.5  px-3 text-gray-800 no-underline hover:text-white hover:bg-[#ffb923] duration-500"
                  onClick={closeDropdown}
                >
                  {t("Freelancers")}
                </Link>
                <Link
                  to="/teachers"
                  className="p-2.5  px-3 text-gray-800 no-underline hover:text-white hover:bg-[#ffb923] duration-500"
                  onClick={closeDropdown}
                >
                  {t("lecturers")}
                </Link>
                <Link
                  to="/Services"
                  className="p-2.5  px-3  pb-3 text-gray-800 no-underline hover:text-white hover:bg-[#ffb923] duration-500"
                  onClick={closeDropdown}
                >
                  {t("Services")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sections;
