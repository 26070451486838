import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "rsuite/Accordion/styles/index.css";
import SearchIcon from "@rsuite/icons/Search";
import "rsuite/Input/styles/index.css";
import Input from "rsuite/Input";
import InputGroup from "rsuite/InputGroup";
import MaskedInput from "rsuite/MaskedInput";
import WorkHistoryIcon from "@mui/icons-material/WorkHistoryOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  freelancer_get,
  search_freelancer,
  search_value,
} from "../../../redux/action/freelancer-action";
import "../../../assets/style/main-pages.css";
import brand from "../../../assets/image/Brand-photos.png";
import Cookies from "js-cookie";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Loading from "../../../sub component/show design data/loading";
import "../../../assets/style/search_loading.css";
import Search_loading from "../search loading/search-loading";
import PaginationComponent from "../freelancer/freeLancer_pagoinations";
import HomeFooter from "../../../sub component/home/footer";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TuneIcon from "@mui/icons-material/Tune";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import {
  citySearch_job,
  city_search_value,
  company_nameSearch_job,
  company_name_search_value,
  countrySearch_job,
  country_search_value,
  functionalAreaSearch_job,
  functional_area_search_value,
  industrySearch_job,
  industry_search_value,
  job_get,
  job_search_value,
  skillSearch_job,
  job_search,
  job_search_data,
  job_search_get,
  state_search_value,
  type_search_value,
  experience_search_value,
  certification_search_value,
} from "../../../redux/action/job-action";
import brand_image from "../../../assets/image/Brand-photos.png";

import JobPagination from "./job-pagination";
import { Helmet } from "react-helmet";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Main_jobs = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [tipe, setTipe] = useState();
  const [title, setTitle] = useState("");
  const [phoneTitle, setPhoneTitle] = useState("");

  const [funcrional_area, setFuncrional_area] = useState();
  const [Industry, setIndustry] = useState("");
  const [Skill, setSkill] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [Experience, setExperience] = useState("");
  const [Type, setType] = useState("");
  const [Certifications, seCertifications] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  const [search, setSearch] = useState("");

  const jobData = useSelector((state) => state.job.job_get);
  const job_search = useSelector((state) => state.job.job_search);
  const jobSearchData = useSelector((state) => state.job.job_search_get);
  //get  total number jobs  for pagination if data>10 then show paginations
  const total_jobs = useSelector((state) => state.job.total_jobs);
  const job_page = useSelector((state) => state.job.job_page);
  const second_job_page = useSelector((state) => state.job.second_job_page);

  // get data for search

  useEffect(() => {
    setSearchData(jobSearchData);
  }, [jobSearchData]);

  useEffect(() => {
    setData(jobData);
  }, [jobData]);

  useEffect(() => {
    setData(job_search);
  }, [job_search]);
  useEffect(() => {
    setData(job_page);
  }, [job_page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);

      await dispatch(job_get());
      await dispatch(job_search_get());

      await setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const [searchLoading, setSearchLoading] = useState(false);

  const user_id = useSelector((state) => state.cv.user.id);
  const searchPerformed = useSelector((state) => state.job.searchPerformed);
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const navigate = useNavigate(); // Initialize useNavigate hook
  const token = useSelector((state) => state.cv.isAuthuntucated);
  //cheak agar  token  nabu   atba bo login balam agar habu du 7alat haya agar am user freelancere drust krdbe atba bo update balam agar druste nakrdbe atba bo add freelancer

  const styles = {
    width: 300,
    marginBottom: 10,
  };

  // amana bo batal krdnawaya bo nmuna say aka agar inputaka value te bu ka cheak man krd value batal akatawa wa agar heche te nabu valuekay te daka
  //company onchange
  const handleCompanyChange = (value) => {
    if (CompanyName === value) {
      // If the same checkbox is clicked again, uncheck it
      setCompanyName("");
    } else {
      // Otherwise, check the clicked checkbox
      setCompanyName(value);
    }
  };

  //functional_area onchange
  const handleTitleChange = (value) => {
    if (title === value) {
      // If the same checkbox is clicked again, uncheck it
      setTitle("");
    } else {
      // Otherwise, check the clicked checkbox
      setTitle(value);
    }
  };

  //functional_area onchange
  const handleIndustryChange = (value) => {
    if (Industry === value) {
      // If the same checkbox is clicked again, uncheck it
      setIndustry("");
    } else {
      // Otherwise, check the clicked checkbox
      setIndustry(value);
    }
  };

  //functional_area onchange
  const handleFuncrional_areaChange = (value) => {
    if (funcrional_area === value) {
      // If the same checkbox is clicked again, uncheck it
      setFuncrional_area("");
    } else {
      // Otherwise, check the clicked checkbox
      setFuncrional_area(value);
    }
  };

  const handleCountryChange = (value) => {
    if (country === value) {
      // If the same checkbox is clicked again, uncheck it
      setCountry("");
    } else {
      // Otherwise, check the clicked checkbox
      setCountry(value);
    }
  };

  const handleStateChange = (value) => {
    if (state === value) {
      // If the same checkbox is clicked again, uncheck it
      setState("");
    } else {
      // Otherwise, check the clicked checkbox
      setState(value);
    }
  };
  const handleCityChange = (value) => {
    if (city === value) {
      // If the same checkbox is clicked again, uncheck it
      setCity("");
    } else {
      // Otherwise, check the clicked checkbox
      setCity(value);
    }
  };

  const handleExperienceChange = (value) => {
    if (Experience === value) {
      // If the same checkbox is clicked again, uncheck it
      setExperience("");
    } else {
      // Otherwise, check the clicked checkbox
      setExperience(value);
    }
  };
  const handleTypeChange = (value) => {
    if (Type === value) {
      // If the same checkbox is clicked again, uncheck it
      setType("");
    } else {
      // Otherwise, check the clicked checkbox
      setType(value);
    }
  };
  const handleCertificationsChange = (value) => {
    if (Certifications === value) {
      // If the same checkbox is clicked again, uncheck it
      seCertifications("");
    } else {
      // Otherwise, check the clicked checkbox
      seCertifications(value);
    }
  };

  const costomsSearch = async () => {
    setSearchLoading(true);
    await dispatch(
      job_search_data(
        CompanyName,
        funcrional_area,
        Industry,
        phoneTitle,
        country,
        state,
        city,
        Experience,
        Certifications,
        Type
      )
    );

    dispatch(functional_area_search_value(funcrional_area)); // Set loading to false after dispatch
    dispatch(industry_search_value(Industry));
    dispatch(company_name_search_value(CompanyName));

    dispatch(type_search_value(Type));
    dispatch(experience_search_value(Experience));

    dispatch(certification_search_value(Certifications));

    dispatch(state_search_value(state));
    dispatch(country_search_value(country));
    dispatch(city_search_value(city));

    job_search_value();
    dispatch(job_search_value(phoneTitle));

    setSearchLoading(false);
  };
  useEffect(() => {
    const search = async () => {
      setSearchLoading(true);
      await dispatch(
        job_search_data(
          CompanyName,
          funcrional_area,
          Industry,
          title || phoneTitle,
          country,
          state,
          city,
          Experience,
          Certifications,
          Type
        )
      );

      dispatch(functional_area_search_value(funcrional_area)); // Set loading to false after dispatch
      dispatch(industry_search_value(Industry));
      dispatch(company_name_search_value(CompanyName));

      dispatch(type_search_value(Type));
      dispatch(experience_search_value(Experience));

      dispatch(certification_search_value(Certifications));

      dispatch(state_search_value(state));
      dispatch(country_search_value(country));
      dispatch(city_search_value(city));

      job_search_value();
      dispatch(job_search_value(title));

      setSearchLoading(false);
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (
      CompanyName !== "" ||
      funcrional_area !== "" ||
      Industry !== "" ||
      title !== "" ||
      country !== "" ||
      state !== "" ||
      city !== "" ||
      Experience !== "" ||
      Certifications !== "" ||
      Type !== ""
    ) {
      search();
    }
  }, [
    country,
    state,
    city,
    Industry,
    CompanyName,
    title,
    Certifications,
    Experience,
    Type,
    funcrional_area,
    dispatch,
  ]);

  const experienceList = [
    { value: "1 to 3 years", label: "1 to 3 years" },
    { value: "3 to 6 years", label: "3 to 6 years" },
    { value: "6 to 9 years", label: "6 to 9 years" },
    { value: "More than 10 years", label: "More than 10 years" },
  ];

  //bo awaya agar hatu datay databaseka dubaray tebu  lay bda wata har title la database bo nmuna 20 danay haya
  //nache 20 jar benuse har yakjar aynuse wa he mobile u desktopm krdwa chunka agar yak dana bkay  natwani la shwene deka maoaka bakar bhenetawa
  const shownIndustries = new Set();
  const shownPhoneIndustries = new Set();

  const shownFunctionalAreas = new Set();
  const shownPhoneFunctionalAreas = new Set();

  const shownCompanies = new Set();
  const shownPhoneCompanies = new Set();

  const shownTitle = new Set();
  const shownPhoneTitle = new Set();

  const showncountry = new Set();
  const shownPhonecountry = new Set();

  const shownState = new Set();
  const shownPhoneState = new Set();

  const shownCity = new Set();
  const shownPhoneCity = new Set();

  const shownExperience = new Set();
  const shownType = new Set();
  const shownPhoneType = new Set();

  const shownCertifications = new Set();
  const shownPhoneCertifications = new Set();

  //get date to show expired
  const currentDate = new Date();

  // bo henanaway county shtakan bo nmuna  companyayak du eshe blaw krdwa du anusre
  // bo nmuna chan esh la iraq haya danusry
  const nameCount = searchData.reduce((acc, item) => {
    acc[item.industry] = (acc[item.industry] || 0) + 1;
    return acc;
  }, {});

  const countryCount = searchData.reduce((acc, item) => {
    acc[item.country] = (acc[item.country] || 0) + 1;
    return acc;
  }, {});

  const stateCount = searchData.reduce((acc, item) => {
    acc[item.state] = (acc[item.state] || 0) + 1;
    return acc;
  }, {});

  const cityCount = searchData.reduce((acc, item) => {
    acc[item.city] = (acc[item.city] || 0) + 1;
    return acc;
  }, {});

  const TypeCount = searchData.reduce((acc, item) => {
    acc[item.job_type] = (acc[item.job_type] || 0) + 1;
    return acc;
  }, {});

  const ExperienceCount = searchData.reduce((acc, item) => {
    acc[item.Experience] = (acc[item.Experience] || 0) + 1;
    return acc;
  }, {});

  const funcrional_areaCount = searchData.reduce((acc, item) => {
    acc[item.funcrional_area] = (acc[item.funcrional_area] || 0) + 1;
    return acc;
  }, {});

  const CertificationsCount = searchData.reduce((acc, item) => {
    acc[item.Certifications] = (acc[item.Certifications] || 0) + 1;
    return acc;
  }, {});

  const company_nameCount = searchData.reduce((acc, item) => {
    acc[item.company_name] = (acc[item.company_name] || 0) + 1;
    return acc;
  }, {});

  const industrysCount = searchData.reduce((acc, item) => {
    acc[item.industry] = (acc[item.industry] || 0) + 1;
    return acc;
  }, {});

  const job_titleCount = searchData.reduce((acc, item) => {
    acc[item.job_title] = (acc[item.job_title] || 0) + 1;
    return acc;
  }, {});
  // name filter if have repeat name not show in search bar

  // Format date to YYYY-MM-DD
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  // posted job time
  const timeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - new Date(date)) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1)
      return interval === 1
        ? i18n.language === "ar"
          ? `  ${t("since")}  ${interval} ${t("year_ago")}  `
          : `   ${interval}      ${t("year_ago")}`
        : // if < 1 like 4 menuts
        i18n.language === "ar"
        ? `  ${t("since")}  ${interval} ${t("years_ago")}  `
        : `   ${interval}      ${t("years_ago")}`;

    //  month

    interval = Math.floor(seconds / 2592000);
    if (interval >= 1)
      return interval === 1
        ? i18n.language === "ar"
          ? `  ${t("since")}  ${interval} ${t("month_ago")}  `
          : `   ${interval}      ${t("month_ago")}`
        : // if < 1 like 4 menuts
        i18n.language === "ar"
        ? `  ${t("since")}  ${interval} ${t("months_ago")}  `
        : `   ${interval}      ${t("months_ago")}`;

    //day
    interval = Math.floor(seconds / 86400);
    if (interval >= 1)
      return interval === 1
        ? i18n.language === "ar"
          ? `  ${t("since")}  ${interval} ${t("day_ago")}  `
          : `   ${interval}      ${t("day_ago")}`
        : // if < 1 like 4 menuts
        i18n.language === "ar"
        ? `  ${t("since")}  ${interval} ${t("days_ago")}  `
        : `   ${interval}      ${t("days_ago")}`;

    //hour

    interval = Math.floor(seconds / 3600);
    if (interval >= 1)
      return interval === 1
        ? i18n.language === "ar"
          ? `  ${t("since")}  ${interval}  ${t("hour_ago")}  `
          : `   ${interval}      ${t("hour_ago")}`
        : // if < 1 like 4 menuts
        i18n.language === "ar"
        ? `  ${t("since")}  ${interval} ${t("hours_ago")}  `
        : `   ${interval}      ${t("hours_ago")}`;

    //menute

    interval = Math.floor(seconds / 60);
    if (interval >= 1)
      return interval === 1
        ? i18n.language === "ar"
          ? `  ${t("since")}  ${interval} ${t("minute_ago")}  `
          : `   ${interval} ${t("minute_ago")}`
        : // if < 1 like 4 menuts
        i18n.language === "ar"
        ? `  ${t("since")}  ${interval} ${t("minutes_ago")}  `
        : `   ${interval}      ${t("minutes_ago")}`;

    return seconds < 10
      ? "Just now"
      : i18n.language === "ar"
      ? `  ${t("since")}  ${interval} ${t("seconds_ago")}  `
      : `   ${interval}      ${t("seconds_ago")}`;
  };

  return (
    <>
     <Helmet>
        {/* Basic Meta Tags */}
        <title>Jobs - Karbein</title>
        <meta
          name="description"
          content="Discover a wide range of job opportunities on Karbein. Our platform provides a professional search and filter system to help you find the perfect job for your career goals."
        />
        <meta
          name="keywords"
          content="jobs, job opportunities, job search, professional jobs, career opportunities, job listings, Karbein"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Jobs - Karbein" />
        <meta
          property="og:description"
          content="Find your next career opportunity on Karbein. Browse through our extensive job listings with advanced search and filter options."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/jobs" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Jobs - Karbein" />
        <meta
          name="twitter:description"
          content="Explore a variety of job opportunities on Karbein. Use our professional search and filter tools to find the ideal job for you."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/jobs" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/jobs" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Jobs - Karbein",
              "description": "Discover a wide range of job opportunities on Karbein. Our platform provides a professional search and filter system to help you find the perfect job for your career goals.",
              "url": "https://karbein.com/jobs",
              "image": "https://karbein.com/images/Brand-photos.png",
              "publisher": {
                "@type": "Organization",
                "name": "Karbein",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://karbein.com/images/Brand-photos.png"
                }
              }
            }
          `}
        </script>
      </Helmet>

      <div
        style={{
          direction:
            i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <div>
            {searchLoading ? (
              <Search_loading />
            ) : (
              <div className="flex justify-between flex-col min-h-screen">
                -{}
                <div className="flex cards-container justify-center min-w-[100%] pt-[130px] w-full">
                  <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container min-w-[90%] flex justify-center  md:gap-[25px] ">
                    <div className="left-details-container hidden md:block ">
                      <div className="left-  border min-w-[390px] ] rounded-[10px]   ">
                        <div className="left-details-header rounded-t-[9px] text-[20px] bg-[#ffb923] h-[45px]  text-white flex  font-[500]   border-top-[20px] justify-center items-center">
                          {t("search")}{" "}
                        </div>
                        <div
                          className=" flex   flex-end flex-col"
                          style={{
                            direction:
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "rtl"
                                : "ltr",
                          }}
                        >
                          <form
                            onSubmit={costomsSearch}
                            className="flex items-center min-w-[90%] mt-[20px] mb-[10px] mx-auto"
                          >
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                              <input
                                type="text"
                                id="voice-search"
                                className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={t("search")}
                                required
                                // value={search}
                                value={phoneTitle}
                                onChange={(e) => {
                                  setPhoneTitle(e.target.value);
                                }}
                              />
                            </div>

                            <button
                              type="submit"
                              className="p-2.5 ms-2 text-sm font-medium text-white  bg-[#ffb923] rounded-lg   hover:bg-[#ffc64a]   rounded-lg  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                              <span className="sr-only">Search</span>
                            </button>
                          </form>

                          <div className="mx-[10px] mb-[5px]">
                            <h1 className="mx-[10px]  flex gap-2.5 items-center  mt-[15px] mb-[7px]">
                              <button
                                type="submit"
                                className="p-1 ms-2 text-sm font-medium text-white  bg-[#ffb923] rounded-lg   hover:bg-[#ffc64a]   rounded-lg  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                              >
                                <TuneIcon className=" text-white" />

                                <span className="sr-only">Search</span>
                              </button>

                              {t("Filtering_by")}
                            </h1>
                            <Accordion
                              expanded={expanded === "panel12"}
                              onChange={handleChange("panel12")}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                <Typography>{t("Job_Title")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-w-full max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownTitle.has(data.job_title)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownTitle.add(data.job_title); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={title === data.job_title}
                                          onChange={() =>
                                            handleTitleChange(data.job_title)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.job_title}{" "}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] min-w-[25px] max-w-[9ch]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {job_titleCount[data.job_title]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel2"}
                              onChange={handleChange("panel2")}
                            >
                              <AccordionSummary
                                aria-controls="panel2d-content"
                                id="panel2d-header"
                              >
                                <Typography>{t("Country")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (showncountry.has(data.country)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    showncountry.add(data.country); // Add company name to set

                                    return (
                                      <div
                                        key={`country-${data.id}`}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={country === data.country}
                                          onChange={() =>
                                            handleCountryChange(data.country)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.country}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {countryCount[data.country]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel3"}
                              onChange={handleChange("panel3")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("State")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownState.has(data.state)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownState.add(data.state); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2  items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={state === data.state}
                                          onChange={() =>
                                            handleStateChange(data.state)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.state}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] min-w-[25px] max-w-[9ch]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {stateCount[data.state]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel4"}
                              onChange={handleChange("panel4")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("City")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownCity.has(data.city)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownCity.add(data.city); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={city === data.city}
                                          onChange={() =>
                                            handleCityChange(data.city)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.city}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] min-w-[25px] max-w-[9ch]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {cityCount[data.city]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel5"}
                              onChange={handleChange("panel5")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("Job_Type")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownType.has(data.job_type)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownType.add(data.job_type); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={Type === data.job_type}
                                          onChange={() =>
                                            handleTypeChange(data.job_type)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.job_type}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {TypeCount[data.job_type]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel6"}
                              onChange={handleChange("panel6")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("Jobs_By_degree")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (
                                      shownCertifications.has(
                                        data.Certifications
                                      )
                                    ) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownCertifications.add(
                                      data.Certifications
                                    ); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            Certifications ===
                                            data.Certifications
                                          }
                                          onChange={() =>
                                            handleCertificationsChange(
                                              data.Certifications
                                            )
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.Certifications}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {
                                                CertificationsCount[
                                                  data.Certifications
                                                ]
                                              }{" "}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel7"}
                              onChange={handleChange("panel7")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("Functional_Area")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col min-w-full max-h-[250px] overflow-y-auto overflow-x-hidden gap-[30px]">
                                  {jobSearchData.map((data) => {
                                    if (
                                      shownFunctionalAreas.has(
                                        data.funcrional_area
                                      )
                                    ) {
                                      return null; // Skip rendering if functional area is already shown
                                    }
                                    shownFunctionalAreas.add(
                                      data.funcrional_area
                                    ); // Add functional area to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            funcrional_area ===
                                            data.funcrional_area
                                          }
                                          onChange={() =>
                                            handleFuncrional_areaChange(
                                              data.funcrional_area
                                            )
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.funcrional_area}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {
                                                funcrional_areaCount[
                                                  data.funcrional_area
                                                ]
                                              }{" "}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel8"}
                              onChange={handleChange("panel8")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("Industry")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col min-w-full max-h-[250px] overflow-y-auto overflow-x-hidden  gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownIndustries.has(data.industry)) {
                                      return null; // Skip rendering if industry is already shown
                                    }
                                    shownIndustries.add(data.industry); // Add industry to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={Industry === data.industry}
                                          onChange={() =>
                                            handleIndustryChange(data.industry)
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.industry}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {nameCount[data.industry]}
                                            </p>
                                          </h1>
                                        </div>{" "}
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion
                              expanded={expanded === "panel9"}
                              onChange={handleChange("panel9")}
                            >
                              <AccordionSummary
                                aria-controls="panel3d-content"
                                id="panel3d-header"
                              >
                                <Typography>{t("company_name")}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full   gap-[30px]">
                                  {searchData.map((data) => {
                                    if (shownCompanies.has(data.company_name)) {
                                      return null; // Skip rendering if company name is already shown
                                    }
                                    shownCompanies.add(data.company_name); // Add company name to set

                                    return (
                                      <div
                                        key={data.id}
                                        className="flex max-w-full gap-2 items-center"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={
                                            CompanyName === data.company_name
                                          }
                                          onChange={() =>
                                            handleCompanyChange(
                                              data.company_name
                                            )
                                          }
                                        />
                                        <div className="flex gap-3 justify-between w-full items-center">
                                          <h1 className="max-w-[260px] break-words">
                                            {data.company_name}
                                          </h1>
                                          <h1
                                            style={{
                                              marginLeft:
                                                i18n.language === "ku" ||
                                                i18n.language === "ar"
                                                  ? "10px"
                                                  : "0px",
                                            }}
                                            className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch]  min-w-[25px]  truncate flex justify-center items-center p-2 rounded-[5px] "
                                          >
                                            <p className="max-w-[7ch] truncate  ">
                                              {
                                                company_nameCount[
                                                  data.company_name
                                                ]
                                              }
                                            </p>
                                          </h1>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* phone searchbar */}
                    <div className="left-details-container mt-[-29px]  block md:hidden ">
                      <form
                        onSubmit={costomsSearch}
                        className="flex items-center min-w-full mx-auto"
                      >
                        <label className="sr-only">Search</label>
                        <div className="relative w-full">
                          <div className="flex  items-end">
                            <button
                              type="submit"
                              className="max-w-[40px]  justify-center   absolute inset-y-0  start-0 flex items-center "
                              // style={{
                              //   marginRight:
                              //     i18n.language === "ku" || i18n.language === "ar"
                              //       ? "0px"
                              //       : "0px",
                              //   marginLeft:
                              //     i18n.language === "ku" || i18n.language === "ar"
                              //       ? "0px"
                              //       : "10px",
                              // }}
                            >
                              <SearchIcon className="mx-2" />
                              <div className="bg-gray-300 h-[20px]  w-[1px]"></div>
                            </button>
                          </div>
                          <input
                            type="text"
                            id="simple-search"
                            className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={t("Job_Title")}
                            required
                            value={phoneTitle}
                            onChange={(e) => {
                              setPhoneTitle(e.target.value);
                            }}
                          />
                        </div>

                        <button
                          type="button"
                          onClick={toggleFilter} // Toggle visibility on click
                          className="p-[8px] ms-2 text-sm font-medium text-white bg-[#ffb923] rounded-lg hover:bg-[#ffc64a] dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          <TuneIcon
                            className=" text-white "
                            style={{ fontSize: "26px" }}
                          />
                          <span className="sr-only">Search</span>
                        </button>
                      </form>

                      {showFilter && ( // Conditional rendering of the div
                        <div className="filter-options mt-4 p-2 border rounded-lg  dark:bg-gray-800">
                          {/* Your filter options go here */}
                          <Accordion
                            expanded={expanded === "panel12"}
                            onChange={handleChange("panel12")}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{t("Job_Title")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneTitle.has(data.job_title)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneTitle.add(data.job_title); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={title === data.job_title}
                                        onChange={() =>
                                          handleTitleChange(data.job_title)
                                        }
                                      />

                                      <div className="flex main-freeLancer-container-text gap-3 justify-between w-full items-center">
                                        {data.job_title}
                                        <h1
                                          style={{
                                            marginRight:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "0px"
                                                : "10px",
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 main-freeLancer-container-text  text-gray-600  h-[25px] max-w-[9ch] truncate  flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {job_titleCount[data.job_title]}
                                          </p>{" "}
                                        </h1>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel2"}
                            onChange={handleChange("panel2")}
                          >
                            <AccordionSummary
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                            >
                              <Typography>{t("Country")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhonecountry.has(data.country)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhonecountry.add(data.country); // Add company name to set

                                  return (
                                    <div
                                      key={`country-${data.id}`}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={country === data.country}
                                        onChange={() =>
                                          handleCountryChange(data.country)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.country}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {countryCount[data.country]}
                                          </p>{" "}
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel3"}
                            onChange={handleChange("panel3")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("State")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneState.has(data.state)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneState.add(data.state); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={state === data.state}
                                        onChange={() =>
                                          handleStateChange(data.state)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.state}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {stateCount[data.state]}
                                          </p>{" "}
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel4"}
                            onChange={handleChange("panel4")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("City")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneCity.has(data.city)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneCity.add(data.city); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={city === data.city}
                                        onChange={() =>
                                          handleCityChange(data.city)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.city}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 mr-[10px]  h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {cityCount[data.city]}
                                          </p>{" "}
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel5"}
                            onChange={handleChange("panel5")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("Job_Type")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneType.has(data.job_type)) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneType.add(data.job_type); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={Type === data.job_type}
                                        onChange={() =>
                                          handleTypeChange(data.job_type)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.job_type}
                                        <h1
                                          style={{
                                            marginRight:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "0px"
                                                : "10px",
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {TypeCount[data.job_type]}
                                          </p>{" "}
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel6"}
                            onChange={handleChange("panel6")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("Jobs_By_degree")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex max-h-[250px] overflow-y-auto overflow-x-hidden flex-col min-w-full  gap-[30px]">
                                {searchData.map((data) => {
                                  if (
                                    shownPhoneCertifications.has(
                                      data.Certifications
                                    )
                                  ) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneCertifications.add(
                                    data.Certifications
                                  ); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          Certifications === data.Certifications
                                        }
                                        onChange={() =>
                                          handleCertificationsChange(
                                            data.Certifications
                                          )
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.Certifications}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 mr-[10px] text-gray-600 h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {
                                              CertificationsCount[
                                                data.Certifications
                                              ]
                                            }{" "}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel7"}
                            onChange={handleChange("panel7")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("Functional_Area")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col min-w-full max-h-[250px] overflow-y-auto overflow-x-hidden gap-[30px]">
                                {jobSearchData.map((data) => {
                                  if (
                                    shownPhoneFunctionalAreas.has(
                                      data.funcrional_area
                                    )
                                  ) {
                                    return null; // Skip rendering if functional area is already shown
                                  }
                                  shownPhoneFunctionalAreas.add(
                                    data.funcrional_area
                                  ); // Add functional area to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          funcrional_area ===
                                          data.funcrional_area
                                        }
                                        onChange={() =>
                                          handleFuncrional_areaChange(
                                            data.funcrional_area
                                          )
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.funcrional_area}
                                        <h1
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 mr-[10px] text-gray-600  h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {
                                              funcrional_areaCount[
                                                data.funcrional_area
                                              ]
                                            }{" "}
                                          </p>
                                        </h1>
                                      </div>{" "}
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel8"}
                            onChange={handleChange("panel8")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("Industry")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col min-w-full max-h-[250px] overflow-y-auto overflow-x-hidden  gap-[30px]">
                                {searchData.map((data) => {
                                  if (shownPhoneIndustries.has(data.industry)) {
                                    return null; // Skip rendering if industry is already shown
                                  }
                                  shownPhoneIndustries.add(data.industry); // Add industry to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={Industry === data.industry}
                                        onChange={() =>
                                          handleIndustryChange(data.industry)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        {data.industry}
                                        <h1
                                          style={{
                                            marginRight:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "0px"
                                                : "10px",
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600  h-[25px] max-w-[9ch] flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          {" "}
                                          <p className="max-w-[7ch] truncate  ">
                                            {nameCount[data.industry]}
                                          </p>{" "}
                                        </h1>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={expanded === "panel9"}
                            onChange={handleChange("panel9")}
                          >
                            <AccordionSummary
                              aria-controls="panel3d-content"
                              id="panel3d-header"
                            >
                              <Typography>{t("company_name")}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="flex flex-col max-h-[250px] overflow-y-auto overflow-x-hidden min-w-full   gap-[30px]">
                                {searchData.map((data) => {
                                  if (
                                    shownPhoneCompanies.has(data.company_name)
                                  ) {
                                    return null; // Skip rendering if company name is already shown
                                  }
                                  shownPhoneCompanies.add(data.company_name); // Add company name to set

                                  return (
                                    <div
                                      key={data.id}
                                      className="flex max-w-full gap-2 items-center"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          CompanyName === data.company_name
                                        }
                                        onChange={() =>
                                          handleCompanyChange(data.company_name)
                                        }
                                      />
                                      <div className="flex gap-3 main-freeLancer-container-text justify-between w-full items-center">
                                        <div className="max-w-[260px] break-words">
                                          {data.company_name}
                                        </div>
                                        <h1
                                          style={{
                                            marginRight:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "0px"
                                                : "10px",
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "10px"
                                                : "0px",
                                          }}
                                          className="bg-gray-100 text-gray-600 h-[25px] min-w-[25px] max-w-[9ch]  flex justify-center items-center p-2 rounded-[5px] "
                                        >
                                          <p className="max-w-[7ch] truncate  ">
                                            {
                                              company_nameCount[
                                                data.company_name
                                              ]
                                            }
                                          </p>{" "}
                                        </h1>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </div>

                    <div className="main-card-container flex flex-col w-[900px] gap-[50px]">
                      <div className="freeLancers_border max-w-[900px] min-w-[100%] border p-1 rounded-[10px] px-0.5 min-h-[100px]">
                        {/* {searchPerformed && search.length === 0 && (
                      <div className="search-message flex justify-center text-center items-center h-[100%] main-freeLancer-container-text">
                        {t("not_haveServices")}
                      </div>
                    )} */}
                        {/* {data.length === 0 && (
                      <div className="search-message flex justify-center text-center items-center h-[100%] main-freeLancer-container-text">
                        {t("not_haveData")}
                      </div>
                    )} */}

                        {data.length === 0 && (
                          <div className="search-message flex justify-center text-center items-center h-[100%] main-freeLancer-container-text">
                            {t("not_haveData")}
                          </div>
                        )}
                        <div className="freelancer-card-container">
                          {data
                            ? data.map((item, index) => (
                                <Link
                                  to={
                                    token
                                      ? `/jobs/job-detail/${item.id}`
                                      : "/login"
                                  }
                                  key={index}
                                >
                                  <Card
                                    sx={{
                                      minWidth: "100%",
                                      boxShadow: "none",
                                      position: "relative",
                                    }}
                                    className="freelancer-card "
                                  >
                                    <CardHeader
                                      avatar={
                                        <img
                                          src={item.image || brand_image}
                                          className="rounded-full card-avatar p-0.5 border-red-900"
                                          alt="Avatar"
                                          title="Avatar"
                                          style={{
                                            marginLeft:
                                              i18n.language === "ku" ||
                                              i18n.language === "ar"
                                                ? "20px"
                                                : "0px",
                                          }}
                                        />
                                      }
                                      title={
                                        <h1
                                          className="freelancer-title"
                                          style={{
                                            fontFamily: '"Poppins", sans-serif',
                                          }}
                                        >
                                          {item.job_title}
                                        </h1>
                                      }
                                      subheader={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontFamily: '"Poppins", sans-serif',
                                            fontStyle: "normal",
                                          }}
                                          className="main-freeLancer-container-text pt-1 break-words"
                                        >
                                          <span>{item.company_name}</span>
                                          <span className="ml-[10px]">
                                            <LocationOnIcon
                                              style={{
                                                color: "gray",
                                                fontSize: "18px",
                                              }}
                                            />
                                            <span>
                                              {item.city}, {item.country}
                                            </span>
                                          </span>
                                        </Typography>
                                      }
                                    />
                                    <CardContent>
                                      <div className="flex items-center gap-2">
                                        <div className="flex items-center gap-2 main-freeLancer-container-text">
                                          <div className="bg-gray-200 h-[10px] w-[10px] rounded-full"></div>
                                          <p> {item.funcrional_area}</p>
                                        </div>
                                        <div className="vertical-hr border-l h-[20px]"></div>
                                        <div className="main-freeLancer-container-text">
                                          {item.job_type}
                                        </div>
                                      </div>
                                      <span className="main-freeLancer-container-text break-words">
                                        {item.description}{" "}
                                        <span className="underline main-freeLancer-container-text break-words hover:text-[#ffb923]">
                                          {t("job_details")}
                                        </span>
                                        ...
                                      </span>
                                      {/* Display time ago here */}
                                      <div className="mt-2 flex gap-2 items-center mb-[-8px] text-green-500 main-freeLancer-container-text">
                                        <AccessTimeOutlinedIcon
                                          style={{
                                            fontSize: "16px",
                                          }}
                                          className="text-[14px] sm:mb-[2px]"
                                        />{" "}
                                        <p className="sm:text-[14px] text-[12px]">
                                          {timeAgo(item.created_at)}
                                        </p>
                                      </div>
                                    </CardContent>

                                    {index !== data.length - 1 && (
                                      <hr className="mx-[20px]" />
                                    )}
                                  </Card>
                                </Link>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className="freelancer-pagination mb-[80px]  flex justify-center   ">
                        {total_jobs > 10 ? (
                          <div>
                            <JobPagination />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="max-w-full">
                  <HomeFooter />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Main_jobs;
