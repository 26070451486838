import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  name_search_value,
  user_delete,
  user_get,
  user_search,
  user_update,
} from "../../../redux/action/auth-action";
import { useTranslation } from "react-i18next";
import User_Pagination from "./Admin-users-pagination";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../../../sub component/show design data/loading";
import Search_loading from "../../main-pages/search loading/search-loading";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { user_action } from "../../../redux/action/cv-action";
import Admin_nav from "../../../app bar/admin-nav";
import Error404 from "../../error page/error404";

const Admin_users = () => {
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [plan_type, setPlanType] = useState("");
  const [subscription_end_date, setSubscription_end_date] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const user_data = useSelector((state) => state.auth.users_get);
  const user_search_data = useSelector((state) => state.auth.user_search);
  const user_page = useSelector((state) => state.auth.user_page);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(user_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setData(user_data);
  }, [user_data]);

  useEffect(() => {
    setData(user_search_data);
  }, [user_search_data]);

  useEffect(() => {
    setData(user_page);
  }, [user_page]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchLoading(true);
    await dispatch(user_search(search));
    dispatch(name_search_value(search));
    setSearchLoading(false);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setName(data[index].name);
    setEmail(data[index].email);
    setRole(data[index].role);
    setPlanType(data[index].plan_type);
    setSubscription_end_date(data[index].subscription_end_date);
  };

  const handleSaveClick = async (index) => {
    const { id } = data[index];
    await dispatch(
      user_update(id, name, email, role, plan_type, subscription_end_date, t)
    );

    const newData = [...data];
    newData[index] = {
      ...newData[index],
      name,
      email,
      role,
      plan_type,
      subscription_end_date,
    };
    setData(newData);
    setEditingIndex(null);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(user_delete(id));
          dispatch(user_search(search));

          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
      }
    });
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
      {loading ? null : <Admin_nav />}
      <div className="flex flex-col flex-1 overflow-y-auto  bg-gray-100">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div className="pt-[100px] px-[2%]">
              {searchLoading ? (
                <Search_loading />
              ) : (
                <div>
                  <ToastContainer />
                  <div className="text-gray-900 mt-4 flex flex-col justify-center">
                    <form
                      onSubmit={handleSearch}
                      className="flex items-center w-full max-w-md mx-auto"
                    >
                      <input
                        type="text"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        placeholder="Search  name..."
                        required
                      />
                      <button
                        type="submit"
                        className="ml-2 p-2.5 text-sm font-medium text-white bg-[#ffb923] rounded-lg border hover:bg-[#ffc64a] focus:ring-4"
                      >
                        <svg
                          className="w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </button>
                    </form>

                    <div className="w-full overflow-x-auto mb-[50px]">
                      <div className="pb-3 flex justify-center"></div>
                      <table className="min-w-full bg-white border rounded-lg shadow overflow-hidden">
                        <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <tr>
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Email</th>
                            <th className="py-3 px-6 text-left">Role</th>
                            <th className="py-3 px-6 text-left">Plan Type</th>
                            <th className="py-3 px-6 text-left">
                              Subscription End Date
                            </th>
                            <th className="py-3 px-6 text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                          {data.map((user, index) => (
                            <tr
                              key={index}
                              className="border-b hover:bg-gray-100"
                            >
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                {editingIndex === index ? (
                                  <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                  />
                                ) : (
                                  <span className="font-medium">
                                    {user.name}
                                  </span>
                                )}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {editingIndex === index ? (
                                  <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                  />
                                ) : (
                                  <span>{user.email}</span>
                                )}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {editingIndex === index ? (
                                  <select
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    className="bg-gray-50 border min-w-[100px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                  >
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                  </select>
                                ) : (
                                  <span>{user.role}</span>
                                )}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {editingIndex === index ? (
                                  <input
                                    type="text"
                                    value={plan_type}
                                    onChange={(e) =>
                                      setPlanType(e.target.value)
                                    }
                                    className="bg-gray-50 border min-w-[100px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                  />
                                ) : (
                                  <span>{user.plan_type}</span>
                                )}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {editingIndex === index ? (
                                  <input
                                    type="date"
                                    value={subscription_end_date}
                                    onChange={(e) =>
                                      setSubscription_end_date(e.target.value)
                                    }
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                  />
                                ) : (
                                  <span>{user.subscription_end_date}</span>
                                )}
                              </td>
                              <td className="py-3 px-6 text-center whitespace-nowrap">
                                {editingIndex === index ? (
                                  <div>
                                    <button
                                      onClick={() => handleSaveClick(index)}
                                      className="text-white bg-green-500 hover:bg-green-600 rounded-lg px-4 py-2.5 mx-1"
                                    >
                                      Save
                                    </button>
                                    <button
                                      onClick={() => setEditingIndex(null)}
                                      className="text-white bg-red-500 hover:bg-red-600 rounded-lg px-4 py-2.5"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : (
                                  <div className="flex justify-center">
                                    <button
                                      onClick={() => handleEditClick(index)}
                                      className="text-white bg-blue-500 hover:bg-blue-600 rounded-lg px-4 py-2.5 mx-1"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => handleDelete(user.id)}
                                      className="text-white bg-red-500 hover:bg-red-600 rounded-lg px-4 py-2.5"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                     
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
   

{(data.length > 0 || user_search_data.length > 0) && (
                           <div className="flex justify-center w-full mb-[50px]">
                           <User_Pagination/>
                           
                           </div>
                      )}
      </div>
    </div>
  );
};

export default Admin_users;
