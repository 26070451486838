import React, { useEffect, useState } from "react";

import Use_skills_hook from "../../../hooks/cv-inputs-hook/skills-hook";
import { skills } from "../../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Skills = () => {
   const dispatch = useDispatch();
 // Set ss to qa.title if qa is defined, otherwise set it to an empty string
 const [inputCount, setInputCount] = useState(1); // Initialize inputCount to 0
  const [skillsData, setSkillsData] = useState([]); // Initialize skillsData with empty array

  // cv1_details
const cv1_details = useSelector((state) => state.cv.cv1_details);
const cv1_skill = cv1_details ? cv1_details.skills : '';

useEffect(() => {
  if (cv1_skill) {
    setInputCount(cv1_skill.length); 
    setSkillsData(cv1_skill); 
  }
}, [cv1_skill]);

// cv2_details
const cv2_details = useSelector((state) => state.cv.cv2_details);
const cv2_skill = cv2_details ? cv2_details.skills : '';

useEffect(() => {
  if (cv2_skill) {
    setInputCount(cv2_skill.length); 
    setSkillsData(cv2_skill); 
  }
}, [cv2_skill]);

// cv3_details
const cv3_details = useSelector((state) => state.cv.cv3_details);
const cv3_skill = cv3_details ? cv3_details.skills : '';

useEffect(() => {
  if (cv3_skill) {
    setInputCount(cv3_skill.length); 
    setSkillsData(cv3_skill); 
  }
}, [cv3_skill]);

// cv4_details
const cv4_details = useSelector((state) => state.cv.cv4_details);
const cv4_skill = cv4_details ? cv4_details.skills : '';

useEffect(() => {
  if (cv4_skill) {
    setInputCount(cv4_skill.length); 
    setSkillsData(cv4_skill); 
  }
}, [cv4_skill]);

// cv5_details
const cv5_details = useSelector((state) => state.cv.cv5_details);
const cv5_skill = cv5_details ? cv5_details.skills : '';

useEffect(() => {
  if (cv5_skill) {
    setInputCount(cv5_skill.length); 
    setSkillsData(cv5_skill); 
  }
}, [cv5_skill]);

// cv6_details
const cv6_details = useSelector((state) => state.cv.cv6_details);
const cv6_skill = cv6_details ? cv6_details.skills : '';

useEffect(() => {
  if (cv6_skill) {
    setInputCount(cv6_skill.length); 
    setSkillsData(cv6_skill); 
  }
}, [cv6_skill]);

// cv7_details
const cv7_details = useSelector((state) => state.cv.cv7_details);
const cv7_skill = cv7_details ? cv7_details.skills : '';

useEffect(() => {
  if (cv7_skill) {
    setInputCount(cv7_skill.length); 
    setSkillsData(cv7_skill); 
  }
}, [cv7_skill]);

// cv8_details
const cv8_details = useSelector((state) => state.cv.cv8_details);
const cv8_skill = cv8_details ? cv8_details.skills : '';

useEffect(() => {
  if (cv8_skill) {
    setInputCount(cv8_skill.length); 
    setSkillsData(cv8_skill); 
  }
}, [cv8_skill]);

// cv9_details
const cv9_details = useSelector((state) => state.cv.cv9_details);
const cv9_skill = cv9_details ? cv9_details.skills : '';

useEffect(() => {
  if (cv9_skill) {
    setInputCount(cv9_skill.length); 
    setSkillsData(cv9_skill); 
  }
}, [cv9_skill]);

// cv10_details
const cv10_details = useSelector((state) => state.cv.cv10_details);
const cv10_skill = cv10_details ? cv10_details.skills : '';

useEffect(() => {
  if (cv10_skill) {
    setInputCount(cv10_skill.length); 
    setSkillsData(cv10_skill); 
  }
}, [cv10_skill]);

// cv11_details
const cv11_details = useSelector((state) => state.cv.cv11_details);
const cv11_skill = cv11_details ? cv11_details.skills : '';

useEffect(() => {
  if (cv11_skill) {
    setInputCount(cv11_skill.length); 
    setSkillsData(cv11_skill); 
  }
}, [cv11_skill]);

// cv12_details
const cv12_details = useSelector((state) => state.cv.cv12_details);
const cv12_skill = cv12_details ? cv12_details.skills : '';

useEffect(() => {
  if (cv12_skill) {
    setInputCount(cv12_skill.length); 
    setSkillsData(cv12_skill); 
  }
}, [cv12_skill]);

// cv13_details
const cv13_details = useSelector((state) => state.cv.cv13_details);
const cv13_skill = cv13_details ? cv13_details.skills : '';

useEffect(() => {
  if (cv13_skill) {
    setInputCount(cv13_skill.length); 
    setSkillsData(cv13_skill); 
  }
}, [cv13_skill]);

// cv14_details
const cv14_details = useSelector((state) => state.cv.cv14_details);
const cv14_skill = cv14_details ? cv14_details.skills : '';

useEffect(() => {
  if (cv14_skill) {
    setInputCount(cv14_skill.length); 
    setSkillsData(cv14_skill); 
  }
}, [cv14_skill]);

// cv15_details
const cv15_details = useSelector((state) => state.cv.cv15_details);
const cv15_skill = cv15_details ? cv15_details.skills : '';

useEffect(() => {
  if (cv15_skill) {
    setInputCount(cv15_skill.length); 
    setSkillsData(cv15_skill); 
  }
}, [cv15_skill]);

// cv16_details
const cv16_details = useSelector((state) => state.cv.cv16_details);
const cv16_skill = cv16_details ? cv16_details.skills : '';

useEffect(() => {
  if (cv16_skill) {
    setInputCount(cv16_skill.length); 
    setSkillsData(cv16_skill); 
  }
}, [cv16_skill]);

// cv17_details
const cv17_details = useSelector((state) => state.cv.cv17_details);
const cv17_skill = cv17_details ? cv17_details.skills : '';

useEffect(() => {
  if (cv17_skill) {
    setInputCount(cv17_skill.length); 
    setSkillsData(cv17_skill); 
  }
}, [cv17_skill]);

// cv18_details
const cv18_details = useSelector((state) => state.cv.cv18_details);
const cv18_skill = cv18_details ? cv18_details.skills : '';

useEffect(() => {
  if (cv18_skill) {
    setInputCount(cv18_skill.length); 
    setSkillsData(cv18_skill); 
  }
}, [cv18_skill]);

// cv19_details
const cv19_details = useSelector((state) => state.cv.cv19_details);
const cv19_skill = cv19_details ? cv19_details.skills : '';

useEffect(() => {
  if (cv19_skill) {
    setInputCount(cv19_skill.length); 
    setSkillsData(cv19_skill); 
  }
}, [cv19_skill]);

// cv20_details
const cv20_details = useSelector((state) => state.cv.cv20_details);
const cv20_skill = cv20_details ? cv20_details.skills : '';

useEffect(() => {
  if (cv20_skill) {
    setInputCount(cv20_skill.length); 
    setSkillsData(cv20_skill); 
  }
}, [cv20_skill]);

  useEffect(() => { 
    dispatch(skills(skillsData)); 
  }, [skillsData]);
  
  const handleSkillsChange = (index, e) => {
    const newSkills = [...skillsData];
    newSkills[index] = e.target.value;
    setSkillsData(newSkills);
  };

  const addInputField = () => {
    setInputCount(inputCount + 1); // Increment inputCount
  };

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();

  return (
    

<Accordion 
className='py-[25px]'
style={{ fontFamily: 'Poppins, sans-serif' }}
expanded={expanded === "panel11"}
onChange={handleChange("panel11")}
>
<AccordionSummary
  aria-controls="panel1d-content"
  id="panel1d-header"
  className='text-[28px]  cv-input-header font-[500] text-gray-700'
>
             
{t("Skills")}                  </AccordionSummary>
<AccordionDetails        style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}    className="p-4 cv-input-content">
{[...Array(inputCount)].map((_, index) => (
            <div key={index} className="fixed-height-input flex-1">
              <label
                htmlFor={`inputname${index}`}
                className="block text-gray-800 font-semibold text-sm my mt-[15px]"
              >
                {t("Skills")}  : {index + 1}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name={`inputname${index}`}
                  className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => handleSkillsChange(index, e)}
                  value={skillsData[index] || ''} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                />
              </div>
            </div>
          ))}
       
     <button onClick={addInputField} className="add-icon min-w-[180px] mt-6 rounded">
     <AddIcon/>   {t("Add_skills")}  
     </button>

</AccordionDetails>
</Accordion>
  );
};

export default Skills;
