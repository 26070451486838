import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { order_page, user_page } from '../../../redux/action/auth-action';


const Order_Pagination = () => {
  const dispatch = useDispatch();
  const totalPage = useSelector(state =>
    state.auth.searchPerformed
      ? state.auth.total_pages_search
      :    state.auth.order_total_pages

  ); 

    const user_name__value = useSelector((state) => state.auth.user_name__value)||"";
    const email__value = useSelector((state) => state.auth.email__value)||"";
    const Plan_Type_search_value = useSelector((state) => state.auth.Plan_Type_search_value)||"";
    const status_search_value = useSelector((state) => state.auth.status_search_value)||"";
    const created_at_value = useSelector((state) => state.auth.created_at_value)||"";



  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {

    dispatch(order_page(page,user_name__value,email__value,Plan_Type_search_value,status_search_value,created_at_value));
    setCurrentPage(page);
  };
  return (
    <Pagination
      variant="outlined"
      count={totalPage}
      page={currentPage}
      onChange={handlePageChange}
      style={{ color: '#ffb923' }}
    />
  );
};

export default Order_Pagination;
