import {
  COURSE,
  LANGUAGES,
  PROFILE,
  REFERENCES,
  SKILLS,
  WORKEXPERIENCE,
} from "../action/action";
import { CONTACT } from "../action/contact-action";

const enitialState = {
  phone: [],
  email: [],
  address: [],
  skills: [],
  //language
  languages: [],
  range: [],
  //course
  course_title: [],
  course_year: [],
  course_description: [],
  //experience
  experience_title: [],
  experience_year: [],
  experience_description: [],
  //profile
  profile_name: [],
  profile_description: [],
  profile_image: [],
  //references
  referenses_name:[],
referenses_organization:[],
referenses_city:[],
referenses_phone:[],
referenses_email:[],
};
export const mainReducer = (state = enitialState, action) => {
  switch (action.type) {
    case CONTACT:
      return {
        ...state,
        phone: action.phone,
        email: action.email,
        address: action.address,
      };
      //skills
    case SKILLS:
      return {
        ...state,
        skills: action.skills,
      };
    // languages
    case LANGUAGES:
      return {
        ...state,
        languages: action.languages,
        range: action.range,
      };
    //  experience
    case WORKEXPERIENCE:
      return {
        ...state,
        experience_title: action.title,
        experience_year: action.year,
        experience_description: action.description,
      };
    //  profile
    case PROFILE:
      return {
        ...state,
        profile_description: action.description,
        profile_image: action.image,
        profile_name: action.name,
      };
      //course
    case COURSE:
      return {
        ...state,
        course_title: action.title,
        course_year: action.year,
        course_description: action.description,
      };
      //referenses
    case REFERENCES:
      return {
        ...state,
        referenses_name: action.name,
        referenses_organization: action.organization,
        referenses_city: action.city,
        referenses_phone: action.phone,
        referenses_email: action.email,
      };
    default:
      return state;
  }
};
