import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage";
import Navbar from "./app bar/navbar";
import What_isCV from "./pages/quations/whatIs-CV";
import Incude from "./pages/quations/incude";
import Purpose from "./pages/quations/purpose";
import HowTo_write from "./pages/quations/howTo-write";
import Format_structure from "./pages/quations/format-structure";
import Cv_type from "./pages/quations/cv-type";
import Cv1 from "./pages/cv-create-designs/cv1";
import Experience from "./pages/cv articles/experience";
import Title from "./pages/cv articles/title";
import Long_cv from "./pages/cv articles/long-cv";
import Gender from "./pages/cv articles/gender";
import Tips_skills from "./pages/cv articles/tips-skills";
import Tips from "./pages/cv articles/tips";

import Cv2 from "./pages/cv-create-designs/cv2";
import Cv3 from "./pages/cv-create-designs/cv3";
import Cv4 from "./pages/cv-create-designs/cv4";
import Cv5 from "./pages/cv-create-designs/cv5";
import Cv6 from "./pages/cv-create-designs/cv6";
import Cv7 from "./pages/cv-create-designs/cv7";
import Cv8 from "./pages/cv-create-designs/cv8";
import Cv9 from "./pages/cv-create-designs/cv9";
import Cv10 from "./pages/cv-create-designs/cv10";
import Cv11 from "./pages/cv-create-designs/cv11";
import Cv12 from "./pages/cv-create-designs/cv12";
import Cv13 from "./pages/cv-create-designs/cv13";
import Cv14 from "./pages/cv-create-designs/cv14";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Login from "./auth/login";
import History_page from "./pages/history/history";
import Cv1_update from "./pages/cv update designs/cv1";
import Cv2_update from "./pages/cv update designs/cv2";
import Cv3_update from "./pages/cv update designs/cv3";
import Cv4_update from "./pages/cv update designs/cv4";
import Cv5_update from "./pages/cv update designs/cv5";
import Cv6_update from "./pages/cv update designs/cv6";
import Cv7_update from "./pages/cv update designs/cv7";
import Cv8_update from "./pages/cv update designs/cv8";
import Cv9_update from "./pages/cv update designs/cv9";
import Cv10_update from "./pages/cv update designs/cv10";
import Cv11_update from "./pages/cv update designs/cv11";
import Cv12_update from "./pages/cv update designs/cv12";
import Cv13_update from "./pages/cv update designs/cv13";
import Cv14_update from "./pages/cv update designs/cv14";
import Qrcode from "./sub component/show design data/qrcode";
import Qrcode_generater from "./sub component/show design data/qrcode";
import Main_jobs from "./pages/main-pages/job/main-jobs";
import Main_freeLancers from "./pages/main-pages/freelancer/main-freeLancers";
import Main_freeLancers_details from "./pages/main-pages/freelancer/freelancer-details";
import Freelancer_insert from "./pages/main-pages/freelancer/freelancer-insert";
import Contact_us from "./pages/contact-us";
import Freelancer_update from "./pages/main-pages/freelancer/freelancer-update";
import Error404 from "./pages/error page/error404";
import Job_details from "./pages/main-pages/job/job-details";
import Main_services from "./pages/main-pages/services/main-services";
import Services_insert from "./pages/main-pages/services/services-insert";
import Cleaning_data from "./pages/main-pages/services/types/cleaning/cleaning";
import Services_update from "./pages/main-pages/services/services-update";
import Services_details from "./pages/main-pages/services/services-details";
import Vehicle_Maintenance from "./components/services/Vehicle-Maintenance";
import Vehicle_Maintenance_data from "./pages/main-pages/services/types/Vehicle Maintenance/Vehicle-Maintenance";
import Electrical_repaier from "./pages/main-pages/services/types/Electrical Repairs/Electrical-Repairs";
import Home_appliance_repair from "./pages/main-pages/services/types/Home appliance/Home-appliance-data";
import Construction_of_houses from "./components/services/Construction_of_houses";
import ContructionOf_houses from "./pages/main-pages/services/types/construction of houses/constructionOf-houses";
import Car_wash from "./pages/main-pages/services/types/Car Wash/Car-Wash";
import Carpet from "./pages/main-pages/services/types/carpet/carpet";
import Post_Construction_Cleaning from "./pages/main-pages/services/types/PostConstruction Cleaning/PostConstruction-Cleaning";
import Skincare_Treatments from "./pages/main-pages/services/types/Skincare Treatments/Skincare-Treatments";
import HairDressing from "./pages/main-pages/services/types/Hairdressing/Hairdressing";
import Photography from "./pages/main-pages/services/types/photography/photography";
import Battery from "./pages/main-pages/services/types/Battery/battery";
import Tire_replacement_data from "./pages/main-pages/services/types/Tire replacement/tire-replacement";
import Garden from "./pages/main-pages/services/types/garden-maintenance/garden-maintenance";
import Makeup_data from "./pages/main-pages/services/types/makeup/makeup";
import Nail_data from "./pages/main-pages/services/types/Nail/Nail";
import Veternairy_data from "./pages/main-pages/services/types/veternary/Veternairy";
import Legal_data from "./pages/main-pages/services/types/Legal/legal";
import FreightForwardingData from "./pages/main-pages/services/types/Freight-Forwarding/freight-forwarding";
import Garden_data from "./pages/main-pages/services/types/garden-maintenance/garden-maintenance";
import ElectronicData from "./pages/main-pages/services/types/Electronic/electronic";
import Travel_tourism_data from "./pages/main-pages/services/types/travel-tourism/travel-tourism";
import Lectrurers_insert from "./pages/main-pages/Lecturers/lecturers-insert";
import Primary_data from "./pages/main-pages/Lecturers/Primary/primary";
import Middle_data from "./pages/main-pages/Lecturers/middle/middle";
import High_data from "./pages/main-pages/Lecturers/high/high";
import Lecturers_details from "./pages/main-pages/Lecturers/lecturers-details";
import Lectrurers_update from "./pages/main-pages/Lecturers/lecturers-update";
import Lecturers_profile from "./pages/main-pages/Lecturers/lecturer-profile";
import Register from "./auth/register";
import Verify_email from "./auth/verify-email";
import Forgot_password from "./auth/forgot-password";
import Reset_password_code from "./auth/reset-password-code";
import Reset_password from "./auth/reset-password";
import Services_profile from "./pages/main-pages/services/services-profile";
import Freelance_Profile from "./pages/main-pages/freelancer/freelance-profile";
import HomeFooter from "./sub component/home/footer";
import Admin_Dashboard from "./pages/admin/main-page";
import Jobs from "./sub component/home/jobs";
import Admin_jobs_update from "./pages/admin/jobs/admin-jobs-update";
import Admin_jobs from "./pages/admin/jobs/Admin-jobs";
import Admin_jobs_insert from "./pages/admin/jobs/Admin-jobs-insert";
import Admin_freeLancers from "./pages/admin/Freelance/Admin-freelance";
import Admin_freeLancers_update from "./pages/admin/Freelance/Admin-freelance-update";
import Admin_freelancer_insert from "./pages/admin/Freelance/Admin-freelance-insert";
import Admin_services from "./pages/admin/services/Admin-services";
import Admin_services_update from "./pages/admin/services/Admin-services-update";
import Admin_services_insert from "./pages/admin/services/Admin-services-insert";
import Admin_Primary from "./pages/admin/Lecturers/Admin-primarySchool-lecturer";
import Admin_Lectrurers_insert from "./pages/admin/Lecturers/Admin-lecturer-insert";
import Admin_Lectrurers_update from "./pages/admin/Lecturers/Admin-lecturer-update";
import Admin_Middle from "./pages/admin/Lecturers/Admin-middleSchool-lecturer";
import Admin_High from "./pages/admin/Lecturers/Admin-highSchool-lecturer";
import Admin_users from "./pages/admin/users/Admin-users";
import GoogleCallback from "./googleCallback";
import Main_Lecturers from "./pages/main-pages/Lecturers/main-lecturers";
import Cv_templates from "./pages/Cv-templates";
import Free_plan from "./pages/Payment Plans/free-plan";
import Monthly_plan from "./pages/Payment Plans/monthly-plan";
import Yearly_plan from "./pages/Payment Plans/yearly-plan";
import AboutUs from "./pages/Contact/about-us";
import RefundForm from "./pages/admin/payment/refound";
import Order from "./pages/admin/payment/Order";
// import logger from '../src/utils/logger'
function App() {
  const { i18n } = useTranslation();
  // if (process.env.NODE_ENV === 'production') {
  //   console.error = () => {};
  //   console.warn = () => {};
  //   console.log = () => {};
  //   console.info = () => {};
  // }

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <>
      {" "}
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/*" element={<Error404 />} />
          <Route path="/About-us" element={<AboutUs />} /> 
          
          {/* seo optimized */}
          <Route path="/Contact-us" element={<Contact_us />} />

          <Route path="/cv/whatIs" element={<What_isCV />} />
          {/* <Route path="/cv/include" element={<Incude />} /> */}
          <Route path="/cv/purpose" element={<Purpose />} />
          <Route path="/cv/howToWrite" element={<HowTo_write />} />
          <Route path="/cv/type" element={<Cv_type />} />
          {/*cv designs*/}
          <Route path="/resumes=1" element={<Cv1 />} /> 
          <Route path="/resumes=2" element={<Cv2 />} />
          <Route path="/resumes=3" element={<Cv3 />} />
          <Route path="resumes=4" element={<Cv4 />} />
          <Route path="/resumes=5" element={<Cv5 />} />
          <Route path="/resumes=6" element={<Cv6 />} />
          <Route path="/resumes=7" element={<Cv7 />} />
          <Route path="/resumes=8" element={<Cv8 />} />
          <Route path="/resumes=9" element={<Cv9 />} />
          <Route path="/resumes=10" element={<Cv10 />} />
          <Route path="/resumes=11" element={<Cv11 />} />
          <Route path="/resumes=12" element={<Cv12 />} />
          <Route path="/resumes=13" element={<Cv13 />} />

          {/* all cv  email verify tested */}
          {/* all cv  login required  tested */}

          {/* <Route path="/auth/google" element={<GoogleCallback />} /> */}
          {/*cv update*/}
          {/* <Route path="/resumes=1/edit/:id" element={<Cv1_update />} />
          <Route path="/resumes=2/edit/:id" element={<Cv2_update />} />
          <Route path="/resumes=3/edit/:id" element={<Cv3_update />} />
          <Route path="/resumes=4/edit/:id" element={<Cv4_update />} />
          <Route path="/resumes=5/edit/:id" element={<Cv5_update />} />
          <Route path="/resumes=6/edit/:id" element={<Cv6_update />} />
          <Route path="/resumes=7/edit/:id" element={<Cv7_update />} />
          <Route path="/resumes=8/edit/:id" element={<Cv8_update />} />
          <Route path="/resumes=9/edit/:id" element={<Cv9_update />} />
          <Route path="/resumes=10/edit/:id" element={<Cv10_update />} />
          <Route path="/resumes=11/edit/:id" element={<Cv11_update />} />
          <Route path="/resumes=12/edit/:id" element={<Cv12_update />} />
          <Route path="/resumes=13/edit/:id" element={<Cv13_update />} />
          <Route path="/resumes=14/edit/:id" element={<Cv14_update />} /> */}
          {/*freelance*/}
          <Route path="/freelance" element={<Main_freeLancers />} />
          <Route
            path="/freelance/freelance-detail/:id"
            element={<Main_freeLancers_details />}
          />
          <Route path="/freelance/add" element={<Freelancer_insert />} />
          <Route path="/freelance/update" element={<Freelancer_update />} />
          <Route path="/freelance/Profile" element={<Freelance_Profile />} />
          {/* all freelance  email verify tested */}
          {/* all freelance  login required  tested */}
          {/* all tested in prod*/}

          {/* freelance tested */}


          {/*jobs*/}
          <Route path="/jobs" element={<Main_jobs />} />
          <Route path="/jobs/job-detail/:id" element={<Job_details />} />
                    {/* jobs tested */}
          {/* all jobs  email verify tested */}
          {/* all freelance  login required  tested */}
          {/* all tested in prod*/}

          {/*services*/}
          <Route path="/services" element={<Main_services />} />{" "}
          {/*seo optimized*/}
          <Route path="/services/add" element={<Services_insert />} />{" "}
          {/*seo optimized*/}
          <Route path="/services/update" element={<Services_update />} />{" "}
          {/*seo optimized*/}
          <Route
            path="/services/services-detail/:id"
            element={<Services_details />}
          />{" "}
          {/*seo optimized*/}
          <Route path="/services/Profile" element={<Services_profile />} />

          {/* all services  email verify tested */}

          {/* all services  login required  tested */}

          <Route
            path="/services/Travel-tourism"
            element={<Travel_tourism_data />}
          />
          {/*seo optimized*/}
          {/*services  all types */}
          <Route
            path="/services/Cleaning-the-house"
            element={<Cleaning_data />}
          />{" "} 

          {/*seo optimized*/}
          <Route
            path="/services/Vehicle-Maintenance"
            element={<Vehicle_Maintenance_data />}
          />{" "}



          {/*seo optimized*/}
          <Route
            path="/services/Electrical-repaier"
            element={<Electrical_repaier />}
          />


          {/*seo optimized*/}
          <Route
            path="/services/Home-Appliance-Repairs"
            element={<Home_appliance_repair />}
          />



          {/*seo optimized*/}
          <Route
            path="/services/construction-of-houses"
            element={<ContructionOf_houses />}
          />




          {/*seo optimized*/}
          <Route path="/services/car-wash" element={<Car_wash />} />


          {/*seo optimized*/}
          <Route path="/services/Carpet-Cleaning" element={<Carpet />} />
          {/*seo optimized*/}

          <Route
            path="/services/Post-Construction-Cleaning"
            element={<Post_Construction_Cleaning />}
          />


          {/*seo optimized*/}
          <Route
            path="/services/Skincare-Treatments"
            element={<Skincare_Treatments />}
          />


          {/*seo optimized*/}
          <Route path="/services/Hairdressing" element={<HairDressing />} />
          {/*seo optimized*/}




          <Route
            path="/services/Photography-Videography"
            element={<Photography />}
          />


          {/*seo optimized*/}
          <Route
            path="/services/Tire-Replacement"
            element={<Tire_replacement_data />}
          />


          {/*seo optimized*/}
          <Route path="/services/Battery-Services" element={<Battery />} />
          {/*seo optimized*/}


          <Route path="/services/Garden-Maintenance" element={<Garden />} />
          {/*seo optimized*/}

          <Route path="/services/Makeup" element={<Makeup_data />} />


          <Route path="/services/Nail" element={<Nail_data />} />


          <Route path="/services/Veterinary" element={<Veternairy_data />} />


          <Route path="/services/Legal-Consultation" element={<Legal_data />} />
          <Route
            path="/services/Freight-Forwarding"
            element={<FreightForwardingData />}
          />
          {/* <Route
            path="/services/Garden-Maintenance"
            element={<Garden_data />}
          /> */}
          <Route
            path="/services/Electronic-Services"
            element={<ElectronicData />}
          />
          {/* <Route
            path="/services/Travel-Tourism"
            element={<Travel_tourism_data />}
          /> */}
          {/*lecturers */}

          <Route path="/teachers/add" element={<Lectrurers_insert />} />
          <Route
            path="/teachers/Primary-School-Teachers"
            element={<Primary_data />}
          />
          <Route
            path="/teachers/Middle-School-Teachers"
            element={<Middle_data />}
          />
          <Route
            path="/teachers/High-School-Teachers"
            element={<High_data />}
          />
          <Route
            path="/teachers/teacher-detail/:id"
            element={<Lecturers_details />}
          />
          <Route path="/teachers" element={<Main_Lecturers />} />
          <Route path="/teachers/update" element={<Lectrurers_update />} />
          <Route path="/teachers/Profile" element={<Lecturers_profile />} />
          {/* all teachers  email verify tested */}
          {/* all services  login required  tested */}


          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Verify-email" element={<Verify_email />} />
          <Route path="/Forgot-Password" element={<Forgot_password />} />
          <Route path="/Reset-Password/:token" element={<Reset_password />} />
          {/* <Route path="/Addmin-Dashboard" element={<Admin_Dashboard />} /> */}
          {/*admin panel */}
          {/*jobs */}
          <Route
            path="/Admin-panel/jobs/update/:id"
            element={<Admin_jobs_update />}
          />
          <Route
            path="/Admin-panel/jobs/insert"
            element={<Admin_jobs_insert />}
          />
          <Route path="/Admin-panel/jobs" element={<Admin_jobs />} />
          {/*freelance */}
          <Route
            path="/Admin-panel/freelance"
            element={<Admin_freeLancers />}
          />
          <Route
            path="/Admin-panel/freelance/update/:id"
            element={<Admin_freeLancers_update />}
          />
          <Route
            path="/Admin-panel/freelance/insert"
            element={<Admin_freelancer_insert />}
          />
          {/*services */}
          <Route path="/Admin-panel/services" element={<Admin_services />} />
          <Route
            path="/Admin-panel/services/update/:id"
            element={<Admin_services_update />}
          />
          <Route
            path="/Admin-panel/services/insert"
            element={<Admin_services_insert />}
          />
          {/*lecturers */}
          <Route
            path="/Admin-panel/Lecturer/insert"
            element={<Admin_Lectrurers_insert />}
          />
          <Route
            path="/Admin-panel/Lecturer/update/:id"
            element={<Admin_Lectrurers_update />}
          />
          <Route
            path="/Admin-panel/Primary-Lecturer"
            element={<Admin_Primary />}
          />
          <Route
            path="/Admin-panel/Middle-Lecturer"
            element={<Admin_Middle />}
          />
          
          <Route path="/Admin-panel/High-Lecturer" element={<Admin_High />} />
          {/*users */}
          <Route path="/Admin-panel/users" element={<Admin_users />} />
          <Route path="/Admin-panel/Refound" element={<RefundForm />} />
          <Route path="/Admin-panel/Order" element={<Order />} />
          {/* <Route path="/Admin-panel/Lecturer/update/:id" element={<Admin_Lectrurers_update />} />           */}
          <Route path="/CV-templates" element={<Cv_templates />} />
          {/* <Route path="/qr" element={<Qrcode_generater />} /> */}
          {/* payment plans */}
          <Route path="/Free-plan" element={<Free_plan />} />
          <Route path="/Monthly-plan" element={<Monthly_plan />} />
          <Route path="/Yearly-plan" element={<Yearly_plan />} />
                    {/* all plans  email verify tested */}

      
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
