import React from 'react'
import Home from '../components/home'

const HomePage = () => {

  return (
    <>
      <Home/>
    </>
  )
}

export default HomePage
