import React, { useEffect, useState } from "react";
import Use_language_hook from "../../../hooks/cv-inputs-hook/language-hook";
import { useDispatch, useSelector } from "react-redux";
import { languages_action } from "../../../redux/action/action";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Language = () => {
    const dispatch = useDispatch();
  const [lang, setLang] = useState([]);
  const [range, setRange] = useState([]);
  const [inputCount, setInputCount] = useState(1);


// For cv1_data
const cv1_data = useSelector((state) => state.cv.cv1_details);
const language1 = cv1_data ? cv1_data.language : "";
const leveleOf_language1 = cv1_data ? cv1_data.leveleOf_language : "";

// For cv2_data
const cv2_data = useSelector((state) => state.cv.cv2_details);
const language2 = cv2_data ? cv2_data.language : "";
const leveleOf_language2 = cv2_data ? cv2_data.leveleOf_language : "";

// For cv3_data
const cv3_data = useSelector((state) => state.cv.cv3_details);
const language3 = cv3_data ? cv3_data.language : "";
const leveleOf_language3 = cv3_data ? cv3_data.leveleOf_language : "";

// For cv4_data
const cv4_data = useSelector((state) => state.cv.cv4_details);
const language4 = cv4_data ? cv4_data.language : "";
const leveleOf_language4 = cv4_data ? cv4_data.leveleOf_language : "";

// For cv5_data
const cv5_data = useSelector((state) => state.cv.cv5_details);
const language5 = cv5_data ? cv5_data.language : "";
const leveleOf_language5 = cv5_data ? cv5_data.leveleOf_language : "";

// For cv6_data
const cv6_data = useSelector((state) => state.cv.cv6_details);
const language6 = cv6_data ? cv6_data.language : "";
const leveleOf_language6 = cv6_data ? cv6_data.leveleOf_language : "";

// For cv7_data
const cv7_data = useSelector((state) => state.cv.cv7_details);
const language7 = cv7_data ? cv7_data.language : "";
const leveleOf_language7 = cv7_data ? cv7_data.leveleOf_language : "";

// For cv8_data
const cv8_data = useSelector((state) => state.cv.cv8_details);
const language8 = cv8_data ? cv8_data.language : "";
const leveleOf_language8 = cv8_data ? cv8_data.leveleOf_language : "";

// For cv9_data
const cv9_data = useSelector((state) => state.cv.cv9_details);
const language9 = cv9_data ? cv9_data.language : "";
const leveleOf_language9 = cv9_data ? cv9_data.leveleOf_language : "";

// For cv10_data
const cv10_data = useSelector((state) => state.cv.cv10_details);
const language10 = cv10_data ? cv10_data.language : "";
const leveleOf_language10 = cv10_data ? cv10_data.leveleOf_language : "";

// For cv11_data
const cv11_data = useSelector((state) => state.cv.cv11_details);
const language11 = cv11_data ? cv11_data.language : "";
const leveleOf_language11 = cv11_data ? cv11_data.leveleOf_language : "";

// For cv12_data
const cv12_data = useSelector((state) => state.cv.cv12_details);
const language12 = cv12_data ? cv12_data.language : "";
const leveleOf_language12 = cv12_data ? cv12_data.leveleOf_language : "";

// For cv13_data
const cv13_data = useSelector((state) => state.cv.cv13_details);
const language13 = cv13_data ? cv13_data.language : "";
const leveleOf_language13 = cv13_data ? cv13_data.leveleOf_language : "";

// For cv14_data
const cv14_data = useSelector((state) => state.cv.cv14_details);
const language14 = cv14_data ? cv14_data.language : "";
const leveleOf_language14 = cv14_data ? cv14_data.leveleOf_language : "";

// For cv15_data
const cv15_data = useSelector((state) => state.cv.cv15_details);
const language15 = cv15_data ? cv15_data.language : "";
const leveleOf_language15 = cv15_data ? cv15_data.leveleOf_language : "";

// For cv16_data
const cv16_data = useSelector((state) => state.cv.cv16_details);
const language16 = cv16_data ? cv16_data.language : "";
const leveleOf_language16 = cv16_data ? cv16_data.leveleOf_language : "";

// For cv17_data
const cv17_data = useSelector((state) => state.cv.cv17_details);
const language17 = cv17_data ? cv17_data.language : "";
const leveleOf_language17 = cv17_data ? cv17_data.leveleOf_language : "";

// For cv18_data
const cv18_data = useSelector((state) => state.cv.cv18_details);
const language18 = cv18_data ? cv18_data.language : "";
const leveleOf_language18 = cv18_data ? cv18_data.leveleOf_language : "";

// For cv19_data
const cv19_data = useSelector((state) => state.cv.cv19_details);
const language19 = cv19_data ? cv19_data.language : "";
const leveleOf_language19 = cv19_data ? cv19_data.leveleOf_language : "";

// For cv20_data
const cv20_data = useSelector((state) => state.cv.cv20_details);
const language20 = cv20_data ? cv20_data.language : "";
const leveleOf_language20 = cv20_data ? cv20_data.leveleOf_language : "";

// For cv1_data
useEffect(() => {
  if (language1 || leveleOf_language1) {
    setInputCount((language1 || leveleOf_language1).length);
    setLang(language1);
    setRange(leveleOf_language1);
  } 
}, [language1, leveleOf_language1]);

// For cv2_data
useEffect(() => {
  if (language2 || leveleOf_language2) {
    setInputCount((language2 || leveleOf_language2).length);
    setLang(language2);
    setRange(leveleOf_language2);
  } 
}, [language2, leveleOf_language2]);

// For cv3_data
useEffect(() => {
  if (language3 || leveleOf_language3) {
    setInputCount((language3 || leveleOf_language3).length);
    setLang(language3);
    setRange(leveleOf_language3);
  } 
}, [language3, leveleOf_language3]);

// For cv4_data
useEffect(() => {
  if (language4 || leveleOf_language4) {
    setInputCount((language4 || leveleOf_language4).length);
    setLang(language4);
    setRange(leveleOf_language4);
  } 
}, [language4, leveleOf_language4]);

// For cv5_data
useEffect(() => {
  if (language5 || leveleOf_language5) {
    setInputCount((language5 || leveleOf_language5).length);
    setLang(language5);
    setRange(leveleOf_language5);
  } 
}, [language5, leveleOf_language5]);

// For cv6_data
useEffect(() => {
  if (language6 || leveleOf_language6) {
    setInputCount((language6 || leveleOf_language6).length);
    setLang(language6);
    setRange(leveleOf_language6);
  } 
}, [language6, leveleOf_language6]);

// For cv7_data
useEffect(() => {
  if (language7 || leveleOf_language7) {
    setInputCount((language7 || leveleOf_language7).length);
    setLang(language7);
    setRange(leveleOf_language7);
  } 
}, [language7, leveleOf_language7]);

// For cv8_data
useEffect(() => {
  if (language8 || leveleOf_language8) {
    setInputCount((language8 || leveleOf_language8).length);
    setLang(language8);
    setRange(leveleOf_language8);
  } 
}, [language8, leveleOf_language8]);

// For cv9_data
useEffect(() => {
  if (language9 || leveleOf_language9) {
    setInputCount((language9 || leveleOf_language9).length);
    setLang(language9);
    setRange(leveleOf_language9);
  } 
}, [language9, leveleOf_language9]);

// For cv10_data
useEffect(() => {
  if (language10 || leveleOf_language10) {
    setInputCount((language10 || leveleOf_language10).length);
    setLang(language10);
    setRange(leveleOf_language10);
  } 
}, [language10, leveleOf_language10]);

// For cv11_data
useEffect(() => {
  if (language11 || leveleOf_language11) {
    setInputCount((language11 || leveleOf_language11).length);
    setLang(language11);
    setRange(leveleOf_language11);
  } 
}, [language11, leveleOf_language11]);

// For cv12_data
useEffect(() => {
  if (language12 || leveleOf_language12) {
    setInputCount((language12 || leveleOf_language12).length);
    setLang(language12);
    setRange(leveleOf_language12);
  } 
}, [language12, leveleOf_language12]);

// For cv13_data
useEffect(() => {
  if (language13 || leveleOf_language13) {
    setInputCount((language13 || leveleOf_language13).length);
    setLang(language13);
    setRange(leveleOf_language13);
  } 
}, [language13, leveleOf_language13]);

// For cv14_data
useEffect(() => {
  if (language14 || leveleOf_language14) {
    setInputCount((language14 || leveleOf_language14).length);
    setLang(language14);
    setRange(leveleOf_language14);
  } 
}, [language14, leveleOf_language14]);

// For cv15_data
useEffect(() => {
  if (language15 || leveleOf_language15) {
    setInputCount((language15 || leveleOf_language15).length);
    setLang(language15);
    setRange(leveleOf_language15);
  } 
}, [language15, leveleOf_language15]);

// For cv16_data
useEffect(() => {
  if (language16 || leveleOf_language16) {
    setInputCount((language16 || leveleOf_language16).length);
    setLang(language16);
    setRange(leveleOf_language16);
  } 
}, [language16, leveleOf_language16]);

// For cv17_data
useEffect(() => {
  if (language17 || leveleOf_language17) {
    setInputCount((language17 || leveleOf_language17).length);
    setLang(language17);
    setRange(leveleOf_language17);
  } 
}, [language17, leveleOf_language17]);

// For cv18_data
useEffect(() => {
  if (language18 || leveleOf_language18) {
    setInputCount((language18 || leveleOf_language18).length);
    setLang(language18);
    setRange(leveleOf_language18);
  } 
}, [language18, leveleOf_language18]);

// For cv19_data
useEffect(() => {
  if (language19 || leveleOf_language19) {
    setInputCount((language19 || leveleOf_language19).length);
    setLang(language19);
    setRange(leveleOf_language19);
  } 
}, [language19, leveleOf_language19]);

// For cv20_data
useEffect(() => {
  if (language20 || leveleOf_language20) {
    setInputCount((language20 || leveleOf_language20).length);
    setLang(language20);
    setRange(leveleOf_language20);
  } 
}, [language20, leveleOf_language20]);




  const handleInputChange = (index, e) => {
    const newLang = [...lang];
    newLang[index] = e.target.value;
    setLang(newLang);
  };
  const handleInputChange1 = (index, e) => {
    const newLang = [...range];
    newLang[index] = e.target.value;
    setRange(newLang);
  };
  const addInputField = () => {
    setInputCount(inputCount + 1);
  };
  useEffect(() => {
    // Dispatch action when lang state changes
    dispatch(languages_action(lang, range));
  }, [lang, range, dispatch])
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();

  return (

         <Accordion 
         className='py-[25px]'
         style={{ fontFamily: 'Poppins, sans-serif' }}
         expanded={expanded === "panel11"}
         onChange={handleChange("panel11")}
       >
         <AccordionSummary
           aria-controls="panel1d-content"
           id="panel1d-header"
           className='text-[30px]  cv-input-header font-[500] text-gray-700'
         >
      {t("language")}                               </AccordionSummary>
     <AccordionDetails        style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }} className="p-4 cv-input-content">
     {[...Array(inputCount)].map((_, index) => (
            <div key={index} className="flex mt-[10px]">
              <div className="fixed-height-input flex-1">
                <label
                  htmlFor={`inputname${index}`}
                  className="block text-gray-800 font-semibold text-sm"
                >
              {t("language")}     
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name={`inputname${index}`}
                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={lang[index] || ""}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
              </div>
              <div style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }}  className="fixed-height-input flex-1 ml-2">
                <label
                  htmlFor="inputname2" 
                  className="block text-gray-800 font-semibold text-sm"
                >
                                {t("level")}     

                </label>
                <div className="mt-2">
                  <select
                    type="text"
                    name="inputname2"
                    className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => handleInputChange1(index, e)}
                   value={range[index] || ""}
                   style={{
                    direction:
                      i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
                  }}
                  >
                    <option>                                {t("level")}     
                    </option>
                    <option value="Begginer">Begginer</option>
                    <option value="Moderate">Moderate</option>
                    <option value="Good">Good</option>
                    <option value="very good">Very good</option>
                    <option value="Fluent">Fluent</option>
                  </select>
                </div>
              </div>
            </div>
          ))}

              <button onClick={addInputField} className="add-icon min-w-[180px] mt-6 rounded">
              <AddIcon/>{t("Add_Language")}     

              </button>
         
         </AccordionDetails>
       </Accordion>
  );
};

export default Language;
