import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  created_at_search_value,
  email_search_value,
  name_search_value,
  order_get,
  order_search,
  Plan_Type_search_value,
  status_search_value,
  user_delete,
  user_get,
  user_name_search_value,
  user_search,
  user_update,
} from "../../../redux/action/auth-action";
import { useTranslation } from "react-i18next";
// import User_Pagination from "./Admin-users-pagination";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../../../sub component/show design data/loading";
import Search_loading from "../../main-pages/search loading/search-loading";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { user_action } from "../../../redux/action/cv-action";
import Admin_nav from "../../../app bar/admin-nav";
import Error404 from "../../error page/error404";
import Order_Pagination from "./order-page";

const Order = () => {
  const [data, setData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Plan_Type, setPlan_Type] = useState("");
  const [Status, setStatus] = useState("");
  const [Created_At, setCreated_At] = useState("");

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const oprder_data = useSelector((state) => state.auth.order_get);
  const user_search_data = useSelector((state) => state.auth.order_search);
  const order_page = useSelector((state) => state.auth.order_page);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(order_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setData(oprder_data);
  }, [oprder_data]);

  useEffect(() => {
    setData(user_search_data);
  }, [user_search_data]);

  useEffect(() => {
    setData(order_page);
  }, [order_page]);

  useEffect(() => {
    dispatch(order_search(name, email, Status, Plan_Type,Created_At));
    dispatch(user_name_search_value(name));
    dispatch(email_search_value(email));
    dispatch(status_search_value(Status));
       dispatch(Plan_Type_search_value(Plan_Type));
       dispatch(created_at_search_value(Created_At));
  }, [name,email,Status,Plan_Type,Created_At]);

  // useEffect(() => {
  //   dispatch(order_search("", email, "", "", ""));
  //   dispatch(email_search_value(email));
  // }, [email]);

  // useEffect(() => {
  //   dispatch(order_search("", "", Status, "", ""));
  //   dispatch(status_search_value(Status));
  // }, [Status]);

  // useEffect(() => {
  //   dispatch(order_search("", "", "", Plan_Type, ""));
  //   dispatch(Plan_Type_search_value(Plan_Type));
  // }, [Plan_Type]);

  // useEffect(() => {
  //   dispatch(order_search("", "", "", "", Created_At));
  //   dispatch(created_at_search_value(Created_At));
  // }, [Created_At]);

  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
      {loading ? null : <Admin_nav />}
      <div className="flex flex-col flex-1 overflow-y-auto bg-gray-100">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div className="pt-[100px] px-[2%]">
              {searchLoading ? (
                <Search_loading />
              ) : (
                <div>
                  <ToastContainer />
                  <div className="text-gray-900 mt-4 flex flex-col justify-center">
                    {/* Add other search options here */}
                    <div className="mt-4 flex flex-wrap justify-center gap-4">
                      {/* Search Option 1 */}
                      <input
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg "
                        required
                        placeholder="Name"
                      />

                      {/* Search Option 2 */}
                      <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        placeholder="Email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg "
                        required
                      />

                      {/* Search Option 3 */}
                      <input
                        type="text"
                        onChange={(e) => setStatus(e.target.value)}
                        value={Status}
                        placeholder="Status"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg "
                        required
                      />

                      {/* Search Option 4 */}
                      <input
                        type="text"
                        onChange={(e) => setPlan_Type(e.target.value)}
                        value={Plan_Type}
                        placeholder="Plan type"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg "
                        required
                      />

                      {/* Search Option 5 */}
                      <input
                        type="date"
                        onChange={(e) => setCreated_At(e.target.value)}
                        value={Created_At}
                        placeholder="Created At"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg "
                        required
                      />
                    </div>

                    <div className="w-full overflow-x-auto mb-[50px]">
                      <div className="pb-3 flex justify-center"></div>
                      <table className="min-w-full bg-white border rounded-lg shadow overflow-hidden">
                        <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <tr>
                            <th className="py-3 px-6 text-left">id</th>

                            <th className="py-3 px-6 text-left">User name</th>
                            <th className="py-3 px-6 text-left">User Email</th>
                            <th className="py-3 px-6 text-left">Payment Id</th>
                            <th className="py-3 px-6 text-left">Plan Type</th>
                            <th className="py-3 px-6 text-left">Status</th>
                            <th className="py-3 px-6 text-left">Created At</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                          {data.map((user, index) => (
                            <tr
                              key={index}
                              className="border-b hover:bg-gray-100"
                            >
                              <td className="py-3 px-6 text-left">{user.id}</td>

                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {user.user_name}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left">
                                {user.user_email}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {user.payment_id}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {user.plan_type}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {user.status}
                              </td>
                              <td className="py-3 px-6 text-left">
                                {user.created_at}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {(data.length > 0 || user_search_data.length > 0) && (
          <div className="flex justify-center w-full mb-[50px]">
            <Order_Pagination />
          </div>
        )}
      </div>
    </div>
  );
};

export default Order;
