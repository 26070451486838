import { COLOR, OPEN } from "../action/open-action";

const enitialState={
bg_color_open:[],
hama:[],
color_open:[],
font_family:[],
bg_color:[],
line_height:[],
font_size:[],
selectedColor:[]
}
  export const  open_reducer = (state=enitialState , action) => {

switch (action.type) {
    case OPEN: 
      return {...state ,
        bg_color:action.bg_color,
        bg_color_open:action.bg_color_open,
        font_family:action.font_family,
        line_height:action.line_height,
        font_size:action.font_size,
        selectedColor:action.selected_color
      }
     case COLOR: 
      return {...state ,
         color_open:action.color_open,
  
      }
    default:
     return state;
  }
  }
