import axios from "axios";
import Cookies from "js-cookie";

export const PROPERTY = "PROPERTY";

export const property_action = (color, font, line_height) => {
  return {
    type: PROPERTY,
    color: color,
    font: font,
    line_height: line_height,
  };
};
//add properties

export const ADD_PROPERTY = "ADD_PROPERTY";

export const addProperty = (
  title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
                  const token = Cookies.get("jwt");

      const response = await axios.post(
        "http://localhost:8000/api/jobs/create",
        {
          title,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
           {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: ADD_PROPERTY, payload: { response, message } });
    } catch (error) {}
  };
};

export const ADD_PROPERTY2 = "ADD_PROPERTY";

export const addProperty2 = (
  title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        "http://localhost:8000/api/jobs/create2",
        {
          title,
          profile_image,
          profile_name,
          profile_description,
          education_scholl,
          education_city,
          education_start_date,
          education_end_date,
          education_description,
          address,
          phone,
          email,
          skills,
          language,
          leveleOf_language,
          course_title,
          course_year,
          course_description,
          experiencetitle,
          experience_year,
          experience_description,
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: ADD_PROPERTY2, payload: { response, message } });
    } catch (error) {}
  };
};
//show property
export const SHOW_PROPERTY = "SHOW_PROPERTY";
export const showProperty = () => {
  
  return async (dispatch) => {
    try {
                  const token = Cookies.get("jwt");

      const response = await axios.get("http://localhost:8000/api/jobs/show"
      ,
      {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      
      );

      dispatch({ type: SHOW_PROPERTY, payload: response.data.message });
    } catch (error) {}
  };
};

//show property by id  details
export const DETAILS = "DETAILS";
export const details = (id) => {
  return async (dispatch) => {
    try {
                  const token = Cookies.get("jwt");
      const response = await axios.get(
        `http://localhost:8000/api/property/details/${id}`,
          {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      dispatch({ type: DETAILS, payload: response.data.data });
    } catch (error) {}
  };
};
// uypdate property
export const UPDATE_PROPERTY = "UPDATE_PROPERTY";

export const updateProperty = ( title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/updateJobTitle/1`,
        {
 title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: UPDATE_PROPERTY, payload: { response, message } });
    } catch (error) {}
  };
};

export const ADD_INFORMATION = "ADD_INFORMATION";

export const add_information_action = (category, title, details, location) => {
  // // Check if all arrays have the same length
  

  return async (dispatch) => {
    try {
      for (let i = 0; i < location ? location.length : 1; i++) {
        const response = await axios.post(
          `http://localhost:8000/api/jobs/create`,
          {
            category: category[i],
            title: title[i],
            details: details[i],
            location: location[i],
          },
          {
            credentials: "include",
          }
        );

        const message = response.data.message;
        dispatch({ type: ADD_INFORMATION, payload: { response, message } });
      }
    } catch (error) {
      console.error("Error adding information:", error);
    }
  };
};

/*export const ADD_INFORMATION = "ADD_INFORMATION";

export const add_informaion_action = ({
  cities,
  
}) => {
  // Check if all arrays have the same length

  return async (dispatch) => {
    try {
      for (let i = 0; i < cities.length; i++) {
        const response = await axios.post(
          `http://localhost:8000/api/information/add`,
          {
            education_city: cities[i],
           
          },
          {
            credentials: "include",
          }
        );

        const message = response.data.message;
        dispatch({ type: ADD_INFORMATION, payload: { response, message } });
      }
    } catch (error) {
      console.error('Error adding information:', error);
    }
  };
};


*/
//cv2 details
export const DETAILS1 = "DETAILS1";
export const details1 = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `http://localhost:8000/api/property/details1/${id}`
      );

      dispatch({ type: DETAILS1, payload: response.data.data});
    } catch (error) {}
  };
};
export const UPDATE_PROPERTY1 = "UPDATE_PROPERTY1";

export const updateProperty1 = ( title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description) => {
  return async (dispatch) => {
    try {
            const token = Cookies.get("jwt");
      const response = await axios.post(
        `http://localhost:8000/api/updateJobTitle1/1`,

          {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
 title,
  profile_image,
  profile_name,
  profile_description,
  education_scholl,
  education_city,
  education_start_date,
  education_end_date,
  education_description,
  address,
  phone,
  email,
  skills,
  language,
  leveleOf_language,
  course_title,
  course_year,
  course_description,
  experiencetitle,
  experience_year,
  experience_description
        },
        {
          credentials: "include",
        }
      );
      const message = response.data.message;
      dispatch({ type: UPDATE_PROPERTY1, payload: { response, message } });
    } catch (error) {}
  };
};
