import { NAME_SEARCHVALUE } from "../action/auth-action";
import {
  CITY_SEARCHVALUE,
  COUNTRY_SEARCHVALUE,
  DOWNLOAD_ACTION,
  GETHIGH,
  GETMIDDLE,
  GETPRIMARY,
  GETSEARDCHHIGH,
  GETSEARDCHMIDDLE_DATA,
  GETSEARDCHPRIMARY,
  LANGUAGE_SEARCHVALUE,
  LECTURERSINSERT,
  LECTURERUPDATEDETAILS,
  LECTURER_DETAILS,
  LECTURER_GET,
  PAGEHIGH,
  PAGEMIDDLE,
  PAGEPRIMARY,
  REVIEW_GET,
  SEARCHHIGH,
  SEARCHMIDDLE,
  SEARCHPRIMARY,
  STATE_SEARCHVALUE,
  STUDY_MODE_SEARCHVALUE,
  SUBJECT_SEARCHVALUE,
  USER_REVIEW_GET
} from "../action/lecturers-action";

const initialState = {
  primary_get: [],
  primary_total_page: null,
  total_pages_search: null,
  page: [],
  search: [],
  searchPerformed: false,
  loading: true,
//middle
 middle_get: [],
  middle_total_page: null,
//high
 high_get: [],
  high_total_page: null,

  //search values
  first_search_value: null,
  second_search_value: null,
  third_search_value: null,
  fourth_search_value: null,
  fifth_search_value: null,
  sixth_search_value:null,
  seventh_search_value:null,
  //details
  lecturer_details:[],
  lecturer_update_details:[]
,
  lecturer_get:[],
  //get total lecturers fr pagination,
  total_primary:[],
  total_middle:[],
  total_high:[],

  //get all data for shgow count in search
  high_search_get:[],
  middle_search_get:[],
  primary_search_get:[],
  isInserted:false,
  download:false,
  //reviews
  review_get_data:[],
  user_review:[],

};

export const lecturers_reducer = (state = initialState, action) => {
  switch (action.type) {
    case GETPRIMARY:
      return {
        ...state,
        primary_get: action.payload.data,
        primary_total_page: action.payload.total_pages,
        total_primary: action.payload.total_lecturers,

        loading: false
      };


      case LECTURERSINSERT:
        return {
          ...state,
          isInserted:action.payload.isInserted,
  
        };  

      case GETSEARDCHHIGH:
        return {
          ...state,
          high_search_get: action.payload.data,
     
        };


        case GETSEARDCHMIDDLE_DATA:
          return {
            ...state,
            middle_search_get: action.payload.data,
       
          };


          case GETSEARDCHPRIMARY:
            return {
              ...state,
              primary_search_get: action.payload.data,
         
            };

            case REVIEW_GET:
              return {
                ...state,
                review_get_data: action.payload.data,
            
              };
   //user review
   case USER_REVIEW_GET:
    return {
      ...state,
      user_review: action.payload,
    };
    case SEARCHPRIMARY:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };
    case PAGEPRIMARY:
      return {
        ...state,
        page: action.payload.data,
      };
//middle
    case GETMIDDLE:
      return {
        ...state,
        middle_get: action.payload.data,
        middle_total_page: action.payload.total_pages,
        total_middle: action.payload.total_lecturers,

        loading: false
      };
    case SEARCHMIDDLE:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };
    case PAGEMIDDLE:
      return {
        ...state,
        page: action.payload.data,
      };

      //high
    case GETHIGH:
      return {
        ...state,
        high_get: action.payload.data,
        high_total_page: action.payload.total_pages,
        total_high: action.payload.total_lecturers,

        loading: false
      };
    case SEARCHHIGH:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };
    case PAGEHIGH:
      return {
        ...state,
        page: action.payload.data,
      };
      case NAME_SEARCHVALUE:
        return {
          ...state,
          seventh_search_value: action.search,
        };

    case COUNTRY_SEARCHVALUE:
      return {
        ...state,
        first_search_value: action.search,
      };
    case CITY_SEARCHVALUE:
      return {
        ...state,
        second_search_value: action.search,
      };
    case STATE_SEARCHVALUE:
      return {
        ...state,
        third_search_value: action.search,
      };
    case SUBJECT_SEARCHVALUE:
      return {
        ...state,
        fourth_search_value: action.search,
      };
    case STUDY_MODE_SEARCHVALUE:
      return {
        ...state,
        fifth_search_value: action.search,
      };
       case LANGUAGE_SEARCHVALUE:
      return {
        ...state,
        sixth_search_value: action.search,
      };
 case LECTURER_DETAILS:
      return {
        ...state,
   lecturer_details: action.payload.data,
        loading: false      };
       case LECTURERUPDATEDETAILS:
      return {
        ...state,
   lecturer_update_details: action.payload.data,
        loading: false      };

          case LECTURER_GET:
      return {
        ...state,
   lecturer_get: action.payload.data,
        loading: false      };
 case DOWNLOAD_ACTION:
      return {
        ...state,
   download: true,
       };  
        
    default:
      return state;
  }
  
};
