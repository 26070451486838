// freelancer-reducer.js
import {
  FREELANCERUPDATEDETAILS,
  FREELANCER_DETAILS,
  FREELANCER_GET,
  PAGEFREELANCER,
  SEARCHFREELANCER,
  SEARCHVALUE,
} from "../action/freelancer-action";
import { TYPE_SEARCHVALUE } from "../action/job-action";
import {
  BATTERY_SERVICES_GET,
  CARPET_GET,
  CAR_WASH_GET,
  CITY_SEARCHVALUE,
  CLEANING_GET,
  CONSTRUCTION_GET,
  COUNTRY_SEARCHVALUE,
  ELECTRICAL_GET,
  ELECTRONIC_SERVICES_GET,
  FREIGHT_FORWARDING_SERVICES_GET,
  GARDEN_GET,
  HAIRDRESSING_GET,
  HOME_APPLIANCE_GET,
  LEGAL_CONSULTATION_GET,
  MAKEUP_GET,
  NAIL_GET,
  PAGCLEANING,
  PAGEELECTRICAL,
  PAGEVEHICLE,
  PAGE_BATTERY_SERVICES,
  PAGE_CARPET,
  PAGE_CAR_WASH,
  PAGE_CONSTRUCTION,
  PAGE_ELECTRONIC_SERVICES,
  PAGE_FREIGHT_FORWARDING_SERVICES,
  PAGE_GARDEN,
  PAGE_HAIRDRESSING,
  PAGE_HOME_APPLIANCE,
  PAGE_LEGAL_CONSULTATION,
  PAGE_MAKEUP,
  PAGE_NAIL,
  PAGE_PHOTOGRAPHY_SERVICES,
  PAGE_POST,
  PAGE_SKINCARE,
  PAGE_TIRE_REPLACEMENT_SERVICES,
  PAGE_TRAVEL_TOURISM_SERVICES,
  PAGE_VETERINARY,
  PHOTOGRAPHY_SERVICES_GET,
  POST_GET,
  SEARCHCOUNTRY,
  SEARCHELECTRICAL,
  SEARCHVEHICLE,
  SEARCH_BATTERY_SERVICES,
  SEARCH_CARPET,
  SEARCH_CAR_WASH,
  SEARCH_CONSTRUCTION,
  SEARCH_ELECTRONIC_SERVICES,
  SEARCH_FREIGHT_FORWARDING_SERVICES,
  SEARCH_GARDEN,
  SEARCH_HAIRDRESSING,
  SEARCH_HOME_APPLIANCE,
  SEARCH_LEGAL_CONSULTATION,
  SEARCH_MAKEUP,
  SEARCH_NAIL,
  SEARCH_PHOTOGRAPHY_SERVICES,
  SEARCH_POST,
  SEARCH_SKINCARE,
  SEARCH_TIRE_REPLACEMENT_SERVICES,
  SEARCH_TRAVEL_TOURISM_SERVICES,
  SEARCH_VETERINARY,
  SERVICESDETAILS,
  SERVICESGET,
  SERVICESINSERT,
  SERVICESPAGE,
  SERVICESUPDATEDETAILS,
  SERVICES_GET_SEARCH,
  SERVICES_SEARCH,
  SKINCARE_GET,
  STATE_SEARCHVALUE,
  TIRE_REPLACEMENT_SERVICES_GET,
  TRAVEL_TOURISM_SERVICES_GET,
  VEHICLE_GET,
  VETERINARY_GET,
  hairdressing_paginate,
} from "../action/services-action";

const initialState = {
  cleaning_get: [],
  loading: true,
  search: [],
  total_pages: null, // Total pages for all freelancers
  total_pages_search: null, // Total pages for search results
  searchPerformed: false,
  cleaning_page: [],
  //search value to use in pagination
  first_search_value: null,
  second_search_value: null,
  third_search_value: null,

  services_update_details: [],
  services_details: [],
  services_get: [],
  services_total_pages:[],
  vehicle_get: [],
  vehicle_page: [],
  vehicle_total_pages: [],
  //electrical
  electrical_get: [],
  electrical_page: [],
  electrical_total_pages: [],

  //home_appliance
  home_appliance_get: [],
  electrical_page: [],
  home_appliance_total_pages: [],
  //construcion house
  Construction_of_Houses_total_pages: [],
  Construction_of_Houses_get: [],
  //garden
  garden_total_pages: [],
  garden_get: [],
  //car wash
  car_wash_total_pages: [],
  car_wash_get: [],
  //carpet
  carpet_total_pages: [],
  carpet_get: [],
  //post
  post_total_pages: [],
  post_get: [],
  //Hairdressing
  Hairdressing_total_pages: [],
  Hairdressing_get: [],
  //Skincare Treatments

  Skincare_total_pages: [],
  Skincare_get: [],
  // makup
  makup_total_pages: [],
  makup_get: [],
  // Nail
  nail_total_pages: [],
  nail_get: [],
  // Veterinary
  veterinary_total_pages: [],
  veterinary_get: [],
  //legal
  legal_total_pages: [],
  legal_get: [],
  //photography
  photography_total_pages: [],
  photography_get: [],
  tire_replacement_total_pages: [],
  tire_replacement_get: [],
  battery_total_pages: [],
  battery_get: [],
  // Freight Forwarding
  freight_forwarding_total_pages: [],
  freight_forwarding_get: [],
  //  electronic services
  electronic_services_total_pages: [],
  electronic_services_get: [],
  //  travel
  travel_total_pages: [],
  travel_get: [],
//get all data for search
  services_search_get:[],
  services_search_total_pages:[],
  type_search_value:[],
  isInserted:false,

  total_battery:[],
  total_carwash:[],
  total_carpet:[],
  total_cleaning:[],
  total_Construction:[],
  total_electrical:[],
  total_electronic:[],
  total_freight_forwarding:[],
  total_garden:[],
  total_hairdessing:[],
  total_home_appliance:[],
  total_legal:[],
  total_makeup:[],
  total_nail:[],
  total_photography:[],
  total_post:[],
  total_skincare:[],
  total_tire_replacement:[],
  total_travel_tourism:[],
  total_vehicle:[],
  total_veterinary:[],


};
export const services_reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEANING_GET:
      return {
        ...state,
        cleaning_get: action.payload.data,
        total_pages: action.payload.total_pages,
        total_cleaning: action.payload.total_services,

        loading: false,
      };
      
      case SERVICESINSERT:
        return {
          ...state,
          isInserted:action.payload.isInserted,
  
        };  
    case SEARCHCOUNTRY:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    case SEARCHVEHICLE:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    case PAGCLEANING:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case COUNTRY_SEARCHVALUE:
      return {
        ...state,
        first_search_value: action.search,
      };
    case CITY_SEARCHVALUE:
      return {
        ...state,
        second_search_value: action.search,
      };
      case TYPE_SEARCHVALUE:
        return {
          ...state,
          type_search_value: action.search,
        };
    case STATE_SEARCHVALUE:
      return {
        ...state,
        third_search_value: action.search,
      };

    // Vehicle Maintenance
    case VEHICLE_GET:
      return {
        ...state,
        vehicle_get: action.payload.data,
        vehicle_total_pages: action.payload.total_pages,
        total_vehicle: action.payload.total_services,

        loading: false,
      };

    case PAGEVEHICLE:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };

    case SERVICESUPDATEDETAILS:
      return {
        ...state,
        services_update_details: action.payload.data,
        loading: false,
      };

    // electrical
    case ELECTRICAL_GET:
      return {
        ...state,
        electrical_get: action.payload.data,
        electrical_total_pages: action.payload.total_pages,
        total_electrical: action.payload.total_services,

        loading: false,
      };

    case PAGEELECTRICAL:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCHELECTRICAL:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // garden
    case GARDEN_GET:
      return {
        ...state,
        garden_get: action.payload.data,
        garden_total_pages: action.payload.total_pages,
        total_garden: action.payload.total_services,

        loading: false,
      };

    case PAGE_GARDEN:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_GARDEN:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // car wash
    case CAR_WASH_GET:
      return {
        ...state,
        car_wash_get: action.payload.data,
        car_wash_total_pages: action.payload.total_pages,
        total_carwash: action.payload.total_services,

        loading: false,
      };

    case PAGE_CAR_WASH:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_CAR_WASH:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // carpet
    case CARPET_GET:
      return {
        ...state,
        carpet_get: action.payload.data,
        carpet_total_pages: action.payload.total_pages,
        total_carpet: action.payload.total_services,

        loading: false,
      };

    case PAGE_CARPET:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_CARPET:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };
    // post
    case POST_GET:
      return {
        ...state,
        post_get: action.payload.data,
        post_total_pages: action.payload.total_pages,
        total_post: action.payload.total_services,

        loading: false,
      };

    case PAGE_POST:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_POST:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Freight Forwarding
    case FREIGHT_FORWARDING_SERVICES_GET:
      return {
        ...state,
        freight_forwarding_get: action.payload.data,
        freight_forwarding_total_pages: action.payload.total_pages,
        total_freight_forwarding: action.payload.total_services,

        loading: false,
      };

    case PAGE_FREIGHT_FORWARDING_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_FREIGHT_FORWARDING_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    //  electronic services
    case ELECTRONIC_SERVICES_GET:
      return {
        ...state,
        electronic_services_get: action.payload.data,
        electronic_services_total_pages: action.payload.total_pages,
        total_electronic: action.payload.total_services,

        loading: false,
      };

    case PAGE_ELECTRONIC_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_ELECTRONIC_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    //  travel services
    case TRAVEL_TOURISM_SERVICES_GET:
      return {
        ...state,
        travel_get: action.payload.data,
        travel_total_pages: action.payload.total_pages,
        total_travel_tourism: action.payload.total_services,

        loading: false,
      };

    case PAGE_TRAVEL_TOURISM_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_TRAVEL_TOURISM_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Hairdressing
    case HAIRDRESSING_GET:
      return {
        ...state,
        Hairdressing_get: action.payload.data,
        Hairdressing_total_pages: action.payload.total_pages,
        total_hairdessing: action.payload.total_services,

        loading: false,
      };

    case PAGE_HAIRDRESSING:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_HAIRDRESSING:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Skincare Treatments
    case SKINCARE_GET:
      return {
        ...state,
        Skincare_get: action.payload.data,
        Skincare_total_pages: action.payload.total_pages,
        total_skincare: action.payload.total_services,

        loading: false,
      };

    case PAGE_SKINCARE:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_SKINCARE:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Makeup Services
    case MAKEUP_GET:
      return {
        ...state,
        makup_get: action.payload.data,
        makup_total_pages: action.payload.total_pages,
        total_makeup: action.payload.total_services,

        loading: false,
      };

    case PAGE_MAKEUP:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_MAKEUP:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Veterinary Services

    case NAIL_GET:
      return {
        ...state,
        nail_get: action.payload.data,
        nail_total_pages: action.payload.total_pages,
        total_nail: action.payload.total_services,

        loading: false,
      };

    case PAGE_NAIL:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_NAIL:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Veterinary Services

    case VETERINARY_GET:
      return {
        ...state,
        veterinary_get: action.payload.data,
        veterinary_total_pages: action.payload.total_pages,
        total_veterinary: action.payload.total_services,

        loading: false,
      };

    case PAGE_VETERINARY:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_VETERINARY:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Legal Consultation Services

    case LEGAL_CONSULTATION_GET:
      return {
        ...state,
        legal_get: action.payload.data,
        legal_total_pages: action.payload.total_pages,
        total_legal: action.payload.total_services,

        loading: false,
      };

    case PAGE_LEGAL_CONSULTATION:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_LEGAL_CONSULTATION:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };
    // photography

    case PHOTOGRAPHY_SERVICES_GET:
      return {
        ...state,
        photography_get: action.payload.data,
        photography_total_pages: action.payload.total_pages,
        total_photography: action.payload.total_services,

        loading: false,
      };

    case PAGE_PHOTOGRAPHY_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_PHOTOGRAPHY_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // tire

    case TIRE_REPLACEMENT_SERVICES_GET:
      return {
        ...state,
        tire_replacement_get: action.payload.data,
        tire_replacement_total_pages: action.payload.total_pages,
        total_tire_replacement: action.payload.total_services,

        loading: false,
      };

    case PAGE_TIRE_REPLACEMENT_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_TIRE_REPLACEMENT_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Battery Services
    case BATTERY_SERVICES_GET:
      return {
        ...state,
        battery_get: action.payload.data,
        battery_total_pages: action.payload.total_pages,
        total_battery: action.payload.total_services,

        loading: false,
      };
    case PAGE_BATTERY_SERVICES:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_BATTERY_SERVICES:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // home appliance
    case HOME_APPLIANCE_GET:
      return {
        ...state,
        home_appliance_get: action.payload.data,
        home_appliance_total_pages: action.payload.total_pages,
        total_home_appliance: action.payload.total_services,

        loading: false,
      };

    case PAGE_HOME_APPLIANCE:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_HOME_APPLIANCE:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    // Construction of Houses
    case CONSTRUCTION_GET:
      return {
        ...state,
        Construction_of_Houses_get: action.payload.data,
        Construction_of_Houses_total_pages: action.payload.total_pages,
        total_Construction: action.payload.total_services,

        loading: false,
      };

    case PAGE_CONSTRUCTION:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
    case SEARCH_CONSTRUCTION:
      return {
        ...state,
        search: action.payload.data,
        loading: false,
        total_pages_search: action.payload.total_pages,
        searchPerformed: true,
      };

    case SERVICESUPDATEDETAILS:
      return {
        ...state,
        services_update_details: action.payload.data,
        loading: false,
      };

    case SERVICESDETAILS:
      return {
        ...state,
        services_details: action.payload.data,
        loading: false,
      };
    case SERVICESGET:
      return {
        ...state,
        services_get: action.payload.data,
        services_total_pages:action.payload.total_pages,
        loading: false,
      };

      case SERVICES_GET_SEARCH:
        return {
          ...state,
          services_search_get: action.payload.data,
          loading: false,
        };
      case SERVICES_SEARCH:
        return {
          ...state,
          search: action.payload.data,
          loading: false,
          total_pages_search: action.payload.total_pages,
          searchPerformed: true,
        };
           case SERVICESPAGE:
      return {
        ...state,
        cleaning_page: action.payload.data,
      };
        case PAGE_BATTERY_SERVICES:
          return {
            ...state,
            cleaning_page: action.payload.data,
          };
    default:
      return state;
  }
};
