import React, { useEffect, useState } from "react";
import "../assets/style/auth.css";
import { useDispatch, useSelector } from "react-redux";
import { forgot_password, login } from "../redux/action/auth-action";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { user_action } from "../redux/action/cv-action";
import Loading from "../sub component/show design data/loading";

const Forgot_password = () => {
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const errors = useSelector((state) => state.auth.errors);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [hasDispatched, setHasDispatched] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(forgot_password(user_name, navigate, t));
      setTimeout(() => {
        setHasDispatched(false);
      }, 5000);
    }

  };
  const navigate = useNavigate();
  // if(token){
  //   navigate("/"); // Navigate to /home after the timeout

  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(user_action());
    

        setLoading(false);
      } catch {}
    };
    fetchData();
  }, [dispatch]);
  return (
<>
{
     loading?
     <Loading/>
     :
     <div className=" h-screen">
     <div
       style={{
         direction:
           i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
       }}
       className="flex auth-container justify-center h-full items-center  pt-[80px] "
     >
       <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 w-[600px]">
         <div className="mx-auto max-w-lg">
           <form
             onSubmit={submit}
             className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow sm:p-6 lg:p-8 bg-white"
           >
             <p className="text-center text-lg font-medium">
               {t("Forgot_password")}{" "}
             </p>
             <div>
               <label
                 htmlFor="email"
                 className="block mb-[10px] ml-[5px] text-sm font-medium text-gray-700"
               >
                 {t("email")}{" "}
               </label>
               <div className="relative">
                 <input
                   type="email"
                   name="email"
                   className="w-full rounded-lg auth-input focus:border-gray-200 border-gray-200 p-3 pe-12 text-sm"
                   placeholder={t("email")}
                   value={user_name}
                   onChange={(e) => setUserName(e.target.value)}
                 />
               </div>
               <div className="mx-[5px] pt-[5px]">
               {errors && errors.email && (
                 <div className="text-red-500">
                   {errors.email === "Please provide your email address."
                     ? t("Email_required")
                     : t("Email_not_found")}
                 </div>
               )}
             </div>
             </div>

            <div className="pt-[10px]">
            <button
               type="submit"
               className="block w-full rounded-lg bg-[#ffb923] px-5 py-3  text-sm font-medium text-white hover:bg-[#ffc64a]"
             >
               {t("send")}
             </button>
            </div>
           </form>
         </div>
       </div>
     </div>
     <ToastContainer />
   </div>
}
</>

  );
};

export default Forgot_password;
