import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";

const Profile_data = ({
  third_content_bg_color,
  ten_content_bg_color,
  fourteen_content_bg_color,
}) => {
  const profile_description = useSelector(
    (state) => state.main.profile_description
  );
  const font_size = useSelector((state) => state.open.font_size);
  const bg_header_color = useSelector((state) => state.open.selectedColor);
  const [isSmallSize, setIsSmallSize] = useState(false);

  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;
  
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  }, [path]);
  const { t, i18n } = useTranslation();

  return (
    <>
      {/*show profile*/}
      <div
      
        className={isSmallSize ? "text-[10px] mt-[20px]  max-w-[75px]" : "text-[15px]"}
        style={{fontSize: `${font_size}` }}
      >
        {profile_description?.length ? (
          <div className={third_content_bg_color || ten_content_bg_color ? "t" : null}>
            <p
              className={
                third_content_bg_color
                  ? `mb-[10px] text-white text-center border-[2px] rounded-[20px] font-bold` 
                  : isSmallSize
                  ? "text-[10px] mb-[-8px] font-bold"
                  : ten_content_bg_color
                  ? " mb-[20px] border-[2px] font-bold text-white  text-center"
                  : fourteen_content_bg_color
                  ? "d-none"
                  : "bg-color rounded-[20px] font-bold"
              }
              style={{
                backgroundColor: third_content_bg_color || ten_content_bg_color ? bg_header_color : null,
                third_content_bg_color,
              }}
            >
summary
            </p>
            <hr
              className={`my-[10px] ${
                third_content_bg_color || ten_content_bg_color || fourteen_content_bg_color ? "d-none" : isSmallSize ? "min-w-[140px]" : null
              }`}
            />
          </div>
        ) : null}
        <div
        
        className={isSmallSize ? "truncate" : null} style={{ wordWrap: "break-word" }}>
          {profile_description}
        </div>
      </div>
    </>
  );
};

// Function to render Profile_data component to string
const renderProfileDataToString = (props) => {
  const profileDataString = renderToString(<Profile_data {...props} />);
  return profileDataString;
};

export default Profile_data;
export { renderProfileDataToString };
