import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import Use_contact_hook from "../../hooks/show-design data-hook/contact-hook";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Contact_data = ({ third_content_bg_color, ten_content_bg_color }) => {
  const { email, phone, address, font_size } = Use_contact_hook();
  const bg_header_color = useSelector((state) => state.open.selectedColor);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;

  useEffect(() => {
    setIsSmallSize(url.includes(path));
  }, [path]);
  const { t, i18n } = useTranslation();

  return (
    <div

    style={{
     
    
        fontSize: font_size 
    }}
      className={isSmallSize ? 'text-[10px] max-w-[75px]' : 'max-w-[200px] text-[15px]'}
    >
      {phone || email || address ? (
        <div>
          <div className={third_content_bg_color || ten_content_bg_color ? "text-center" : null}>
            <p
              className={
                isSmallSize ? "text-[10px] font-bold mb-[-8px]"
                : third_content_bg_color
                ? `mb-[-8px]  text-center font-bold border-[2px] rounded-[20px]`
                : ten_content_bg_color
                ? "mb-[20px] border-[2px] font-bold"
                : "bg-color rounded-[20px] font-bold"
              }
              style={{
                border: third_content_bg_color || ten_content_bg_color
                  ? `1px solid ${bg_header_color}`
                  : null,
              }}
            >
Contact            </p>
          </div>
          <hr className={`my-[10px] ${third_content_bg_color || ten_content_bg_color ? "d-none" : ""}`} />
        </div>
      ) : null}
      <div className={third_content_bg_color ? " pt-[20px]" : null}>
        <div className="flex flex-col">
          {phone && (
            <div className="flex gap-2  item-container" style={{ marginBottom: '5px' }}>
              <PhoneIcon style={{ fontSize: isSmallSize ? 10 : undefined }} />
              <p className={isSmallSize ? 'max-w-[55px] break-words line-clamp-2	' : 'max-w-[200px] break-words line-clamp-2'}>
                {phone}
              </p>
            </div>
          )}
          {email && (
            <div className={isSmallSize ? 'mt-[-6px] flex gap-2 item-container '  : 'flex gap-2  item-container'} style={{ marginBottom: '5px' }}>
              <EmailIcon style={{ fontSize: isSmallSize ? 10 : undefined }} />
              <p className={third_content_bg_color ? 'max-w-[160px] break-words line-clamp-3' : 'max-w-[200px] break-words line-clamp-3	'}>
                {email}
              </p>
            </div>
          )}
          {address && (
            <div className={isSmallSize ? 'mt-[-6px] flex gap-2 item-container' : 'flex gap-2  item-container'} style={{ marginBottom: '5px' }}>
              <PlaceIcon style={{ fontSize: isSmallSize ? 10 : undefined }} />
              <p className={isSmallSize ? 'max-w-[55px] break-words line-clamp-2	' : 'max-w-[200px] break-words line-clamp-2'}>
                {address}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact_data;
