import React from 'react'
import brand from '../../assets/image/Brand-loading.png'
const Loading = () => {
  return (
    <>

<div className='flex space-x-2 z-50 min-w-full fixed  justify-center items-center bg-white min-h-screen dark:invert'>
<div className="flex justify-center items-center h-screen">
<div class="image-container">
  <img src={brand} alt="Your Image"/>
  <div class="border-animation"></div>
</div>
</div>
</div>
   
    </>
  )
}

export default Loading
