import { EDUCATION, education } from "../action/action";

const enitialState = {
  education: [],
  scholl: [],
  city: [],
  start_date: [],
  end_date: [],
  description: [],
};
export const education_reducer = (state = enitialState, action) => {
  switch (action.type) {
    case EDUCATION:
      return {
        ...state,
        education: action.education,
        scholl:action.scholl, 
        city: action.city,
        start_date: action.start_date,
        end_date: action.end_date,
        description: action.description,
      };

    default:
      return state;
  }
};
