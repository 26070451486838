import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/style/cv-template.css";
import html2pdf from "html2pdf.js";
import * as htmlToImage from "html-to-image";
//
import { open_action } from "../../redux/action/open-action";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PhotoshopPicker } from "react-color";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import {
  add_informaion_action,
  add_information_action,
  property_action,
} from "../../redux/action/property-action";
import Bottom_nav from "../../app bar/bottom-nav";
import Skills_data from "../../sub component/show design data/skills_data";
import Contact_data from "../../sub component/show design data/contact_data";
import Education_data from "../../sub component/show design data/education_data";
import Languages_data from "../../sub component/show design data/languages_data";
import Profile_data from "../../sub component/show design data/profile_data";
import Experience_data from "../../sub component/show design data/experience_data";
import Course_data from "../../sub component/show design data/course_data";
import Image_data from "../../sub component/show design data/image_data";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Button } from "@mui/material";
import Profile_name from "../../sub component/show design data/profile-name";
import { cv11_insert } from "../../redux/action/cv-action";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

const Cv11_content = () => {
  //useState
  const [value, setValue] = useState("");
  const [selectedColor, setSelectedColor] = useState("#0000"); // Initial color (red in this case)
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  //get data from redux
  const font_family = useSelector((state) => state.open.font_family);
  const email = useSelector((state) => state.main.email);
  const phone = useSelector((state) => state.main.phone);
  const address = useSelector((state) => state.main.address);
  //skills data
  const [contact_email, setContact_email] = useState();
  const [contact_phone, setContact_phone] = useState();
  const [contact_address, setContact_address] = useState();
  useEffect(() => {
    setContact_email(email);
    setContact_phone(phone);
    setContact_address(address);
  }, [email, phone, address]);
  //redux  styling bg color
  const line_height = useSelector((state) => state.open.line_height);
  const font_size = useSelector((state) => state.open.font_size);
  //course
  const course_title = useSelector((state) => state.main.course_title);
  const course_year = useSelector((state) => state.main.course_year);
  const course_description = useSelector(
    (state) => state.main.course_description
  );
  //education
  const city = useSelector((state) => state.education.city);
  const scholl = useSelector((state) => state.education.scholl);
  const start_date = useSelector((state) => state.education.start_date);
  const end_date = useSelector((state) => state.education.end_date);
  const description = useSelector((state) => state.education.description);
  //work experience
  const experience_title = useSelector((state) => state.main.experience_title);
  const experience_year = useSelector((state) => state.main.experience_year);
  const experience_description = useSelector(
    (state) => state.main.experience_description
  );
  //language
  const lang = useSelector((state) => state.main.languages);
  const range = useSelector((state) => state.main.range);
  //profile
  const profile_name = useSelector((state) => state.main.profile_name);
  const profile_description = useSelector(
    (state) => state.main.profile_description
  );

  const skills_data = useSelector((state) => state.main.skills);
  const language = useSelector((state) => state.main.languages);
  const leveleOf_language = useSelector((state) => state.main.range);
  const profile_image = useSelector((state) => state.main.profile_image);
  const submit = async () => {
    await dispatch(
      cv11_insert(
        color,
        font_family,
        font_size,
        line_height,
        profile_image,
        profile_name,
        profile_description,
        scholl,
        city,
        start_date,
        end_date,
        description,
        address,
        phone,
        email,
        skills_data,
        language,
        leveleOf_language,
        course_title,
        course_year,
        course_description,
        experience_title,
        experience_year,
        experience_description
      )
    );
  };

  //to change bg color

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(property_action(selectedColor));
  }, [selectedColor, dispatch]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const downloadPDF = () => {
    if (token) {
      const capture =
        window.innerWidth >= 1200
          ? document.querySelector(".cv-designs-desktop .actual")
          : document.querySelector(".cv-designs-phone .actual");

      html2canvas(capture, {
        scale: 2,
        logging: true,
        width: capture.scrollWidth,
        height: capture.scrollHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save("jobversion.pdf");
        setStatus("downloaded");
      });
    } else {
      if (!token) {
        toast.info(t("Please_log_in"));
      }
      setStatus("idle");
    }
  };

  // const [isSmallSize, setIsSmallSize] = useState(false);

  // const location = useLocation();
  // const url = ["/cv/1"];
  // const path = location.pathname;
  // useEffect(() => {
  //   if (url.includes(path)) {
  //     setIsSmallSize(true);
  //   } else {
  //     setIsSmallSize(false);
  //   }
  // });
  const [status, setStatus] = useState("idle");

  const handleClick = () => {
    setStatus("downloading");
  };

  const handleAnimationEnd = () => {
    if (status === "downloading") {
      setStatus("downloaded");
    }
  };

  const handleDownload = () => {
    setStatus("downloading");
    setTimeout(() => {
      downloadPDF();
    }, 700); // Delay of 1 second (1000 milliseconds)
  };

  const color = useSelector((state) => state.open.selectedColor);
  return (
    <div className="cv-designs w-full">
      <div className="cv-designs-desktop   flex  justify-center min-w-full  flex-col ">
        <div className="flex  justify-center mb-[20px]  mt-[20px]  w-full">
          <button
            className={`download-button transform active:scale-95 bg-[#ffb923] hover:bg-[#ffc64a] text-white text-[13px] rounded-lg font-bold tracking-widest h-[50px] w-[200px] ${
              status === "downloading" && "pointer-events-none"
            }`}
            onClick={handleDownload}
          >
            <div className="flex justify-center items-center relative">
              <div className="svg-container">
                {status === "idle" && (
                  <svg
                    className="download-icon"
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="download-arrow"
                      d="M13 9L9 13M9 13L5 9M9 13V1"
                      stroke="#F2F2F2"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17"
                      stroke="#F2F2F2"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                {status === "downloading" && (
                  <div
                    className="download-loader text-white"
                    onAnimationEnd={handleAnimationEnd}
                  ></div>
                )}
                {status === "downloaded" && (
                  <svg
                    className="check-svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.1071 7.9071C15.4976 7.51658 15.4976 6.88341 15.1071 6.49289C14.7165 6.10237 14.0834 6.10237 13.6929 6.49289L8.68568 11.5001L7.10707 9.92146C6.71655 9.53094 6.08338 9.53094 5.69286 9.92146C5.30233 10.312 5.30233 10.9452 5.69286 11.3357L7.97857 13.6214C8.3691 14.0119 9.00226 14.0119 9.39279 13.6214L15.1071 7.9071Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
              <div className="button-copy pl-2 leading-none uppercase">
                {status === "idle" && "DOWNLOAD"}
                {status === "downloading" && "DOWNLOADING"}
                {status === "downloaded" && "DOWNLOADED"}
              </div>
            </div>
          </button>
        </div>

        <div className="flex justify-center overflow-auto  min-w-[210mm]">
          <div className="bg-white actual   mb-[20%]     border overflow- shadow-lg kuuy min-h-[297mm]  min-w-[210mm]">
            {/* <button onClick={downloadPDF}>download</button> */}

            <div className="flex items-center bg-gray-700 min-h-[200px] text-white  max-h-[200px] overflow-hidden  gap-[40px]">
              <Image_data eleven_content_bg_color />

              <div
                style={{
                  fontFamily: `${font_family}`,
                }}
                className="flex flex-col  mr-[20px]    "
              >
                <div className="max-w-[480px]">
                  <Profile_name />
                </div>
                <div
                  style={{
                    lineHeight: `${line_height}`,
                    fontFamily: `${font_family}`,
                    fontSize: `${font_size}`,
                  }}
                  className="text-white  text-[15px] mt-[20px] mb-[30px] ml-[20px]"
                >
                  <div className="flex gap-[10px] ml-[-20px] items-start">
                    {phone ? (
                      <div className="flex gap-2 ">
                        <PhoneIcon />
                        <p className="max-w-[125px]  line-clamp-4 break-words">
                          {phone}
                        </p>
                      </div>
                    ) : null}

                    {address ? (
                      <div className="flex gap-2">
                        <PlaceIcon />
                        <p className="max-w-[120px] line-clamp-4 break-words">
                          {address}
                        </p>
                      </div>
                    ) : null}

                    {/* email */}
                    {email ? (
                      <div className="flex gap-2 items-">
                        <EmailIcon />
                        <p className="max-w-[150px] line-clamp-4  break-words">
                          {email}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {/*mini background div*/}
            <div
              style={{
                lineHeight: `${line_height}`,
                fontFamily: `${font_family}`,
              }}
              className=" flex gap-[30px]"
            >
              <div className="min-h-[100%] text-black w-[250px] ">
                <div className="ml-[20px]">
                  {" "}
                  <div className="mt-[21px]">
                    <Education_data />{" "}
                  </div>
                  <Skills_data list_black />
                  <Languages_data />
                </div>
              </div>
              <div className=" w-[500px] text-[black]  mr-[20px] ">
                <div className="mt-[21px]">
                  <Profile_data />
                </div>
                <Experience_data />
                <Course_data />
              </div>
            </div>
          </div>
        </div>

        <Bottom_nav />
      </div>
      <div
        className="design-icon flex justify-end "
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      >
        <button className="bg-[#ffb923]  h-[100px] w-[100px] fixed bottom-2 right-4 rounded-[50px]">
          <FindInPageIcon style={{ fontSize: "60px", color: "white" }} />
        </button>
      </div>

      <div
        className="offcanvas offcanvas-end cv-designs-phone  flex  min-w-full"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* <button onClick={downloadPDF}>downloadsss</button> */}

        <div className="offcanvas-header  z-50  fixed shadow-lg w-full bg-white ">
          <div className="flex gap-3 items-center r-[-20px]">
            <button
              className={`download-button transform active:scale-95 bg-[#ffb923] hover:bg-[#ffc64a] text-white text-[13px] rounded-lg font-bold tracking-widest h-[50px] w-[200px] ${
                status === "downloading" && "pointer-events-none"
              }`}
              onClick={handleDownload}
            >
              <div className="flex justify-center items-center relative">
                <div className="svg-container">
                  {status === "idle" && (
                    <svg
                      className="download-icon"
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="download-arrow"
                        d="M13 9L9 13M9 13L5 9M9 13V1"
                        stroke="#F2F2F2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17"
                        stroke="#F2F2F2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                  {status === "downloading" && (
                    <div
                      className="download-loader text-white"
                      onAnimationEnd={handleAnimationEnd}
                    ></div>
                  )}
                  {status === "downloaded" && (
                    <svg
                      className="check-svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.1071 7.9071C15.4976 7.51658 15.4976 6.88341 15.1071 6.49289C14.7165 6.10237 14.0834 6.10237 13.6929 6.49289L8.68568 11.5001L7.10707 9.92146C6.71655 9.53094 6.08338 9.53094 5.69286 9.92146C5.30233 10.312 5.30233 10.9452 5.69286 11.3357L7.97857 13.6214C8.3691 14.0119 9.00226 14.0119 9.39279 13.6214L15.1071 7.9071Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                <div className="button-copy pl-2 leading-none uppercase">
                  {status === "idle" && "DOWNLOAD"}
                  {status === "downloading" && "DOWNLOADING"}
                  {status === "downloaded" && "DOWNLOADED"}
                </div>
              </div>
            </button>
            <button
              className="btn-close h-[50px]  text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <p className="font-Manrope text-4xl text-black duration-500 ml-[-50px] z-10 group-hover:scale-0"></p>
            </button>
          </div>
        </div>

        <div className="flex   mt-[-10px] justify-center pt-[100px] ">
          <div className="offcanvas-body min-h-screen min-w-full    flex justify-center  ">
            <div className=" flex flex-col  cv-designs-phone ">
              {/* <button onClick={downloadPDF}>download</button> */}

              <div className="bg-white actual   mb-[20%]     border overflow- shadow-lg kuuy min-h-[297mm]  min-w-[210mm]">
                {/* <button onClick={downloadPDF}>download</button> */}

                <div className="flex items-center bg-gray-700 min-h-[200px] text-white  max-h-[200px] overflow-hidden  gap-[40px]">
                  <Image_data eleven_content_bg_color />

                  <div
                    style={{
                      fontFamily: `${font_family}`,
                    }}
                    className="flex flex-col  mr-[20px]    "
                  >
                    <div className="max-w-[480px]">
                      <Profile_name />
                    </div>
                    <div
                      style={{
                        lineHeight: `${line_height}`,
                        fontFamily: `${font_family}`,
                        fontSize: `${font_size}`,
                      }}
                      className="text-white  text-[15px] mt-[20px] mb-[30px] ml-[20px]"
                    >
                      <div className="flex gap-[20px] ml-[-20px] items-start">
                        {phone ? (
                          <div className="flex gap-2 ">
                            <PhoneIcon />
                            <p className="max-w-[125px]  line-clamp-4 break-words">
                              {phone}
                            </p>
                          </div>
                        ) : null}

                        {address ? (
                          <div className="flex gap-2">
                            <PlaceIcon />
                            <p className="max-w-[120px] line-clamp-4 break-words">
                              {address}
                            </p>
                          </div>
                        ) : null}

                        {/* email */}
                        {email ? (
                          <div className="flex gap-2 items-">
                            <EmailIcon />
                            <p className="max-w-[150px] line-clamp-4  break-words">
                              {email}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/*mini background div*/}
                <div
                  style={{
                    lineHeight: `${line_height}`,
                    fontFamily: `${font_family}`,
                  }}
                  className=" flex gap-[30px]"
                >
                  <div className="min-h-[100%] text-black w-[250px] ">
                    <div className="ml-[20px]">
                      {" "}
                      <div className="mt-[21px]">
                        <Education_data />{" "}
                      </div>
                      <Skills_data list_black />
                      <Languages_data />
                    </div>
                  </div>
                  <div className=" w-[500px] text-[black]  mr-[20px] ">
                    <div className="mt-[21px]">
                      <Profile_data />
                    </div>
                    <Experience_data />
                    <Course_data />
                  </div>
                </div>
              </div>

              <Bottom_nav />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Cv11_content;
