import React, { useEffect, useState } from "react";
import "../../../assets/style/main-pages.css";
import Cleaning from "../../../components/services/cleaning";
import Vehicle_Maintenance from "../../../components/services/Vehicle-Maintenance";
import Electrical from "../../../components/services/electrical";
import Freight_Forwarding from "../../../components/services/Freight_Forwarding";
import Home_ApplianceRepairs from "../../../components/services/Home_ApplianceRepairs";
import Construction_of_houses from "../../../components/services/Construction_of_houses";
import Garden_Maintenance from "../../../components/services/Garden_Maintenance";
import Car_Washing from "../../../components/services/Car_Wash";
import Electronic_Services from "../../../components/services/Electronic_Services";
import Carpet from "../../../components/services/Carpet";
import Post from "../../../components/services/Post";
import Hairdressing from "../../../components/services/Hairdressing";
import Treatments from "../../../components/services/Treatments";
import Makeup from "../../../components/services/Makeup";
import Nail from "../../../components/services/Nail";
import Veterinary from "../../../components/services/Veterinary";
import Mortgage from "../../../components/services/Mortgage";
import LegalConsultation from "../../../components/services/LegalConsultation";
import Photography from "../../../components/services/Photography";
import Tire_Replacement from "../../../components/services/Tire_Replacement";
import Battery_Services from "../../../components/services/Battery_Services";
import HomeFooter from "../../../sub component/home/footer";
import '../../../assets/style/services.css'
import Travel from "../../../components/services/travel";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";

const Main_services = () => {
  useEffect(() => {
    // Scroll to top when component is mounted
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  return (
    <>
    <Helmet>
  {/* Basic Meta Tags */}
  <title>Services - Karbein</title>
  <meta 
    name="description" 
    content="Discover a wide range of services available on Karbein, from car wash and carpet cleaning to specialized services tailored to your needs. Explore and find the best service providers for all your requirements." 
  />
 <meta name="keywords" content="Cleaning the House, Vehicle Maintenance, Electrical Repair, Home Appliance Repairs, Construction of Houses, Car Wash, Carpet Cleaning, Post-Construction Cleaning, Skincare Treatments, Hairdressing, Photography, Videography, Tire Replacement, Battery Services, Garden Maintenance, Makeup, Nail Care, Veterinary Services, Legal Consultation, Freight Forwarding, Electronic Services, Travel Tourism, professional services, Karbein"/>

  <meta name="robots" content="index, follow" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  {/* Open Graph Tags */}
  <meta property="og:title" content="Services - Karbein" />
  <meta 
    property="og:description" 
    content="Explore a diverse range of services on Karbein. From everyday needs like car wash and carpet cleaning to unique services, find what you need and connect with top service providers." 
  />
  <meta property="og:image" content="https://karbein.com/images/Brand-photos.png" />
  <meta property="og:url" content="https://karbein.com/services" />
  <meta property="og:type" content="website" />
  <meta property="og:site_name" content="Karbein" />

  {/* Twitter Card Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Services - Karbein" />
  <meta 
    name="twitter:description" 
    content="Find a comprehensive list of services on Karbein. Whether you need car wash, carpet cleaning, or other specialized services, we’ve got you covered with top providers." 
  />
  <meta name="twitter:image" content="https://karbein.com/images/Brand-photos.png" />
  <meta name="twitter:url" content="https://karbein.com/services" />

  {/* Canonical Tag */}
  <link rel="canonical" href="https://karbein.com/services" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Services",
        "description": "Explore a diverse range of services on Karbein. Find car wash, carpet cleaning, and more with top service providers.",
        "image": "https://karbein.com/images/Brand-photos.png",
        "url": "https://karbein.com/services",
        "publisher": {
          "@type": "Organization",
          "name": "Karbein",
          "logo": {
            "@type": "ImageObject",
            "url": "https://karbein.com/images/Brand-photos.png"
          }
        }
      }
    `}
  </script>
</Helmet>

{

  loading?
  <Loading/>
  :
  <div className="min-w-[100%]">
  <div className="flex min-w-[100%] justify-between flex-col min-h-screen">

    <div className="flex justify-center min-w-[100%] pt-[100px]">
      <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container min-w-[100%] flex justify-center gap-[40px]">
        <div className="flex flex-col min-w-[100%]">
          <div className="grid grid-cols-1 services-grid sm:grid-cols-4 gap-4 min-w-[100%] p-6">
            <Cleaning />
            <Vehicle_Maintenance />
            <Electrical />
            <Freight_Forwarding />
            <Home_ApplianceRepairs />
            <Construction_of_houses />
            <Garden_Maintenance />
            <Car_Washing />
            <Electronic_Services />
            <Carpet />
            <Post />
            <Hairdressing />
            <Treatments />
            <Makeup /> 
            <Nail />
            <Veterinary />
            <LegalConsultation />
            <Photography />
            <Tire_Replacement />
            <Battery_Services />
            <Travel />
          </div>
        </div>
      </div>
    </div>
    <div className="max-w-full">
    <HomeFooter/>

    </div>
  </div>
</div>
}
    
    </>
  );
};

export default Main_services;
