import { createStore, combineReducers, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { education_reducer } from '../reducer/education-reducer';
import { profile_reducer } from '../reducer/profile-reducer';
import { open_reducer } from '../reducer/open-reducer';
import { mainReducer } from '../reducer/contact-reducer';
import { property_reducer } from '../reducer/property-reducer';
import { cv_reducer } from '../reducer/cv_reducer';
import { auth_reducer } from '../reducer/auth-reducer';
import { freelancer_reducer } from '../reducer/freelancer-reducer';
import { job_reducer } from '../reducer/job-reducer';
import { services_reducer } from '../reducer/services-reducer';
import { lecturers_reducer } from '../reducer/lecturers-reducer';

const rootReducer = combineReducers({
education:education_reducer,
profile:profile_reducer,
open:open_reducer,
main:mainReducer,
property:property_reducer,
cv:cv_reducer,
auth:auth_reducer,
freelancer:freelancer_reducer,
job:job_reducer,
services:services_reducer,
lecturers:lecturers_reducer

});

export const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
