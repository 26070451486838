import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Verify_email = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className=' bg-white h-screen w-full fixed z-50' >
      <main>
        <div className="max-w-screen-xl mx-auto px-4 flex items-center justify-start h-screen md:px-8">
          <div className="max-w-lg mx-auto space-y-3 text-center">
            <h3 className="text-[#ffb923] main-freeLancer-container-text font-semibold error-text">
              {t("Verify_Email")}{" "}
            </h3>
            <p className="error-text text-gray-800     text-3xl font-semibold sm:text-4xl">
              {t("Email_Verification")}
            </p>
            <p className="text-gray-600 error-text  main-freeLancer-container-text">{t("Verify_your_Email")}</p>
            <div className=" error-text flex flex-wrap items-center justify-center gap-3">
              <Link
                to={"/Verify-email"}
                className="  main-freeLancer-container-text block py-2 px-4 text-white font-medium bg-[#ffb923] duration-150 hover:bg-[#ffc64a] active:bg-[#ffb923] rounded-lg"
              >
                {t("Verify_Email")}{" "}
              </Link>
              <Link
                to={"/"}
                className=" main-freeLancer-container-text  error-textblock py-2 px-4 text-gray-700 hover:bg-gray-50 hover:text-[#ffb923] font-medium duration-150 active:bg-[#ffe1a0] border rounded-lg"
              >
                {t("Home")}{" "}
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Verify_email;
