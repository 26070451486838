import { CheckIcon } from "@mantine/core";
import { Alert } from "@mui/material";
import axios from '../../axiosConfig';
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
console.error = function() {};
console.warn = function() {};
//login
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR";

export const login = (email, password, navigate, t) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/login`,
        { email, password },
        {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true,
        }
      );

      const token = response.data.token;
      Cookies.set("jwt", token, { expires: 7 });

      dispatch({ type: LOGIN, payload: { user: response.data.user, token } });
      toast.success(t("login_successful"));
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          errorMessage = error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }
      dispatch({ type: LOGIN_ERROR, payload: { errors: errorMessage } });
    }
  };
};
//register
export const REGISTER = "REGISTER";

export const register = (
  name,
  email,
  password,
  password_confirmation,
  navigate,
  t
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/register`,
        {
          name,
          email,
          password,
          password_confirmation,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const message = response.status === 200 ? "registered successfully" : "";
      dispatch({
        type: REGISTER,
        payload: { ...response, message },
      });
      toast.success(t("Created_successfully")); // Using translation function passed as parameter
      setTimeout(() => {
        navigate("/login"); // Navigate to /home after the timeout
      }, 2000);
    } catch (error) {
      let errorMessage = {}
      if (error.response) {
        // Backend validation errors
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          // Custom errors from Laravel (email not found or invalid password)
          errorMessage =
            error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errors: errorMessage,
        },
      });
    }
  };
};
//get user
export const USER = "USER";
export const user = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(`REACT_APP_URL/api/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: USER, payload: { data, total_pages } });
    } catch (error) {
    }
  };
};

export const VERIFY_EMAIL = "VERIFY_EMAIL";

export const verify_email = (email, token, navigate, t) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
`        ${process.env.REACT_APP_URL}/api/verify-email
`        ,
        {
          email,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const message = response.status === 200 ? "registered successfully" : "";
      dispatch({
        type: VERIFY_EMAIL,
        payload: { ...response, message },
      });
      toast.success(t("verified_successfully")); // Using translation function passed as parameter
      setTimeout(() => {
        window.location.reload(); // Refresh the current page
        navigate(-1); // Navigate to the previous page
    }, 2000);
    
    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        // Backend validation errors
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          // Custom errors from Laravel (email not found or invalid password)
          errorMessage =
            error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      console.error("Verification error:", errorMessage); // Add this line

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errors: t("Invalid_verification"),
        },
      });
    }
  };
};

export const RESEND_VERIFY_EMAIL = "VERIFY_EMAIL";

export const resend_verify_email = (email, navigate, t) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
       ` ${process.env.REACT_APP_URL}/api/resend-verification-email`,
        {
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const message = response.status === 200 ? "registered successfully" : "";
      dispatch({
        type: RESEND_VERIFY_EMAIL,
        payload: { ...response, message },
      });
      toast.success(t("Resended_code")); // Using translation function passed as parameter
      // setTimeout(() => {
      //   navigate("/"); // Navigate to /home after the timeout
      // }, 2000);
    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        // Backend validation errors
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          // Custom errors from Laravel (email not found or invalid password)
          errorMessage =
            error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      dispatch({
        type: LOGIN_ERROR,
        payload: {},
      });
    }
  };
};
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

export const forgot_password = (email, navigate, t) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/forgot-password`,
        {
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      const token = response.data.token;
      Cookies.set("jwt", token, { expires: 1 });

      dispatch({
        type: FORGOT_PASSWORD,
        payload: {
          user: response.data.user,
          token,
        },
      });
      toast.success(t("Resended_code")); // Using translation function passed as parameter

    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        // Backend validation errors
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          // Custom errors from Laravel (email not found or invalid password)
          errorMessage =
            error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errors: errorMessage,
        },
      });
    }
  };
};
export const RESET_PASSWORD_CODE = "RESET_PASSWORD";

export const reset_password_code = (email, token, navigate, t) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/reset-password-code`,
        {
          email,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const message = response.status === 200 ? "registered successfully" : "";
      dispatch({
        type: RESET_PASSWORD_CODE,
        payload: { ...response, message },
      });
      toast.success(t("verified_successfully")); // Using translation function passed as parameter
      setTimeout(() => {
        navigate("/Reset-Password", { state: { token: token, email: email } }); // Navigate to /home after the timeout
      }, 2000);
    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        // Backend validation errors
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors;
        } else if (error.response.status === 401) {
          // Custom errors from Laravel (email not found or invalid password)
          errorMessage =
            error.response.data.message || "Invalid email or password";
        } else {
          errorMessage = error.response.data.message || errorMessage;
        }
      }

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errors: t("Invalid_verification"),
        },
      });
    }
  };
};

export const RESET_PASSWORD = "RESET_PASSWORD";
export const reset_password = (
  email,
  token,
  password,
  password_confirmation,
  navigate,
  t
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
`          ${process.env.REACT_APP_URL}/api/reset-password
`        
        ,
        {
          email,
          token,
          password,
          password_confirmation,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      const message =
        response.status === 200 ? "Password reset successfully" : "";
      dispatch({
        type: RESET_PASSWORD,
        payload: { response, message },
      });
      toast.success(t("Password_reset_successful"));
      // Optionally navigate after successful password reset
      setTimeout(() => {
        navigate("/login"); // Navigate to /home after the timeout
      }, 2000);
    } catch (error) {
      let errorMessage = "An error occurred";

      if (error.response) {
        if (error.response.status === 422) {
          errorMessage = error.response.data.errors; // Validation errors from backend
        } else {
          errorMessage = error.response.data.message || errorMessage; // Other backend errors
        }
      } else {
        errorMessage = error.message || errorMessage; // Network or other errors
      }

      // Dispatch error in a consistent format
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errors: { password: errorMessage }, // Ensure errors are in expected object format
        },
      });
    }
  };
};

//addmin panel

export const USER_GET = "USER_GET";
export const user_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/users/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: USER_GET, payload: { data , total_pages} });
    } catch (error) {
      dispatch({ type: USER_GET, payload: error });
    }
  };
};
export const USER_SEARCH = "USER_SEARCH";
export const user_search = (name) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/users/get?name=${name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: USER_SEARCH, payload: { data,total_pages } });
    } catch (error) {
      dispatch({ type: USER_SEARCH, payload: error });
    }
  };
};


export const USER_PAGE = "USER_PAGE";
export const user_page = (page,name) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/users/get?page=${page}&name=${name}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: USER_PAGE, payload: { data } });
    } catch (error) {
      dispatch({ type: USER_PAGE, payload: error });
    }
  };
};



export const USER_UPDATE = "USER_UPDATE";

export const user_update = (
  id,
  name,
  email,
  role,
  plan_type,
  subscription_end_date,
  t
) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
      ` ${process.env.REACT_APP_URL}/api/users/update/${id}`,
        {
          name,
          email,
          role,
          plan_type,
          subscription_end_date,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      dispatch({
        type: USER_UPDATE,
        payload: { ...response },
      });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter
   
    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter

    }
  };
};
export const NAME_SEARCHVALUE = "NAME_SEARCHVALUE";
export const name_search_value = (search) => {
  return {
    type: NAME_SEARCHVALUE,
    search: search,
  };
};


export const USER_DELETE = "USER_DELETE";
export const user_delete = (
  id,

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/users/delete/${id}`,
{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: USER_DELETE, payload: { response, status } });
    //   setTimeout(() => {
    //     navigate("/services/Profile"); // Navigate to /home after the timeout
    // }, 2000); 
    } catch (error) {
    }
  };
};

//payment gateweay if user paid or not its main if user actived or not
export const USER_PAYMENT = "USER_PAYMENT";
export const user_payment = (

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/user/subscription-status`,
{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const data = response.data.is_active;

      dispatch({ type: USER_PAYMENT, payload: { data } });
    //   setTimeout(() => {
    //     navigate("/services/Profile"); // Navigate to /home after the timeout
    // }, 2000); 
    } catch (error) {
    }
  };
};


//payment actions 

//refound
export const REFOUND = "REFOUND";
export const refound = (
  paymentId

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/payment/refund/${paymentId}`,
{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: REFOUND, payload: { response, status } });
      toast.success("Refunded Successfuly"); // Using translation function passed as parameter

    } catch (error) {
      toast.error('Refund faild'); // Using translation function passed as parameter

    }
  };
};
//refound

export const ORDER_GET = "ORDER_GET";
export const order_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/order/get`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: ORDER_GET, payload: { data , total_pages} });
    } catch (error) {
      dispatch({ type: ORDER_GET, payload: error });
    }
  };
};
export const ORDER_SEARCH = "ORDER_SEARCH";
export const order_search = (user_name,user_email,statusData,plan_type,created_at) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/order/get?user_name=${user_name}&user_email=${user_email}&status=${statusData}&plan_type=${plan_type}&created_at=${created_at}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: ORDER_SEARCH, payload: { data , total_pages} });
    } catch (error) {
      dispatch({ type: ORDER_SEARCH, payload: error });
    }
  };
};
export const ORDER_PAGE = "ORDER_PAGE";
export const order_page = (page,user_name,user_email,plan_type,statusData,created_at) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/order/get?page=${page}&user_name=${user_name}&user_email=${user_email}&status=${statusData}&plan_type=${plan_type}&created_at=${created_at}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: ORDER_PAGE, payload: { data } });
    } catch (error) {
      dispatch({ type: ORDER_PAGE, payload: error });
    }
  };
};
//search parameter for order searchs
export const USERNAME_SEARCHVALUE = "NAME_SEARCHVALUE";
export const user_name_search_value = (search) => {
  return {
    type: USERNAME_SEARCHVALUE,
    search: search,
  };
};


export const EMAIL_SEARCHVALUE = "EMAIL_SEARCHVALUE";
export const email_search_value = (search) => {
  return {
    type: EMAIL_SEARCHVALUE,
    search: search,
  };
};


export const PLAN_TYPE__SEARCHVALUE = "PLAN_TYPE__SEARCHVALUE";
export const Plan_Type_search_value = (search) => {
  return {
    type: PLAN_TYPE__SEARCHVALUE,
    search: search,
  };
};
export const STATUS__SEARCHVALUE = "STATUS__SEARCHVALUE";
export const status_search_value = (search) => {
  return {
    type: STATUS__SEARCHVALUE,
    search: search,
  };
};

export const CRETAED_AT__SEARCHVALUE = "CRETAED_AT__SEARCHVALUE";
export const created_at_search_value = (search) => {
  return {
    type: CRETAED_AT__SEARCHVALUE,
    search: search,
  };
};