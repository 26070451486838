import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Use_course_hook from "../../hooks/show-design data-hook/course-hook";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Course_data = ({ third_content_bg_color, ten_content_bg_color }) => {
  const {
    course_title,
    course_year,
    course_description,
    font_size,
    combine_courses,
  } = Use_course_hook();

  const bg_header_color = useSelector((state) => state.open.selectedColor);
  const [isSmallSize, setIsSmallSize] = useState(false);

  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  });
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
      
        className={
          isSmallSize ? "text-[10px] max-w-[140px] main-w-[140px]  mt-[-10px]" : "flex flex-col mt-[0px] "
        }
        style={{       fontSize: `${font_size}` }
        
        }
      >
        {course_title.length ||
        course_year.length ||
        course_description.length ? (
          <div
            className={
              third_content_bg_color || ten_content_bg_color
                ? "text-center"
                : null
            }
          >
            {" "}
            <p
              className={
                third_content_bg_color
                  ? `mb-[10px]  border-[2px] font-bold text-white rounded-[20px]`
                  : isSmallSize
                  ? "text-[10px] mb-[-8px] font-bold"
                  : ten_content_bg_color
                  ? " mb-[20px] border-[2px] font-bold text-white "
                  : "bg-color rounded-[20px] font-bold"
              }
              // `1px solid ${bg_header_color}`
              style={{
                backgroundColor:
                  third_content_bg_color || ten_content_bg_color
                    ? ` ${bg_header_color}`
                    : null,
                third_content_bg_color,
              }}
            >
              {" "}
              
              Course
            </p>{" "}
            <hr
              className={`my-[10px] ${
                third_content_bg_color || ten_content_bg_color ? "d-none" 
:
                          isSmallSize ? "min-w-[140px]" :null
              }`}
            />{" "}
          </div>
        ) : null}
        {combine_courses(course_title, course_year, course_description).map(
          (combined, index) => (
            <div key={index} className="flex flex-col  gap-2">
              <div className="flex justify-between  gap-2">
                <div className="max-w-[90%] break-words truncate font-bold  ">
                  {combined.course_title}{" "}
                </div>

                <p className="max-w-[80px] break-words	">
                  {combined.course_year}
                </p>
              </div>
              <p
                className={
                  isSmallSize
                    ? " max-w-[80px] break-words truncate "
                    : "max-w-[500px] break-words	"
                }
              >
                {combined.course_description}
              </p>
              <p
                className={
                  isSmallSize
                    ? " max-w-[80px] break-words truncate "
                    : "max-w-[500px] break-words	"
                }
              >
                {combined.experience_description}
              </p>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Course_data;
