import React, { useCallback, useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import "rsuite/Accordion/styles/index.css";
import "rsuite/Input/styles/index.css";
import { styled } from "@mui/material/styles";

import LocationOnIcon from "@mui/icons-material/LocationOnOutlined"; // (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/Input/styles/index.css";
import "rsuite/InputGroup/styles/index.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { useDispatch, useSelector } from "react-redux";
import TuneIcon from "@mui/icons-material/Tune";

import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import { Country, State, City } from "country-state-city";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import brand_image from "../../../assets/image/Brand-photos.png";

import Cookies from "js-cookie";
import {
  get_primary,
  getSearch_primary,
  language_search_value,
  lecturers_delete,
  lecturers_get,
  search_primary,
  study_mode_search_value,
  subject_search_value,
} from "../../../redux/action/lecturers-action";
import {
  city_search_value,
  country_search_value,
  state_search_value,
} from "../../../redux/action/services-action";
import Search_loading from "../../main-pages/search loading/search-loading";
import Loading from "../../../sub component/show design data/loading";
import Primary_Pagination from "../../main-pages/Lecturers/Primary/primary-page";
import { user_action } from "../../../redux/action/cv-action";
import HomeFooter from "../../../sub component/home/footer";
import Swal from "sweetalert2";
import Admin_nav from "../../../app bar/admin-nav";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@rsuite/icons/Search";
import { name_search_value } from "../../../redux/action/auth-action";
import Error404 from "../../error page/error404";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Admin_Primary = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState("");
  const [Subject, setSubject] = useState("");
  const [Study_mode, setStudy_mode] = useState("");
  const [searchData, setSearchData] = useState([]);

  const [ShowFilterBar, setShowFilterBar] = useState();

  const [nameSearch_data, setNameSearch_data] = useState([]);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const handleCheckboxChange = (value) => {
    if (selectedSubject === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedSubject("");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedSubject(value);
    }
  };

  const subjects = [
    { value: "Kurdish", label: "Kurdish" },
    { value: "Arabic", label: "Arabic" },
    { value: "English", label: "English" },
    { value: "math", label: "math" },
    { value: "science", label: "science" },
    { value: "chemistry", label: "chemistry" },
    { value: "Physics", label: "Physics" },
    { value: "Biology", label: "Biology" },
    { value: "computer", label: "computer" },
    { value: "economy", label: "economy" },
  ];

  const languages = [
    { value: "Kurdish", label: "Kurdish" },
    { value: "Arabic", label: "Arabic" },
    { value: "English", label: "English" },
  ];

  const modes = [
    { value: "offline", label: "offline" },
    { value: "online", label: "online" },
  ];

  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState(null);
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const primary_search_get =
    useSelector((state) => state.lecturers.primary_search_get) || "";

  const Data = useSelector((state) => state.lecturers.primary_get) || "";
  const search = useSelector((state) => state.lecturers.search) || "";
  const page = useSelector((state) => state.lecturers.page) || "";
  const second_job_page = useSelector((state) => state.job.second_job_page);
  useEffect(() => {
    setData(Data);
  }, [Data]);

  useEffect(() => {
    setSearchData(primary_search_get);
  }, [primary_search_get]);

  useEffect(() => {
    setData(search);
  }, [search]);
  useEffect(() => {
    setData(page);
  }, [page]);
  useEffect(() => {
    setData(second_job_page);
  }, [second_job_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      await dispatch(get_primary());
      await dispatch(getSearch_primary());
      await dispatch(lecturers_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const [searchLoading, setSearchLoading] = useState(false);
  //search by name
  const nameSearch = async () => {
    setSearchLoading(true);
    await dispatch(
      search_primary(
        country,
        state,
        selectedCity,
        selectedSubject,
        selectedMode,
        selectedLanguage,
        nameSearch_data
      )
    );
    setSearchLoading(false);

    dispatch(name_search_value(nameSearch_data));
  };
  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(
        search_primary(
          country,
          state,
          selectedCity,
          selectedSubject,
          selectedMode,
          selectedLanguage,
          nameSearch_data
        )
      );
      setSearchLoading(false);
      dispatch(country_search_value(country));
      dispatch(city_search_value(selectedCity));
      dispatch(state_search_value(state));
      dispatch(subject_search_value(selectedSubject));
      dispatch(study_mode_search_value(selectedMode));
      dispatch(language_search_value(selectedLanguage));
      dispatch(name_search_value(nameSearch_data));
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (
      country !== "" ||
      state !== "" ||
      selectedCity !== "" ||
      selectedSubject !== "" ||
      selectedMode !== "" ||
      selectedLanguage !== ""
    ) {
      Search();
    }
  }, [
    country,
    state,
    selectedCity,
    selectedSubject,
    selectedMode,
    selectedLanguage,
    dispatch,
  ]);

  const searchPerformed = useSelector(
    (state) => state.services.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const navigate = useNavigate();

  const user_id = useSelector((state) => state.cv.user.id);
  const services_data = useSelector((state) => state.lecturers.lecturer_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  useEffect(() => {
    setLecturerData(services_data);
  }, [services_data]);

  const user = lecturerData.find((user) => user.user_id === `${user_id}`);
  // bo mawaya agar dubara habu neshan nadre
  const shownSubject = new Set();
  const shownPhoneSubject = new Set();

  const showncountry = new Set();
  const shownPhonecountry = new Set();

  const shownState = new Set();
  const shownPhoneState = new Set();

  const shownCity = new Set();
  const shownPhoneCity = new Set();

  const shownStudy_mode = new Set();
  const shownPhoneStudy_mode = new Set();

  const shownLanguage = new Set();
  const shownPhoneLanguage = new Set();

  const subjectCount = searchData.reduce((acc, item) => {
    acc[item.Subject] = (acc[item.Subject] || 0) + 1;
    return acc;
  }, {});

  const countryCount = searchData.reduce((acc, item) => {
    acc[item.country] = (acc[item.country] || 0) + 1;
    return acc;
  }, {});

  const study_modeCount = searchData.reduce((acc, item) => {
    acc[item.study_mode] = (acc[item.study_mode] || 0) + 1;
    return acc;
  }, {});

  const languageCount = searchData.reduce((acc, item) => {
    acc[item.language] = (acc[item.language] || 0) + 1;
    return acc;
  }, {});

  const StateCount = searchData.reduce((acc, item) => {
    acc[item.state] = (acc[item.state] || 0) + 1;
    return acc;
  }, {});
  const CityCount = searchData.reduce((acc, item) => {
    acc[item.city] = (acc[item.city] || 0) + 1;
    return acc;
  }, {});

  const handleSubjectChange = (value) => {
    if (selectedSubject === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedSubject(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedSubject(value);
    }
  };

  const handleLanguageChange = (value) => {
    if (selectedLanguage === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedLanguage(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedLanguage(value);
    }
  };

  const handleModeChange = (value) => {
    if (selectedMode === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedMode(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedMode(value);
    }
  };
  const handleCountryChange = (value) => {
    if (country === value) {
      // If the same checkbox is clicked again, uncheck it
      setCountry(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setCountry(value);
    }
  };
  const handleStateChange = (value) => {
    if (state === value) {
      // If the same checkbox is clicked again, uncheck it
      setState(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setState(value);
    }
  };

  const handleCityChange = (value) => {
    if (selectedCity === value) {
      // If the same checkbox is clicked again, uncheck it
      setSelectedCity(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setSelectedCity(value);
    }
  };
  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(lecturers_delete(id));

          if (search.length <= 1) {
            await dispatch(search_primary(""));
          } else {
            await dispatch(
              search_primary(
                country,
                state,
                selectedCity,
                selectedSubject,
                selectedMode,
                selectedLanguage,
                nameSearch_data
              )
            );
          }

          await dispatch(getSearch_primary());

          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
      }
    });
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
      {loading ? null : <Admin_nav />}
      <div className="flex flex-col flex-1 overflow-y-auto ">
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div className="">
              {searchLoading ? (
                <Search_loading />
              ) : (
                <div className="pt-[100px] px-[2%]">
                  {/* phone searchbar */}
                  <div className="left-details-   ">
                    <form
                      onSubmit={nameSearch}
                      className="flex items-center max-w-[260px] mx-auto"
                      >
                      <label className="sr-only">Search</label>
                      <div dir="rtl" className="relative ">
                        <button
                          type="submit"
                          className="max-w-[40px]  justify-center   absolute inset-y-0  start-0 flex items-center "
                          style={{
                            marginRight:
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "0px"
                                : "0px",
                            marginLeft:
                              i18n.language === "ku" || i18n.language === "ar"
                                ? "0px"
                                : "10px",
                          }}
                        >
                          <SearchIcon />
                        </button>
                        <input
                          type="text"
                          id="simple-search"
                          className=" input_add_freelancer  w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder={t("Name")}
                          required
                          value={nameSearch_data}
                          onChange={(e) => {
                            setNameSearch_data(e.target.value);
                          }}
                        />
                      </div>

                      <button
                        type="button"
                        onClick={toggleFilter} // Toggle visibility on click
                        className="p-[6px] ms-2 text-sm font-medium text-white bg-[#ffb923] rounded-lg hover:bg-[#ffc64a] dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        <TuneIcon className="text-white h-4 w-4" />
                        <span className="sr-only">Search</span>
                      </button>
                    </form>

                    {showFilter && ( // Conditional rendering of the div
                      <div className="filter-options mt-4 p-2 border rounded-lg  dark:bg-gray-800">
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChange("panel2")}
                          className="bg-gray-100"
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("Subject")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhoneSubject.has(data.Subject)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhoneSubject.add(data.Subject); // Add company name to set

                                return (
                                  <div
                                    key={data.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedSubject === data.Subject}
                                      onChange={() =>
                                        handleSubjectChange(data.Subject)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.Subject == "economy"
                                        ? t("economy")
                                        : data.Subject == "Kurdish"
                                        ? t("Kurdish")
                                        : data.Subject == "Arabic"
                                        ? t("Arabic")
                                        : data.Subject == "English"
                                        ? t("English")
                                        : data.Subject == "math"
                                        ? t("math")
                                        : data.Subject == "science"
                                        ? t("science")
                                        : data.Subject == "chemistry"
                                        ? t("chemistry")
                                        : data.Subject == "computer"
                                        ? t("computer")
                                        : data.Subject == "Biology"
                                        ? t("Biology")
                                        : data.Subject == "Physics"
                                        ? t("Physics")
                                        : null}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {subjectCount[data.Subject]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>{" "}
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel22"}
                          onChange={handleChange("panel22")}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("Country")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhonecountry.has(data.country)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhonecountry.add(data.country); // Add company name to set

                                return (
                                  <div
                                    key={`country-${data.id}`}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={country === data.country}
                                      onChange={() =>
                                        handleCountryChange(data.country)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.country}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {countryCount[data.country]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChange("panel3")}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("State")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhoneState.has(data.state)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhoneState.add(data.state); // Add company name to set

                                return (
                                  <div
                                    key={data.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={state === data.state}
                                      onChange={() =>
                                        handleStateChange(data.state)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.state}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {StateCount[data.state]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel4"}
                          onChange={handleChange("panel4")}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("City")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhoneCity.has(data.city)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhoneCity.add(data.city); // Add company name to set

                                return (
                                  <div
                                    key={data.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedCity === data.city}
                                      onChange={() =>
                                        handleCityChange(data.city)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.city}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {CityCount[data.city]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel5"}
                          onChange={handleChange("panel5")}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("language")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhoneLanguage.has(data.language)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhoneLanguage.add(data.language); // Add company name to set

                                return (
                                  <div
                                    key={data.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedLanguage === data.language
                                      }
                                      onChange={() =>
                                        handleLanguageChange(data.language)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.language == "English"
                                        ? t("English")
                                        : data.language == "Kurdish"
                                        ? t("Kurdish")
                                        : data.language == "Arabic"
                                        ? t("Arabic")
                                        : null}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {languageCount[data.language]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel6"}
                          onChange={handleChange("panel6")}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography>{t("study_mode")}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="flex flex-col min-w-full overflow-x-auto  gap-[30px]">
                              {searchData.map((data) => {
                                if (shownPhoneStudy_mode.has(data.study_mode)) {
                                  return null; // Skip rendering if company name is already shown
                                }
                                shownPhoneStudy_mode.add(data.study_mode); // Add company name to set

                                return (
                                  <div
                                    key={data.id}
                                    className="flex gap-2 items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={selectedMode === data.study_mode}
                                      onChange={() =>
                                        handleModeChange(data.study_mode)
                                      }
                                    />
                                    <div className="flex gap-3 justify-between w-full items-center">
                                      {data.study_mode}
                                      <h1
                                        style={{
                                          marginLeft:
                                            i18n.language === "ku" ||
                                            i18n.language === "ar"
                                              ? "10px "
                                              : null,
                                        }}
                                        className="bg-gray-100 text-gray-600 mr-[10px] h-[25px] w-[25px] flex justify-center items-center p-2 rounded-[5px] "
                                      >
                                        {study_modeCount[data.study_mode]}
                                      </h1>
                                    </div>{" "}
                                  </div>
                                );
                              })}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </div>
                  <div className="text-gray-900 flex flex-col justify-center">
                    {/* Add other search options here */}

                    <div className="w-full overflow-x-auto mb-[50px]">
                      <div className="pb-3 flex justify-center"></div>
                      <table className="min-w-full bg-gray-50 border rounded-lg  overflow-hidden">
                        <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <tr>
                            <th className="py-3 px-6 text-left">id</th>
                            <th className="py-3 px-6 text-left"> image</th>

                            <th className="py-3 px-6 text-left"> name</th>
                            <th className="py-3 px-6 text-left"> Email</th>
                            <th className="py-3 px-6 text-left">phone</th>
                            <th className="py-3 px-6 text-left">language</th>
                            <th className="py-3 px-6 text-left">duration</th>
                            <th className="py-3 px-6 text-left">price</th>
                            <th className="py-3 px-6 text-left">study mode</th>
                            <th className="py-3 px-6 text-left">
                              years teaching
                            </th>
                            <th className="py-3 px-6 text-left">grade level</th>
                            <th className="py-3 px-6 text-left">Subject</th>
                            <th className="py-3 px-6 text-left">
                              Certifications
                            </th>
                            <th className="py-3 px-6 text-left">
                              Edit
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                          {data.map((data, index) => (
                            <tr
                              key={index}
                              className="border-b hover:bg-gray-100"
                            >
                              <td className="py-3 px-6 text-left">{data.id}</td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  <img
                                    src={data.image ? data.image : brand_image}
                                    alt=""
                                    className=" min-h-[70px]  max-h-[70px] p-1 rounded-[10px] min-w-[70px] max-w-[70px]"        
                                  />
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">{data.name}</span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.email}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.phone}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.language}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.duration}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.price} {data.currency} 
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.study_mode}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.numberOf_years_teaching}
                                </span>
                              </td>{" "}
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.grade_level}
                                </span>
                              </td>{" "}
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.Subject}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                <span className="font-medium">
                                  {data.Certifications}
                                </span>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                              <div className="flex">
<Link to={`/Admin-panel/Lecturer/update/${data.id}`}>
<button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>

</Link>                               <button onClick={()=>
  handleDelete(data.id)
} type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Delete</button>

                               </div>    
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {data.length === 0 && (
                          <div className="search-message my-[30px] flex justify-center w-full text-center items-center h-[100%] main-freeLancer-container-text">
                            {t("not_haveData")}
                          </div>
                        )}

                        
                    </div>
                  </div>
                  <div className="flex justify-center w-full mb-[50px]">
                    
                  {
          data.length>0?
<Primary_Pagination />          :null

        }                    
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Admin_Primary;
