import React, { useEffect, useRef, useState } from "react";
import "../assets/style/auth.css";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  register,
  resend_verify_email,
  verify_email,
} from "../redux/action/auth-action";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { user_action } from "../redux/action/cv-action";
import Loading from "../../src/sub component/show design data/loading";

const Verify_email = () => {
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const token = useSelector((state) => state.cv.isAuthuntucated);
  const errors = useSelector((state) => state.auth.errors);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [codes, setCodes] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);

  useEffect(() => {
    inputsRef.current[0]?.focus();
  }, []);

  const handleKeyDown = (e, index) => {
    if (
      !/^[0-9]$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }

    if (e.key === "Delete" || e.key === "Backspace") {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
        setCodes((prevCodes) => {
          const newCodes = [...prevCodes];
          newCodes[index - 1] = "";
          return newCodes;
        });
      }
    }
  };

  const handleInput = (e, index) => {
    const { value } = e.target;
    setCodes((prevCodes) => {
      const newCodes = [...prevCodes];
      newCodes[index] = value;
      const fullCode = newCodes.join("");
      setCode(fullCode); // Set the full code to the code state
      return newCodes;
    });

    if (value && index < codes.length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!/^[0-9]{4}$/.test(text)) {
      return;
    }
    const digits = text.split("");
    setCodes(digits);
    inputsRef.current.forEach((input, index) => {
      if (index < digits.length) {
        input.value = digits[index];
      }
    });
    inputsRef.current[digits.length]?.focus();
  };
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }
  const email = useSelector((state) => state.cv.user.email);
  const email_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  if (email_verified != null) {
    navigate("/");
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  const [hasDispatched, setHasDispatched] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
        if (!hasDispatched) {
          setHasDispatched(true);
          await dispatch(verify_email(email, code, navigate, t));
          setTimeout(() => {
            setHasDispatched(false);
          }, 5000);
        }
  };

  const handleReset = async (e) => {
    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(resend_verify_email(email, navigate, t));
      setTimeout(() => {
        setHasDispatched(false);
      }, 6000);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        token?
        <div
          style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}
          className="flex auth-container justify-center h-screen items-center  pt-[80px] "
        >
          <div className="text-center  w-[600px] h-[400px] bg-white mx-[20px] px-4 sm:px-8 py-10 rounded-xl shadow">
            <header className="mb-8">
              <h1 className="text-2xl font-bold mb-1">
                {t("Email_Verification")}{" "}
              </h1>
              <p className="text-xs text-slate-500">{t("verify_header")}</p>
            </header>
            <form id="otp-form" className="otp-form" onSubmit={handleSubmit}>
              <div className="flex items-center justify-center gap-3">
                {Array.from({ length: 4 }, (_, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputsRef.current[index] = el)}
                    type="text"
                    className="otp-input w-[70px] h-[70px] text-center text-2xl font-extrabold text-slate-900 bg-gray-100 border border-transparent hover:border-[#ffb923]/5 appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#ffb923]/5 focus:ring-2 focus:ring-[#ffb9232c]"
                    maxLength="1"
                    value={codes[index]}
                    onChange={(e) => handleInput(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    onPaste={handlePaste}
                    pattern="\d*"
                    required
                  />
                ))}
              </div>
              {errors ? (
                <div className="mx-[5px] pt-[10px]">
                  {errors && errors && (
                    <div className="text-red-500">
                      
                      {errors?
                      t("Invalid_verification")
                      :null
                    }</div>
                  )}
                </div>
              ) : null}
              <div className="max-w-[260px] mx-auto mt-4">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#ffb923] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-[#ffc64a] focus:outline-none focus:ring focus:ring-[#ffb923]/5 focus-visible:outline-none focus-visible:ring focus-visible:ring-[#ffb923]/5 transition-colors duration-150"
                >
                  {t("Verify_account")}{" "}
                </button>
              </div>
            </form>
            <div className="text-xs text-slate-500 mt-4">
              {t("dont_receive_code")}{" "}
              <a
                onClick={handleReset}
                className="font-medium cursor-pointer  text-indigo-500 hover:text-indigo-600"
              >
                {t("Resend")}{" "}
              </a>
            </div>
          </div>

          <ToastContainer />
        </div>
        :<Loading />

      )}
    </>
  );
};

export default Verify_email;
