import React from "react";
import { Link } from "react-router-dom";
import travel from "../../assets/image/travel.jpg";
import { useTranslation } from "react-i18next";
const Travel = () => {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/services/Travel-Tourism">
        <div className="flex justify-center border in-h-full max-w-full border-gray-500 rounded-lg bg-white">
          <div className="relative max-w-full min-w-full">
            <div className="cleaning h-[200px] rounded-lg relative">
              <img
                src={travel}
                alt="karbein - services" title='karbein - services'                className="h-[100%] w-full object-cover rounded-lg"
              />
              <h1 className="absolute inset-0 flex items-center text-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 rounded-lg">


{t("Travel_Tourism")}              </h1>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default Travel;
