import { CV1_DETAILS } from "../action/cv-action";
import { ADD_PROPERTY, DETAILS, DETAILS1, PROPERTY, SHOW_PROPERTY, UPDATE_PROPERTY } from "../action/property-action";


const enitialState={
color:[],
set_color:[],
set_font:[],
set_line_height:[],
set_design_id:[],
show_property:[],
details:[],
details1:[],

UPDATE_PROPERTY
}
  export const  property_reducer = (state=enitialState , action) => {
//ADD user
switch (action.type) {
  //add
    case PROPERTY: 
      return {...state ,
        color:action.color ,
                set_font:action.font ,
        set_line_height:action.line_height ,
        set_design_id:action.design_id ,

      }
  case ADD_PROPERTY: 
      return {...state ,
        set_color:action.color ,
      }
  case SHOW_PROPERTY: 
      return {...state ,
        show_property:action.payload,
      }
       case CV1_DETAILS: 
      return {...state ,
        details:action.payload,
      }
       case DETAILS1: 
      return {...state ,
        details1:action.payload,
      }
      case UPDATE_PROPERTY:
          return {...state ,
        UPDATE_PROPERTY:action.payload,
      }

    default:
     return state;
  }
  }
