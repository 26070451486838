import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { refound } from "../../../redux/action/auth-action";
import { lecturers_get } from "../../../redux/action/lecturers-action";
import Loading from "../../../sub component/show design data/loading";
import { user_action } from "../../../redux/action/cv-action";
import Error404 from "../../error page/error404";
import Admin_nav from "../../../app bar/admin-nav";

const RefundForm = () => {
  const [paymentId, setPaymentId] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(refound(paymentId));
  };
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
            {loading ? null : <Admin_nav />}
            <div className="flex flex-col flex-1 overflow-y-auto bg-gray-100">

            {loading ? (
        <Loading />
      ) : (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <div className="container py-16">
            <ToastContainer />
            <div className="max-w-md mx-auto shadow-lg px-6 py-8 rounded-lg bg-white">
              <h2 className="text-2xl font-semibold mb-4">Refund Payment</h2>
              <p className="text-gray-600 mb-6">
                Enter the payment ID to process a refund.
              </p>
              <form onSubmit={handleSubmit}>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}
                <div className="space-y-4">
                  <div>
                    <label
                      htmlFor="paymentId"
                      className="block text-gray-600 mb-2"
                    >
                      Payment ID
                    </label>
                    <input
                      type="text"
                      name="paymentId"
                      id="paymentId"
                      value={paymentId}
                      onChange={(e) => setPaymentId(e.target.value)}
                      className="block w-full border border-gray-300 px-4 py-3 text-gray-600 text-sm rounded focus:ring-0 focus:border-teal-500 placeholder-gray-400"
                      placeholder="Enter payment ID"
                      required
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className={`block w-full py-2 text-center text-white bg-[#ffb923] border border-[#c0912b] rounded hover:bg-[#ffc64a] transition ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Submit Refund"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
            </div>

    
    </div>
  );
};

export default RefundForm;
