import axios from "axios";
import Cookies from "js-cookie";
import {
  toast
} from "react-toastify";

export const SERVICESINSERT = "SERVICESINSERT";

export const services_insert = (
  image,
  title,
  delevery,
  type,
  description,
  phone,
  email,
  country,
  state,
  city,
  price,
  currency,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();

      formData.append("image", image);
      formData.append("title", title);
      formData.append("delevery", delevery);
      formData.append("type", type);
      formData.append("description", description);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("price", price);
      formData.append("currency", currency);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/services/insert`,
        formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const  isInserted=true

      const status = response.data.data;
      dispatch({
        type: SERVICESINSERT,
        payload: {
          response,
          status,
          isInserted
        }
      });
      
      toast.success(t("Created_successfully")); // Using translation function passed as parameter
    setTimeout(() => {
          navigate("/services/Profile"); // Navigate to /home after the timeout
      }, 2000); 
    } catch (error) {
      const  isInserted=false

      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: SERVICESINSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly

    }
  };
};

//admin insert
export const SERVICES_ADMIN_INSERT = "SERVICES_ADMIN_INSERT";

export const services_admin_insert = (
  image,
  title,
  delevery,
  type,
  description,
  phone,
  email,
  country,
  state,
  city,
  price,
  currency,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();

      formData.append("image", image);
      formData.append("title", title);
      formData.append("delevery", delevery);
      formData.append("type", type);
      formData.append("description", description);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("price", price);
      formData.append("currency", currency);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/services/insert`,
        formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const  isInserted=true

      const status = response.data.data;
      dispatch({
        type: SERVICESINSERT,
        payload: {
          response,
          status,
          isInserted
        }
      });
      toast.success(t("Created_successfully")); // Using translation function passed as parameter

    } catch (error) {
      const  isInserted=false

      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: SERVICES_ADMIN_INSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly

    }
  };
};
export const SERVICESUPDATE = "SERVICESUPDATE";

export const services_update = (
  id,
  image,
  title,
  delevery,
  type,
  description,
  phone,
  email,
  price,
  currency,
  t,
  navigate

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();

      formData.append("image", image);
      formData.append("title", title);
      formData.append("delevery", delevery);
      formData.append("type", type);
      formData.append("description", description);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("price", price);
      formData.append("currency", currency);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/services/update`,
        formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const status = response.data.data;
      dispatch({
        type: SERVICESUPDATE,
        payload: {
          response,
          status
        }
      });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter

    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter
    }
  };
};


//admin

export const SERVICES_ADMIN_UPDATE = "SERVICESUPDATE";

export const services_admin_update = (
  id,
  image,
  title,
  delevery,
  type,
  description,
  phone,
  email,
  price,
  currency,
  t,
  navigate

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();

      formData.append("image", image);
      formData.append("title", title);
      formData.append("delevery", delevery);
      formData.append("type", type);
      formData.append("description", description);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("price", price);
      formData.append("currency", currency);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/services/update/admin/${id}`,
        formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const status = response.data.data;
      dispatch({
        type: SERVICES_ADMIN_UPDATE,
        payload: {
          response,
          status
        }
      });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter

    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter
    }
  };
};


export const SERVICES_DELETE = "SERVICES_DELETE";
export const services_delete = (
  id,
  t
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/services/delete/${id}`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }, {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({
        type: SERVICES_DELETE,
        payload: {
          response,
          status
        }
      });
    
    } catch (error) {}
  };
};


export const CLEANING_GET = "CLEANING_GET";
export const cleaning_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/cleaning
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: CLEANING_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};


//country search
export const SEARCHCOUNTRY = "SEARCHCOUNTRY";
export const search_country = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/cleaning?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCHCOUNTRY,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCHCOUNTRY,
        payload: error
      });
    }
  };
};

export const PAGCLEANING = "PAGCLEANING";
export const page_services = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/cleaning?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGCLEANING,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
export const HOME_APPLIANCE_GET = "HOME_APPLIANCE_GET";
export const home_appliance_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/home-appliance-repairs
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: HOME_APPLIANCE_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Home Appliance Repairs by Country
export const SEARCH_HOME_APPLIANCE = "SEARCH_HOME_APPLIANCE";
export const home_appliance_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/home-appliance-repairs?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_HOME_APPLIANCE,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_HOME_APPLIANCE,
        payload: error
      });
    }
  };
};

// Pagination for Home Appliance Repairs
export const PAGE_HOME_APPLIANCE = "PAGE_HOME_APPLIANCE";
export const home_appliance_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/home-appliance-repairs?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_HOME_APPLIANCE,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};



// Vehicle Maintenance

export const VEHICLE_GET = "VEHICLE_GET";
export const vehicle_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/vehicle-maintenance
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: VEHICLE_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};


//country search
export const SEARCHVEHICLE = "SEARCHVEHICLE";
export const vehicle_country = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/vehicle-maintenance?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCHVEHICLE,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCHVEHICLE,
        payload: error
      });
    }
  };
};


export const PAGEVEHICLE = "PAGEVEHICLE";
export const page_vehicle = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/vehicle-maintenance?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGEVEHICLE,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};


// Construction of Houses
export const CONSTRUCTION_GET = "CONSTRUCTION_GET";
export const construction_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/construction-of-houses
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: CONSTRUCTION_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Construction of Houses by Country
export const SEARCH_CONSTRUCTION = "SEARCH_CONSTRUCTION";
export const construction_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/construction-of-houses?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_CONSTRUCTION,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_CONSTRUCTION,
        payload: error
      });
    }
  };
};

// Pagination for Construction of Houses
export const PAGE_CONSTRUCTION = "PAGE_CONSTRUCTION";
export const construction_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/construction-of-houses?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_CONSTRUCTION,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
// Garden Maintenance
export const GARDEN_GET = "GARDEN_GET";
export const garden_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/garden-maintenance
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: GARDEN_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Garden Maintenance by Country
export const SEARCH_GARDEN = "SEARCH_GARDEN";
export const garden_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/garden-maintenance?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_GARDEN,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_GARDEN,
        payload: error
      });
    }
  };
};

// Pagination for Garden Maintenance
export const PAGE_GARDEN = "PAGE_GARDEN";
export const garden_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/garden-maintenance?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_GARDEN,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};


// Car Wash
export const CAR_WASH_GET = "CAR_WASH_GET";
export const car_wash_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/car-wash
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: CAR_WASH_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Car Wash by Country
export const SEARCH_CAR_WASH = "SEARCH_CAR_WASH";
export const car_wash_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/car-wash?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_CAR_WASH,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_CAR_WASH,
        payload: error
      });
    }
  };
};

// Pagination for Car Wash
export const PAGE_CAR_WASH = "PAGE_CAR_WASH";
export const car_wash_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/car-wash?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_CAR_WASH,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};

// Carpet Services
export const CARPET_GET = "CARPET_GET";
export const carpet_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/carpet
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: CARPET_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Carpet Services by Country
export const SEARCH_CARPET = "SEARCH_CARPET";
export const carpet_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/carpet?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_CARPET,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_CARPET,
        payload: error
      });
    }
  };
};

// Pagination for Carpet Services
export const PAGE_CARPET = "PAGE_CARPET";
export const carpet_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/carpet?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_CARPET,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};




// Post Services
export const POST_GET = "POST_GET";
export const post_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        ` ${process.env.REACT_APP_URL}/api/services/post`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: POST_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Post Services by Country
export const SEARCH_POST = "SEARCH_POST";
export const post_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/post?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_POST,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_POST,
        payload: error
      });
    }
  };
};

// Pagination for Post Services
export const PAGE_POST = "PAGE_POST";
export const post_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/post?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_POST,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};

// Hairdressing Services
export const HAIRDRESSING_GET = "HAIRDRESSING_GET";
export const hairdressing_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/hairdressing`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: HAIRDRESSING_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Hairdressing Services by Country
export const SEARCH_HAIRDRESSING = "SEARCH_HAIRDRESSING";
export const hairdressing_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/hairdressing?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_HAIRDRESSING,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_HAIRDRESSING,
        payload: error
      });
    }
  };
};

// Pagination for Hairdressing Services
export const PAGE_HAIRDRESSING = "PAGE_HAIRDRESSING";
export const hairdressing_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/hairdressing?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_HAIRDRESSING,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};


// Skincare Treatments
export const SKINCARE_GET = "SKINCARE_GET";
export const skincare_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `          ${process.env.REACT_APP_URL}/api/services/skincare-treatments
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: SKINCARE_GET,
        payload: {
          data,
          total_pages, 
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Skincare Treatments Services by Country
export const SEARCH_SKINCARE = "SEARCH_SKINCARE";
export const skincare_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/skincare-treatments?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_SKINCARE,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_SKINCARE,
        payload: error
      });
    }
  };
};

// Pagination for Skincare Treatments Services
export const PAGE_SKINCARE = "PAGE_SKINCARE";
export const skincare_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/skincare-treatments?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_SKINCARE,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};

// Makeup Services
export const MAKEUP_GET = "MAKEUP_GET";
export const makeup_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/makeup
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages
      const total_services = response.data.data.total;

      dispatch({
        type: MAKEUP_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Makeup Services by Country
export const SEARCH_MAKEUP = "SEARCH_MAKEUP";
export const makeup_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/makeup?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_MAKEUP,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_MAKEUP,
        payload: error
      });
    }
  };
};

// Pagination for Makeup Services
export const PAGE_MAKEUP = "PAGE_MAKEUP";
export const makeup_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/makeup?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_MAKEUP,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};

// Nail Services
export const NAIL_GET = "NAIL_GET";
export const nail_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/nail
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: NAIL_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Nail Services by Country
export const SEARCH_NAIL = "SEARCH_NAIL";
export const nail_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/nail?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_NAIL,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_NAIL,
        payload: error
      });
    }
  };
};

// Pagination for Nail Services
export const PAGE_NAIL = "PAGE_NAIL";
export const nail_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/nail?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_NAIL,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
// Veterinary Services
export const VETERINARY_GET = "VETERINARY_GET";
export const veterinary_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/veterinary
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: VETERINARY_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Veterinary Services by Country
export const SEARCH_VETERINARY = "SEARCH_VETERINARY";
export const veterinary_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/veterinary?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_VETERINARY,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_VETERINARY,
        payload: error
      });
    }
  };
};

// Pagination for Veterinary Services
export const PAGE_VETERINARY = "PAGE_VETERINARY";
export const veterinary_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/veterinary?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_VETERINARY,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
// Legal Consultation Services
export const LEGAL_CONSULTATION_GET = "LEGAL_CONSULTATION_GET";
export const legal_consultation_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/legal-consultation
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: LEGAL_CONSULTATION_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

// Search Legal Consultation Services by Country
export const SEARCH_LEGAL_CONSULTATION = "SEARCH_LEGAL_CONSULTATION";
export const legal_consultation_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/legal-consultation?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCH_LEGAL_CONSULTATION,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_LEGAL_CONSULTATION,
        payload: error
      });
    }
  };
};

// Pagination for Legal Consultation Services
export const PAGE_LEGAL_CONSULTATION = "PAGE_LEGAL_CONSULTATION";
export const legal_consultation_paginate = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/legal-consultation?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGE_LEGAL_CONSULTATION,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
export const PHOTOGRAPHY_SERVICES_GET = "PHOTOGRAPHY_SERVICES_GET";
export const photography_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/photography
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: PHOTOGRAPHY_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_PHOTOGRAPHY_SERVICES = "SEARCH_PHOTOGRAPHY_SERVICES";
export const photography_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/photography?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SEARCH_PHOTOGRAPHY_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_PHOTOGRAPHY_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_PHOTOGRAPHY_SERVICES = "PAGE_PHOTOGRAPHY_SERVICES";
export const photography_services_paginate = (page, country = " ", city = " ",stateSearch=" ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/photography?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_PHOTOGRAPHY_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_PHOTOGRAPHY_SERVICES,
        payload: error
      });
    }
  };
};
export const TIRE_REPLACEMENT_SERVICES_GET = "TIRE_REPLACEMENT_SERVICES_GET";
export const tire_replacement_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `       ${process.env.REACT_APP_URL}/api/services/tire-replacement
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: TIRE_REPLACEMENT_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_TIRE_REPLACEMENT_SERVICES = "SEARCH_TIRE_REPLACEMENT_SERVICES";
export const tire_replacement_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/tire-replacement?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      
      dispatch({
        type: SEARCH_TIRE_REPLACEMENT_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_TIRE_REPLACEMENT_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_TIRE_REPLACEMENT_SERVICES = "PAGE_TIRE_REPLACEMENT_SERVICES";
export const tire_replacement_services_paginate = (page, country = " ", city = " ",stateSearch=" ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/tire-replacement?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_TIRE_REPLACEMENT_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_TIRE_REPLACEMENT_SERVICES,
        payload: error
      });
    }
  };
};

export const FREIGHT_FORWARDING_SERVICES_GET = "FREIGHT_FORWARDING_SERVICES_GET";
export const freight_forwarding_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/freight-forwarding
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: FREIGHT_FORWARDING_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_FREIGHT_FORWARDING_SERVICES = "SEARCH_FREIGHT_FORWARDING_SERVICES";
export const freight_forwarding_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/freight-forwarding?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SEARCH_FREIGHT_FORWARDING_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_FREIGHT_FORWARDING_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_FREIGHT_FORWARDING_SERVICES = "PAGE_FREIGHT_FORWARDING_SERVICES";
export const freight_forwarding_services_paginate = (page, country = " ", city = " ",state="") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/freight-forwarding?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${state}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_FREIGHT_FORWARDING_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_FREIGHT_FORWARDING_SERVICES,
        payload: error
      });
    }
  };
};
export const ELECTRONIC_SERVICES_GET = "ELECTRONIC_SERVICES_GET";
export const electronic_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `    ${process.env.REACT_APP_URL}/api/services/Electronic
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: ELECTRONIC_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_ELECTRONIC_SERVICES = "SEARCH_ELECTRONIC_SERVICES";
export const electronic_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/Electronic?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SEARCH_ELECTRONIC_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_ELECTRONIC_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_ELECTRONIC_SERVICES = "PAGE_ELECTRONIC_SERVICES";
export const electronic_services_paginate = (page, country = " ", city = " ",stateSearch="") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/Electronic?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_ELECTRONIC_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_ELECTRONIC_SERVICES,
        payload: error
      });
    }
  };
};

export const BATTERY_SERVICES_GET = "BATTERY_SERVICES_GET";
export const battery_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/battery
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: BATTERY_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_BATTERY_SERVICES = "SEARCH_BATTERY_SERVICES";
export const battery_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/battery?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SEARCH_BATTERY_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_BATTERY_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_BATTERY_SERVICES = "PAGE_BATTERY_SERVICES";
export const battery_services_paginate = (page, country = " ", city = " ",stateSearch=" ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/battery?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_BATTERY_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_BATTERY_SERVICES,
        payload: error
      });
    }
  };
};


export const ELECTRICAL_GET = "ELECTRICAL_GET";
export const electrical_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `      ${process.env.REACT_APP_URL}/api/services/electrical-repairs
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: ELECTRICAL_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};


//country search
export const SEARCHELECTRICAL = "SEARCHELECTRICAL";
export const electrical_country = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/electrical-repairs?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: SEARCHELECTRICAL,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCHELECTRICAL,
        payload: error
      });
    }
  };
};


export const PAGEELECTRICAL = "PAGEELECTRICAL";
export const page_electrical = (page, country = " ", city = " ", stateSearch = " ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/electrical-repairs?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: PAGEELECTRICAL,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};









export const TRAVEL_TOURISM_SERVICES_GET = "TRAVEL_TOURISM_SERVICES_GET";
export const travel_tourism_services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `        ${process.env.REACT_APP_URL}/api/services/Travel-Tourism
`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      const total_services = response.data.data.total;

      dispatch({
        type: TRAVEL_TOURISM_SERVICES_GET,
        payload: {
          data,
          total_pages,
          total_services
        }
      });
    } catch (error) {}
  };
};

export const SEARCH_TRAVEL_TOURISM_SERVICES = "SEARCH_TRAVEL_TOURISM_SERVICES";
export const travel_tourism_services_search = (country, stateSearch, citySearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/Travel-Tourism?countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SEARCH_TRAVEL_TOURISM_SERVICES,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SEARCH_TRAVEL_TOURISM_SERVICES,
        payload: error
      });
    }
  };
};

export const PAGE_TRAVEL_TOURISM_SERVICES = "PAGE_TRAVEL_TOURISM_SERVICES";
export const travel_tourism_services_paginate = (page, country = " ", city = " ",stateSearch=" ") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/Travel-Tourism?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      dispatch({
        type: PAGE_TRAVEL_TOURISM_SERVICES,
        payload: {
          data
        }
      });
    } catch (error) {
      dispatch({
        type: PAGE_TRAVEL_TOURISM_SERVICES,
        payload: error
      });
    }
  };
};


export const TYPE_SEARCHVALUE = "TYPE_SEARCHVALUE";
export const type_search_value = (search) => {
  return {
    type: TYPE_SEARCHVALUE,
    search: search,
  };
};

export const CITY_SEARCHVALUE = "CITY_SEARCHVALUE";
export const city_search_value = (search) => {
  return {
    type: CITY_SEARCHVALUE,
    search: search,
  };
};
export const COUNTRY_SEARCHVALUE = "COUNTRY_SEARCHVALUE";
export const country_search_value = (search) => {
  return {
    type: COUNTRY_SEARCHVALUE,
    search: search,
  };
};
export const STATE_SEARCHVALUE = "STATE_SEARCHVALUE";
export const state_search_value = (search) => {
  return {
    type: STATE_SEARCHVALUE,
    search: search,
  };
};
//freeelancer update details
export const SERVICESUPDATEDETAILS = "SERVICESUPDATEDETAILS";
export const services_update_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/update/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      dispatch({
        type: SERVICESUPDATEDETAILS,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};
//freeelancer update details
export const SERVICESDETAILS = "SERVICESDETAILS";
export const services_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      dispatch({
        type: SERVICESDETAILS,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};



export const SERVICESGET = "SERVICESGET";
export const services_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SERVICESGET,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SERVICESGET,
        payload: error
      });
    }
  };
};

//pagination  for  get all data for afmin panel

export const SERVICESPAGE = "SERVICESPAGE";
export const services_page = (page, country = " ", city = " ", stateSearch = " ", typeSearch = "") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/get?page=${page}&countrySearch=${country}&citySearch=${city}&stateSearch=${stateSearch}&typeSearch=${typeSearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({
        type: SERVICESPAGE,
        payload: {
          data
        }
      });
    } catch (error) {}
  };
};









export const SERVICES_SEARCH = "SERVICES_SEARCH";
export const services_search = (country, stateSearch, citySearch, typeSearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/get?typeSearch=${typeSearch}&countrySearch=${country}&stateSearch=${stateSearch}&citySearch=${citySearch}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SERVICES_SEARCH,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SERVICES_SEARCH,
        payload: error
      });
    }
  };
};
export const SERVICES_GET_SEARCH = "SERVICES_GET_SEARCH";
export const services_get_search = (country, stateSearch, citySearch, typeSearch) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/services/search_get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data;
      const total_pages = response.data.total_pages;
      dispatch({
        type: SERVICES_GET_SEARCH,
        payload: {
          data,
          total_pages
        }
      });
    } catch (error) {
      dispatch({
        type: SERVICES_GET_SEARCH,
        payload: error
      });
    }
  };
};