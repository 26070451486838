import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import brand_image from "../../../assets/image/Brand-photos.png";

import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

import Cookies from "js-cookie";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { user_action } from "../../../redux/action/cv-action";
import { useTranslation } from "react-i18next";
import { login } from "../../../redux/action/auth-action";
import {
  experience_search_value,
  freelance_delete,
  freelancer_get,
  freelancer_search_get,
  functiona_area_search_value,
  search_freelancer,
  search_value,
} from "../../../redux/action/freelancer-action";
import Loading from "../../../sub component/show design data/loading";
import Search_loading from "../../main-pages/search loading/search-loading";
import HomeFooter from "../../../sub component/home/footer";
import PaginationComponent from "../../main-pages/freelancer/freeLancer_pagoinations";
import Swal from "sweetalert2";
import Admin_nav from "../../../app bar/admin-nav";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import Error404 from "../../error page/error404";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Admin_freeLancers = () => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [title, setTitle] = useState([]);
  const [experience, setExperience] = useState([]);
  const [ShowFilterBar, setShowFilterBar] = useState();

  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);
  const freeLance_searchData = useSelector(
    (state) => state.freelancer.freelancer_search_get
  );

  const experience_data = [
    { value: t("one_to_3_years"), label: "1 to 3 years" },
    { value: t("four_to_6_years"), label: "4 to 6 years" },
    { value: t("more_than_6"), label: "More than 6 years" },
  ];

  const freelancer_search = useSelector(
    (state) => state.freelancer.freelancer_search
  );
  const freelancer_page = useSelector(
    (state) => state.freelancer.freelancer_page
  );

  useEffect(() => {
    setData(freeLanceData);
  }, [freeLanceData]);

  useEffect(() => {
    setSearchData(freeLance_searchData);
  }, [freeLance_searchData]);

  useEffect(() => {
    setData(freelancer_search);
  }, [freelancer_search]);
  useEffect(() => {
    setData(freelancer_page);
  }, [freelancer_page]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(freelancer_get());
      await dispatch(freelancer_search_get());

      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  //accordion
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [searchLoading, setSearchLoading] = useState(false);

  const searchSubmit = async () => {
    setSearchLoading(true);
    await dispatch(search_freelancer(search, title, experience));
    setSearchLoading(false);
    dispatch(search_value(search));

    // Set loading to false after dispatch
  };
  useEffect(() => {
    dispatch(user_action());
  }, []);

  const user_id = useSelector((state) => state.cv.user.id);

  const searchPerformed = useSelector(
    (state) => state.freelancer.searchPerformed
  );
  const handlePhoneCall = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };

  const handleSendEmail = (email) => {
    window.open(`mailto:${email}`);
  };
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const Search = async () => {
      setSearchLoading(true);
      await dispatch(search_freelancer(search, title, experience));
      setSearchLoading(false);
      dispatch(functiona_area_search_value(title));
      dispatch(experience_search_value(experience));
    };

    //  ama yakam jar  esh naka tanha agar value yakek la searchakan gora inja esh akat
    if (title !== "" || experience !== "") {
      Search();
    }
  }, [title, experience]);

  const shownTitle = new Set();
  const shownPhoneTitle = new Set();

  const shownExperience = new Set();

  const TitleCount = searchData.reduce((acc, item) => {
    acc[item.functional_area] = (acc[item.functional_area] || 0) + 1;
    return acc;
  }, {});
  const experienceCount = searchData.reduce((acc, item) => {
    acc[item.experience] = (acc[item.experience] || 0) + 1;
    return acc;
  }, {});

  const handleTitleChange = (value) => {
    if (title === value) {
      // If the same checkbox is clicked again, uncheck it
      setTitle(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setTitle(value);
    }
  };

  const handleExperienceChange = (value) => {
    if (experience === value) {
      // If the same checkbox is clicked again, uncheck it
      setExperience(" ");
    } else {
      // Otherwise, check the clicked checkbox
      setExperience(value);
    }
  };
  const services_data = useSelector((state) => state.lecturers.lecturer_get);
  const [lecturerData, setLecturerData] = useState([]);
  const [actionsCompleted, setActionsCompleted] = useState(false);

  const user = freeLanceData.find((user) => user.user_id === `${user_id}`);
  const [showFilter, setShowFilter] = useState(false);

  // Function to toggle filter options visibility
  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(freelance_delete(id));
          await dispatch(search_freelancer("", title, experience));

          await dispatch(freelancer_search_get());

          Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "There was an error deleting your file.",
            icon: "error",
          });
        }
      }
    });
  };

  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen">
    {loading ? null : <Admin_nav />}
    <div className="flex flex-col flex-1 overflow-y-auto bg-gray-100">
      <div>
        {loading ? (
          <Loading />
        ) : (
          <div className="">
            {searchLoading ? (
              <Search_loading />
            ) : (
              <div className="pt-[100px] px-[2%]">
                <form
                            onSubmit={searchSubmit}
                            className="flex items-center max-w-[260px] mx-auto"
                          >
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                              <button
                                type="submit"
                                className="max-w-[40px]  justify-center   absolute inset-y-0  start-0 flex items-center "
                                style={{
                                  marginRight:
                                    i18n.language === "ku" ||
                                    i18n.language === "ar"
                                      ? "0px"
                                      : "0px",
                                  marginLeft:
                                    i18n.language === "ku" ||
                                    i18n.language === "ar"
                                      ? "0px"
                                      : "10px",
                                }}
                              >
                                <SearchIcon />
                              </button>
                              <input
                                type="text"
                                id="simple-search"
                                className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder={t("name")}
                                required
                                value={search}
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                }}
                              />
                            </div>

                  
                          </form>
                <div className="text-gray-900 mt-2 flex flex-col justify-center">
                  {/* Add other search options here */}
              

                  <div className="w-full overflow-x-auto mb-[50px]">
                    <div className="pb-3 flex justify-center"></div>
                    <table className="min-w-full bg-white border rounded-lg shadow overflow-hidden">
                      <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <tr>
                          <th className="py-3 px-6 text-left">id</th>
                          <th className="py-3 px-6 text-left">image</th>

                          <th className="py-3 px-6 text-left"> name</th>
                          <th className="py-3 px-6 text-left"> Email</th>
                          <th className="py-3 px-6 text-left">phone</th>
                          <th className="py-3 px-6 text-left">experience</th>
                          <th className="py-3 px-6 text-left">functional area</th>
                          <th className="py-3 px-6 text-left">per hour price</th>
                          <th className="py-3 px-6 text-left">Edit</th>

                        </tr>
                      </thead>
                      <tbody className="text-gray-600 text-sm font-light">
                        {data.map((data, index) => (
                          <tr
                            key={index}
                            className="border-b hover:bg-gray-100"
                          >
                            <td className="py-3 px-6 text-left">{data.id}</td>
                            <td className="py-3 px-6 text-left">

                              <img               
                              
                              
className=" min-h-[70px]  max-h-[70px] p-1 rounded-[10px] min-w-[70px] max-w-[70px]"


 src={data.image ? data.image : brand_image}
 alt="" />
                            </td>

                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.name}
                              </span>
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.email}
                              </span>
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.phone} 
                              </span>
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.experience} Years 
                              </span>
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.functional_area}
                              </span>
                            </td>
                            <td className="py-3 px-6 text-left whitespace-nowrap">
                              <span className="font-medium">
                                {data.per_hour_price} {data.currency} /Hour

                              </span>
                            </td>
                            <td className="py-3 px-6 text-left">
                            <div className="flex">
<Link to={`/Admin-panel/freelance/update/${data.id}`}>
<button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Update</button>

</Link>                               <button onClick={()=>
  handleDelete(data.id)
} type="button" class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Delete</button>

                               </div>                            </td>          
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {data.length === 0 && (
                          <div className="search-message my-[30px] flex justify-center w-full text-center items-center h-[100%] main-freeLancer-container-text">
                            {t("not_haveData")}
                          </div>
                        )}
                  </div>
                </div>
                <div className="flex justify-center w-full mb-[50px]">
                {
          data.length>0?
          <PaginationComponent />
          :null

        }
      </div>
      
              </div>
            )}
          </div>
        )}
      </div>

       
    </div>
  </div>
  );
};

export default Admin_freeLancers;
