import { useTranslation } from "react-i18next";
import "../../assets/style/pricing.css";
import { Link } from "react-router-dom";
const Pricing = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full  flex justify-center pt-[100px] items-center min-h-screen">
      <div
        style={{
          fontFamily: "Poppins, sans-serif",
        }}
        className="   max-w-[1800px] freelance-div justify-center  mx-auto "
      >
        <div
          style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          {/* <h1 className="text-center  works_title  whitespace-normal break-words  leading-tight font-[500] min-w-[100%] mb-[30px]  tracking-tight text-gray-900 ">
            {t("pricing")}
          </h1> */}
          <div>
            <div className="flex  min-w-full  justify-center  ">
              <div className="   min-w-[100%] flex  justify-center    gap-[40px] ">
                <section className="flex flex-wrap  items-center justify-center  pb-10">
                  <div
                    className="p-4 sm:px-10 flex  justify-center items-center text-base h-100vh mx-auto"
                    id="pricing"
                  >
                    <div className="isolate mx-auto grid  pricing-grid grid-cols-3 gap-8  max-w-none lg:grid-cols-3">
                      <div className="ring-1  ring-gray-200 rounded-3xl p-8 xl:p-10  max-w-[360px]">
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id="tier-standard"
                            className="text-gray-900 text-2xl font-semibold leading-8"
                          >
                            {t("Free")}
                          </h3>
                        </div>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $0
                          </span>
                        </p>
                        <Link
                     to={'/Free-plan'}
                          className="text-[#ffb923] hover:bg-[#ffb923]  hover:text-white ring-1 ring-inset ring-[#ffdd94]  mt-6 block rounded-md py-2 px-3 text-center text-base font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
            {t("Buy_Now")}
            </Link>
                        <ul
                          role="list"
                          className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                        >
                  

                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Create_CV")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_freelancer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_lecturer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Add_service")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Free_content")}
                          </li>
                        </ul>
                      </div>

                      <div className="ring-2 ring-[#ffdd94] rounded-3xl p-8 xl:p-10 max-w-[360px]">
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id="tier-extended"
                            className="text-[#ffb923] text-2xl font-semibold leading-8"
                          >
                            {t("Monthly")}
                          </h3>
                          <p className="rounded-full bg-[#ffb923]/10 px-2.5 py-1 text-xs font-semibold leading-5 text-[#ffb923]">
                            {t("Most_popular")}
                          </p>
                        </div>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="line-through text-2xl font-sans text-gray-500/70">
                            $5
                          </span>
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $3
                          </span>
                        </p>
                        <Link
                     to={'/Monthly-plan'}
                     aria-describedby="tier-extended"
                          className="bg-[#ffb923]   text-white hover:text-red-900 hover:bg-[#ffc64a]  shadow-sm mt-6 block rounded-md py-2 px-3 text-center text-base font-medium  "
                        >
            {t("Buy_Now")}
            </Link>
                        <ul
                          role="list"
                          className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                        >
                              <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Create_CV")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_freelancer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_lecturer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Add_service")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>

                            {t("Monthly_content")}
                          </li>
                        </ul>
                      </div>

                      <div className="ring-1 ring-gray-200 rounded-3xl p-8 xl:p-10 max-w-[360px]">
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id="tier-standard"
                            className="text-gray-900 text-2xl font-semibold leading-8"
                          >
                            {t("Yearly")}
                          </h3>
                        </div>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="line-through text-2xl font-sans text-gray-500/70">
                            $40
                          </span>
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            $28
                          </span>
                        </p>
                        <Link
                     to={'/Yearly-plan'}
                     aria-describedby="tier-standard"
                          className="text-[#ffb923] hover:bg-[#ffb923]  hover:text-white ring-1 ring-inset ring-[#ffdd94]  mt-6 block rounded-md py-2 px-3 text-center text-base font-medium leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
            {t("Buy_Now")}
            </Link>
                        <ul
                          role="list"
                          className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                        >
                          
                              <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Create_CV")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_freelancer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("add_lecturer")}
                          </li>
                          <li className="flex gap-x-3 text-base">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                            {t("Add_service")}
                          </li>
                          <li className="flex gap-x-3 text-base break-words  max-w-[100%]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-[#ffb923]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>

                            {t("Yearly_content")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
