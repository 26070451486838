import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Lang_swith = () => {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", name: "English" },
    { code: "ar", name: "العربية" },
    { code: "ku", name: "كوردی" },
  ];
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      changeLanguage(storedLanguage);
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="relative inline-block text-left">
      <div>
        <div
          onClick={toggleDropdown}
          className="i flex items-center gap-2 text-[15px] cursor-pointer  mr-[-10px]   "
          id="options-menu"
          aria-haspopup="true"
        >
          <span>
            
          {i18n.language==="ku"?'KU':i18n.language==="ar"?'AR':'EN'    }
          </span> {/* Toggle text */}
          {/* Arrow Icon */}
          {isOpen ? (
            <svg
            className="w-3 h-3 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 7 7.674 1.3a.91.91 0 0 0-1.348 0L1 7"
              />
            </svg>
          ) : (
            <svg
            className="w-3 h-3 text-gray-600 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 8"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
              />
            </svg>
          )}
        </div>
      </div>

      {/* Dropdown menu, show/hide based on state */}
      {isOpen && (
        <div
          className="origin-top-right cursor-pointer absolute right-0 mt-2 w-40 rounded shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="" role="none">
            {/* Dropdown items */}
            {languages.map((lang) => (
              <div className="gap-[50px]" key={lang.code}>
                <div
                  className={
                    lang.code === i18n.language
                      ? "bg-[#ffb923]  text-white gap-2 rounded  flex p-2   "
                      : " p-2  "
                  }
                  onClick={() => {
                    changeLanguage(lang.code);
                    setIsOpen(false); // Close dropdown after selection
                  }}
                >
                  <span className="ml-2 gap-3 ">{lang.name}</span>
                  {/* Checkmark Icon for selected item */}
                  {lang.code === i18n.language && (
                    <svg
                      className=" h-5 w-5 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Lang_swith;
