import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SelectCurrency from "react-select-currency";
import { Country, State, City } from "country-state-city";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";

import Cookies from "js-cookie";
import {
  admin_freelancer_insert,
  freelancer_get,
  freelancer_insert,
} from "../../../redux/action/freelancer-action";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";
import HomeFooter from "../../../sub component/home/footer";
import Admin_nav from "../../../app bar/admin-nav";
import Error404 from "../../error page/error404";

const Admin_freelancer_insert = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const { t, i18n } = useTranslation();
  const renderTranslatedTNamwe = () => {
    return { __html: t("long cv title") };
  };
  const [loading, setLoading] = useState(true);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setCountry(value);
  };
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [experience, setExperience] = useState("");
  const [city, setcity] = useState();
  const [description, setDescription] = useState("");
  const [functional_area, setFunctional_area] = useState("");
  const [skills, setSkills] = useState();
  const [per_hour_price, setPer_hour_price] = useState("");
  const [currency, setCurrency] = useState("");
  const [image, setImage] = useState(null);

  //user
  const [data, setData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const freeLanceData = useSelector((state) => state.freelancer.freelancer_get);
  useEffect(() => {
    setData(freeLanceData);
  }, [freeLanceData]);
  const fetchCurrencies = async () => {
    try {
      const response = await fetch(
        "https://openexchangerates.org/api/currencies.json"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch currencies");
      }
      const data = await response.json();
      const currencyList = Object.keys(data).map((code) => ({
        code,
        name: data[code],
      }));
      setCurrency(currencyList);
    } catch (error) {}
  };

  const currencies = [
    { code: "AED", name: "United Arab Emirates Dirham" },
    { code: "AFN", name: "Afghan Afghani" },
    { code: "ALL", name: "Albanian Lek" },
    { code: "AMD", name: "Armenian Dram" },
    { code: "ANG", name: "Netherlands Antillean Guilder" },
    { code: "AOA", name: "Angolan Kwanza" },
    { code: "ARS", name: "Argentine Peso" },
    { code: "AUD", name: "Australian Dollar" },
    { code: "AWG", name: "Aruban Florin" },
    { code: "AZN", name: "Azerbaijani Manat" },
    { code: "BAM", name: "Bosnia-Herzegovina Convertible Mark" },
    { code: "BBD", name: "Barbadian Dollar" },
    { code: "BDT", name: "Bangladeshi Taka" },
    { code: "BGN", name: "Bulgarian Lev" },
    { code: "BHD", name: "Bahraini Dinar" },
    { code: "BIF", name: "Burundian Franc" },
    { code: "BMD", name: "Bermudian Dollar" },
    { code: "BND", name: "Brunei Dollar" },
    { code: "BOB", name: "Bolivian Boliviano" },
    { code: "BRL", name: "Brazilian Real" },
    { code: "BSD", name: "Bahamian Dollar" },
    { code: "BTN", name: "Bhutanese Ngultrum" },
    { code: "BWP", name: "Botswana Pula" },
    { code: "BYN", name: "Belarusian Ruble" },
    { code: "BZD", name: "Belize Dollar" },
    { code: "CAD", name: "Canadian Dollar" },
    { code: "CDF", name: "Congolese Franc" },
    { code: "CHF", name: "Swiss Franc" },
    { code: "CLP", name: "Chilean Peso" },
    { code: "CNY", name: "Chinese Yuan" },
    { code: "COP", name: "Colombian Peso" },
    { code: "CRC", name: "Costa Rican Colón" },
    { code: "CUP", name: "Cuban Peso" },
    { code: "CVE", name: "Cape Verdean Escudo" },
    { code: "CZK", name: "Czech Koruna" },
    { code: "DJF", name: "Djiboutian Franc" },
    { code: "DKK", name: "Danish Krone" },
    { code: "DOP", name: "Dominican Peso" },
    { code: "DZD", name: "Algerian Dinar" },
    { code: "EGP", name: "Egyptian Pound" },
    { code: "ERN", name: "Eritrean Nakfa" },
    { code: "ETB", name: "Ethiopian Birr" },
    { code: "EUR", name: "Euro" },
    { code: "FJD", name: "Fijian Dollar" },
    { code: "FKP", name: "Falkland Islands Pound" },
    { code: "FOK", name: "Funko Coin" },
    { code: "GBP", name: "British Pound Sterling" },
    { code: "GEL", name: "Georgian Lari" },
    { code: "GGP", name: "Guernsey Pound" },
    { code: "GHS", name: "Ghanaian Cedi" },
    { code: "GIP", name: "Gibraltar Pound" },
    { code: "GMD", name: "Gambian Dalasi" },
    { code: "GNF", name: "Guinean Franc" },
    { code: "GTQ", name: "Guatemalan Quetzal" },
    { code: "GYD", name: "Guyanaese Dollar" },
    { code: "HKD", name: "Hong Kong Dollar" },
    { code: "HNL", name: "Honduran Lempira" },
    { code: "HRK", name: "Croatian Kuna" },
    { code: "HTG", name: "Haitian Gourde" },
    { code: "HUF", name: "Hungarian Forint" },
    { code: "IDR", name: "Indonesian Rupiah" },
    { code: "ILS", name: "Israeli New Shekel" },
    { code: "IMP", name: "Manx Pound" },
    { code: "INR", name: "Indian Rupee" },
    { code: "IQD", name: "Iraqi Dinar" },
    { code: "IRR", name: "Iranian Rial" },
    { code: "ISK", name: "Icelandic Króna" },
    { code: "JEP", name: "Jersey Pound" },
    { code: "JMD", name: "Jamaican Dollar" },
    { code: "JOD", name: "Jordanian Dinar" },
    { code: "JPY", name: "Japanese Yen" },
    { code: "KES", name: "Kenyan Shilling" },
    { code: "KGS", name: "Kyrgystani Som" },
    { code: "KHR", name: "Cambodian Riel" },
    { code: "KID", name: "Kid Laroi Coin" },
    { code: "KMF", name: "Comorian Franc" },
    { code: "KRW", name: "South Korean Won" },
    { code: "KWD", name: "Kuwaiti Dinar" },
    { code: "KYD", name: "Cayman Islands Dollar" },
    { code: "KZT", name: "Kazakhstani Tenge" },
    { code: "LAK", name: "Laotian Kip" },
    { code: "LBP", name: "Lebanese Pound" },
    { code: "LKR", name: "Sri Lankan Rupee" },
    { code: "LRD", name: "Liberian Dollar" },
    { code: "LSL", name: "Lesotho Loti" },
    { code: "LYD", name: "Libyan Dinar" },
    { code: "MAD", name: "Moroccan Dirham" },
    { code: "MDL", name: "Moldovan Leu" },
    { code: "MGA", name: "Malagasy Ariary" },
    { code: "MKD", name: "Macedonian Denar" },
    { code: "MMK", name: "Myanmar Kyat" },
    { code: "MNT", name: "Mongolian Tugrik" },
    { code: "MOP", name: "Macanese Pataca" },
    { code: "MRU", name: "Mauritanian Ouguiya" },
    { code: "MUR", name: "Mauritian Rupee" },
    { code: "MVR", name: "Maldivian Rufiyaa" },
    { code: "MWK", name: "Malawian Kwacha" },
    { code: "MXN", name: "Mexican Peso" },
    { code: "MYR", name: "Malaysian Ringgit" },
    { code: "MZN", name: "Mozambican Metical" },
    { code: "NAD", name: "Namibian Dollar" },
    { code: "NGN", name: "Nigerian Naira" },
    { code: "NIO", name: "Nicaraguan Córdoba" },
    { code: "NOK", name: "Norwegian Krone" },
    { code: "NPR", name: "Nepalese Rupee" },
    { code: "NZD", name: "New Zealand Dollar" },
    { code: "OMR", name: "Omani Rial" },
    { code: "PAB", name: "Panamanian Balboa" },
    { code: "PEN", name: "Peruvian Sol" },
    { code: "PGK", name: "Papua New Guinean Kina" },
    { code: "PHP", name: "Philippine Peso" },
    { code: "PKR", name: "Pakistani Rupee" },
    { code: "PLN", name: "Polish Zloty" },
    { code: "PYG", name: "Paraguayan Guarani" },
    { code: "QAR", name: "Qatari Rial" },
    { code: "RON", name: "Romanian Leu" },
    { code: "RSD", name: "Serbian Dinar" },
    { code: "RUB", name: "Russian Ruble" },
    { code: "RWF", name: "Rwandan Franc" },
    { code: "SAR", name: "Saudi Riyal" },
    { code: "SBD", name: "Solomon Islands Dollar" },
    { code: "SCR", name: "Seychellois Rupee" },
    { code: "SDG", name: "Sudanese Pound" },
    { code: "SEK", name: "Swedish Krona" },
    { code: "SGD", name: "Singapore Dollar" },
    { code: "SHP", name: "Saint Helena Pound" },
    { code: "SLL", name: "Sierra Leonean Leone" },
    { code: "SOS", name: "Somali Shilling" },
    { code: "SRD", name: "Surinamese Dollar" },
    { code: "SSP", name: "South Sudanese Pound" },
    { code: "STN", name: "São Tomé & Príncipe Dobra" },
    { code: "SYP", name: "Syrian Pound" },
    { code: "SZL", name: "Swazi Lilangeni" },
    { code: "THB", name: "Thai Baht" },
    { code: "TJS", name: "Tajikistani Somoni" },
    { code: "TMT", name: "Turkmenistani Manat" },
    { code: "TND", name: "Tunisian Dinar" },
    { code: "TOP", name: "Tongan Paʻanga" },
    { code: "TRY", name: "Turkish Lira" },
    { code: "TTD", name: "Trinidad & Tobago Dollar" },
    { code: "TVD", name: "Tuvalu Coin" },
    { code: "TWD", name: "New Taiwan Dollar" },
    { code: "TZS", name: "Tanzanian Shilling" },
    { code: "UAH", name: "Ukrainian Hryvnia" },
    { code: "UGX", name: "Ugandan Shilling" },
    { code: "USD", name: "United States Dollar" },
    { code: "UYU", name: "Uruguayan Peso" },
    { code: "UZS", name: "Uzbekistani Som" },
    { code: "VES", name: "Venezuelan Bolívar" },
    { code: "VND", name: "Vietnamese Dong" },
    { code: "VUV", name: "Vanuatu Vatu" },
    { code: "WST", name: "Samoan Tala" },
    { code: "XAF", name: "Central African CFA Franc" },
    { code: "XCD", name: "East Caribbean Dollar" },
    { code: "XDR", name: "Special Drawing Rights" },
    { code: "XOF", name: "West African CFA Franc" },
    { code: "XPF", name: "CFP Franc" },
    { code: "YER", name: "Yemeni Rial" },
    { code: "ZAR", name: "South African Rand" },
    { code: "ZMW", name: "Zambian Kwacha" },
    { code: "ZWL", name: "Zimbabwean Dollar" },
  ];

  const handleChange = (event) => {
    setCurrency(event.target.value || "");
  };

  const submit = async (e) => {
    e.preventDefault();
    await dispatch(
      admin_freelancer_insert(
        image || "",
        name,
        email,
        phone,

        selectedCountry.name,
        selectedState.name,
        selectedCity,
        experience,
        description,

        functional_area,
        skills,
        per_hour_price,
        currency,
        t,
        navigate
      )
    );
    setLoading(false);
  };
  //user id
  const user_id = useSelector((state) => state.cv.user.id);

  const handleCurrencyChange = (selectedCurrency) => {
    setCurrency(selectedCurrency);
  };
  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState("");
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.isoCode === e.target.value);
    setSelectedCountry(country);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleStateChange = (e) => {
    const state = states.find((s) => s.isoCode === e.target.value);
    setSelectedState(state);
    setSelectedCity("");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(freelancer_get());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const [findData, setfindData] = useState([]);

  const freelance_data = useSelector(
    (state) => state.freelancer.freelancer_get
  );

  useEffect(() => {
    setfindData(freelance_data);
  }, [freelance_data]);

  // useEffect(() => {
  //   // Check if lecturerData is populated and if user_id exists in lecturerData
  //   if (findData.length > 0) {
  //     const user = findData.find((user) => user.user_id === `${user_id}`);
  //     if (user) {
  //       navigate("/freelance/update"); // Navigate to "/update" route if user found
  //     } else {
  //       navigate("/freelance/add"); // Navigate to "/add" route if user not found
  //     }
  //   }
  // }, [findData, user_id]);
  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }

  return (
    <div className="flex h-screen ">
      {loading ? null : <Admin_nav />}

      <div className="flex flex-col  flex-1 overflow-y-auto ">
        {loading ? (
          <Loading />
        ) : (
          <div
            className="flex justify-center mb-[20px] min-w-[100%] pt-[100px]  w-full"
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
          >
            <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container   min-w-[90%]  flex  justify-center    gap-[40px] ">
              {/* <div className="left-details-container ">
              <div className="left-details  border rounded h-[150px]  ">
                <div className="left-details-header text-[20px] bg-[#ffb923] h-[45px]  text-white flex  font-[500]   border-top-[20px] justify-center items-center">
                  {t("Add_freelance")}{" "}
                </div>

                <div className="mx-[20px] flex  gap-[10px] flex-col text-[18px] justify-center  my-[20px] max-w-[340px] break-words">
                  <p>
                    {" "}
                    - <Link to={"/jobs"}>{t("Finding_a_job")}</Link>{" "}
                  </p>
                  <p className=" ">
                    {" "}
                    - <Link to={"/freelance"}>
                      {t("freelancers_list")}
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div> */}
              <div className=" main-card-container flex flex-col w-[900px] gap-[50px]">
                <div className="freeLancers_border max-w-[900px]   min-w-[100%] border rounded min-h-[100px] ">
                  {/* <div className="flex justify-end mt-[-100px]">
      Add freelancer
    </div> */}

                  <div className="freelancer-card-container ">
                    <div
                      className="add-input-group   w-[100%] bg-gray-100 p-[40px] rounded"
                      onSubmit={submit}
                    >
                      <form>
                        <div className="grid gap-6 mb-6 md:grid-cols-2 ">
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("image")}{" "}
                            </label>
                            <input
                              className="block w-full pr-[15px] text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              id="multiple_files"
                              type="file"
                              multiple
                              accept="image/*"
                              onChange={(e) => {
                                setImage(e.target.files[0]);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("freelancer_Name")}{" "}
                            </label>
                            <input
                              type="text"
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("freelancer_Name")}
                              required
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("phone_number")}{" "}
                            </label>
                            <PhoneInput
                              inputStyle={{
                                minHeight: "2.6rem", // Adjust the height to match other inputs
                                border: "1px solid #d2d6dc",
                                backgroundColor: "#F9FAFB",
                                paddingLeft:
                                  i18n.language === "en" ? "100px" : null,
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "100px"
                                    : "0px",

                                color: "#1a202c",
                                fontSize: "0.875rem",

                                borderRadius: "0.5rem", // Rounded corners
                                direction:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "rtl"
                                    : "ltr",

                                width: "100%",
                              }}
                              buttonStyle={{
                                borderRadius:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "0px 0.5rem 0.5rem 0px"
                                    : "0.5rem 0px 0px 0.5rem",

                                // Rounded corners
                                border: "1px solid #d2d6dc",
                                "&:hover": {},
                                width: "70px",
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "30px"
                                    : "0px",
                                // marginLeft:
                                //     i18n.language === "ku" || i18n.language === "ar" ?  "0px"
                                // :    "30px",
                              }}
                              inputClass="input_add_freelancer rtl-phone-input-button min-h-[50px]  bg-gray-900 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              buttonClass="custom-button-class" // Add this line to apply the class to the dropdown button
                              containerClass="custom-container-class" // Add this line to apply the class to the container
                              dropdownClass="custom-dropdown-class" // Add this line to apply the class to the dropdown container
                              onChange={setPhone}
                              placeholder={t("phone_number")}
                            />
                          </div>

                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">
                              {t("Email")}{" "}
                            </label>
                            <input
                              type="email"
                              id="company"
                              className="input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Email")}
                              required
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Skills")}{" "}
                            </label>
                            <input
                              type="text"
                              className="  input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Skills")}
                              required
                              onChange={(e) => {
                                setSkills(e.target.value);
                              }}
                            />
                          </div>

                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("Country")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedCountry?.isoCode || ""}
                              onChange={handleCountryChange}
                            >
                              <option value="">{t("select_country")} </option>
                              {countries.map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("State")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedState?.isoCode || ""}
                              onChange={handleStateChange}
                              disabled={!selectedCountry}
                            >
                              <option value="">{t("select_state")} </option>
                              {states.map((state) => (
                                <option
                                  key={state.isoCode}
                                  value={state.isoCode}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("City")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedCity}
                              onChange={(e) => setSelectedCity(e.target.value)}
                              disabled={!selectedCountry}
                            >
                              <option value="">{t("select_city")} </option>
                              {cities.map((city) => (
                                <option key={city.name} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("per_hour_price")}{" "}
                            </label>
                            <input
                              type="number"
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("per_hour_price")}
                              required
                              onChange={(e) => {
                                setPer_hour_price(e.target.value);
                              }}
                            />
                          </div>
                          <div className="custom-currency-select">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("currency")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={currency}
                              onChange={handleChange}
                              required
                            >
                              <option value=" ">{t("currency")} </option>
                              {currencies.map((currency) => (
                                <option
                                  key={currency.code}
                                  value={currency.code}
                                >
                                  {currency.name} ({currency.code})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            {t("Functional_Area")}{" "}
                          </label>
                          <input
                            type="text"
                            className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder={t("Functional_Area")}
                            required
                            onChange={(e) => {
                              setFunctional_area(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-6">
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("experience")} / {"Years"}
                            </label>
                            <input
                              type="number"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("experience")}
                              required
                              onChange={(e) => {
                                setExperience(e.target.value);
                              }}
                            />
                             
                          </div>{" "}
                        </div>
                        <div className="mb-6">
                          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            {t("Description")}{" "}
                          </label>
                          <textarea
                            className=" input_add_freelancer text-area border bg-gray-50"
                            //               onChange={(e) => setProfileDescription(e.target.value)}
                            //               value={profileDescription}\

                            placeholder={t("Description")}
                            required
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>

                        <button
                          type="submit"
                          className="text-white bg-[#ffb923] hover:bg-[#ffcc5f] focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          {t("send")}
                        </button>
                      </form>
                    </div>{" "}
                  </div>
                </div>

                <div className="freelancer-pagination mb-[80px]  flex justify-center   "></div>
              </div>
            </div>

            <ToastContainer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin_freelancer_insert;
